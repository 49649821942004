import { Pipe, PipeTransform } from '@angular/core';
import { CustomerArticleInterface } from '../../services/customer-article/customer-article.interface';
import { Observable } from 'rxjs';
import { CustomerArticleService } from '../../services/customer-article/customer-article.service';

@Pipe({
  name: 'getCustomerArticleById',
})
export class GetCustomerArticleByIdPipe implements PipeTransform {
  constructor(private customerArticleService: CustomerArticleService) {}

  transform(id: string): Observable<CustomerArticleInterface> {
    return this.customerArticleService.getById(id);
  }
}
