<div *ngIf="item?.oArticle" [class.2xl:pt-0]="first" class="js-tile bg-white pt-12 2xl:pt-6 pb-6 relative z-10">
  <a [routerLink]="'/article/' + item.oArticle.sArticleID | localizeUrlPathAsync | async" class="absolute inset-0 rounded-20px"></a>
  <div class="flex tablet:block z-30 mb-2 tablet:mb-0">
    <!--    <div-->
    <!--      *ngIf="cutToSize"-->
    <!--      class="js-flyout-index tablet:absolute tablet:left-0 inline-block"-->
    <!--      [ngClass]="{ 'desktop-grid:top-14 2xl:top-0': first, 'desktop-grid:top-14 2xl:top-6': !first }"-->
    <!--    >-->
    <!--      <div class="relative">-->
    <!--        <button-->
    <!--          class="js-show-flyout js-index bg-gela-black leading-tight flex items-center text-white relative rounded-default w-26px h-26px"-->
    <!--        >-->
    <!--          <span class="ifont ifont&#45;&#45;info text-3xl absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"></span>-->
    <!--        </button>-->
    <!--        <div-->
    <!--          class="js-flyout hidden px-5 pt-6 pb-5 absolute -top-7 -translate-y-1/4 bg-white left-full translate-x-2 border border-primary-light z-30 rounded-flyout shadow-flyout bg-white w-36 md:w-60"-->
    <!--        >-->
    <!--          <img src="assets/images/layout/flyout_arrow.svg" alt="" class="absolute top-8 left-0 -translate-x-full" />-->
    <!--          <p class="font-montserrat font-bold text-small mb-3.5 pr-5">Auf Mass</p>-->
    <!--          <p class="text-small mt-2.5">Lorem ipsum dolor sit amet, consectetur adipiscing elit ut.</p>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div-->
    <!--      *ngIf="promotion"-->
    <!--      [ngClass]="{-->
    <!--        'desktop-grid:top-14 2xl:top-0': first,-->
    <!--        'desktop-grid:top-14 2xl:top-6': !first,-->
    <!--        'tablet:left-10': cutToSize,-->
    <!--        'tablet:left-0': !cutToSize-->
    <!--      }"-->
    <!--    >-->
    <!--      <div class="relative inline-block">-->
    <!--        <button class="js-show-flyout px-2.5 py-5px pr-8 bg-gela-black leading-tight flex items-center text-white relative rounded-default">-->
    <!--          <span class="uppercase text-small font-montserrat font-bold">Promotion</span>-->
    <!--          <span class="ifont ifont&#45;&#45;info text-3xl absolute right-1 top-1/2 -translate-y-1/2"></span>-->
    <!--        </button>-->

    <!--        <div-->
    <!--          class="js-flyout hidden px-5 pt-6 pb-5 absolute -top-7 -translate-y-1/4 bg-white left-full translate-x-2 border border-primary-light z-30 rounded-flyout shadow-flyout bg-white w-36 lg:w-60"-->
    <!--        >-->
    <!--          <img src="assets/images/layout/flyout_arrow.svg" alt="" class="absolute top-8 left-0 -translate-x-full" />-->

    <!--          <p class="font-montserrat font-bold text-small mb-3.5 pr-5">Promotion</p>-->
    <!--          <p class="text-small mt-2.5">Lorem ipsum dolor sit amet, consectetur adipiscing elit ut.</p>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
  <div class="flex">
    <app-image
      [image]="item.oArticle! | articleMainImage"
      class="w-20 tablet:w-32 h-20 tablet:h-32 object-contain object-center"
      classes="w-20 tablet:w-32 h-20 tablet:h-32 object-contain object-center"
    ></app-image>

    <div class="block basis-flyout-card-sml tablet:basis-flyout-card pl-3" style="max-width: 456px">
      <div class="js-flyout-header w-full flex -mt-0.5 absolute top-6 2xl:top-0 left-0 2xl:relative z-20">
        <p class="text-small truncate w-flyout-cat">{{ item.oArticle!.oProductInfo[0]?.sTitle || "" }}</p>
        <div class="ml-auto flex items-center md:-mr-1.5 -translate-y-1">
          <div class="relative mr-1.5 last:mr-0 leading-none">
            <app-article-ordered [article]="item.oArticle!"></app-article-ordered>
          </div>
          <div class="relative mr-0.5 last:mr-0 leading-none">
            <app-preferred-commission [item]="item.oArticle!"></app-preferred-commission>
          </div>
          <div class="relative mr-1.5 last:mr-0 leading-none">
            <!--            {{> atom / notice-->
            <!--            button = "true"-->
            <!--            small = "true"-->
            <!--            flyout - search = "true"-->
            <!--            fylout - left = fylout - left-->
            <!--            first - flyout = first - in - flyout-->
            <!--            last - flyout = last - flyout-->
            <!--            table = ""-->
            <!--            last - table = ""-->
            <!--            last - grid = last-->
            <!--            detail = ""-->
            <!--            mini - card = ""-->
            <!--            }}-->
          </div>
          <div class="hidden tablet:block relative mr-1.5 last:mr-0 leading-none">
            <button (click)="deleteFavoriteItem()" class="relative w-5 h-5 group">
              <span
                class="ifont ifont--like-small-active transition-all duration-200 opacity-100 text-icon absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-20 text-primary group-hover:text-gela-black group-hover:opacity-100"
              ></span>
              <span
                class="ifont ifont--like-small transition-all duration-200 opacity-0 text-icon absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 text-primary group-hover:text-gela-black"
              ></span>
            </button>
          </div>
        </div>
      </div>
      <div class="-mt-1.5 2xl:-mt-0.5">
        <span class="text-small font-montserrat font-bold leading-tight">{{ item.oArticle!.sArticleID }}</span>
        <p class="line-clamp-2 tablet:line-clamp-1">{{ item.oArticle!.sDescription }}</p>
      </div>
      <div class="tablet:flex items-start mt-5">
        <div class="flex flex-col -mb-4 -mt-18px z-10">
          <app-article-price [article]="item.oArticle!">
            <app-article-availability [article]="item.oArticle!" [showButton]="true"></app-article-availability>
          </app-article-price>
        </div>
        <div class="ml-0 tablet:ml-auto flex items-center mt-7 tablet:-mt-2.5 -mr-1.5">
          <app-add-to-cart
            (quantityChanged)="onQuantityChange($event)"
            [article]="$any(item.oArticle!)"
            [quantity]="item.decQuantity"
            [size]="'m'"
            class="w-full"
          ></app-add-to-cart>
          <div class="block tablet:hidden relative ml-4 leading-none">
            <button (click)="deleteFavoriteItem()" class="relative w-7 h-7 group z-0">
              <span
                class="ifont ifont--like-small-active transition-all duration-200 opacity-100 text-3xl absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-20 text-primary group-hover:text-gela-black group-hover:opacity-100"
              ></span>
              <span
                class="ifont ifont--like-small transition-all duration-200 opacity-0 text-3xl absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 text-primary group-hover:text-gela-black"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex tablet:block z-30 mb-2 tablet:mb-0">
    <!-- @lobos if there is the auf mass button if needs the class left-10 otherwise it needs class left-0 -->
    <div class="tablet:absolute desktop-grid:top-14 2xl:top-0 xl:left-0 inline-block">
      <app-article-shop-info [article]="item.oArticle" placement="inline"></app-article-shop-info>
    </div>
  </div>
</div>
