import { Pipe, PipeTransform } from '@angular/core';
import { ImageHelperService } from '../../services/catalog/image-helper.service';
import { CloudinaryImage } from '@cloudinary/url-gen';

@Pipe({
  name: 'getDefaultCloudinaryImage',
})
export class GetDefaultCloudinaryImagePipe implements PipeTransform {
  constructor(private imageHelperService: ImageHelperService) {}

  transform(_: any): CloudinaryImage {
    return this.imageHelperService.getDefaultCloudinaryImage();
  }
}
