import { FavoriteService, HookEvent, HookHandlerAbstract, WorkflowCreateHook } from '@lobos/library-v2';
import { Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { HandleQtyInput } from './util/favorite-item-qty-input-handler-util';
import { FavoriteItem, FavoriteList } from '@lobos/common';

type type = WorkflowCreateHook<'CreateFavoriteItemHook'>;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class FavoriteCreateItemHandler extends HookHandlerAbstract<type, Observable<FavoriteItem | undefined>> {
  constructor(private modal: SimpleModalService, private favoriteService: FavoriteService<FavoriteList, FavoriteItem>) {
    super();
  }

  ids(): type[] {
    return [{ type: 'WorkflowCreateHook', entity: 'CreateFavoriteItemHook' }];
  }

  override before(
    event: HookEvent<type, Observable<FavoriteItem>>,
    _: HookEvent<type, Observable<FavoriteItem>>,
  ): () => Observable<FavoriteItem | undefined> {
    const favoriteItem: FavoriteItem = event.params[0];
    const callable = event.callable;
    return HandleQtyInput(favoriteItem, callable, this.modal, this.favoriteService);
  }
}
