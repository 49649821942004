/* eslint-disable angular/document-service */
import { ChangeDetectionStrategy, Component, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-scroll-top-button',
  templateUrl: './scroll-top-button.component.html',
  styleUrls: ['./scroll-top-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollTopButtonComponent {
  windowIsScrolled = false;

  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.windowIsScrolled = true;
    } else if (document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.windowIsScrolled = false;
    }
  }

  scrollToTop() {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  }
}
