import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-link-text',
  templateUrl: './link-text.component.html',
  styleUrls: ['./link-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkTextComponent {
  @Input() text: string | undefined;
  @Input() href: string | undefined;
  @Input() attr: string = '';
  @Input() classes: string = '';
}
