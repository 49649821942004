<div class="js-accordion items-center" (click)="accordionToggle($event)" *ngIf="cmsMenu">
  <div class="w-full flex items-center justify-between">
    <p class="js-accordion-show text-xl text-white font-montserrat font-bold leading-6 whitespace-nowrap">
      {{ cmsMenu.title }}
    </p>
    <span
      class="js-accordion-icon text-white ifont ifont--chevron-down text-3xl flex items-center justify-end transition-all duration-200"
    ></span>
  </div>

  <div class="js-accordion-wrap leading-tight max-h-0 overflow-hidden transition-all duration-200">
    <div class="js-accordion-content">
      <ul class="md:block text-primary mt-6 tracking-normal">
        <li class="mb-4 last:mb-0" *ngFor="let child of cmsMenu?.children">
          <a
            [routerLink]="'/cms/' + child.url | localizeUrlPath"
            [title]="child.title"
            class="w-full pb-3.5 md:pb-0 block text-white hover:text-primary font-montserrat font-medium"
          >
            {{ child.title }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
