<div
  [class.js-leave-open]="drag"
  class="relative w-full flex items-center cursor-pointer break-words hover:bg-transparent group transition-all duration-200"
>
  <div *ngIf="form" class="flex items-center h-4 cursor-pointer" [formGroup]="form">
    <input
      [id]="name"
      [name]="name"
      type="checkbox"
      [formControlName]="name"
      [value]="value"
      [class.border-error]="hasError"
      [disabled]="disabled"
      [class.js-leave-open]="drag"
      class="js-checkbox bg-transparent cursor-pointer h-4 w-4 text-primary border border-gela-black focus:ring-0 ring-offset-transparent ring-transparent checked:bg-secondary focus:checked:bg-secondary checked:hover:bg-secondary checked:bg-none disabled:opacity-25"
    />
  </div>
  <div *ngIf="!form" class="flex items-center h-4 cursor-pointer">
    <input
      [id]="name"
      [name]="name"
      [value]="value"
      type="checkbox"
      [checked]="value"
      (change)="changeValue()"
      [class.border-error]="hasError"
      [disabled]="disabled"
      [class.js-leave-open]="drag"
      class="js-checkbox bg-transparent cursor-pointer h-4 w-4 text-primary border border-gela-black focus:ring-0 ring-offset-transparent ring-transparent checked:bg-secondary focus:checked:bg-secondary checked:hover:bg-secondary checked:bg-none disabled:opacity-25"
    />
  </div>
  <div [class.js-leave-open]="drag" class="ml-3 w-full cursor-pointer">
    <label
      for="{{ name }}"
      [class.text-error]="hasError"
      [class.js-leave-open]="drag"
      [class.opacity-25]="disabled"
      [innerHTML]="label"
      class="js-checkbox-label py-3 text-left text-small font-montserrat font-bold w-full inline-block cursor-pointer"
    ></label>
  </div>

  <ng-container *ngIf="drag">
    <button class="cursor-ns-resize">
      <span class="ifont ifont--drag-drop text-3xl"></span>
    </button>
  </ng-container>
</div>
