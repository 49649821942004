import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { filter, first, map, tap } from 'rxjs/operators';
import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { register } from 'swiper/element/bundle';
import { ResetPasswordModalComponent } from './components/reset-password-modal/reset-password-modal.component';
import { ComnormService } from './services/comnorm/comnorm.service';
import { VERSION } from '../environments/version';
import { PageMetaService } from '@lobos/library-v2';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modal: SimpleModalService,
    protected comNormService: ComnormService,
    private pageMetaService: PageMetaService, // call PageMetaService to ensure metadata is displayed correctly
  ) {
    //console.log(consoleInfo);
    console.log('VERSION: ', VERSION);
  }

  ngOnInit() {
    this.route.queryParamMap
      .pipe(
        filter((params: ParamMap) => params.has('redirect') || params.has('reset-password')),
        map((params: ParamMap) => {
          if (params.has('redirect')) {
            this.modal
              .addModal(LoginModalComponent)
              .pipe(
                first(),
                tap((result: string | null) => {
                  if (result === 'success') {
                    this.router.navigateByUrl(decodeURIComponent(params.get('redirect')!));
                  }
                }),
              )
              .subscribe();
          }
          if (params.has('reset-password')) {
            this.modal
              .addModal(ResetPasswordModalComponent, { guiId: params.get('reset-password') })
              .pipe(
                first(),
                tap((result: string | null) => {
                  if (result === 'success') {
                    this.router.navigate([decodeURIComponent(params.get('redirect')!)]);
                  }
                }),
              )
              .subscribe();
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    register();
  }
}
