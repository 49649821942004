import { Component, Input } from '@angular/core';
import { FlyoutPosition } from '../flyout/flyout.component';
import { Observable } from 'rxjs';
import { AuthService, CartHeaderQuery, CartService, CreateCartItemInterface, switchTap } from '@lobos/library-v2';
import { GelaCartHeader } from '../../services/cart/model/gela-cart-header.model';
import { GelaArticle } from '../../services/catalog/model/gela-article';
import { SimpleModalService } from 'ngx-simple-modal';
import { ItemCommissionModalComponent } from '../../features/checkout/checkout-features/cart/cart-components/item-commission-modal/item-commission-modal.component';
import { filter, first } from 'rxjs/operators';
import { CartItem } from '@lobos/common';
import { GelaCartItem } from '../../services/cart/model/gela-cart-item.model';

@Component({
  selector: 'app-preferred-commission',
  templateUrl: './preferred-commission.component.html',
  styleUrls: ['./preferred-commission.component.scss'],
})
export class PreferredCommissionComponent {
  @Input() button: boolean = true;

  @Input() flyoutPosition: FlyoutPosition = 'left';

  @Input() item: GelaArticle | undefined;

  isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;

  cartHeader$: Observable<GelaCartHeader | undefined> = this.cartHeaderQuery.selectActive();

  constructor(
    private authService: AuthService,
    private cartHeaderQuery: CartHeaderQuery<GelaCartHeader>,
    private modalService: SimpleModalService,
    private cartService: CartService<GelaCartHeader, CartItem, CreateCartItemInterface>,
  ) {}

  showModal(item: GelaArticle, header: GelaCartHeader) {
    return this.modalService
      .addModal(ItemCommissionModalComponent, {
        item: { oArticle: item, sPL1Commission: header.sPreferredCommission } as GelaCartItem,
        dontShowOption: true,
      })
      .pipe(
        first(),
        filter((item) => !!item),
        switchTap((item) => this.cartService.updateCartHeader({ ...header, sPreferredCommission: item.sPL1Commission })),
      )
      .subscribe();
  }
}
