import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface MessageModel {
  message: string;
  translate?: boolean;
}

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-simple-message.component.html',
  styleUrls: ['./modal-simple-message.component.scss'],
})
export class ModalSimpleMessageComponent extends SimpleModalComponent<MessageModel, boolean> implements MessageModel {
  title: string = '';
  message: string = '';
  translate: boolean = false;
}
