import { Pipe, PipeTransform } from '@angular/core';
import { P48APIAuthorizationGroup } from '@lobos/common';

@Pipe({
  name: 'roleList',
})
export class RoleListPipe implements PipeTransform {
  public transform(roles: P48APIAuthorizationGroup[] | null): { key: number; value: string }[] {
    const normalizedRoles: { key: number; value: string }[] = [];
    if (!roles?.length) {
      return normalizedRoles;
    }

    for (const role of roles) {
      normalizedRoles.push({
        key: role.lngAPIAuthorizationGroupID as number,
        value: role.sRoleName,
      });
    }

    return normalizedRoles;
  }
}
