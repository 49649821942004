import { Inject, Injectable } from '@angular/core';
import { Article, FavoriteItem, FavoriteList, PaginationResponseInterface, Price } from '@lobos/common';
import { Label, LABEL_PRINT_CONFIG, LabelMapperInterface, LabelPrintConfig, MapperAbstract } from '@lobos/label-print-v2';
import { FavoriteService, PriceQuery, PriceService } from '@lobos/library-v2';
import { TranslocoDatePipe, TranslocoDecimalPipe, TranslocoLocaleService } from '@ngneat/transloco-locale';
import { combineLatest, forkJoin, iif, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { ArticleHelperService } from '../../catalog/article-helper.service';
import { ImageHelperService } from '../../catalog/image-helper.service';
import { GelaArticle } from '../../catalog/model/gela-article';
import { GelaFavoriteItem } from '../../favorite/model/gela-favorite-item.model';

export interface CustomerArticleID {
  lngCustomerID: number;
  sArticleID: string;
  sArticleName: string;
  sBranchKey: string;
  sCustomerArticleID: string;
  shtPListVariant: number;
  sInfo: string;
  sItemText: string;
}

@Injectable()
export class FavoriteMapper extends MapperAbstract implements LabelMapperInterface {
  constructor(
    @Inject(LABEL_PRINT_CONFIG) private config: LabelPrintConfig,
    private date: TranslocoDatePipe,
    private decimal: TranslocoDecimalPipe,
    private favoriteService: FavoriteService<FavoriteList, GelaFavoriteItem>,
    private imageHelper: ImageHelperService,
    private articleHelper: ArticleHelperService,
    private priceService: PriceService,
    private priceQuery: PriceQuery,
    private translocoLocaleService: TranslocoLocaleService,
  ) {
    super();
  }

  public filename(id: string): Observable<string> {
    return this.favoriteService
      .getFavoriteListById(id)
      .pipe(map((list: FavoriteList | undefined) => list!.sListname + '.pdf' || 'print.pdf'));
  }

  public map(id: string): Observable<Label[]> {
    return this.favoriteService.getFavoriteItems(id).pipe(
      switchMap((favorites: PaginationResponseInterface<GelaFavoriteItem>) =>
        forkJoin(
          favorites.data
            .filter((item) => item.oArticle)
            .map((favorite: GelaFavoriteItem) =>
              combineLatest([
                of(favorite),
                of(undefined).pipe(
                  switchMap(() =>
                    iif(
                      () => this.priceQuery.hasEntity(favorite.oArticle?.sArticleID),
                      this.priceQuery.selectEntity(favorite.oArticle?.sArticleID),
                      this.articleHelper
                        .getPriceFromApi(favorite.oArticle?.sArticleID.toString(), {
                          articleId: favorite.oArticle?.sArticleID,
                          unit: favorite.oArticle?.sQuantityUnitSales,
                          currency: 'CHF',
                          qty: favorite.oArticle?.lngSalesPriceUnit > 0 ? favorite.oArticle.lngSalesPriceUnit : 1,
                          factor1: favorite.oArticle?.decFactor1,
                          factor2: favorite.oArticle?.decFactor2,
                          factor3: favorite.oArticle?.decFactor3,
                        })
                        .pipe(switchMap(() => this.priceQuery.selectEntity(favorite.oArticle?.sArticleID))),
                    ),
                  ),
                  first(),
                ),
              ]).pipe(map(([favorite, price]: [GelaFavoriteItem, Price | undefined]) => this.mapFavoriteToLabel(favorite, price))),
            ),
        ),
      ),
    );
  }

  private mapFavoriteToLabel(favorite: GelaFavoriteItem, priceInfo: Price | undefined): Label {
    const article: GelaArticle = favorite.oArticle!;
    const price = this.translocoLocaleService.localizeNumber(
      priceInfo?.decAmountNetFC || 0,
      'currency',
      this.translocoLocaleService.getLocale(),
      { currency: priceInfo?.sCurrencyCode },
    );
    const discountedFrom = priceInfo?.decSpecialPriceNet ? this.decimal.transform(priceInfo.decBasePriceNet) : undefined;

    const inclNet = this.translocoLocaleService.localizeNumber(
      priceInfo?.decAmountGrossFC || 0,
      'currency',
      this.translocoLocaleService.getLocale(),
      { currency: priceInfo?.sCurrencyCode },
    );

    const inclGross = this.translocoLocaleService.localizeNumber(
      priceInfo?.decBasePriceGross || 0,
      'currency',
      this.translocoLocaleService.getLocale(),
      { currency: priceInfo?.sCurrencyCode },
    );

    const exclGross = this.translocoLocaleService.localizeNumber(
      priceInfo?.decBasePriceNet || 0,
      'currency',
      this.translocoLocaleService.getLocale(),
      { currency: priceInfo?.sCurrencyCode },
    );

    return {
      data: {
        code: this.determineCode(favorite),
        sku: article.sArticleID as string,
        title: article.sName,
        description: article.sPL1CustomerLabel || article.sDescription,
        price,
        discountedFrom,
        date: this.date.transform(Date.now()),
        unit: `${priceInfo?.lngSalesPriceUnit} ${priceInfo?.sQuantityUnit}`,
        currency: priceInfo?.sCurrencyCode,
        logoPath: this.config.logoPath,
        imgPath: this.imageHelper.getArticleMainImage(article)?.toURL(),
        customFields: {
          inclNet: inclNet,
          inclGross: inclGross,
          exclGross: exclGross,
          articleType: article.sArticleCode1,
          amount: favorite.decQuantity,
        },
      },
      amount: 1,
      print: false,
      type: 'data',
    };
  }

  private determineCode(favorite: FavoriteItem): string {
    const article: Article = favorite.oArticle!;

    return `${article.sArticleID}#${favorite.decQuantity || 1}`;
  }
}
