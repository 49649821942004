import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-article-availability-icon',
  templateUrl: './article-availability-icon.component.html',
  styleUrls: ['./article-availability-icon.component.scss'],
})
export class ArticleAvailabilityIconComponent {
  private _status: number = 0;
  get status(): number {
    return this._status;
  }

  @Input() set status(value: number) {
    if (value !== undefined) {
      this._status = value;
    }
  }

  getColorForStatus(status: AvailableStatus): string {
    // get color for status
    switch (status) {
      case AvailableStatus.Available:
        return '#27AE60'; // green color for available status 1
      case AvailableStatus.PartialDelivery:
        return '#FFA500'; // orange color for partial delivery status 2
      case AvailableStatus.Residues:
        return '#FFF200'; // yellow color for residues status 3
      case AvailableStatus.Default:
        return 'bg-gray-300'; // // standard color for default status 0
      default:
        return 'bg-gray-300'; // standard color
    }
  }
}

export enum AvailableStatus {
  Available = 1,
  PartialDelivery = 2,
  Residues = 3,
  Default = 0,
}
