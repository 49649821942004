import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { FavoriteItem } from '@lobos/common';

@Component({
  selector: 'app-favorite-qty-modal',
  templateUrl: './favorite-qty-modal.component.html',
  styleUrls: ['./favorite-qty-modal.component.scss'],
})
export class FavoriteQtyModalComponent extends SimpleModalComponent<FavoriteItem, FavoriteItem | undefined> {
  favoriteItem!: FavoriteItem;
  decQuantityDown: number = 0;
  decQuantityUp: number = 0;
  decQuantityDefault: number = 1;

  constructor() {
    super();
  }

  override mapDataObject(data: FavoriteItem) {
    super.mapDataObject(data);
    this.favoriteItem = data;
    this.setData();
  }

  setData() {
    if (this.favoriteItem.oArticle) {
      if (this.favoriteItem.oArticle.decQuantityPackage <= 0 || !this.favoriteItem.oArticle.shtNoPackageBreak)
        this.favoriteItem.oArticle.decQuantityPackage = 1;
      // rounding down
      //	this.decQuantityDown = this.decQuantity / this._oCartItem.oArticle.decQuantityPackage;
      this.decQuantityDown = Math.floor(this.favoriteItem.decQuantity / this.favoriteItem.oArticle.decQuantityPackage);
      this.decQuantityDown = this.decQuantityDown * this.favoriteItem.oArticle.decQuantityPackage;

      // rounding up
      //	this.decQuantityUp = this.decQuantity / this._oCartItem.oArticle.decQuantityPackage;
      this.decQuantityUp = Math.ceil(this.favoriteItem.decQuantity / this.favoriteItem.oArticle.decQuantityPackage);
      this.decQuantityUp = this.decQuantityUp * this.favoriteItem.oArticle.decQuantityPackage;

      this.decQuantityDefault =
        this.favoriteItem.oArticle.decQuantityPackage > 0 && this.favoriteItem.oArticle.shtNoPackageBreak >= 1
          ? this.favoriteItem.oArticle.decQuantityPackage
          : 1;
    }
  }

  roundUp() {
    this.result = {
      ...this.favoriteItem,
      decQuantity: this?.favoriteItem?.decQuantity ? this.decQuantityUp : this.decQuantityDefault,
    } as FavoriteItem;
    this.close();
  }

  roundDown() {
    this.result = {
      ...this.favoriteItem,
      decQuantity: this?.favoriteItem?.decQuantity ? this.decQuantityDown : this.decQuantityDefault,
    } as FavoriteItem;
    this.close();
  }

  cancel() {
    this.close();
  }
}
