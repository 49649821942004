import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CustomerArticleNoteInterface } from './customer-article-note.interface';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerArticleNoteState extends EntityState<CustomerArticleNoteInterface> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'customer-article-note' })
export class CustomerArticleNoteStore extends EntityStore<CustomerArticleNoteState> {}
