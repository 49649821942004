import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ModalConfirmComponent } from '../../../../../../components/modal-confirm/modal-confirm.component';
import { CommissionService } from '../../../../../../services/cart/commission.service';
import { CommissionInterface } from '../../../../../../services/cart/interfaces/commission.interface';
import { GelaCartItem } from '../../../../../../services/cart/model/gela-cart-item.model';

/**
 * Maybe there is a way to reuse it instead of copying it
 */
@UntilDestroy()
@Component({
  selector: 'app-item-commission-modal',
  templateUrl: './item-commission-modal.component.html',
  styleUrls: ['./item-commission-modal.component.scss'],
})
export class ItemCommissionModalComponent
  extends SimpleModalComponent<{ item: GelaCartItem; dontShowOption?: boolean }, GelaCartItem>
  implements OnInit
{
  public form?: FormGroup<{ sPL1Commission: FormControl<string> }>;

  public item?: GelaCartItem;

  public dontShowOption: boolean = false;

  public commission$: Observable<{ key: string; value: string }[]> = this.commissionService.getAll().pipe(
    map((commissions: CommissionInterface[]) =>
      commissions.map((commission: CommissionInterface) => ({
        key: commission.sTitle,
        value: commission.sTitle,
      })),
    ),
  );

  constructor(private commissionService: CommissionService, private modalService: SimpleModalService) {
    super();
  }

  public ngOnInit(): void {
    this.form = new FormGroup<{ sPL1Commission: FormControl<string> }>({
      sPL1Commission: new FormControl<string>(this.item?.sPL1Commission || '', { nonNullable: true }),
    });
  }

  public newCommission($event: string): void {
    this.commissionService
      .add($event)
      .pipe(
        catchError((error: string) => {
          this.form?.setValue({
            sPL1Commission: $event,
          });
          return this.modalService.addModal(ModalConfirmComponent, {
            message: error,
            translate: true,
            buttonCancel: undefined,
          });
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  public apply(noCommission: boolean = false): void {
    this.result = { ...this.item!, sPL1Commission: noCommission ? '' : this.form!.value.sPL1Commission! };
    this.close();
  }
}
