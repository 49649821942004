import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CustomerArticleInterface } from './customer-article.interface';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerArticleState extends EntityState<CustomerArticleInterface> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'customer-article' })
export class CustomerArticleStore extends EntityStore<CustomerArticleState> {}
