<ng-container *transloco="let t; scope: 'header'">
  <div class="js-mobile-menu min-h-full bg-primary-bright px-5 pt-5">
    <div class="js-level js-level-1" *ngIf="{ activeLevel: activeLevel$ | async } as context">
      <div class="flex justify-end">
        <button
          *ngIf="context.activeLevel"
          type="button"
          class="js-mobile-back pl-4 relative inline-flex items-center font-montserrat font-bold text-primary group"
          (click)="setActiveLevelById(context.activeLevel.lngParentGroup.toString())"
        >
          <span
            class="ifont ifont--chevron-left text-3xl absolute -left-3.5 top-1/2 -translate-y-1/2 transition-all duration-200 group-hover:-translate-x-2.5"
          ></span>
          {{ t("header.mobile-navigation-menu.back") }}
        </button>
        <ng-container *ngIf="!context.activeLevel">
          <button
            (click)="setLanguage('de')"
            [ngClass]="translocoService.getActiveLang() === 'de' ? 'text-gela-black' : 'text-primary'"
            class="py-0.5 font-montserrat uppercase transition-all duration-200 hover:text-gela-black"
          >
            De
          </button>
          <span class="w-px h-6 bg-primary-light mx-5"></span>
          <button
            (click)="setLanguage('fr')"
            [ngClass]="translocoService.getActiveLang() === 'fr' ? 'text-gela-black' : 'text-primary'"
            class="py-0.5 font-montserrat uppercase transition-all duration-200 hover:text-gela-black"
          >
            Fr
          </button>
        </ng-container>
      </div>
      <div>
        <div *ngIf="activeCatalogLevel$ | async as catalogFirstLevel" class="first:mt-4 mt-12">
          <p class="text-xl font-montserrat font-bold mb-5">
            {{ context.activeLevel ? context.activeLevel.sTitle : t("header.mobile-navigation-menu.title") }}
          </p>
          <ul class="divide-y divide-primary-light leading-tight border-y border-primary-light">
            <li *ngFor="let item of catalogFirstLevel" class="pt-1.5 pb-1.5 flex items-center min-h-48px">
              <a
                [title]="item.sTitle"
                [routerLink]="item.sUrlPath | catalogPath | localizeUrlPath"
                (click)="closeMenu()"
                class="w-full font-montserrat font-medium pr-4"
                >{{ item.sTitle }}</a
              >
              <button *ngIf="item.sUrlPath | pathLength" (click)="setActiveLevelId(item)" class="js-open-mobile-sublevel">
                <span
                  class="ifont ifont--chevron-right pl-2.5 border-l border-primary-light text-3xl text-primary pointer-events-none"
                ></span>
              </button>
            </li>
          </ul>
        </div>
        <ng-container *ngIf="!context.activeLevel">
          <div *ngFor="let cmsMenu of cmsMainMenu$ | async" class="first:mt-4 mt-12">
            <p class="text-xl font-montserrat font-bold mb-5">{{ cmsMenu.title }}</p>
            <ul class="divide-y divide-primary-light leading-tight border-y border-primary-light">
              <li *ngFor="let item of cmsMenu.children" class="pt-1.5 pb-1.5 flex items-center min-h-48px">
                <a
                  [routerLink]="'/cms/' + item.url | cmsUrlReplace | localizeUrlPath"
                  (click)="closeMenu()"
                  class="w-full font-montserrat font-medium"
                  >{{ item.title }}</a
                >
              </li>
            </ul>
          </div>

          <ul class="mt-12">
            <li class="pb-6" *ngFor="let footerNav of footerNav$ | async">
              <a
                (click)="closeMenu()"
                [routerLink]="'/cms/' + footerNav.url | localizeUrlPath"
                class="w-full font-montserrat font-medium"
                >{{ footerNav.title }}</a
              >
            </li>
          </ul>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
