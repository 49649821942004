import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Category } from '@lobos/common';
import { GelaCatalog } from '../../services/catalog/model/gela-catalog';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryListComponent {
  @Input() category: Category | null | undefined;
  @Input() subLevelCategories: GelaCatalog[] | null | undefined;
  isOpen: boolean = true;

  public toggleIsOpen(): void {
    this.isOpen = !this.isOpen;
  }
}
