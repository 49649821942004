<div *transloco="let t; scope: 'header'" class="js-nav" [class.hidden]="!navOpen">
  <div class="fixed z-30 top-0 left-0">
    <div class="flex h-screen">
      <div
        class="js-nav-list--1 w-nav-bar-1-sml xl:w-nav-bar-1 3xl:w-nav-bar-1-big h-screen bg-white pr-5 xl:pr-42px pl-6 xl:pl-58px last:pr-9 xl:last:pr-70px pt-24 relative"
      >
        <button
          (click)="closeNavigation()"
          class="js-btn-close-nav p-1 leading-none rounded-default group hover:bg-primary-bright transition-all duration-200 absolute right-9 top-9"
        >
          <span class="ifont ifont--close text-3xl group-hover:text-primary transition-all duration-200"></span>
        </button>
        <h3 class="font-montserrat font-bold text-2xl text-primary text-gela-black">{{ t("header.navigation.all-categories") }}</h3>
        <ul class="font-montserrat font-medium leading-tight flex flex-col space-y-12 relative z-10 pb-10 pt-70px" data-level="1">
          <li *ngFor="let navigation of menuLevel1">
            <a
              [routerLink]="navigation.sUrlPath | catalogPath | localizeUrlPath"
              data-id="level2_1"
              [title]="navigation.sTitle"
              (mouseenter)="openSecondLevelNavigation(navigation, $event)"
              (click)="closeNavigation()"
              class="flex items-center group js-open-subnav w-full"
            >
              <div class="w-nav-link-sml xl:w-nav-link first:w-full pl-4 first:pl-0 flex items-center">
                <div class="js-btn-text w-auto mr-7 mr-auto">
                  <div class="relative mr-4 pb-1.5 text-small xl:text-base hyphens-auto big:hyphens-none">
                    {{ navigation.sTitle }}
                    <span
                      class="js-btn-line h-3px w-full bg-gela-black inline-block absolute left-0 bottom-0 opacity-0 transition-opacity duration-200 group-hover:opacity-100"
                    ></span>
                  </div>
                </div>
                <span
                  class="js-btn-icon -mt-1.5 ml-auto ifont ifont--chevron-right text-primary text-3xl relative after:w-6 after:h-6 after:bg-gela-black after:rounded-full after:opacity-0 after:absolute after:left-1/2 after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:-z-10 after:transition-all after:duration-200 group-hover:after:opacity-100 group-hover:text-white"
                ></span>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div
        class="js-nav-list--2 opacity-0 pointer-events-none transition-all duration-200 js-show-btn w-nav-bar-2-sml xl:w-nav-bar-2 3xl:w-nav-bar-2-big relative text-center h-screen overflow-auto scrollbar-none bg-paper pr-5 xl:pr-42px pl-6 xl:pl-58px last:pr-9 xl:last:pr-70px pt-24"
      >
        <div
          *ngIf="selectedMenu2$ | async as secondLevelNav"
          class="js-sublevel h-0 opacity-0 overflow-hidden transition-all duration-200 js-open"
          data-id="level2_1"
        >
          <h3 class="font-montserrat font-bold text-2xl text-primary text-left text-gela-black">{{ secondLevelNav.sTitle }}</h3>
          <ul
            *ngIf="menuLevel2$ | async as menuLevel2"
            class="font-montserrat font-medium leading-tight text-left flex flex-col relative space-y-4 z-10 pb-10 pt-70px"
            data-level="2"
          >
            <li *ngFor="let navigation of menuLevel2">
              <a
                [routerLink]="navigation.sUrlPath | catalogPath | localizeUrlPath"
                data-id="level3_1"
                [title]="navigation.sTitle"
                (mouseenter)="openThirdLevelNavigation(navigation, $event)"
                (click)="closeNavigation()"
                class="flex items-center group js-open-subnav w-full"
              >
                <div class="w-92px min-h-92px min-w-92px xl:w-120px xl:min-h-120px xl:min-w-120px bg-white p-2.5 rounded-flyout">
                  <app-image [image]="navigation | catalogMainImage" class="mx-auto" [classes]="'mx-auto'"></app-image>
                </div>
                <div class="w-nav-link-sml xl:w-nav-link first:w-full pl-4 first:pl-0 flex items-center">
                  <div class="js-btn-text w-auto mr-7 mr-auto">
                    <div class="relative mr-4 pb-1.5 text-small xl:text-base hyphens-auto big:hyphens-none">
                      {{ navigation.sTitle }}
                      <span
                        class="js-btn-line h-3px w-full bg-gela-black inline-block absolute left-0 bottom-0 opacity-0 transition-opacity duration-200 group-hover:opacity-100"
                      ></span>
                    </div>
                  </div>
                  <span
                    class="js-btn-icon -mt-1.5 ml-auto ifont ifont--chevron-right text-primary text-3xl relative after:w-6 after:h-6 after:bg-gela-black after:rounded-full after:opacity-0 after:absolute after:left-1/2 after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:-z-10 after:transition-all after:duration-200 group-hover:after:opacity-100 group-hover:text-white"
                  ></span>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <button
          (click)="navScrollDownButton(false)"
          class="js-btn fixed top-100vh -translate-y-1/2 -translate-x-1/2 leading-none z-40 group transition-all duration-200"
        >
          <span class="ifont ifont--chevron-down text-3xl absolute top-2 left-1/2 -translate-x-1/2 group-hover:text-white"></span>
          <span class="bg-primary-light w-20 h-20 rounded-full inline-block group-hover:bg-secondary"></span>
        </button>
      </div>
      <div
        class="js-nav-list--3 opacity-0 pointer-events-none transition-all duration-200 js-show-btn w-nav-bar-2-sml xl:w-nav-bar-2 3xl:w-nav-bar-2-big relative text-center h-screen overflow-auto scrollbar-none bg-primary-bright pr-5 xl:pr-42px pl-6 xl:pl-58px last:pr-9 xl:last:pr-70px pt-24"
      >
        <div
          *ngIf="selectedMenu3$ | async as thirdLevelNav"
          class="js-sublevel overflow-hidden transition-all duration-200 js-opem"
          data-id="level3_1"
        >
          <h3 class="font-montserrat font-bold text-2xl text-left text-gela-black">{{ thirdLevelNav.sTitle }}</h3>
          <ul
            *ngIf="menuLevel3$ | async as menuLevel3"
            class="font-montserrat font-medium leading-tight text-left flex flex-col space-y-4 relative z-10 pb-10 pt-70px"
            data-level="3"
          >
            <li *ngFor="let navigation of menuLevel3">
              <a
                [routerLink]="navigation.sUrlPath | catalogPath | localizeUrlPath"
                [title]="navigation.sTitle"
                (click)="closeNavigation()"
                class="flex items-center group js-open-subnav w-full"
              >
                <div class="w-92px xl:w-120px bg-white p-2.5 rounded-flyout">
                  <app-image [image]="navigation | catalogMainImage" class="mx-auto" [classes]="'mx-auto'"></app-image>
                </div>
                <div class="w-nav-link-sml xl:w-nav-link first:w-full pl-4 first:pl-0 flex items-center">
                  <div class="js-btn-text w-auto mr-7 mr-auto">
                    <div class="relative mr-4 pb-1.5 text-small xl:text-base hyphens-auto big:hyphens-none">
                      {{ navigation.sTitle }}
                      <span
                        class="js-btn-line h-3px w-full bg-gela-black inline-block absolute left-0 bottom-0 opacity-0 transition-opacity duration-200 group-hover:opacity-100"
                      ></span>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <button
          (click)="navScrollDownButton(true)"
          class="js-btn-second fixed top-100vh -translate-y-1/2 -translate-x-1/2 leading-none z-40 group transition-all duration-200"
        >
          <span class="ifont ifont--chevron-down text-3xl absolute top-2 left-1/2 -translate-x-1/2 group-hover:text-white"></span>
          <span class="bg-primary-light w-20 h-20 rounded-full inline-block group-hover:bg-secondary"></span>
        </button>
      </div>
    </div>
  </div>
  <div
    (click)="closeNavigation()"
    class="js-bg-close-nav w-full bg-gela-black opacity-64 h-screen inline-block absolute top-0 left-0 z-20 cursor-pointer"
  ></div>
</div>
