import { CartService, CreateCartItemInterface } from '@lobos/library-v2';
import { Observable, of } from 'rxjs';
import { SimpleModalService } from 'ngx-simple-modal';
import { QtyModalComponent } from '../../../../components/qty-modal/qty-modal.component';
import { first, switchMap } from 'rxjs/operators';
import { Cart, CartHeader, CartItem } from '@lobos/common';
import { Decimal } from 'decimal.js';

export function IsPartialPackingUnitNeeded(oCartItem: CreateCartItemInterface) {
  return oCartItem.oArticle?.shtNoPackageBreak
    ? !new Decimal(oCartItem.decQuantity).modulo(oCartItem.oArticle.decQuantityPackage).equals(0)
    : false;
}

export function HandleQtyInput(
  oCartItem: CreateCartItemInterface,
  callable: (params?: any[]) => Observable<Cart<CartHeader, CartItem> | undefined>,
  modal: SimpleModalService,
  cartService: CartService<CartHeader, CartItem, CreateCartItemInterface>,
  bCreateMode: boolean,
): (params?: any[]) => Observable<Cart<CartHeader, CartItem> | undefined> {
  if (IsPartialPackingUnitNeeded(oCartItem)) {
    modal
      .addModal<CreateCartItemInterface | CartItem, CreateCartItemInterface | CartItem | undefined>(QtyModalComponent, oCartItem)
      .pipe(
        switchMap((cartItem: CreateCartItemInterface | CartItem | undefined) => {
          if (!!cartItem && bCreateMode) {
            return cartService.createCartItem({
              ...(cartItem as CreateCartItemInterface),
            });
          }

          if (!!cartItem && !bCreateMode) {
            return cartService.updateCartItem({
              ...(cartItem as CartItem),
            });
          }
          return of(undefined);
        }),
        first(),
      )
      .subscribe();
    return () => of(undefined);
  } else {
    return callable;
  }
}
