import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FavoriteItem, FavoriteList } from '@lobos/common';
import { FavoriteService } from '@lobos/library-v2';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SimpleModalService } from 'ngx-simple-modal';
import { of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { GelaFavoriteItem } from '../../services/favorite/model/gela-favorite-item.model';

/**
 * TODO
 * - promotion and cutToSize might be read from the item.oArticle and don't need to be input parameter
 * - promotion and cutToSize text and markup maybe in own component
 * - price flyout styling (globally) and z-index
 */
@UntilDestroy()
@Component({
  selector: 'app-favorites-flyout-item',
  templateUrl: './favorite-item.component.html',
  styleUrls: ['./favorite-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoriteItemComponent {
  @Input() item!: GelaFavoriteItem;
  @Input() first: boolean = false;
  @Input() cutToSize: boolean = false;
  @Input() promotion: boolean = false;

  constructor(private modal: SimpleModalService, private favoriteService: FavoriteService<FavoriteList, FavoriteItem>) {}

  async deleteFavoriteItem() {
    this.modal
      .addModal(ModalConfirmComponent, {
        message: 'header.favorites.modal-confirm.message',
        translate: true,
      })
      .pipe(
        switchMap((result: boolean) => (result ? this.favoriteService.deleteFavoriteItem(this.item) : of(undefined))),
        first(),
        untilDestroyed(this),
      )
      .subscribe();
  }

  async onQuantityChange(quantity: number) {
    await this.favoriteService
      .updateFavoriteItem({ ...this.item, decQuantity: quantity })
      .pipe(first(), untilDestroyed(this))
      .toPromise();
  }
}
