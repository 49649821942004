import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'productNameReplace' })
export class CatalogProductNameReplacePipe implements PipeTransform {
  transform(value: string): string {
    return value
      .replace(/-/g, '.')
      .slice(1)
      .substring(0, value.length - 3);
  }
}
