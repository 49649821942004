import { inject, Pipe, PipeTransform } from '@angular/core';
import { Language } from '@lobos/common';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'languageList',
})
export class LanguageListPipe implements PipeTransform {
  translocoService = inject(TranslocoService);

  public transform(languages: Language[] | null): { key: string; value: string }[] {
    const normalizedLanguages: { key: string; value: string }[] = [];
    if (!languages?.length) {
      return normalizedLanguages;
    }

    for (const language of languages) {
      normalizedLanguages.push({
        key: language.shtLanguageID.toString(),
        value: this.translocoService.translate('users.forms.select-values.language-' + language.sIsoCode, {}, 'profile'),
      });
    }

    return normalizedLanguages;
  }
}
