<!-- start login modal -->
<ng-container *transloco="let t; scope: 'shared'">
  <div class="js-modal-signin js-modal fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gela-black opacity-64 transition-opacity" aria-hidden="true"></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&ZeroWidthSpace;</span>
      <div
        class="relative inline-block align-bottom text-left overflow-hidden shadow-flyout transform transition-all sm:my-8 sm:align-middle sm:max-w-modal sm:w-full"
      >
        <div class="w-full bg-white p-6 border border-primary-light rounded-20px">
          <form class="flex flex-col space-y-6" (submit)="changePassword()">
            <p class="font-montserrat font-bold text-lg tablet:text-2xl pr-6">
              {{ t("shared.reset-password.title") }}
            </p>
            <app-input
              name="password"
              [label]="t('shared.reset-password.password')"
              [type]="'password'"
              [form]="form"
              [hasError]="!!form.get('password')?.touched && !!form.get('password')?.errors"
              [errorText]="
                form
                  | getError
                    : 'password'
                    : {
                        required: t('shared.reset-password.error.password-required')
                      }
              "
            ></app-input>
            <app-input
              name="passwordConfirm"
              [label]="t('shared.reset-password.password-confirm')"
              [type]="'password'"
              [form]="form"
              [hasError]="!!form.get('passwordConfirm')?.touched && !!form.get('passwordConfirm')?.errors"
              [errorText]="
                form
                  | getError
                    : 'passwordConfirm'
                    : {
                        required: t('shared.reset-password.error.password-confirm-required'),
                        equal: t('shared.reset-password.error.equal-password')
                      }
              "
            ></app-input>
            <div class="w-full flex space-x-5 self-start pt-6">
              <div class="basis-1/2">
                <button
                  type="submit"
                  class="w-full font-montserrat font-bold px-6 py-4 rounded-button transition-all duration-200 self-start bg-secondary text-white hover:bg-gela-black"
                  value=""
                >
                  {{ t("shared.reset-password.button-title") }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="block absolute top-5 right-4 z-10">
          <button
            (click)="close()"
            class="js-close-modal p-1 leading-none rounded-default group text-primary hover:bg-primary-bright transition-all duration-200"
          >
            <span class="ifont ifont--close text-icon-sml group-hover:text-primary-dark transition-all duration-200"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- end login modal -->
