<div *transloco="let t; scope: 'header'" class="js-header-big--categories hidden mobile-nav:block bg-primary-light mt-18px py-2.5">
  <ul
    class="max-w-page 3xl:max-w-midscreen 4xl:max-w-bigscreen px-5 md:px-10 mobile-nav:px-82px mx-auto flex items-center justify-between 2xl:justify-start"
  >
    <li class="first:-ml-5 mr-8 3xl:mr-3-9vw first:2xl:mr-auto last:mr-0">
      <button
        (click)="openNav()"
        class="js-open-nav font-montserrat font-bold text-gela-black rounded-button-nav flex items-center py-1.5 px-5 bg-primary-bright hover:bg-white transition-all duration-200"
      >
        <span class="ifont ifont--burger text-3xl xl:mr-4"></span>
        <span class="hidden xl:inline-block">{{ t("header.navigation.title") }}</span>
      </button>
    </li>
    <!-- TODO remove slice -> this is just temporary to remove old unused categories    -->
    <ng-container *ngIf="cmsMenu; else shopMenu">
      <li
        *ngFor="let navigation of cmsMenu"
        class="js-nav-item first:-ml-5 mr-4 xl:mr-6 2xl:mr-8 3xl:mr-auto first:2xl:mr-auto last:mr-0 basis-min-content 2xl:basis-auto grow-0 2xl:grow-1"
      >
        <a
          [routerLink]="'cms/' + navigation.url | localizeUrlPath"
          [title]="navigation.title"
          class="text-gela-black group font-montserrat font-medium transition-all duration-200 leading-tight relative pb-5.5"
        >
          {{ navigation.title }}
          <span
            *ngIf="'cms/' + navigation.url | cmsRouterLinkActive | async"
            class="w-full h-5px bg-gela-black inline-block absolute left-0 bottom-0 transition-all duration-200"
          ></span>
          <span
            class="w-full h-5px bg-gela-black inline-block absolute left-0 bottom-0 transition-all duration-200 opacity-0 group-hover:opacity-100"
          ></span>
        </a>
      </li>
    </ng-container>
    <ng-template #shopMenu>
      <li
        *ngFor="let navigation of menuLevel1"
        class="js-nav-item first:-ml-5 mr-4 xl:mr-6 3xl:mr-auto first:2xl:mr-auto last:mr-0 basis-min-content 2xl:basis-auto grow-0 2xl:grow-1"
      >
        <a
          [routerLink]="navigation.sUrlPath | catalogPath | localizeUrlPath"
          [title]="navigation.sTitle"
          class="text-gela-black group font-montserrat font-medium transition-all duration-200 leading-tight relative pb-5.5"
        >
          {{ navigation.sTitle }}
          <span
            *ngIf="navigation.sUrlPath | catalogPath | cmsRouterLinkActive | async"
            class="w-full h-5px bg-gela-black inline-block absolute left-0 bottom-0 transition-all duration-200"
          ></span>
          <span
            class="w-full h-5px bg-gela-black inline-block absolute left-0 bottom-0 transition-all duration-200 opacity-0 group-hover:opacity-100"
          ></span>
        </a>
      </li>
    </ng-template>
  </ul>
</div>
