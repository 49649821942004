<ng-container *transloco="let t; scope: 'shared'">
  <ng-container *ngIf="article$ | async as article; else articleNotFound">
    <ng-container *ngIf="article">
      <div
        *ngIf="viewMode === 'grid'; else listView"
        class="js-tile bg-white rounded-20px h-full max-w-tile px-6 pb-6 pt-3.5 relative flex flex-col z-10 border-2 border-transparent hover:border-primary-light transition-all duration-200"
      >
        <a
          [routerLink]="'/article/' + article?.sArticleID | localizeUrlPathAsync | async"
          [queryParams]="article?.sArticleID!.toString() | urlFilterParams"
          class="absolute inset-0 rounded-20px"
        ></a>
        <div class="flex items-center">
          <p class="text-small w-tile-cat truncate mr-auto" [innerHTML]="articleTitle ? articleTitle : parentCategoryName"></p>
          <div class="relative z-20 mr-0.5 last:mr-0 leading-none translate-y-px">
            <app-article-ordered [article]="article" flyoutPosition="top"></app-article-ordered>
          </div>
          <div class="relative z-20 mr-0.5 last:mr-0 leading-none translate-y-px">
            <app-article-note [article]="article"></app-article-note>
          </div>
          <div class="relative z-20 mr-0.5 last:mr-0 leading-none translate-y-px">
            <app-preferred-commission [item]="article"></app-preferred-commission>
          </div>
        </div>

        <div class="js-flyout-index absolute top-16 left-6 inline-block z-0 z-10">
          <app-article-shop-info [article]="article"></app-article-shop-info>
        </div>
        <app-image [image]="article | articleMainImage" classes="w-181px h-181px object-contain object-center mx-auto mt-5"></app-image>
        <div class="mt-18px mb-4">
          <span class="text-small font-montserrat font-bold leading-tight">{{ article.sArticleID }}</span>
          <p class="line-clamp-3 mt-2 min-h-60px" [innerHTML]="article.sDescription"></p>
        </div>

        <div class="min-h-60px">
          <div class="flex flex-row">
            <app-article-price [article]="article">
              <app-article-availability [article]="article" [showButton]="true"></app-article-availability>
            </app-article-price>
          </div>
        </div>

        <div class="flex items-center mt-auto">
          <app-add-to-cart [article]="article" [quantity]="quantity" (quantityChanged)="quantityChanged($event)"></app-add-to-cart>
          <div *ngIf="favorable" class="ml-6 2xl:ml-6 4xl:ml-5">
            <app-article-favorite [article]="article" [quantity]="quantity" size="l"></app-article-favorite>
          </div>
          <div *ngIf="removable" class="ml-6 2xl:ml-6 4xl:ml-5">
            <app-tooltip [text]="removeText">
              <button (click)="onRemove.emit(true)" class="js-show-flyout js-open-modal-delete group">
                <span
                  class="ifont ifont--delete text-icon text-primary text-3xl group-hover:text-gela-black transition-all duration-200 pointer-events-none"
                ></span>
              </button>
            </app-tooltip>
          </div>
        </div>
      </div>
      <ng-template #listView>
        <div class="js-tile pb-6 pt-4 relative flex z-10 w-full">
          <a [routerLink]="'/article/' + article?.sArticleID | localizeUrlPathAsync | async" class="absolute inset-0"></a>
          <div class="absolute z-0 top-6 left-0 inline-block">
            <app-article-shop-info [article]="article"></app-article-shop-info>
          </div>

          <app-image
            [image]="article | articleMainImage"
            class="w-40 h-40 object-contain object-center mt-2.5"
            classes="w-40 h-40 object-contain object-center mt-2.5"
          ></app-image>
          <div class="pl-5 -ml-1 w-full">
            <div class="flex items-center">
              <p class="text-small w-tile-cat truncate mr-auto">{{ articleTitle ? articleTitle : parentCategoryName }}</p>
              <div class="relative z-10 mr-0.5 last:mr-0 leading-none translate-y-1.5">
                <app-article-ordered [article]="article" flyoutPosition="top"></app-article-ordered>
              </div>
              <div class="relative z-20 mr-0.5 last:mr-0 leading-none translate-y-1.5">
                <app-article-note [article]="article"></app-article-note>
              </div>
              <div class="relative z-20 mr-0.5 last:mr-0 leading-none translate-y-1.5">
                <app-preferred-commission [item]="article"></app-preferred-commission>
              </div>
              <div *ngIf="removable" class="relative z-20 mr-0.5 last:mr-0 leading-none translate-y-1.5">
                <app-tooltip [text]="removeText">
                  <button (click)="onRemove.emit(true)" class="js-show-flyout js-open-modal-delete group">
                    <span
                      class="ifont ifont--delete text-icon text-primary group-hover:text-gela-black transition-all duration-200 pointer-events-none"
                    ></span>
                  </button>
                </app-tooltip>
              </div>
            </div>
            <div class="flex">
              <div class="mt-18px mb-4 pr-6">
                <span class="text-small font-montserrat font-bold leading-tight">{{ article.sArticleID }}</span>
                <p class="line-clamp-3 mt-2 min-h-60px" [innerHTML]="article.sDescription"></p>
              </div>
              <div class="mt-6 ml-auto shrink-0">
                <div class="flex">
                  <app-article-price [article]="article"></app-article-price>
                  <app-article-availability [article]="article" [showButton]="true"></app-article-availability>
                </div>

                <div class="flex items-center mt-auto">
                  <app-add-to-cart [article]="article" [quantity]="quantity" (quantityChanged)="quantityChanged($event)"></app-add-to-cart>
                  <div *ngIf="favorable" class="ml-6">
                    <app-article-favorite [article]="article" [quantity]="quantity" size="l"></app-article-favorite>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
  <ng-template #articleNotFound>
    <div
      *ngIf="viewMode === 'grid'; else listView"
      class="js-tile bg-white rounded-20px h-full max-w-tile px-6 pb-6 pt-3.5 relative flex flex-col z-10 border-2 border-transparent hover:border-primary-light transition-all duration-200"
    >
      {{ t("shared.article-item.item-not-found", { sku: articleId }) }}
    </div>
    <ng-template #listView>
      <div class="js-tile pb-6 pt-4 relative flex z-10 w-full">{{ t("shared.article-item.item-not-found", { sku: articleId }) }}</div>
    </ng-template>
  </ng-template>
</ng-container>
