import { Component, Input } from '@angular/core';
import { Params } from '@angular/router';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() text: string = '';
  @Input() classes: string = '';
  @Input() type: 'button' | 'submit' = 'button';
  @Input() href: string | undefined;
  @Input() linkParams: Params = {};
  @Input() public value: string | number | boolean = '';
  @Input() disabled: boolean = false;
  @Input() fullWidth: boolean = true;
}
