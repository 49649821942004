<div
  [ngClass]="{
    'bg-red-400': toastPackage.toastType === 'toast-error',
    'bg-primary-light': toastPackage.toastType !== 'toast-error'
  }"
  class="max-w-sm w-full shadow-flyout pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden rounded-20px p-6 flex items-start relative leading-tight"
>
  <button
    (click)="remove()"
    class="p-1 leading-none rounded-default group text-gela-black hover:bg-primary-bright transition-all duration-200 top-4 right-4 absolute"
  >
    <span class="ifont ifont--close text-icon-sml group-hover:text-primary-dark transition-all duration-200"></span>
  </button>
  <span
    [ngClass]="{
      'ifont--form-error': toastPackage.toastType === 'toast-error',
      'ifont--notification': toastPackage.toastType !== 'toast-error'
    }"
    class="ifont text-icon mr-2.5 text-gela-black"
  ></span>
  <p *ngIf="message && options.enableHtml" class="font-montserrat font-bold text-gela-black text-left pr-10" [innerHTML]="message"></p>
  <p *ngIf="message && !options.enableHtml" class="font-montserrat font-bold text-gela-black text-left pr-10">
    {{ message }}
  </p>
</div>
