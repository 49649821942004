<!-- start modal -->
<ng-container *transloco="let t; scope: 'shared'">
  <div class="js-login-modal fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <form
        class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-modal-big sm:w-full sm:p-6"
      >
        <div class="block absolute top-0 right-0 pt-4 pr-4 z-10 hover-pointer">
          <span
            (click)="cancel()"
            class="js-close-modal bg-white p-2 inline-flex items-center justify-center hover:text-primary hover:bg-gray-100 focus:text-primary rounded"
          >
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </span>
        </div>
        <div class="flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
            <h1 class="text-lg leading-6 font-bold mb-1" id="modal-title">{{ t("shared.modal-outline-agreement.title") }}</h1>
            <div class="mb-4">{{ t("shared.modal-outline-agreement.msg") }}</div>
            <div class="overflow-x-auto">
              <table class="min-w-full bg-white rounded-lg">
                <thead class="bg-primary-bright hidden md:table-header-group">
                  <tr>
                    <th class="text-xs md:text-sm font-montserrat font-bold text-right">
                      {{ t("shared.modal-outline-agreement.contract-number") }}
                    </th>
                    <th class="text-xs md:text-sm font-montserrat font-bold text-right">
                      {{ t("shared.modal-outline-agreement.contract-price") }}
                    </th>
                    <th class="text-xs md:text-sm font-montserrat font-bold text-right">
                      {{ t("shared.modal-outline-agreement.contract-available") }}
                    </th>
                    <th class="text-xs md:text-sm font-montserrat font-bold text-right">
                      {{ t("shared.modal-outline-agreement.contract-qty-total") }}
                    </th>
                    <th class="text-xs md:text-sm font-montserrat font-bold text-right">
                      {{ t("shared.modal-outline-agreement.contract-qty-called") }}
                    </th>
                    <th class="text-xs md:text-sm font-montserrat font-bold text-right">
                      {{ t("shared.modal-outline-agreement.contract-min-call") }}
                    </th>
                    <th class="text-xs md:text-sm font-montserrat font-bold"></th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-primary-light">
                  <ng-container *ngFor="let oOutlineAgreement of oOutlineAgreementArray">
                    <tr
                      *ngFor="let item of oOutlineAgreement.oOutlineAgreementItems | where : ['sArticleID', oCartItem.oArticle.sArticleID]"
                      class="w-full flex md:table-row relative ng-star-inserted"
                    >
                      <td class="p-2 basis-1/2 align-middle text-xs md:text-sm text-right">{{ item.lngContractID }}</td>
                      <td class="p-2 basis-1/12 align-middle text-xs md:text-sm text-right">
                        {{ item.decPrice !== null ? (item.decPrice | translocoCurrency) : "N/A" }}
                      </td>
                      <td class="p-2 mt-2 basis-1/12 flex justify-center items-center text-xs md:text-sm">
                        <app-article-availability
                          [article]="oCartItem.oArticle"
                          [shtStorageID]="oOutlineAgreement.shtStorageID"
                          [quantity]="item.decMinCall"
                        ></app-article-availability>
                      </td>
                      <td class="p-2 basis-1/2 align-middle text-xs md:text-sm text-right">{{ item.decQuantityTotal }}</td>
                      <td class="p-2 basis-1/2 align-middle text-xs md:text-sm text-right">{{ item.decQuantityCalled }}</td>
                      <td class="p-2 basis-1/2 align-middle text-xs md:text-sm text-right">{{ item.decMinCall }}</td>
                      <td class="p-2 basis-2/12 align-middle text-xs md:text-sm text-right">
                        <app-gela-button
                          [type]="'button'"
                          [look]="'primary'"
                          [size]="'small'"
                          [text]="t('shared.modal-outline-agreement.contract-btn')"
                          (click)="selectOutlineAgreement(oOutlineAgreement)"
                        ></app-gela-button>
                      </td>
                    </tr>
                  </ng-container>
                  <tr>
                    <td colspan="5" class="p-2 text-xs md:text-sm text-right">
                      <app-gela-button
                        [type]="'button'"
                        [look]="'secondary'"
                        [size]="'small'"
                        [text]="t('shared.modal-outline-agreement.contract-no-btn')"
                        (click)="cancel()"
                      ></app-gela-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
<!-- end modal -->
