import { Component, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { DomHelperService } from '../../../services/dom/dom-helper.service';
import { CmsMenu } from '../../../services/cms/interfaces/menu.interface';
import { filter, map, tap } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GelaCatalog } from '../../../services/catalog/model/gela-catalog';

@UntilDestroy()
@Component({
  selector: 'app-category-navigation',
  templateUrl: './category-navigation.component.html',
  styleUrls: ['./category-navigation.component.scss'],
})
export class CategoryNavigationComponent implements OnInit {
  @Input() menuLevel1: GelaCatalog[] | undefined | null;
  @Input() menu: CmsMenu[] | undefined | null;
  public cmsMenu: CmsMenu[] | null = null;

  constructor(@Inject(DOCUMENT) private document: Document, private location: Location, private router: Router) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.location.path().includes('cms')),
        tap((value: boolean) => {
          if (value) {
            this.setCmsMenu();
          } else {
            this.cmsMenu = null;
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  openNav(): void {
    const navEl: Element | null = this.document.querySelector('.js-nav');

    if (navEl?.classList.contains('hidden')) {
      DomHelperService.showContent(navEl);
      DomHelperService.bodyScrollDisable(this.document);
    } else {
      DomHelperService.hideContent(navEl);
      DomHelperService.bodyScrollEnable(this.document);

      DomHelperService.removeActiveStateLink(this.document.querySelector('.js-nav')!);
      this.document.querySelector('.js-nav-list--2')!.classList.add('hidden');
      this.document.querySelector('.js-nav-list--3')!.classList.add('hidden');
    }
  }

  setCmsMenu() {
    if (!this.menu) {
      return;
    }
    for (const item of this.menu) {
      if (this.location.path().substring(8).startsWith(item.url)) {
        this.cmsMenu = item.children || [];
      }
    }
  }
}
