import { Pipe, PipeTransform } from '@angular/core';
import { Categories } from '../../services/cms/interfaces/cms-page.interface';

@Pipe({
  name: 'cmsBlogCategoryList',
})
export class CmsBlogCategoryListPipe implements PipeTransform {
  public transform(categories: Categories | null): { key: string; value: string }[] {
    const normalizedCategories: { key: string; value: string }[] = [];
    if (!categories) {
      return normalizedCategories;
    }

    for (const [key, value] of Object.entries(categories)) {
      normalizedCategories.push({
        key: key,
        value: value,
      });
    }

    return normalizedCategories;
  }
}
