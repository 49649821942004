import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pathLength',
})
export class PathLengthPipe implements PipeTransform {
  public transform(urlPath: string | string[]): boolean {
    return Array.isArray(urlPath) ? urlPath[0].split('/').length < 3 : urlPath.split('/').length < 3;
  }
}
