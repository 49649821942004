import { Pipe, PipeTransform } from '@angular/core';
import { CmsImageService } from '../../services/cms/cms-image.service';
import { CloudinaryImage } from '@cloudinary/url-gen';

@Pipe({
  name: 'cmsCloudinaryImage',
})
export class CmsCloudinaryImagePipe implements PipeTransform {
  transform(src: string | undefined, params?: { width: number; height: number }): CloudinaryImage {
    return CmsImageService.buildCloudinaryUrl(src ? src : 'test', params);
  }
}
