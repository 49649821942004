<div
  *transloco="let t; scope: 'header'"
  class="js-header-big-wrap transition-all duration-200 flex items-center flex-wrap mobile-nav:flex-nowrap max-w-page 3xl:max-w-midscreen 4xl:max-w-bigscreen px-4 md:px-10 tablet:px-82px mx-auto pt-3 mobile-nav:pt-9 pb-4 mobile-nav:pb-11"
>
  <a [routerLink]="'/' | localizeUrlPathAsync | async" class="js-logo-compact-wrap lg:mr-0 self-center hidden">
    <img
      src="assets/images/layout/geiser_logo--compact_g.svg"
      alt="Rudolf Geiser AG"
      class="w-full mr-7 mobile-nav:mr-0 w-9 mobile-nav:w-14"
    />
  </a>
  <a
    [routerLink]="'/' | localizeUrlPathAsync | async"
    class="js-logo-wrap max-w-xs mobile-nav:max-w-none w-2/3 sm:w-1/2 mobile-nav:w-auto mb-5 lg:mb-0 mr-18px tablet-big:mr-auto lg:mr-0 3xl:mr-18px relative self-center"
  >
    <div class="flex w-10/12 sm:w-full mobile-nav:w-auto">
      <img src="assets/images/layout/geiser_logo--compact.svg" alt="Rudolf Geiser AG" class="js-header-logo h-[62px]" />

      <ng-container *ngIf="currentVersionKey$ | async as currentVersionKey">
        <img
          *ngIf="currentVersionKey === 'de'"
          src="assets/images/layout/geiser_logo--claim_DEU.svg"
          alt="Rudolf Geiser AG"
          class="js-header-compact--claim ml-0.5 mt-0.5 h-[60px]"
        />
        <img
          *ngIf="currentVersionKey === 'fr'"
          src="assets/images/layout/geiser_logo--claim_FRA.svg"
          alt="Rudolf Geiser AG"
          class="js-header-compact--claim ml-0.5 mt-0.5 h-[60px]"
        />
      </ng-container>
    </div>
  </a>
  <nav class="js-header-compact--navbar hidden ml-7 compact-header-sml:ml-10 xl:ml-12 2xl:ml-24 mr-6 whitespace-nowrap self-end items-end">
    <!--@lobos this is active state for this link -->
    <a
      [routerLink]="'/' | localizeUrlPathAsync | async"
      [title]="t('header.main-navigation.shop-title')"
      class="text-gela-black hover:text-gela-black font-montserrat font-bold transition-all duration-200 leading-tight mr-4 lg:mr-5 xl:mr-7 last:mr-0 relative pb-7 mt-3"
    >
      {{ t("header.main-navigation.shop-title") }}
      <span class="w-full h-5px bg-gela-black inline-block absolute left-0 bottom-0 transition-all duration-200 opacity-100"></span>
    </a>
    <div class="self-start mt-1.5">
      <button
        (click)="openNav()"
        class="js-open-nav font-montserrat font-bold text-gela-black rounded-button-nav flex items-center py-17px xl:py-9px pr-18px xl:pr-5 pl-14 xl:pl-16 bg-primary-bright hover:bg-primary-light transition-all duration-200 mr-4 lg:mr-5 xl:mr-7 relative"
      >
        <span class="ifont ifont--burger text-2xl xl:text-3xl xl:mr-4 absolute left-1/2 xl:left-5 -translate-x-1/2 xl:translate-x-0"></span>
        <span class="hidden xl:inline-block text-small leading-tight">{{ t("header.navigation.title") }}</span>
      </button>
    </div>
    <div class="flex flex-wrap xl:flex-nowrap">
      <!-- link: nav compact start -->
      <a
        *ngFor="let menuItem of menu"
        [routerLink]="'cms/' + menuItem.url | localizeUrlPathAsync | async"
        [title]="menuItem.title"
        class="leading-tight text-primary hover:text-gela-black font-montserrat font-bold transition-all duration-200 mr-4 lg:mr-5 xl:mr-7 last:mr-0 relative pb-7 mt-3"
      >
        {{ menuItem.title }}
      </a>
    </div>
  </nav>
  <button
    (click)="increaseSearchBar($event)"
    class="js-header-search-compact-show hidden text-primary leading-none hover:text-primary-dark hover:bg-primary-bright rounded-default p-2 transition-all duration-200 mt-2 ml-auto"
  >
    <span class="ifont ifont--search text-3xl pointer-events-none -translate-x-px"></span>
  </button>
  <!--  <app-main-search class="js-header-search ml-0 desktop-xs:ml-6 xl:ml-68px 3xl:ml-auto self-center relative"></app-main-search>-->
  <app-main-search
    class="js-header-search ml-0 desktop-xs:ml-6 xl:ml-68px 3xl:ml-auto self-center basis-search-tablet desktop-xs:basis-search-sml xl:basis-search 3xl:basis-search-big"
  ></app-main-search>
  <button
    (click)="scannerHelper.openScanner()"
    class="js-header-scan ml-1 -mr-4 tablet:mr-0 2xl:mr-68px xl:mr-auto p-7px mobile-nav:p-0 mobile-nav:w-12 mobile-nav:h-12 flex items-center justify-center leading-none rounded-default group hover:bg-primary-bright transition-all duration-200 bg-primary-bright"
  >
    <span
      class="ifont ifont--scanner text-2xl mobile-nav:text-3xl text-primary group-hover:text-primary-dark transition-all duration-200"
    ></span>
  </button>
  <div class="js-icons hidden mobile-nav:flex ml-auto">
    <!-- @lobos only render class js-show-info if user is logged in -->

    <app-user-flyout class="js-show-info relative self-center leading-tight mr-1.5 3xl:mr-1-9vw last:mr-0"></app-user-flyout>
    <app-favorites-flyout class="js-show-info relative self-center leading-tight mr-1.5 3xl:mr-1-9vw last:mr-0"></app-favorites-flyout>
    <app-cart-flyout class="js-show-info relative self-center leading-tight mr-1.5 3xl:mr-1-9vw last:mr-0"></app-cart-flyout>
  </div>
</div>
