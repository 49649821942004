<svg
  version="1.1"
  id="Ebene_1"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  viewBox="0 0 12.2 11.7"
  style="enable-background: new 0 0 12.2 11.7"
  xml:space="preserve"
>
  <style type="text/css">
    .st0 {
      display: none;
      fill: #ffffff;
    }
    .st1 {
      fill: #ffffff;
    }
    .st2 {
      fill: #3c3c3b;
    }
    .st3 {
      fill: none;
      stroke: #3c3c3b;
      stroke-miterlimit: 10;
    }
  </style>
  <path
    class="st0"
    d="M13.9,13.9c-5.3,0-10.7,0-16,0c0-5.3,0-10.7,0-16c5.3,0,10.7,0,16,0C13.9,3.2,13.9,8.5,13.9,13.9z M-0.1,6.2
	C-0.2,9,2.4,11.8,6,11.9c3.6,0.1,6.3-2.7,6.3-5.8C12.3,2.8,9.7,0,6,0C4.4,0,2.9,0.6,1.7,1.7C0.5,2.8-0.1,4.2-0.1,6.2z"
  />
  <path
    class="st1"
    d="M6,1.2c1,0,1.9,0.2,2.7,0.7c1.2,0.8,2,1.8,2.2,3.2c0.2,1.4-0.1,2.7-1,3.9c-0.7,0.7-1.5,1.3-2.4,1.6
	c-0.5,0.1-1.1,0.2-1.6,0.2c-1.7-0.1-3.1-0.8-4-2.2C0.3,6.3,1,3,3.9,1.6C4.6,1.3,5.3,1.2,6,1.2z M6.7,5.3c-0.4,0-0.8,0-1.2,0
	c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.2,0,0.4c0,0.9,0,1.9,0,2.8c0,0.2,0,0.2,0.2,0.2c0.3,0,0.6,0,0.9,0c0.2,0,0.2,0,0.2-0.2
	c0-1.1,0-2.1,0-3.2C6.7,5.4,6.7,5.4,6.7,5.3z M6.8,3.8c0-0.4-0.3-0.7-0.8-0.7c-0.4,0-0.7,0.3-0.8,0.6c0,0.4,0.3,0.7,0.7,0.8
	C6.5,4.5,6.8,4.2,6.8,3.8z"
  />
  <path
    class="st2"
    d="M6.7,5.3c0,0,0,0.1,0,0.1c0,1.1,0,2.1,0,3.2c0,0.1,0,0.2-0.2,0.2c-0.3,0-0.6,0-0.9,0c-0.2,0-0.2-0.1-0.2-0.2
	c0-0.9,0-1.9,0-2.8c0-0.1,0-0.2,0-0.4c0-0.1,0-0.1,0.1-0.1C5.9,5.3,6.3,5.3,6.7,5.3z"
  />
  <path class="st2" d="M6.8,3.8c0,0.4-0.3,0.7-0.8,0.7c-0.4,0-0.8-0.3-0.7-0.8c0-0.4,0.3-0.6,0.8-0.6C6.5,3.1,6.8,3.4,6.8,3.8z" />
  <ellipse class="st3" cx="6.1" cy="5.8" rx="5.6" ry="5.3" />
</svg>
