import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Article, CartItem, PriceQueryInterface, SalesOrderItem, StockAvailability } from '@lobos/common';
import { Catch, PriceService } from '@lobos/library-v2';
import { Observable, of } from 'rxjs';
import { isPlatformServer } from '@angular/common';
import { GelaPrice } from './model/gela-price';
import { GelaStockAvailabilityService } from '../stock-availability/gela-stock-availability.service';
import { GelaStockAvailabilityQueryInterface } from '../stock-availability/gela-stock-availability-query.interface';

@Injectable({
  providedIn: 'root',
})
export class ArticleHelperService {
  constructor(
    private priceService: PriceService<GelaPrice>,
    @Inject(PLATFORM_ID) private _platformId: any,
    protected stockAvailabilityService: GelaStockAvailabilityService,
  ) {}

  public static itemIsMaster(item: CartItem | SalesOrderItem): boolean {
    if (item.shtFixedItemIDItemGroup) {
      return item.shtFixedItemIDItemGroup === 0 || item.shtFixedItemIDItemGroup === item.shtFixedItemID;
    } else {
      return true;
    }
  }

  public static itemIsChildOf(child: CartItem | SalesOrderItem, master: CartItem | SalesOrderItem): boolean {
    if (child.shtFixedItemIDItemGroup) {
      return (
        child.shtFixedItemIDItemGroup !== 0 &&
        child.shtFixedItemIDItemGroup === master.shtFixedItemIDItemGroup &&
        child.shtFixedItemIDItemGroup !== child.shtFixedItemID
      );
    } else {
      return false;
    }
  }

  @Catch({
    label: 'ArticleHelperService.getPriceFromApi()',
  })
  public getPriceFromApi(priceQueryKey: string, priceQuery: PriceQueryInterface): Observable<GelaPrice | undefined> {
    if (isPlatformServer(this._platformId)) {
      return of(undefined);
    }

    return this.priceService.getByPriceQuery(priceQuery, priceQueryKey);
  }

  public getAvailability(article: Article): Observable<StockAvailability | undefined> {
    const params: GelaStockAvailabilityQueryInterface = {
      sArticleID: article.sArticleID,
      decQuantity: 0,
      dtDeliveryDate: 0,
      shtStorageID: 0,
    };
    return this.stockAvailabilityService.get(params);
  }
}
