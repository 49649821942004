import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[appLoading]',
})
export class LoadingDirective {
  @Input()
  @HostBinding('class.app-loading')
  appLoading: boolean | null = null;
}
