import { Pipe, PipeTransform } from '@angular/core';
import { Roles } from '../../features/cms/cms-components/team/team.component';

@Pipe({
  name: 'cmsRoleList',
})
export class CmsRoleListPipe implements PipeTransform {
  public transform(roles: Roles | null): { key: string; value: string }[] {
    const normalizedRoles: { key: string; value: string }[] = [];
    if (!roles) {
      return normalizedRoles;
    }

    for (const [key, value] of Object.entries(roles)) {
      normalizedRoles.push({
        key: key,
        value: value,
      });
    }

    return normalizedRoles;
  }
}
