import { Injectable } from '@angular/core';
import { ElasticSearchService, HookEvent, HookHandlerAbstract, HookPriority, LanguageChangedHook } from '@lobos/library-v2';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';

type type = LanguageChangedHook;

@Injectable({
  providedIn: 'root',
})
export class LanguageChangedChangeElasticSearchIndexHandler extends HookHandlerAbstract<type, Observable<string>> {
  constructor(private elasticSearch: ElasticSearchService, private transloco: TranslocoService) {
    super();
  }

  override priority(): number {
    return HookPriority.HIGHEST;
  }

  ids(): type[] {
    return [{ type: 'LanguageChangedHook' }];
  }

  override after(event: HookEvent<type, Observable<string>>, result: Observable<string>): Observable<string> {
    this.elasticSearch.setIndexLanguage(this.transloco.getActiveLang());

    return super.after(event, result);
  }
}
