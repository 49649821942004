<button
  *ngIf="!href"
  [type]="type"
  class="font-montserrat font-bold px-6 py-4 rounded-button transition-all duration-200"
  [ngClass]="classes"
  [class.opacity-50]="disabled"
  [class.pointer-events-none]="disabled"
  [class.w-full]="fullWidth"
  [value]="value"
  [disabled]="disabled"
>
  {{ text }}
</button>

<a
  *ngIf="href"
  class="font-montserrat font-bold px-6 py-4 rounded-button transition-all duration-200"
  [ngClass]="classes"
  [class.opacity-50]="disabled"
  [class.pointer-events-none]="disabled"
  [class.w-full]="fullWidth"
  [routerLink]="href"
  [queryParams]="linkParams"
>
  {{ text }}
</a>
