<div
  class="js-select-wrap relative z-0 rounded-default font-normal"
  *transloco="let t"
  [class.bg-primary-light]="grey"
  [class.bg-white]="!grey"
>
  <button (click)="toggleIsOpen()" type="button" class="js-select js-select--table w-full text-left relative pl-3.5 pr-16 h-61px">
    <span class="js-select-text block text-gela-black pointer-events-none font-montserrat font-bold text-small leading-tight">{{
      label
    }}</span>
    <span
      class="js-select-default ifont ifont--table-filter text-3xl absolute top-1/2 -translate-y-1/2 right-8 pointer-events-none"
      [class.opacity-0]="values.length"
    ></span>
    <span
      class="js-select-active ifont ifont--table-filter-active text-3xl absolute top-1/2 -translate-y-1/2 right-10 -mr-1 w-5 h-5 rounded-full bg-gela-black text-white inline-flex items-center justify-center pointer-events-none"
      [class.opacity-0]="!values.length"
    ></span>
    <span
      class="js-select-icon ifont ifont--chevron-down text-3xl absolute top-1/2 -translate-y-1/2 right-1.5 pointer-events-none"
      [class.rotate-180]="isOpen"
    ></span>
  </button>
  <div
    class="js-select-options js-select-options--table max-h-232px overflow-y-auto scrollbar w-full bg-white divide-y divide-primary-light py-2.5 absolute left-0 border-r border-l border-b border-primary-light rounded-b-default"
    [class.hidden]="!isOpen"
  >
    <div class="divide-y divide-primary-light">
      <div
        *ngFor="let option of options"
        class="js-checkbox-wrap px-6 relative w-full flex items-center cursor-pointer break-words hover:bg-primary-bright transition-all duration-200"
      >
        <div class="flex items-center h-4 cursor-pointer">
          <input
            [id]="addPrefix(option.key)"
            [name]="name"
            [value]="option.key"
            type="checkbox"
            (change)="toggle(option.key)"
            class="js-checkbox bg-transparent cursor-pointer h-4 w-4 text-primary border border-gela-black focus:ring-0 ring-offset-transparent ring-transparent checked:bg-secondary focus:checked:bg-secondary checked:hover:bg-secondary checked:bg-none"
            [checked]="values.includes(option.key)"
          />
        </div>
        <div class="ml-3 w-full cursor-pointer">
          <label
            [for]="addPrefix(option.key)"
            class="js-checkbox-label py-3 text-left text-small font-montserrat font-bold w-full inline-block cursor-pointer"
          >
            {{ translateValue ? t($any(option.value)) : option.value }}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
