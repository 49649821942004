import { Pipe, PipeTransform } from '@angular/core';
import { CatalogInfo } from '../../services/catalog/model/catalog-info';
import { Location } from '@angular/common';

@Pipe({
  name: 'catalogInfo',
})
export class CatalogInfoPipe implements PipeTransform {
  constructor(private location: Location) {}

  transform(items: CatalogInfo[]): CatalogInfo | undefined {
    const urlLocation = this.location.path().split('?')[0].slice(4);
    return items.find((item) => item.sUrlPath.includes(urlLocation)) || items[0];
  }
}
