import { Component, Input, OnInit } from '@angular/core';
import { HierarchicalCategory, Hierarchy, Product } from '@lobos/common';
import { ProductQuery } from '@lobos/library-v2';
import { iif, Observable, of, switchMap } from 'rxjs';
import { GelaProductService } from '../../services/catalog/gela-product.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
})
export class ProductItemComponent implements OnInit {
  @Input() product?: Product;
  @Input() viewMode: 'grid' | 'list' = 'grid';
  @Input() articleCount: number = 0;
  @Input() productNumber?: string;
  product$?: Observable<Product | undefined>;
  parentCategoryName: string | undefined;

  constructor(private productService: GelaProductService, private productQuery: ProductQuery) {}

  ngOnInit(): void {
    this.product$ = iif(
      () => !!this.product,
      of(this.product),
      of(this.productNumber).pipe(
        switchMap((productNumber: string | undefined) =>
          iif(
            () => this.productQuery.hasEntity((product: Product) => product!.sNavname === productNumber),
            this.productQuery.selectEntity((product: Product) => product!.sNavname === productNumber),
            this.productService
              .getByNavName(productNumber!)
              .pipe(switchMap(() => this.productQuery.selectEntity((product: Product) => product!.sNavname === productNumber))),
          ),
        ),
        tap((product: Product | undefined) => {
          if (product) {
            this.articleCount = product.oArticles.length;
          }
        }),
      ),
    ).pipe(
      tap((product: Product | undefined) => {
        const categories: HierarchicalCategory[] = (product?.listHierarchicalCategories[0] as Hierarchy).hierarchy;
        this.parentCategoryName = categories[categories.length - 2].sValue || product?.sTitle;
      }),
    );
  }
}
