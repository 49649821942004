import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ArticleQuery, ArticleService } from '@lobos/library-v2';
import { UntilDestroy } from '@ngneat/until-destroy';
import { iif, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { GelaArticle } from '../../services/catalog/model/gela-article';
import { HierarchicalCategory, Hierarchy } from '@lobos/common';
import { ComnormService } from '../../services/comnorm/comnorm.service';

@UntilDestroy()
@Component({
  selector: 'app-article-item',
  templateUrl: './article-item.component.html',
  styleUrls: ['./article-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleItemComponent implements OnInit {
  @Input() article?: GelaArticle;
  @Input() articleId?: string;
  @Input() quantity = 1;
  @Input() removable: boolean = false;
  @Input() removeText: string = '';
  @Input() favorable: boolean = true;
  @Input() viewMode: 'grid' | 'list' = 'grid';
  @Input() articleTitle: string = '';
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onQuantityChange: EventEmitter<number> = new EventEmitter<number>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onRemove: EventEmitter<boolean> = new EventEmitter<boolean>();
  article$?: Observable<GelaArticle | undefined>;
  parentCategoryName: string | undefined;

  constructor(
    private articleQuery: ArticleQuery<GelaArticle>,
    private articleService: ArticleService<GelaArticle>,
    protected comNormService: ComnormService,
  ) {}

  ngOnInit() {
    this.article$ = iif(
      () => !!this.article,
      // true
      of(this.article),
      // false
      of(this.articleId).pipe(
        switchMap((id?: string) =>
          iif(
            () => this.articleQuery.hasEntity(id!),
            this.articleQuery.selectEntity(id!),
            this.articleService.getArticleByID(id!).pipe(switchMap(() => this.articleQuery.selectEntity(id!))),
          ),
        ),
      ),
    ).pipe(
      tap((article) => {
        if (this.quantity === 1) {
          this.quantity =
            article && article?.decQuantityPackage > 0 && article.shtNoPackageBreak >= 1 ? article?.decQuantityPackage : this.quantity;
        }
      }),
      tap((article) => {
        const categories: HierarchicalCategory[] = (article?.oProductInfo[0]?.listHierarchicalCategories[0] as Hierarchy).hierarchy;
        if (categories && categories.length) {
          this.parentCategoryName = categories[categories.length - 2]?.sValue || article?.oProductInfo[0]?.sTitle || '';
        } else {
          this.parentCategoryName = '';
        }
      }),
    );
  }

  quantityChanged(quantity: number) {
    this.quantity = quantity;
    this.onQuantityChange.emit(quantity);
  }
}
