import { Pipe, PipeTransform } from '@angular/core';
import { Roles } from '../../features/cms/cms-components/team/team.component';

@Pipe({
  name: 'cmsReadableRole',
})
export class CmsReadableRolePipe implements PipeTransform {
  public transform(role: string, roles: Roles): string {
    let returnRole = '';
    if (roles) {
      returnRole = roles[role];
    }
    return returnRole;
  }
}
