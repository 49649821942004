import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { DomHelperService } from '../../services/dom/dom-helper.service';
import { MobileNavId } from '../footer-mobile-navbar/footer-mobile-navbar.component';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent {
  public activeMobileNav = MobileNavId.NONE;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  setActiveMobileNav(nav: MobileNavId) {
    this.activeMobileNav = nav;

    if (nav === MobileNavId.NONE) {
      DomHelperService.bodyScrollEnable(this.document);
    } else {
      DomHelperService.bodyScrollDisable(this.document);
    }
  }
}
