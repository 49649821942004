import { Injectable } from '@angular/core';
import { StringHelper, UrlHelperService } from '@lobos/library-v2';
import { TranslocoService } from '@ngneat/transloco';
import { Parameter } from '@lobos/common';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CatalogHelperService {
  constructor(private translocoService: TranslocoService, private urlHelper: UrlHelperService, private location: Location) {}

  public localizedUrlPath(_params: Parameter | undefined, path: string, language: string): string {
    //const catalogName = this.urlHelper.normalize(params.sCatalogName);
    const urlPath = StringHelper.trim(`${language}/${path}`, '/');
    return `/${urlPath}`;
  }

  public getCatalogPathByLocation(path: string | string[]): string {
    if (Array.isArray(path)) {
      const urlLocation = this.location.path().split('?')[0].slice(4);
      return path.find((item) => item.includes(urlLocation)) || path[0];
    }

    return path;
  }
}
