import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestDebounceService } from '@lobos/library-v2';
import { Observable, of, switchMap } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { GelaArticle } from '../../catalog/model/gela-article';
import { CustomerArticleNoteInterface } from './customer-article-note.interface';
import { CustomerArticleNoteStore } from './customer-article-note.store';

@Injectable({ providedIn: 'root' })
export class CustomerArticleNoteService {
  constructor(private http: HttpClient, private store: CustomerArticleNoteStore, private debouncer: RequestDebounceService) {}

  public getForArticle(articleId: string): Observable<CustomerArticleNoteInterface> {
    return of(undefined).pipe(
      tap(() => this.store.setLoading(true)),
      switchMap(() => this.http.get<CustomerArticleNoteInterface>(`api/customer-article-note/${articleId}`)),
      tap((note: CustomerArticleNoteInterface) => this.store.upsert(note.id, note)),
      finalize(() => this.store.setLoading(false)),
    );
  }

  public getAll(): Observable<CustomerArticleNoteInterface[]> {
    return of(undefined).pipe(
      tap(() => this.store.setLoading(true)),
      switchMap(() =>
        this.debouncer.debounceAll<CustomerArticleNoteInterface, CustomerArticleNoteStore>(
          this.store,
          this.http.get<CustomerArticleNoteInterface[]>(`api/customer-article-note`),
        ),
      ),
      tap(() => this.store.setHasCache(true)),
      finalize(() => this.store.setLoading(false)),
    );
  }

  public createForArticle(article: GelaArticle, note: string): Observable<CustomerArticleNoteInterface> {
    return of(undefined).pipe(
      tap(() => this.store.setLoading(true)),
      switchMap(() =>
        this.http.post<CustomerArticleNoteInterface>(`api/customer-article-note`, {
          note,
          articleId: article.sArticleID,
        }),
      ),
      tap((note: CustomerArticleNoteInterface) => this.store.upsert(note.id, note)),
      finalize(() => this.store.setLoading(false)),
    );
  }

  public remove(note: CustomerArticleNoteInterface): Observable<CustomerArticleNoteInterface> {
    return of(undefined).pipe(
      tap(() => this.store.setLoading(true)),
      switchMap(() => this.http.delete<CustomerArticleNoteInterface>(`api/customer-article-note/${note.id}`)),
      tap(() => this.store.remove(note.id)),
      finalize(() => this.store.setLoading(false)),
      map(() => note),
    );
  }
}
