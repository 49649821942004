<div class="js-input relative w-full" (click)="startAnimation()" [class.js-has-error]="hasError">
  <label
    [for]="name"
    [ngClass]="{
      'top-0 scale-81.25': !!(form ? form.value[name] : value),
      'bg-white top-1/2': !(form ? form.value[name] : value)
    }"
    class="js-label origin-left px-1 transition-all duration-200 absolute text-primary-dark -translate-y-1/2 left-3 pointer-events-none origin-left"
  >
    <span class="relative z-20 text-primary-dark">{{ label }} <ng-container *ngIf="required">*</ng-container></span>
    <span class="js-label-border bg-white w-full h-1 left-0 top-2.5 absolute"></span>
  </label>

  <ng-container *ngIf="form" [formGroup]="form">
    <input
      [type]="showPassword ? 'text' : 'password'"
      [name]="name"
      [placeholder]="placeholder"
      [required]="required"
      [formControlName]="name"
      [class.border-error]="hasError"
      (focus)="startAnimation()"
      (blur)="startAnimation()"
      (focusout)="resetAnimation($event)"
      autocomplete="off"
      tabindex="0"
      class="w-full py-3.5 pr-3 pl-3.5 rounded-default border-2 leading-tight rounded-default focus:ring-0 focus:border-primary placeholder:text-primary-dark"
      [ngClass]="hasError ? 'border-secondary-dark' : 'border-primary-light'"
    />
  </ng-container>

  <input
    *ngIf="!form"
    [type]="showPassword ? 'text' : 'password'"
    [name]="name"
    [placeholder]="placeholder"
    [required]="required"
    [(ngModel)]="value"
    (ngModelChange)="valueChangeDebouncer.next(value)"
    [class.border-error]="hasError"
    tabindex="0"
    autocomplete="off"
    (focusout)="resetAnimation($event)"
    (focus)="startAnimation()"
    (blur)="startAnimation()"
    class="w-full py-3.5 pr-3 pl-3.5 rounded-default border-2 leading-tight rounded-default focus:ring-0 focus:border-primary placeholder:text-primary-dark"
    [ngClass]="hasError ? 'border-secondary-dark' : 'border-primary-light'"
  />

  <button (click)="toggleShow()" type="button" class="cursor-pointer absolute right-18px top-3.5 w-6 h-6">
    <span [class.opacity-0]="showPassword" class="ifont ifont--show text-2xl absolute top-0 left-0 text-primary"></span>
    <span
      [class.opacity-100]="showPassword"
      [class.opacity-0]="!showPassword"
      class="ifont ifont--hide text-2xl absolute top-0 left-0 text-primary"
    ></span>
  </button>
</div>
<ng-container *ngIf="hasError && errorText">
  <span class="ifont ifont--form-error text-3xl absolute text-secondary-dark right-1 top-2.5"></span>
  <p class="text-secondary-dark text-small mt-1.5 leading-tight -mb-0.5">{{ errorText }}</p>
</ng-container>
