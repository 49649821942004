<div
  class="js-select-wrap relative z-20 text-center font-normal"
  *transloco="let t"
  [ngClass]="{
    'rounded-button': !isOpen,
    'rounded-t-20px': isOpen,
    'opacity-50': form ? form.controls[name] && form.controls[name].disabled : disabled
  }"
>
  <label
    class="js-label origin-left px-1 transition-all duration-200 absolute -translate-y-1/2 left-3 pointer-events-none z-10"
    [ngClass]="{ 'top-0 scale-81.25': !!value, 'bg-white text-primary-dark top-1/2': !value }"
  >
    <span class="relative z-20 text-primary-dark">{{ label }}</span>
    <span
      class="js-label-border w-full h-1 left-0 top-2.5 absolute z-10"
      [class.bg-primary-bright]="grey && value"
      [class.bg-white]="!grey"
    ></span>
  </label>
  <button
    type="button"
    [disabled]="form ? form.controls[name] && form.controls[name].disabled : disabled"
    [class.border-error]="hasError"
    [ngClass]="hasError ? 'border-secondary-dark' : 'border-primary-light'"
    class="js-select bg-white text-left relative pr-12 py-3.5 pl-3.5 flex w-full items-center justify-start border-2 border-primary-light rounded-default"
    (click)="toggleIsOpen()"
  >
    <span class="js-select-text block max-w-sm truncate text-gela-black pointer-events-none leading-tight pr-10">
      {{ showValue && value ? (translateValue ? t($any(options | getValueFromKey : value)) : (options | getValueFromKey : value)) : label }}
    </span>
    <span
      class="js-select-icon ifont ifont--chevron-down text-3xl absolute top-1/2 -translate-y-1/2 right-3 pointer-events-none"
      [ngClass]="{ 'rotate-180': isOpen }"
    ></span>
  </button>
  <div
    [class.hidden]="!isOpen"
    class="js-select-options text-left w-full bg-white py-2.5 absolute left-0 border-r border-l border-b border-primary-light rounded-b-default z-30 -translate-y-px"
  >
    <div *ngIf="editMode" class="mt-3 mb-1 px-6">
      <div class="relative">
        <form (submit)="submitNew()">
          <input
            type="text"
            class="js-select-add w-full py-3.5 pr-90px pl-3.5 border-2 border-primary-light leading-tight rounded-default focus:ring-0 focus:border-primary placeholder:text-primary-dark"
            [(ngModel)]="newValue"
            [name]="'new-' + name"
            [placeholder]="newPlaceholder"
            aria-required="true"
            [required]="true"
            [attr.aria-label]="newPlaceholder"
            (focusin)="editInputFocused.emit(true)"
            (focusout)="editInputFocused.emit(false)"
          />
          <span class="w-px h-10 bg-primary-light top-1.5 absolute right-12"></span>
          <button
            type="submit"
            class="js-select-add-btn absolute w-12 h-12 leading-none right-0 top-1/2 -translate-y-1/2 right-0.5 text-primary transition-all duration-200 hover:text-white hover:bg-secondary"
          >
            <span class="ifont ifont--add-big text-2xl pointer-events-none"></span>
          </button>
        </form>
      </div>
    </div>

    <!-- TODO find a less copy & paste way -->
    <ng-container *ngIf="form" [formGroup]="form">
      <div [ngClass]="hasMaxHeight ? 'max-h-173px overflow-y-auto scrollbar scrollbar--select' : ''" class="divide-y divide-primary-light">
        <div
          *ngFor="let option of options"
          class="px-6 relative w-full flex items-center cursor-pointer hover:bg-primary-bright transition-all duration-200"
        >
          <div class="flex items-center h-4 cursor-pointer">
            <input
              [id]="addPrefix(option.key)"
              [name]="name"
              [value]="option.key"
              [formControlName]="name"
              type="radio"
              class="js-radio js-radio-change cursor-pointer bg-transparent h-4 w-4 text-primary border border-gela-black ring-offset-transparent ring-transparent focus:ring-0 checked:bg-secondary focus:checked:bg-secondary checked:hover:bg-secondary checked:bg-none"
              (change)="select(option.key)"
            />
          </div>
          <div class="ml-3 w-full cursor-pointer">
            <label
              [for]="addPrefix(option.key)"
              class="js-radio-label py-3 text-small font-montserrat font-bold w-full inline-block cursor-pointer flex items-center"
            >
              <span class="js-radio-label--text">
                {{ translateValue ? t($any(option.value || "")) : option.value || "" }}
              </span>
            </label>
          </div>
        </div>
        <div
          *ngIf="!options.length && emptyValue"
          class="px-6 relative w-full flex items-center cursor-pointer hover:bg-primary-bright transition-all duration-200"
        >
          <div class="ml-3 w-full cursor-pointer">
            <label class="js-radio-label py-3 text-small font-montserrat font-bold w-full inline-block cursor-pointer flex items-center">
              <span class="js-radio-label--text">
                {{ emptyValue }}
              </span>
            </label>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!form">
      <div class="divide-y divide-primary-light">
        <div
          *ngFor="let option of options"
          class="px-6 relative w-full flex items-center cursor-pointer hover:bg-primary-bright transition-all duration-200"
        >
          <div class="flex items-center h-4 cursor-pointer">
            <input
              [id]="addPrefix(option.key)"
              [name]="name"
              [value]="option.key"
              type="radio"
              (change)="select(option.key)"
              class="js-radio js-radio-change cursor-pointer bg-transparent h-4 w-4 text-primary border border-gela-black ring-offset-transparent ring-transparent focus:ring-0 checked:bg-secondary focus:checked:bg-secondary checked:hover:bg-secondary checked:bg-none"
              [checked]="option.key === value"
            />
          </div>
          <div class="ml-3 w-full cursor-pointer">
            <label
              [for]="addPrefix(option.key)"
              class="js-radio-label py-3 text-small font-montserrat font-bold w-full inline-block cursor-pointer flex items-center"
            >
              <span class="js-radio-label--text">
                {{ translateValue ? t($any(option.value || "")) : option.value || "" }}
              </span>
            </label>
          </div>
        </div>
        <div
          *ngIf="!options.length && emptyValue"
          class="px-6 relative w-full flex items-center cursor-pointer hover:bg-primary-bright transition-all duration-200"
        >
          <div class="ml-3 w-full cursor-pointer">
            <label class="js-radio-label py-3 text-small font-montserrat font-bold w-full inline-block cursor-pointer flex items-center">
              <span class="js-radio-label--text">
                {{ emptyValue }}
              </span>
            </label>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<ng-container *ngIf="hasError && errorText">
  <span class="ifont ifont--form-error text-3xl absolute text-secondary-dark right-1 top-2.5"></span>
  <p class="text-secondary-dark text-small mt-1.5 leading-tight -mb-0.5">{{ errorText }}</p>
</ng-container>
