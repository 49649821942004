import { Pipe, PipeTransform } from '@angular/core';
import { GelaCustomer } from '../../interfaces/customer/gela-customer.interface';

@Pipe({
  name: 'truckRouteDays',
})
export class TruckRouteDaysPipe implements PipeTransform {
  public transform(customer: GelaCustomer): string[] {
    const result: string[] = [];

    if (customer.lngPL1TourMonday) {
      result.push('profile.my-profile.addition.condition.truck-deliveries.monday');
    }
    if (customer.lngPL1TourTuesday) {
      result.push('profile.my-profile.addition.condition.truck-deliveries.tuesday');
    }
    if (customer.lngPL1TourWednesday) {
      result.push('profile.my-profile.addition.condition.truck-deliveries.wednesday');
    }
    if (customer.lngPL1TourThursday) {
      result.push('profile.my-profile.addition.condition.truck-deliveries.thursday');
    }
    if (customer.lngPL1TourFriday) {
      result.push('profile.my-profile.addition.condition.truck-deliveries.friday');
    }

    return result;
  }
}
