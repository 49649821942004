import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { ModalConfirmComponent } from '../../../../../../components/modal-confirm/modal-confirm.component';
import { CustomerArticleNoteInterface } from '../../../../../../services/customer/customer-article-note/customer-article-note.interface';
import { CustomerArticleNoteService } from '../../../../../../services/customer/customer-article-note/customer-article-note.service';

@UntilDestroy()
@Component({
  selector: 'app-note-edit-modal',
  templateUrl: './note-edit-modal.component.html',
  styleUrls: ['./note-edit-modal.component.scss'],
})
export class NoteEditModalComponent extends SimpleModalComponent<null, string | null> implements OnInit {
  @Input() note: CustomerArticleNoteInterface | undefined;

  public form: UntypedFormGroup | undefined;

  constructor(private fb: UntypedFormBuilder, private modal: SimpleModalService, private service: CustomerArticleNoteService) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      note: [this.note?.note || '', Validators.required],
    });
  }

  remove(note: CustomerArticleNoteInterface) {
    this.modal
      .addModal(ModalConfirmComponent, {
        message: 'profile.notes.note-list.modal-confirm.message',
        translate: true,
      })
      .pipe(
        filter((result: boolean) => result),
        switchMap(() => this.service.remove(note)),
        tap(() => this.close()),
        first(),
        untilDestroyed(this),
      )
      .subscribe();
  }

  save(note: CustomerArticleNoteInterface, form: UntypedFormGroup, $event: SubmitEvent) {
    $event.preventDefault();
    form.markAllAsTouched();
    if (!form.valid) {
      return;
    }

    this.service
      .createForArticle(note.article, form.value.note)
      .pipe(
        first(),
        tap(() => this.close()),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
