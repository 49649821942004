import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HashMap, LangDefinition, Translation, TRANSLOCO_CONFIG, TranslocoConfig, TranslocoLoader } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoaderService implements TranslocoLoader {
  constructor(@Inject(TRANSLOCO_CONFIG) private config: TranslocoConfig, private http: HttpClient) {}

  getTranslation(lang: string): Observable<HashMap> {
    let result;
    if (environment.loadTranslationFromServer) {
      if (this.config.availableLangs!.includes(lang as string & LangDefinition)) {
        lang = `core/${lang.toString()}`;
      }
      result = this.http.get<Translation>(`${environment.host}${environment.apiUrl}/translation/${lang}`);
    } else {
      result = this.http.get<Translation>(`${environment.host}/assets/i18n/${lang}.json`);
    }

    return result;
  }
}
