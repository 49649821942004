import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortConfig } from './table-header-sort-config.model';

@Component({
  selector: 'app-table-header-sort',
  templateUrl: './table-header-sort.component.html',
  styleUrls: ['./table-header-sort.component.scss'],
})
export class TableHeaderSortComponent<T extends string> {
  @Input() tableHeaderName: T = '' as T;
  @Input() sortStatus: SortConfig<T> = { column: this.tableHeaderName, direction: 'asc' };
  @Output() sortClicked: EventEmitter<SortConfig<T>> = new EventEmitter<SortConfig<T>>();
}
