import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, EventEmitter, HostListener, Inject, Output } from '@angular/core';

@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<MouseEvent>();

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    const clickedInside: boolean = (this.elementRef.nativeElement as HTMLElement).contains(targetElement);
    if (clickedInside) {
      return;
    }

    const modalOpen: boolean = this.document.body.classList.contains('modal-open');
    if (modalOpen) {
      return;
    }

    this.clickOutside.emit(event);
  }

  constructor(@Inject(DOCUMENT) private document: Document, private elementRef: ElementRef) {}
}
