import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, of } from 'rxjs';

import { StockAvailability } from '@lobos/common';
import { CATALOG_CONFIG, CatalogConfig, StockAvailabilityStore, RequestDebounceService, StockAvailabilityQuery } from '@lobos/library-v2';
import { GelaStockAvailabilityQueryInterface } from './gela-stock-availability-query.interface';
import { isPlatformServer } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class GelaStockAvailabilityService<T extends StockAvailability = StockAvailability> {
  constructor(
    protected store: StockAvailabilityStore<T>,
    protected query: StockAvailabilityQuery<T>,
    private http: HttpClient,
    @Inject(CATALOG_CONFIG) private catalogConfig: CatalogConfig,
    @Inject(PLATFORM_ID) private _platformId: any,
    private debouncer: RequestDebounceService,
  ) {}

  get(queryDto: GelaStockAvailabilityQueryInterface): Observable<T | undefined> {
    if (isPlatformServer(this._platformId)) {
      return of(undefined);
    }

    const params = Object.entries(queryDto).reduce((httpParams, [key, value]) => {
      return httpParams.set(key, value.toString());
    }, new HttpParams());

    return this.debouncer.debounce<T, StockAvailabilityStore<T>, StockAvailabilityQuery<T>>(
      `${queryDto.sArticleID}-${queryDto.decQuantity}`,
      this.store,
      this.query,
      this.http.get<T>(this.catalogConfig.apiUrl + `/gela-stock-availability`, { params: params }),
    );
  }
}
