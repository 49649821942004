import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Href, HrefType } from '../../services/cms/interfaces/cms-href.interface';

@Component({
  selector: 'app-cms-anchor',
  templateUrl: './cms-anchor.component.html',
  styleUrls: ['./cms-anchor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsAnchorComponent {
  @Input() href: Href | null | undefined;
  @Input() classes: string = '';
  @Input() title: string | undefined;

  public hrefTypes = HrefType;
}
