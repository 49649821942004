import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface ButtonModel {
  label: string;

  translate?: boolean;
  classes?: string;
}

export interface ConfirmModel {
  message: string;
  translate?: boolean;
  buttonConfirm?: ButtonModel;
  buttonCancel?: ButtonModel;
}

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent extends SimpleModalComponent<ConfirmModel, boolean> implements ConfirmModel {
  title: string = '';
  message: string = '';
  translate: boolean = false;
  buttonCancel: ButtonModel = {
    label: 'core.modal-confirm.cancel',
    translate: true,
  };
  buttonConfirm: ButtonModel = {
    label: 'core.modal-confirm.confirm',
    translate: true,
  };

  confirm() {
    this.result = true;
    this.close();
  }

  cancel() {
    this.result = false;
    this.close();
  }
}
