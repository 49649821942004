<footer class="bg-gela-black 5xl:max-w-maxpage mx-auto pb-14 tablet:pb-0 relative">
  <div class="max-w-page 3xl:max-w-midscreen 4xl:max-w-bigscreen px-5 md:px-10 tablet:px-82px mx-auto pb-10">
    <div class="grid grid-cols-12 3xl:grid-cols-10 lg:gap-x-5">
      <div class="col-span-full lg:col-span-3 3xl:col-span-2 md:flex lg:block mt-6 md:mt-16 relative z-10">
        <a href="/" class="w-72-5vw sm:w-auto mb-8 md:mb-16 flex">
          <img src="assets/images/layout/geiser_logo--neg--no-claim.svg" alt="Rudolf Geiser AG" class="h-[62px]" />
          <ng-container *ngIf="currentVersionKey$ | async as currentVersionKey">
            <img
              *ngIf="currentVersionKey === 'de'"
              src="assets/images/layout/geiser_logo--claim_neg_DEU.svg"
              alt="Rudolf Geiser AG"
              class="h-[60px]"
            />
            <img
              *ngIf="currentVersionKey === 'fr'"
              src="assets/images/layout/geiser_logo--claim_neg_FRA.svg"
              alt="Rudolf Geiser AG"
              class="h-[60px]"
            />
          </ng-container>
        </a>
        <div class="text-primary-light leading-tight mt-2.5 lg:mt-0 md:ml-6 lg:ml-0 sm:flex lg:block">
          <div>
            <ng-container *ngFor="let footer of footer$ | async">
              <div class="first:mt-0 mt-6 phone-mid:mt-0 md:mt-0 lg:mt-68px sm:first:mr-6 lg:first:mr-0">
                <markdown [data]="footer.text"></markdown>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div
        class="hidden md:flex col-span-full lg:col-span-9 3xl:col-span-8 flex divide-y divide-primary-light phone-mid:divide-none md:divide-none border-primary-light border-b phone-mid:border-b-0 md:border-b-0 flex-wrap xl:flex-nowrap justify-start mt-6 phone-mid:mt-24 md:mt-24 lg:mt-170px xl:mt-188px ml-0 3xl:ml-1-4vw leading-tight"
      >
        <div
          class="mb-4 md:mb-12 xl:mb-0 pt-4 md:pt-0 phone-mid:pr-12 md:pr-12 xl:pr-0 mr-0 xl:mr-12 3xl:mr-2-6vw last:mr-0 w-full phone-mid:w-auto md:w-auto phone-mid:basis-1/2 md:basis-1/2 xl:basis-footer-1 3xl:basis-footer-big"
        >
          <div class="js-accordion--head w-full flex items-center">
            <p class="text-xl text-white font-montserrat font-bold leading-6 whitespace-nowrap">Shop</p>
            <div class="js-accordion--icon block md:hidden ml-auto text-primary">
              <span class="ifont ifont--chevron-down text-3xl"></span>
            </div>
          </div>
          <ul class="js-accordion--body hidden md:block text-primary mt-6 tracking-normal">
            <ng-container *ngIf="catalogFirstLevelFlyout$ | async as catalogFirstLevel">
              <li class="mb-4 last:mb-0" *ngFor="let item of catalogFirstLevel">
                <a
                  [title]="item.sTitle"
                  [routerLink]="item.sUrlPath | catalogPath | localizeUrlPath"
                  class="w-full pb-3.5 md:pb-0 block text-white hover:text-primary font-montserrat font-medium"
                >
                  {{ item.sTitle }}
                </a>
              </li>
            </ng-container>
          </ul>
        </div>

        <ng-container *ngFor="let cmsMenu of cmsMainMenu$ | async">
          <div
            class="mb-4 md:mb-12 xl:mb-0 pt-4 md:pt-0 phone-mid:pr-12 md:pr-12 xl:pr-0 mr-0 xl:mr-12 3xl:mr-2-6vw last:mr-0 w-full phone-mid:w-auto md:w-auto phone-mid:basis-1/2 md:basis-1/2 xl:basis-footer-2 3xl:basis-footer-big"
          >
            <div class="js-accordion--head w-full flex items-center">
              <a
                [routerLink]="'/cms/' + cmsMenu.url | localizeUrlPath"
                class="text-xl text-white font-montserrat font-bold leading-6 whitespace-nowrap"
                >{{ cmsMenu.title }}</a
              >
              <div class="js-accordion--icon block md:hidden ml-auto text-primary">
                <span class="ifont ifont--chevron-down text-3xl"></span>
              </div>
            </div>
            <ul class="js-accordion--body hidden md:block text-primary mt-6 tracking-normal">
              <li *ngFor="let cmsMenuChild of cmsMenu.children" class="mb-4 last:mb-0">
                <!-- link: footer start -->
                <a
                  [routerLink]="'/cms/' + cmsMenuChild.url | localizeUrlPath"
                  [title]="cmsMenuChild.title"
                  class="w-full pb-3.5 md:pb-0 block text-white hover:text-primary font-montserrat font-medium"
                >
                  {{ cmsMenuChild.title }}
                </a>
                <!-- link: footer end -->
              </li>
            </ul>
          </div>
        </ng-container>
      </div>

      <div
        class="md:hidden col-span-full lg:col-span-9 3xl:col-span-8 flex divide-y divide-primary-light phone-mid:divide-none md:divide-none border-primary-light border-b phone-mid:border-b-0 md:border-b-0 flex-wrap xl:flex-nowrap justify-start mt-6 phone-mid:mt-24 md:mt-24 lg:mt-170px xl:mt-188px ml-0 3xl:ml-1-4vw leading-tight"
      >
        <div
          class="mb-4 md:mb-12 xl:mb-0 pt-4 md:pt-0 phone-mid:pr-12 md:pr-12 xl:pr-0 mr-0 xl:mr-12 3xl:mr-2-6vw last:mr-0 w-full phone-mid:w-auto md:w-auto phone-mid:basis-1/2 md:basis-1/2 xl:basis-footer-1 3xl:basis-footer-big"
        >
          <div class="js-accordion items-center" (click)="accordionToggle($event)" *ngIf="catalogFirstLevelFlyout$">
            <div class="w-full flex items-center justify-between">
              <p class="js-accordion-show text-xl text-white font-montserrat font-bold leading-6 whitespace-nowrap">Shop</p>
              <span
                class="js-accordion-icon text-white ifont ifont--chevron-down text-3xl flex items-center justify-end transition-all duration-200"
              ></span>
            </div>

            <div class="js-accordion-wrap leading-tight max-h-0 overflow-hidden transition-all duration-200">
              <div class="js-accordion-content">
                <ul class="js-accordion--body md:block text-primary mt-6 tracking-normal">
                  <ng-container *ngIf="catalogFirstLevelFlyout$ | async as catalogFirstLevel">
                    <li class="mb-4 last:mb-0" *ngFor="let item of catalogFirstLevel">
                      <a
                        [title]="item.sTitle"
                        [routerLink]="item.sUrlPath | catalogPath | localizeUrlPath"
                        class="w-full pb-3.5 md:pb-0 block text-white hover:text-primary font-montserrat font-medium"
                      >
                        {{ item.sTitle }}
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <app-footer-accordion
          class="mb-4 md:mb-12 xl:mb-0 pt-4 md:pt-0 phone-mid:pr-12 md:pr-12 xl:pr-0 mr-0 xl:mr-12 3xl:mr-2-6vw last:mr-0 w-full phone-mid:w-auto md:w-auto phone-mid:basis-1/2 md:basis-1/2 xl:basis-footer-1 3xl:basis-footer-big"
          *ngFor="let cmsMenu of cmsMainMenu$ | async"
          [cmsMenu]="cmsMenu"
        ></app-footer-accordion>
      </div>

      <div
        class="absolute phone-mid:relative md:relative bottom-32 phone-mid:bottom-0 md:bottom-0 col-span-full text-gela-black mb-10 md:mb-16 px-0 mt-4 md:-mt-5"
      >
        <ul class="flex justify-end mr-0 3xl:mr-0-39vw">
          <li class="mr-5 md:mr-30px last:mr-0">
            <!-- link: social start -->
            <a
              [href]="'https://www.facebook.com/rudolfgeiserag'"
              target="_blank"
              title="Facebook"
              class="hover:bg-primary hover:text-white w-12 h-12 inline-flex justify-center items-center bg-white rounded-full"
            >
              <span class="ifont ifont--facebook text-[32px]"></span>
            </a>
            <!-- link: social end -->
          </li>
          <li class="mr-5 md:mr-30px last:mr-0">
            <!-- link: social start -->
            <a
              [href]="'https://www.instagram.com/rudolfgeiserag/'"
              target="_blank"
              title="Instagram"
              class="hover:bg-primary hover:text-white w-12 h-12 inline-flex justify-center items-center bg-white rounded-full"
            >
              <span class="ifont ifont--instagram text-[32px]"></span>
            </a>
            <!-- link: social end -->
          </li>
          <li class="mr-5 md:mr-30px last:mr-0">
            <!-- link: social start -->
            <a
              [href]="'https://www.youtube.com/channel/UCZxIk3QUpNHSEzJuMNOQXtA/feed'"
              target="_blank"
              title="Youtube"
              class="hover:bg-primary hover:text-white w-12 h-12 inline-flex justify-center items-center bg-white rounded-full"
            >
              <span class="ifont ifont--youtube text-[32px]"></span>
            </a>
            <!-- link: social end -->
          </li>
          <li class="mr-5 md:mr-30px last:mr-0">
            <!-- link: social start -->
            <a
              [href]="'https://www.linkedin.com/company/rudolf-geiser-ag/'"
              target="_blank"
              title="Linkedin"
              class="hover:bg-primary hover:text-white w-12 h-12 inline-flex justify-center items-center bg-white rounded-full"
            >
              <span class="ifont ifont--linkedin text-[32px]"></span>
            </a>
            <!-- link: social end -->
          </li>
        </ul>
      </div>
      <div class="mt-12 phone-mid:mt-0 md:mt-0 flex flex-col md:flex-row col-span-full text-white leading-tight px-0">
        <p class="order-2 md:order-1 mr-auto text-primary-light">© Rudolf Geiser AG {{ yearToday }}</p>
        <ul class="order-1 md:order-2 mb-122px phone-mid:mb-6 md:mb-0 md:flex mr-0 3xl:mr-0-39vw">
          <li class="md:mr-12 3xl:mr-68px last:mr-0 mb-4 md:mb-0" *ngFor="let footerNav of footerNav$ | async">
            <a
              [routerLink]="'/cms/' + footerNav.url | localizeUrlPath"
              title="Newsletter"
              class="font-white font-montserrat font-medium hover:text-primary w-full block pb-3.5 md:pb-0"
              >{{ footerNav.title }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
