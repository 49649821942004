import { Pipe, PipeTransform } from '@angular/core';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { ImageHelperService } from '../../services/catalog/image-helper.service';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'cloudinaryImage',
})
export class CloudinaryImagePipe implements PipeTransform {
  constructor(private imageHelperService: ImageHelperService) {}

  transform(src: string | undefined): CloudinaryImage {
    return src
      ? this.imageHelperService.buildCloudinaryUrl(`${environment.images.cloudinary.fetchImageUrl}/${src}`)
      : this.imageHelperService.returnFallbackImage();
  }
}
