import { Component, Input } from '@angular/core';
import { BreadcrumbsService } from '@lobos/library-v2';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbItem } from '@lobos/common';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input()
  public isBackOptionShown: boolean = false;

  public crumbs$: Observable<BreadcrumbItem[]> = this.breadcrumbsService.breadcrumbs$;

  public translate$: Observable<boolean> = this.route.data.pipe(
    map((data: any) => !!data.translate),
    shareReplay(1),
  );

  public backCrumb$: Observable<BreadcrumbItem> = this.crumbs$.pipe(
    filter((crumbs: BreadcrumbItem[]) => crumbs.length >= 2),
    map((crumbs: BreadcrumbItem[]) => crumbs[crumbs.length - 2]),
  );

  constructor(private breadcrumbsService: BreadcrumbsService, private route: ActivatedRoute) {}
}
