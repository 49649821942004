import { Injectable } from '@angular/core';
import { Label, LabelData, PaddingBoxInterface, Template } from '@lobos/label-print-v2';
import { Gela3x8Renderer } from './gela3x8.renderer';

@Injectable({
  providedIn: 'root',
})
export class Gela3x17Renderer extends Gela3x8Renderer {
  public override template(): Template {
    return {
      id: 'gela3x17',
      title: 'profile.label-print.domain.template.suedo3x17.title',
      format: { width: 210, height: 297 },
      boxSizes: { width: 70, height: 16.9 },
      orientation: 'p',
      numberOfBoxes: { horizontal: 3, vertical: 17 },
    };
  }

  protected override async drawData(label: Label, x: number, y: number): Promise<void> {
    const labelData: LabelData = label.data;
    let paddingBox: PaddingBoxInterface;

    const extraPaddingX = 2.5;

    // Title
    paddingBox = this.addPadding(x, y - 1.2, this.boxWidth * (this.configuration!.extra?.hideImage ? 1 : 2 / 3), this.boxHeight * (1 / 3));
    this.jspdf.drawText(
      labelData.title + ' / ' + labelData.unit + ` / Menge: ${labelData.customFields.amount}`,
      paddingBox.x + extraPaddingX,
      paddingBox.y,
      paddingBox.width,
      8,
      2,
      'bold',
    );

    // Description
    paddingBox = this.addPadding(
      x,
      y + this.boxHeight * (1 / 3) - 3.2,
      this.boxWidth * (this.configuration!.extra?.hideImage ? 2 / 3 : 1),
      this.boxHeight * (1 / 3),
    );
    this.jspdf.drawText(labelData.description, paddingBox.x + extraPaddingX, paddingBox.y, paddingBox.width, 7, 2);

    // BarCode (EAN13)
    paddingBox = this.addPadding(
      x + this.boxWidth * (this.configuration!.extra?.hideLogo ? 0 : 1 / 3),
      y + this.boxHeight * (2 / 3) - 1.8,
      this.boxWidth * (2 / 3),
      this.boxHeight * (1 / 3) - this.jspdf.getTextDimensions(labelData.code).h,
    );
    await this.addCode128(labelData.code, paddingBox.x + extraPaddingX, paddingBox.y, paddingBox.width, paddingBox.height);

    // Image
    if (this.configuration!.extra?.hideImage) {
      paddingBox = this.addPadding(x + this.boxWidth * (2 / 3), y, this.boxWidth * (1 / 3), this.boxHeight * (2 / 3));
      await this.addImage(labelData.imgPath!, paddingBox.x, paddingBox.y, paddingBox.width, paddingBox.height);
    }

    if (this.configuration!.extra?.hideLogo) {
      // Logo
      paddingBox = this.addPadding(
        x + this.boxWidth * (2 / 3),
        y + this.boxHeight * (2 / 3) - 3,
        this.boxWidth * (1 / 3),
        this.boxHeight * (1 / 3),
      );
      await this.addImage(labelData.logoPath!, paddingBox.x, paddingBox.y, paddingBox.width, paddingBox.height);
    }
  }
}
