import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { SalesDocumentService } from '@lobos/library-v2';
import { take } from 'rxjs/operators';
import { SalesDocument } from '@lobos/common';
import { BehaviorSubject } from 'rxjs';

export interface DownloadInterface {
  salesDocuments: SalesDocument[];
}

@Component({
  selector: 'app-modal-downloads',
  templateUrl: './modal-downloads.component.html',
  styleUrls: ['./modal-downloads.component.scss'],
})
export class ModalDownloadsComponent extends SimpleModalComponent<DownloadInterface, boolean> implements DownloadInterface {
  salesDocuments: SalesDocument[] = [];

  isLoadingDownload$ = new BehaviorSubject<boolean>(false);

  constructor(private salesDocumentService: SalesDocumentService) {
    super();
  }

  downloadDocument(doc: SalesDocument): void {
    this.isLoadingDownload$.next(true);
    this.salesDocumentService
      .download(doc)
      .pipe(take(1))
      .subscribe(() => this.isLoadingDownload$.next(false));
  }
}
