import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ID } from '@datorama/akita';

export interface EditEvent {
  type: 'new' | 'edit' | 'delete';
  id: ID;
  value?: string;
}

@Component({
  selector: 'app-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrls: ['./button-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonDropdownComponent {
  @Input() public label: string = '';
  @Input() public name: string = '';
  @Input() public options: { key: string | number; value: string | number; count?: number }[] = [];
  @Input() public translateValue: boolean = false;
  @Input() public value: string | number = '';
  @Input() public showValue = false;
  @Input() public count?: number = undefined;
  @Input() public prefix: string = '';
  @Input() public editMode: boolean = false;
  @Input() public newPlaceholder: string = '';
  @Input() public isOpen = false;
  @Input() public hasMaxHeight = false;
  @Input() public nullValue: string = '';

  @Output() public valueChange: EventEmitter<string | number> = new EventEmitter<string | number>();
  @Output() public editSelected: EventEmitter<EditEvent> = new EventEmitter<EditEvent>();
  @Output() public isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public newValue = '';

  @HostListener('document:click', ['$event'])
  clickOut(event: Event) {
    if (this.isOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.toggleIsOpen();
    }
  }

  constructor(private elementRef: ElementRef) {}

  toggleIsOpen(): void {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
  }

  addPrefix(value: string | number): string {
    return this.prefix + value;
  }
}
