import {
  CartHeaderQuery,
  CartService,
  CreateCartItemInterface,
  HookEvent,
  HookHandlerAbstract,
  switchTap,
  WorkflowCreateHook,
} from '@lobos/library-v2';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cart, CartHeader, CartItem } from '@lobos/common';
import { SimpleModalService } from 'ngx-simple-modal';
import { GelaCartHeader } from '../../../services/cart/model/gela-cart-header.model';
import { ItemCommissionModalComponent } from '../../../features/checkout/checkout-features/cart/cart-components/item-commission-modal/item-commission-modal.component';
import { filter, first, switchMap } from 'rxjs/operators';

type type = WorkflowCreateHook<'CreateCartItemHook'>;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CartCreateItemPreferredCommission extends HookHandlerAbstract<type, Observable<Cart<CartHeader, CartItem> | undefined>> {
  constructor(
    private modal: SimpleModalService,
    private cartService: CartService<GelaCartHeader, CartItem, CreateCartItemInterface>,
    private cartQuery: CartHeaderQuery<GelaCartHeader>,
  ) {
    super();
  }

  ids(): type[] {
    return [{ type: 'WorkflowCreateHook', entity: 'CreateCartItemHook' }];
  }

  override before(
    event: HookEvent<type, Observable<Cart<GelaCartHeader, CartItem> | undefined>>,
    _: HookEvent<type, Observable<Cart<CartHeader, CartItem> | undefined>>,
  ): () => Observable<Cart<GelaCartHeader, CartItem> | undefined> {
    const cartHeader: GelaCartHeader | undefined = this.cartQuery.getActive();

    if (cartHeader && !cartHeader.bShownPopUp && cartHeader.lngOrderID !== 1 && !event.params[0].sPL1Commission) {
      return () =>
        this.modal.addModal(ItemCommissionModalComponent, { item: event.params[0], dontShowOption: true }).pipe(
          first(),
          filter((item) => !!item),
          switchTap((item) =>
            this.cartService.updateCartHeader({ ...cartHeader, bShownPopUp: true, sPreferredCommission: item.sPL1Commission }),
          ),
          switchMap((item) => this.cartService.createCartItem(item)),
        );
    }
    return event.callable;
  }
}
