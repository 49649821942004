import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GelaArticle } from '../../../../services/catalog/model/gela-article';

@Component({
  selector: 'app-search-article-item',
  templateUrl: './search-article-item.component.html',
  styleUrls: ['./search-article-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchArticleItemComponent implements OnChanges {
  @Input() article!: GelaArticle;
  @Output() linkClicked: EventEmitter<Event> = new EventEmitter();

  quantity: number = 1;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['article']) {
      this.quantity =
        changes['article'].currentValue?.shtNoPackageBreak >= 1 ? changes['article'].currentValue?.decQuantityPackage || 1 : 1;
    }
  }

  quantityChanged(quantity: number) {
    this.quantity = quantity;
  }
}
