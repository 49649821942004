<div *transloco="let t; scope: 'header'" class="js-mobile-account min-h-full bg-white px-5 pt-5 mb-16">
  <div class="mt-11">
    <ng-container *ngIf="authUser$ | async as user">
      <div class="mb-4 text-gela-black">
        <p class="text-xl font-montserrat font-bold">
          {{ user.oContact.sFirstName + " " + user.oContact.sLastName + " (" + user.lngCustomerID + ")" }}
        </p>
      </div>
      <ul class="text-gela-black divide-y divide-primary-light pb-2">
        <li>
          <a
            [routerLink]="'/profile/users' | localizeUrlPathAsync | async"
            [title]="t('header.user-profile.users')"
            (click)="closeMenu()"
            class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
          >
            {{ t("header.user-profile.users") }}
          </a>
        </li>
        <li>
          <a
            [routerLink]="'/profile/my-profile' | localizeUrlPathAsync | async"
            [title]="t('header.user-profile.profile')"
            (click)="closeMenu()"
            class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
          >
            {{ t("header.user-profile.profile") }}
          </a>
        </li>
        <li>
          <a
            [routerLink]="'/profile/favorites' | localizeUrlPathAsync | async"
            [title]="t('header.user-profile.favorites')"
            (click)="closeMenu()"
            class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
          >
            {{ t("header.user-profile.favorites") }}
          </a>
        </li>
        <li>
          <a
            [routerLink]="'/profile/notes' | localizeUrlPathAsync | async"
            [title]="t('header.user-profile.notes')"
            (click)="closeMenu()"
            class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
          >
            {{ t("header.user-profile.notes") }}
          </a>
        </li>
        <li>
          <a
            [routerLink]="'/profile/orders' | localizeUrlPathAsync | async"
            [title]="t('header.user-profile.orders')"
            (click)="closeMenu()"
            class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
          >
            {{ t("header.user-profile.orders") }}
          </a>
        </li>
        <li>
          <a
            [routerLink]="'/profile/offers' | localizeUrlPathAsync | async"
            [title]="t('header.user-profile.offers')"
            (click)="closeMenu()"
            class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
          >
            {{ t("header.user-profile.offers") }}
          </a>
        </li>
        <li>
          <a
            [routerLink]="'/profile/credits' | localizeUrlPathAsync | async"
            [title]="t('header.user-profile.credits')"
            (click)="closeMenu()"
            class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
          >
            {{ t("header.user-profile.credits") }}
          </a>
        </li>
        <li>
          <a
            [routerLink]="'/profile/backorders' | localizeUrlPathAsync | async"
            [title]="t('header.user-profile.backlog')"
            (click)="closeMenu()"
            class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
          >
            {{ t("header.user-profile.backlog") }}
          </a>
        </li>
        <li>
          <a
            [routerLink]="'/profile/outline-agreement' | localizeUrlPathAsync | async"
            [title]="t('header.user-profile.general-agreement')"
            (click)="closeMenu()"
            class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
          >
            {{ t("header.user-profile.general-agreement") }}
          </a>
        </li>
        <li>
          <a
            [routerLink]="'/profile/pricelist' | localizeUrlPathAsync | async"
            [title]="t('header.user-profile.price-list')"
            (click)="closeMenu()"
            class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
          >
            {{ t("header.user-profile.price-list") }}
          </a>
        </li>
        <li>
          <a
            href="{{ t('header.user-profile.configurator-url') | urlReplace : user }}"
            [title]="t('header.user-profile.configurator')"
            (click)="closeMenu()"
            class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
          >
            {{ t("header.user-profile.configurator") }}
          </a>
        </li>
        <li>
          <a
            [routerLink]="t('header.user-profile.light-configuration-url')"
            [title]="t('header.user-profile.light-configuration')"
            (click)="closeMenu()"
            class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
          >
            {{ t("header.user-profile.light-configuration") }}
          </a>
        </li>
        <li>
          <div class="flex py-3.5 mt-8 pb-9">
            <a
              (click)="logout(); closeMenu()"
              [title]="t('header.user-profile.logout')"
              class="self-end flex items-center relative pr-8 py-1 font-montserrat font-bold text-small -mr-1 transition-all duration-200 hover:text-primary cursor-pointer"
            >
              <span class="whitespace-nowrap">{{ t("header.user-profile.logout") }}</span>
              <span class="ifont ifont--logout text-icon absolute -right-1 top-1/2 -translate-y-1/2"></span>
            </a>
          </div>
        </li>
      </ul>
    </ng-container>
  </div>
</div>
