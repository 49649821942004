<ng-container *ngIf="look === 'primary'">
  <button
    class="text-white mr-5 3xl:mr-10 last:mr-0 bg-secondary border-2 border-secondary hover:bg-gela-black hover:border-gela-black font-montserrat font-bold self-center rounded-button transition-all duration-200 inline-block"
    [ngClass]="[
      size === 'large' ? 'px-6 py-3.5' : 'px-6 py-2.5',
      fullWidth ? 'w-full' : '',
      classes ? classes : '',
      disabled ? 'opacity-50 cursor-not-allowed hover:bg-secondary hover:border-secondary' : ''
    ]"
    [disabled]="disabled"
    *ngIf="type === 'button'"
    title="{{ text }}"
  >
    <span>{{ text }}</span>

    <span *ngIf="loading" class="loading-box">
      <span class="loader"></span>
    </span>
  </button>

  <a
    class="text-white mr-5 3xl:mr-10 last:mr-0 bg-secondary border-2 border-secondary hover:bg-gela-black hover:border-gela-black font-montserrat font-bold self-center rounded-button transition-all duration-200 inline-block"
    [ngClass]="[size === 'large' ? 'px-6 py-3.5' : 'px-6 py-2.5', fullWidth ? 'w-full' : '', classes ? classes : '']"
    *ngIf="type === 'link'"
    (click)="click($event)"
    [routerLink]="href"
    [queryParams]="linkParams"
    >{{ text }}</a
  >
</ng-container>

<ng-container *ngIf="look === 'secondary'">
  <button
    class="font-montserrat font-bold rounded-button transition-all duration-200 bg-primary-light text-gela-black hover:text-white hover:bg-secondary text-center items-center justify-center inline-block"
    [ngClass]="[
      size === 'large' ? 'px-6 py-3.5' : 'px-6 py-2.5',
      fullWidth ? 'w-full' : '',
      classes ? classes : '',
      disabled ? 'opacity-50 cursor-not-allowed hover:bg-primary-light' : ''
    ]"
    *ngIf="type === 'button'"
    [disabled]="disabled"
    title="{{ text }}"
  >
    <span>{{ text }}</span>

    <span *ngIf="loading" class="loading-box">
      <span class="loader-inverted"></span>
    </span>
  </button>

  <a
    class="font-montserrat font-bold rounded-button transition-all duration-200 bg-primary-light text-gela-black hover:text-white hover:bg-secondary text-center items-center justify-center inline-block"
    [ngClass]="[size === 'large' ? 'px-6 py-3.5' : 'px-6 py-2.5', fullWidth ? 'w-full' : '']"
    *ngIf="type === 'link'"
    (click)="click($event)"
    [routerLink]="href"
    [queryParams]="linkParams"
    >{{ text }}</a
  >
</ng-container>

<ng-container *ngIf="look === 'tertiary'">
  <button
    class="inline-block text-center font-montserrat font-bold border-2 border-primary-light rounded-button transition-all duration-200 bg-white hover:text-white hover:bg-secondary hover:border-secondary"
    [ngClass]="[
      size === 'large' ? 'px-6 py-3.5' : 'px-6 py-2.5',
      fullWidth ? 'w-full' : '',
      disabled ? 'opacity-50 cursor-not-allowed hover:bg-white hover:border-primary-light' : ''
    ]"
    *ngIf="type === 'button'"
    [disabled]="disabled"
    title="{{ text }}"
  >
    <span>{{ text }}</span>

    <span *ngIf="loading" class="loading-box">
      <span class="loader-inverted"></span>
    </span>
  </button>

  <a
    class="inline-block text-center font-montserrat font-bold border-2 border-primary-light rounded-button transition-all duration-200 bg-white hover:text-white hover:bg-secondary hover:border-secondary"
    [ngClass]="[size === 'large' ? 'px-6 py-3.5' : 'px-6 py-2.5', fullWidth ? 'w-full' : '']"
    *ngIf="type === 'link'"
    (click)="click($event)"
    [routerLink]="href"
    [queryParams]="linkParams"
    >{{ text }}</a
  >
</ng-container>

<ng-container *ngIf="look === 'border'">
  <button
    class="w-full inline-block text-center font-montserrat font-bold border-2 border-primary-light rounded-button transition-all duration-200 bg-white hover:text-white hover:bg-secondary hover:border-secondary"
    [ngClass]="[
      size === 'large' ? 'px-6 py-3.5' : 'px-6 py-2.5',
      disabled ? 'opacity-50 cursor-not-allowed hover:bg-white hover:border-primary-light' : ''
    ]"
    [disabled]="disabled"
    title="{{ text }}"
  >
    <span>{{ text }}</span>

    <span *ngIf="loading" class="loading-box">
      <span class="loader-inverted"></span>
    </span>
  </button>
</ng-container>

<ng-container *ngIf="look === 'border-icon'">
  <button
    class="js-show-article-table border-2 border-primary-light relative font-montserrat font-bold flex justify-center items-center rounded-button transition-all duration-200 tablet:ml-3 group tablet:hover:text-white tablet:hover:bg-secondary tablet:hover:border-secondary mt-3 tablet:mt-0"
    [ngClass]="[size === 'large' ? 'px-6 py-3.5' : 'px-6 py-3', disabled ? 'opacity-50 cursor-not-allowed' : '']"
    [disabled]="disabled"
    title="{{ text }}"
  >
    <span class="relative pr-8">
      <span>{{ text }}</span>

      <span *ngIf="loading" class="loading-box">
        <span class="loader-inverted"></span>
      </span>
      <span
        class="ifont ifont--chevron-down text-3xl text-primary js-chevron tablet:group-hover:text-white absolute -right-1.5 top-1/2 -translate-y-1/2"
      ></span>
    </span>
  </button>
</ng-container>

<ng-container *ngIf="look === 'tab'">
  <button
    class="js-tab-btn mr-2.5 2xl:mr-5 3xl:mr-10 last:mr-0 border-2 border-primary-light hover:border-gela-black font-montserrat font-bold self-center rounded-button transition-all duration-200"
    [ngClass]="[size === 'large' ? 'px-6 py-3.5' : 'px-6 py-2.5', disabled ? 'opacity-50 cursor-not-allowed' : '']"
    [disabled]="disabled"
    title="{{ text }}"
  >
    <span>{{ text }}</span>

    <span *ngIf="loading" class="loading-box">
      <span class="loader-inverted"></span>
    </span>
  </button>
</ng-container>
