import { Pipe, PipeTransform } from '@angular/core';
import { Article } from '@lobos/common';

@Pipe({
  name: 'articleBrandLink',
})
export class ArticleBrandLinkPipe implements PipeTransform {
  transform(article: Article): string {
    let brand: string | undefined;

    brand = article.oFeatures.find((feature) => feature.lngFeatureID === '3600e332-7d73-41d1-ba2a-a9f0cc43e772')?.sValue;

    if (!brand) {
      brand = article.oReferenceLink.find((referenceLink) => referenceLink.sTypeCode === 'Brand')?.sLinkReference.split('.')[0];
    }
    return '/brand/' + brand;
  }
}
