import { FavoriteService } from '@lobos/library-v2';
import { Observable, of } from 'rxjs';
import { SimpleModalService } from 'ngx-simple-modal';
import { first, switchMap } from 'rxjs/operators';
import { Article, FavoriteItem, FavoriteList } from '@lobos/common';
import { FavoriteQtyModalComponent } from '../../../../components/favorite-qty-modal/favorite-qty-modal.component';
import { Decimal } from 'decimal.js';

export function IsPartialPackingUnitNeeded(article: Article | undefined, quantity: number) {
  return article?.shtNoPackageBreak ? !new Decimal(quantity).modulo(article.decQuantityPackage).equals(0) : false;
}

export function HandleQtyInput(
  newFavoriteItem: FavoriteItem,
  callable: (params?: any[]) => Observable<FavoriteItem>,
  modal: SimpleModalService,
  favoriteService: FavoriteService<FavoriteList, FavoriteItem>,
): (params?: any[]) => Observable<FavoriteItem | undefined> {
  if (IsPartialPackingUnitNeeded(newFavoriteItem.oArticle, newFavoriteItem.decQuantity)) {
    modal
      .addModal<FavoriteItem, FavoriteItem | undefined>(FavoriteQtyModalComponent, newFavoriteItem)
      .pipe(
        switchMap((favoriteItem: FavoriteItem | undefined) => {
          if (!!favoriteItem) {
            return favoriteService.createFavoriteItem(favoriteItem, favoriteItem.gListID);
          }
          return of(undefined);
        }),
        first(),
      )
      .subscribe();
    return () => of(undefined);
  } else {
    return callable;
  }
}
