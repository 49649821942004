<ng-container *transloco="let t; scope: 'header'">
  <div appClickOutside (clickOutside)="closeFlyout()">
    <ng-container *ngIf="{ isLoggedIn: isLoggedIn$ | async, user: user$ | async } as context">
      <button
        (click)="open(context.isLoggedIn!)"
        class="js-open-signin-modal p-2 leading-none rounded-default group hover:bg-primary-bright transition-all duration-200 relative"
      >
        <span
          class="ifont ifont--person-default text-3xl text-primary group-hover:text-primary-dark transition-all duration-200 pointer-events-none"
        ></span>
        <span
          *ngIf="context.isLoggedIn"
          class="bg-gela-black border-2 border-white text-white text-small font-montserrat font-bold rounded-full w-22px h-22px flex items-center justify-center absolute bottom-2 -right-px pointer-events-none"
          ><i class="ifont ifont--step-done text-xl absolute"></i
        ></span>
      </button>

      <div
        *ngIf="context.user"
        class="js-info absolute border border-primary-light bg-white rounded-flyout shadow-flyout translate-y-0 md:top-full -right-3 z-50 block"
      >
        <div [class.hidden]="!showFlyout" class="max-h-500px md:max-h-flyout-header scrollbar overflow-auto p-3 pb-182px">
          <div class="md:w-614px bg-white pt-3">
            <div class="flex justify-between items-start mb-4 px-3">
              <p class="font-montserrat font-bold text-lg break-words w-11/12">
                {{
                  (context.user.oContact.sFirstName || "") +
                    " " +
                    (context.user.oContact.sLastName || "") +
                    " (" +
                    (context.user.oCustomer.sMatchCode || "") +
                    ")"
                }}
              </p>
              <button (click)="closeFlyout()" class="js-close-header-flyout transition-all duration-200 hover:text-primary">
                <span class="ifont ifont--close text-3xl pointer-events-none"></span>
              </button>
            </div>
            <ul class="text-gela-black divide-y divide-primary-light px-3">
              <ng-container *ngIf="authUser$ | async as authUser">
                <li *ngIf="accessId.SHOW_USER_MANAGEMENT | hasAccess | async">
                  <a
                    [routerLink]="'/profile/users' | localizeUrlPathAsync | async"
                    [title]="t('header.user-profile.users')"
                    (click)="closeFlyout()"
                    class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
                  >
                    {{ t("header.user-profile.users") }}
                  </a>
                </li>
              </ng-container>
              <li>
                <a
                  [routerLink]="'/profile/my-profile' | localizeUrlPathAsync | async"
                  [title]="t('header.user-profile.profile')"
                  (click)="closeFlyout()"
                  class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
                >
                  {{ t("header.user-profile.profile") }}
                </a>
              </li>
              <li>
                <a
                  [routerLink]="'/profile/favorites' | localizeUrlPathAsync | async"
                  [title]="t('header.user-profile.favorites')"
                  (click)="closeFlyout()"
                  class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
                >
                  {{ t("header.user-profile.favorites") }}
                </a>
              </li>
              <li>
                <a
                  [routerLink]="'/profile/notes' | localizeUrlPathAsync | async"
                  [title]="t('header.user-profile.notes')"
                  (click)="closeFlyout()"
                  class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
                >
                  {{ t("header.user-profile.notes") }}
                </a>
              </li>
              <li *ngIf="accessId.SHOW_ORDERS | hasAccess | async">
                <a
                  [routerLink]="'/profile/orders' | localizeUrlPathAsync | async"
                  [title]="t('header.user-profile.orders')"
                  (click)="closeFlyout()"
                  class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
                >
                  {{ t("header.user-profile.orders") }}
                </a>
              </li>
              <li *ngIf="accessId.SHOW_OFFERS | hasAccess | async">
                <a
                  [routerLink]="'/profile/offers' | localizeUrlPathAsync | async"
                  [title]="t('header.user-profile.offers')"
                  (click)="closeFlyout()"
                  class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
                >
                  {{ t("header.user-profile.offers") }}
                </a>
              </li>
              <li *ngIf="accessId.SHOW_CREDIT_NOTES | hasAccess | async">
                <a
                  [routerLink]="'/profile/credits' | localizeUrlPathAsync | async"
                  [title]="t('header.user-profile.credits')"
                  (click)="closeFlyout()"
                  class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
                >
                  {{ t("header.user-profile.credits") }}
                </a>
              </li>
              <li *ngIf="accessId.SHOW_BACKORDER | hasAccess | async">
                <a
                  [routerLink]="'/profile/backorders' | localizeUrlPathAsync | async"
                  [title]="t('header.user-profile.backlog')"
                  (click)="closeFlyout()"
                  class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
                >
                  {{ t("header.user-profile.backlog") }}
                </a>
              </li>
              <li>
                <a
                  [routerLink]="'/profile/outline-agreement' | localizeUrlPathAsync | async"
                  [title]="t('header.user-profile.general-agreement')"
                  (click)="closeFlyout()"
                  class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
                >
                  {{ t("header.user-profile.general-agreement") }}
                </a>
              </li>
              <li>
                <a
                  [routerLink]="'/profile/pricelist' | localizeUrlPathAsync | async"
                  [title]="t('header.user-profile.price-list')"
                  (click)="closeFlyout()"
                  class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
                >
                  {{ t("header.user-profile.price-list") }}
                </a>
              </li>
              <li>
                <a
                  href="{{ t('header.user-profile.configurator-url') | urlReplace : context.user }}"
                  target="_blank"
                  [title]="t('header.user-profile.configurator')"
                  (click)="closeFlyout()"
                  class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
                >
                  {{ t("header.user-profile.configurator") }}
                </a>
              </li>
              <li>
                <a
                  [routerLink]="t('header.user-profile.light-configuration-url')"
                  [title]="t('header.user-profile.light-configuration')"
                  (click)="closeFlyout()"
                  class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
                >
                  {{ t("header.user-profile.light-configuration") }}
                </a>
              </li>

              <ng-container *ngIf="authUser$ | async as user">
                <li *ngIf="user.lngP48SalesRepID">
                  <a
                    [routerLink]="'/sales-rep' | localizeUrlPathAsync | async"
                    (click)="closeFlyout()"
                    class="w-full inline-block px-4 py-3.5 rounded-default hover:bg-primary-bright hover:text-primary-dark font-montserrat font-medium"
                  >
                    {{ t("header.user-profile.sales-rep") }}
                  </a>
                </li>
              </ng-container>

              <li>
                <div class="flex py-3.5 mt-8">
                  <a
                    (click)="logout()"
                    [title]="t('header.user-profile.logout')"
                    class="self-end flex items-center relative pr-8 py-1 font-montserrat font-bold text-small -mr-1 transition-all duration-200 hover:text-primary cursor-pointer"
                  >
                    <span class="whitespace-nowrap">{{ t("header.user-profile.logout") }}</span>
                    <span class="ifont ifont--logout text-icon absolute -right-1 top-1/2 -translate-y-1/2"></span>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
