import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { AlreadyOrdered } from './already-ordered.interface';
import { AlreadyOrderedStore } from './already-ordered.store';

@Injectable({ providedIn: 'root' })
export class AlreadyOrderedService {
  constructor(private http: HttpClient, private store: AlreadyOrderedStore) {}

  public getForArticle(articleId: string | number): Observable<AlreadyOrdered> {
    return of(undefined).pipe(
      tap(() => this.store.setLoading(true)),
      switchMap(() => this.http.get<AlreadyOrdered>(`api/already-ordered/${articleId}`)),
      tap((note: AlreadyOrdered) => this.store.upsert(note.sArticleId, note)),
      finalize(() => this.store.setLoading(false)),
    );
  }
}
