import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getValueFromKey',
})
export class GetValueFromKeyPipe implements PipeTransform {
  transform(
    items: { key: string | number; value: string | number }[],
    key: string | number | undefined | null,
    label?: string,
  ): string | number {
    return items.find((option) => option.key === key)?.value || label || '';
  }
}
