import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormHelperService {
  public removeError(form: UntypedFormGroup, field: string, errorCode: string) {
    const currentErrors: ValidationErrors = form.get(field)?.errors || {};
    delete currentErrors[errorCode];

    form.get(field)!.setErrors(Object.keys(currentErrors).length ? { ...currentErrors } : null);
  }

  public addError(form: UntypedFormGroup, field: string, errors: ValidationErrors) {
    const currentErrors: ValidationErrors = form.get(field)?.errors || {};
    form.get(field)!.setErrors({ ...currentErrors, ...errors });
  }
}
