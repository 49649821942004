import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input } from '@angular/core';
import { DomHelperService } from '../../../services/dom/dom-helper.service';
import { DOCUMENT } from '@angular/common';
import { CatalogService } from '@lobos/library-v2';
import { BehaviorSubject, iif, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { GelaCatalog } from '../../../services/catalog/model/gela-catalog';
import { Catalog } from '@lobos/common';
import { GelaCatalogQuery } from '../../../services/catalog/catalog.query';

@Component({
  selector: 'app-category-nav-flyout',
  templateUrl: './category-nav-flyout.component.html',
  styleUrls: ['./category-nav-flyout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryNavFlyoutComponent {
  @Input() navOpen: boolean = false;
  @Input() menuLevel1: GelaCatalog[] | undefined | null;
  private _menuLevel2: BehaviorSubject<GelaCatalog | undefined> = new BehaviorSubject<GelaCatalog | undefined>(undefined);
  private _menuLevel3: BehaviorSubject<GelaCatalog | undefined> = new BehaviorSubject<GelaCatalog | undefined>(undefined);
  public selectedMenu2$ = this._menuLevel2.asObservable();
  public selectedMenu3$ = this._menuLevel3.asObservable();
  public menuLevel2$: Observable<GelaCatalog[] | undefined> = this._menuLevel2.asObservable().pipe(
    filter((catalog: GelaCatalog | undefined) => !!catalog),
    switchMap((catalog: GelaCatalog | undefined) =>
      iif(
        () => this.catalogQuery.getHasCategoriesByParentGroupId(catalog!.lngGroup),
        this.catalogQuery.selectCategoriesByParentGroupId$(catalog!.lngGroup),
        this.catalogService
          .getCatalogLevelByParentGroupId(String(catalog!.lngGroup))
          .pipe(switchMap(() => this.catalogQuery.selectCategoriesByParentGroupId$(catalog!.lngGroup))),
      ),
    ),
    map((catalog: Catalog[]) => catalog as GelaCatalog[]),
  );

  public menuLevel3$: Observable<GelaCatalog[] | undefined> = this._menuLevel3.asObservable().pipe(
    filter((catalog: GelaCatalog | undefined) => !!catalog),
    switchMap((catalog: GelaCatalog | undefined) =>
      iif(
        () => this.catalogQuery.getHasCategoriesByParentGroupId(catalog!.lngGroup),
        this.catalogQuery.selectCategoriesByParentGroupId$(catalog!.lngGroup),
        this.catalogService
          .getCatalogLevelByParentGroupId(String(catalog!.lngGroup))
          .pipe(switchMap(() => this.catalogQuery.selectCategoriesByParentGroupId$(catalog!.lngGroup))),
      ),
    ),
    map((catalog: Catalog[]) => catalog as GelaCatalog[]),
  );

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private catalogService: CatalogService,
    private catalogQuery: GelaCatalogQuery,
    private elementRef: ElementRef,
  ) {}

  closeNavigation(): void {
    const navEl: Element | null = this.document.querySelector('.js-nav');
    DomHelperService.hideContent(navEl);
    DomHelperService.bodyScrollEnable(this.document);
    DomHelperService.removeActiveStateLink(this.document.querySelector('.js-nav')!);
    //this.document.querySelector('.js-nav-list--2')!.classList.add('hidden');
    //this.document.querySelector('.js-nav-list--3')!.classList.add('hidden');
  }

  openSecondLevelNavigation(navigation: GelaCatalog, event: Event): void {
    this._menuLevel2.next(navigation);
    const id = (event.target! as Element).getAttribute('data-id');
    const level2El: Element | null = this.document.querySelector('.js-nav-list--2');
    const level3El: Element | null = this.document.querySelector('.js-nav-list--3');

    let delay = 201;

    if (!this.document.querySelector('.js-sublevel.js-open')) {
      delay = 0;
    }

    this.removeOpenState(((event.target! as Element).parentNode!.parentNode! as Element).getAttribute('data-level')!);
    DomHelperService.removeActiveStateLink(this.document.querySelector('.js-nav-list--1')!);
    DomHelperService.activeStateLink(event.target as Element);

    setTimeout(() => {
      if (level2El!.classList.contains('opacity-0')) {
        level2El!.classList.remove('opacity-0', 'pointer-events-none');
      }

      if (!level3El!.classList.contains('opacity-0')) {
        level3El!.classList.add('opacity-0', 'pointer-events-none');
      }

      //show next level for hovered link
      this.showSublevel(id!, level2El!);
    }, delay);
  }

  openThirdLevelNavigation(navigation: GelaCatalog, event: Event): void {
    this._menuLevel3.next(navigation);
    let delay = 201;
    const id = (event.target as Element).getAttribute('data-id');
    const level3El = this.document.querySelector('.js-nav-list--3');

    if (!this.document.querySelector('.js-sublevel.js-open')) {
      delay = 0;
    }
    this.removeOpenState(((event.target! as Element).parentNode!.parentNode! as Element).getAttribute('data-level')!);
    DomHelperService.removeActiveStateLink(this.document.querySelector('.js-nav-list--2')!);
    DomHelperService.activeStateLink(event.target as Element);

    setTimeout(() => {
      if (level3El!.classList.contains('opacity-0')) {
        level3El!.classList.remove('opacity-0', 'pointer-events-none');
      }

      level3El!.scrollTop = 0;

      //show next level for hovered link
      this.showSublevel(id!, level3El!);
    }, delay);
  }

  showSublevel(id: string, parentEl: Element) {
    parentEl.querySelector('.js-sublevel[data-id="' + id + '"]')!.classList.remove('invisible', 'opacity-0', 'h-0');
    parentEl.querySelector('.js-sublevel[data-id="' + id + '"]')!.classList.add('js-open');
  }

  removeOpenState(levelinfo: string) {
    if (this.document.querySelectorAll('.js-sublevel.js-open').length > 0) {
      [].forEach.call(this.document.querySelectorAll('.js-sublevel.js-open'), (open: Element) => {
        //hide sublevel if hovered on other link and the level of the link is not the same
        if ((open.querySelector('.js-open-subnav')?.parentNode?.parentNode as Element)?.getAttribute('data-level') != levelinfo) {
          open.classList.remove('js-open');
          open.classList.add('invisible', 'opacity-0');
          open.addEventListener(DomHelperService.getTransitionEndEventName(this.document)!, () => {
            if (!open.classList.contains('js-open')) {
              open.classList.add('h-0');
            }
          });
        }
      });
    }
  }

  navScrollDownButton(isSecondLevel: boolean): void {
    const buttonClass = isSecondLevel ? '.js-btn-second' : '.js-btn';
    const button = this.elementRef.nativeElement.querySelector(buttonClass);

    const parentNode = button.parentNode as HTMLElement;
    const currentScrollPos = parentNode.scrollTop;
    const navMaxHeight = parseFloat(
      getComputedStyle(this.elementRef.nativeElement.querySelector('.js-show-btn')).getPropertyValue('height'),
    );
    const maxScrollPos = parentNode.scrollHeight - navMaxHeight;
    const scrollDif = window.innerHeight / 4;

    if (currentScrollPos < maxScrollPos) {
      parentNode.scroll({
        top: currentScrollPos + scrollDif,
        behavior: 'smooth',
      });
    }
  }
}
