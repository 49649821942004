import { Pipe, PipeTransform } from '@angular/core';
import { CartItem, SalesOrderItem } from '@lobos/common';
import { ArticleHelperService } from '../../services/catalog/article-helper.service';

@Pipe({
  name: 'cartItemIsMaster',
})
export class CartItemIsMasterPipe implements PipeTransform {
  transform<T extends CartItem | SalesOrderItem>(items: T[]): T[] {
    return items ? items.filter((item: T) => ArticleHelperService.itemIsMaster(item)) : [];
  }
}
