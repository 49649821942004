import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Params } from '@angular/router';

@Component({
  selector: 'app-gela-button',
  templateUrl: './gela-button.component.html',
  styleUrls: ['./gela-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GelaButtonComponent {
  @Input() text: string = '';
  @Input() size: 'small' | 'large' | undefined;
  @Input() look: 'primary' | 'secondary' | 'tertiary' | 'border' | 'border-icon' | 'tab' | undefined;
  @Input() loading: boolean | null = null;
  @Input() type: 'button' | 'link' = 'button';
  @Input() href: string | undefined;
  @Input() linkParams: Params = {};
  @Input() public value: string | number | boolean = '';
  @Input() disabled: boolean = false;
  @Input() fullWidth: boolean = false;
  @Input() classes: string = '';

  @Output() linkClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  public click($event: MouseEvent): void {
    this.linkClick.emit($event);
  }
}
