import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CATALOG_CONFIG, CatalogConfig, SearchService, SearchStore } from '@lobos/library-v2';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GelaSearchService extends SearchService {
  /**
   * Creates an instance of search service.
   */
  constructor(
    protected override store: SearchStore,
    @Inject(CATALOG_CONFIG) private gelaCatalogConfig: CatalogConfig,
    protected override http: HttpClient,
  ) {
    super(store, gelaCatalogConfig, http);
  }

  getProductsByArticleID(articleID: string) {
    this.store.setLoading(true);
    return this.http.get<any>(this.gelaCatalogConfig.apiUrl + '/products/articleID/' + articleID).pipe(
      tap((response) => {
        this.store.update({
          products: response.products,
          productTotal: response.productTotal,
        });
        this.store.setLoading(false);
      }),
      catchError((error: Error) => {
        this.store.setLoading(false);
        return throwError(() => error);
      }),
    );
  }

  getSearchSuggestions(term: string) {
    return this.http.get<string[]>(this.gelaCatalogConfig.apiUrl + '/articles/search-term/' + term);
  }
}
