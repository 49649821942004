<div *ngIf="article" class="bg-white p-3 relative flex z-10 justify-end">
  <a
    [queryParams]="article.sArticleID!.toString() | urlFilterParams"
    [routerLink]="'/article/' + article.sArticleID | localizeUrlPathAsync | async"
    class="absolute inset-0 rounded-20px"
  ></a>
  <app-image
    [image]="(article | returnGelaArticleOrUndefined) ? ($any(article) | articleMainImage) : (article | getDefaultCloudinaryImage)"
    classes="w-92px h-92px object-contain object-center mr-14"
  ></app-image>

  <div *transloco="let t; scope: 'catalog'" class="block basis-mini-article-card">
    <div class="flex mt-1">
      <span class="text-small font-montserrat font-bold leading-tight">{{
        t("catalog.article-mini-card.sku-and-unit", {
          sku: article.sArticleID,
          unit: article.sQuantityUnitSales,
          packageSize: article.decQuantityPackage
        })
      }}</span>
      <div
        *ngIf="article | returnGelaArticleOrUndefined as gelaArticle"
        class="ml-auto flex items-center -mr-1.5 -mt-2.5 translate-y-1 relative z-20 js-mini-icons"
      >
        <div class="relative mr-1.5 last:mr-0 leading-none">
          <app-article-ordered [article]="gelaArticle"></app-article-ordered>
        </div>
        <div class="relative mr-1.5 last:mr-0 leading-none">
          <app-flyout [closable]="false" [disableOnTouch]="true">
            <ng-container trigger>
              <button (click)="openDownloadModal(gelaArticle)" class="js-download group">
                <span
                  class="ifont ifont--download text-icon text-primary -translate-y-0.5 group-hover:text-gela-black transition-all duration-200"
                ></span>
              </button>
              <ng-content></ng-content>
            </ng-container>
            <div flyout>{{ t("catalog.article-mini-card.tooltip-datasheet") }}</div>
          </app-flyout>
        </div>
        <div class="relative mr-1.5 last:mr-0 leading-none">
          <app-article-note [article]="gelaArticle" [mini]="true"></app-article-note>
        </div>
        <div class="relative mr-0.5 last:mr-0 leading-none">
          <app-preferred-commission [item]="gelaArticle"></app-preferred-commission>
        </div>
        <div class="relative mr-1.5 last:mr-0 leading-none">
          <app-flyout [closable]="false" [disableOnTouch]="true">
            <ng-container trigger>
              <app-article-favorite [article]="gelaArticle" [quantity]="quantity" [size]="'s'"></app-article-favorite>

              <ng-content></ng-content>
            </ng-container>
            <div flyout>{{ t("catalog.article-mini-card.tooltip-favorite") }}</div>
          </app-flyout>
        </div>
        <div class="relative mr-1.5 last:mr-0 leading-none">
          <app-flyout [closable]="false" [disableOnTouch]="true">
            <ng-container trigger>
              <button (click)="openArticlePreview(gelaArticle)" class="js-open-modal-article group">
                <span class="ifont ifont--open text-icon text-primary group-hover:text-gela-black transition-all duration-200"></span>
              </button>
              <ng-content></ng-content>
            </ng-container>
            <div flyout>{{ t("catalog.article-mini-card.tooltip-preview") }}</div>
          </app-flyout>
        </div>
      </div>
    </div>
    <div class="flex items-start mt-4">
      <div class="flex flex-col -mb-4 -mt-2">
        <div class="flex flex-row">
          <app-in-viewport>
            <ng-template>
              <app-article-price [article]="article" [priceScaleType]="'minicard'" [size]="'m'">
                <app-article-availability
                  *ngIf="article | returnGelaArticleOrUndefined as gelaArticle"
                  [article]="gelaArticle"
                ></app-article-availability>
              </app-article-price>
            </ng-template>
          </app-in-viewport>
        </div>
      </div>
      <div class="ml-auto flex items-center mt-auto relative z-0">
        <app-add-to-cart [article]="article" (quantityChanged)="quantityChanged($event)"></app-add-to-cart>
      </div>
    </div>
  </div>

  <app-in-viewport>
    <ng-template>
      <app-article-shop-info *ngIf="article | returnGelaArticleOrUndefined as gelaArticle" [article]="gelaArticle"></app-article-shop-info>
    </ng-template>
  </app-in-viewport>
</div>
