import { Pipe, PipeTransform } from '@angular/core';
import { ImageGalleryItem, ImageHelperService } from '../../services/catalog/image-helper.service';
import { Product } from '@lobos/common';

@Pipe({
  name: 'productImages',
})
export class ProductImagesPipe implements PipeTransform {
  constructor(private imageHelperService: ImageHelperService) {}

  transform(product: Product): ImageGalleryItem[] {
    return this.imageHelperService.getProductImages(product);
  }
}
