import { Pipe, PipeTransform } from '@angular/core';
import { Feature } from '@lobos/common';

@Pipe({
  name: 'getFeatureValue',
})
export class GetFeatureValuePipe implements PipeTransform {
  transform(items: Feature[], key: string): string | number {
    return items.find((option) => option.sName === key)?.sValue || '';
  }
}
