import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FocusableDirective, SearchQuery, switchTap, UrlHelperService } from '@lobos/library-v2';
import { combineLatest, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { debounceTime, filter, first, map, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Article, Category, HierarchicalCategory, Hierarchy, Product } from '@lobos/common';
import { GelaArticle } from '../../../../services/catalog/model/gela-article';
import { CatalogHelperService } from '../../../../services/catalog/catalog-helper.service';
import { GelaProduct } from '../../../../services/catalog/model/gela-product';
import { DOCUMENT } from '@angular/common';
import { GelaSearchService } from '../../../../services/search/gela-search.service';

@UntilDestroy()
@Component({
  selector: 'app-main-search',
  templateUrl: './main-search.component.html',
  styleUrls: ['./main-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainSearchComponent implements OnInit {
  @Output() searchItemClicked: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('searchInput') searchInput: ElementRef | undefined;
  @ViewChild(FocusableDirective)
  private focusList: FocusableDirective | undefined;
  searchControl: UntypedFormControl = new UntypedFormControl('');
  termSuggestions: string[] = [];
  articles$: Observable<GelaArticle[]> = this.searchQuery.articles$.pipe(
    map((articles) => articles.slice(0, 3)),
    tap((articles) => {
      articles.map((article) => {
        article.oProductInfo[0].listHierarchicalCategories.map((categories) => {
          if (this.isOfTypeHierarchy(categories)) {
            const category = (categories as Hierarchy).hierarchy[(categories as Hierarchy).hierarchy.length - 2];
            // no duplicates
            if (!this.categories.some((value) => value.sCategory === category.sValue)) {
              this.categories.push({
                sCategory: category.sValue,
                sUrlPath: this.getCategoryUrlPath(category.sName.slice(-1), (categories as Hierarchy).sUrlPath), // .slice(-1) gets last char of string
              });
            }
          }
        });
      });
    }),
  );
  categories$: Observable<Category[]> = this.searchQuery.categories$;
  categories: { sCategory: string; sUrlPath: string }[] = [];
  products$: Observable<GelaProduct[]> = <Observable<GelaProduct[]>>this.searchQuery.products$.pipe(
    map((products) => <GelaProduct[]>products.slice(0, 3)),
    tap((products) => {
      products.map((product) => {
        product.listHierarchicalCategories.map((categories) => {
          if (this.isOfTypeHierarchy(categories)) {
            const category = (categories as Hierarchy).hierarchy[(categories as Hierarchy).hierarchy.length - 2];
            // no duplicates
            if (!this.categories.some((value) => value.sCategory === category.sValue)) {
              this.categories.push({
                sCategory: category.sValue,
                sUrlPath: this.getCategoryUrlPath(category.sName.slice(-1), (categories as Hierarchy).sUrlPath), // .slice(-1) gets last char of string
              });
            }
          }
        });
      });
    }),
  );
  showResults: boolean = false;
  hasFocus: boolean = false;
  exactMatch: GelaArticle | undefined = undefined;
  isLoading: boolean = false;

  constructor(
    private searchService: GelaSearchService,
    private searchQuery: SearchQuery<GelaArticle>,
    private router: Router,
    private urlHelper: UrlHelperService,
    private catalogHelperService: CatalogHelperService,
    private changeRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.categories$
      .pipe(
        map((categories) => {
          const slicedCategories = categories.slice(0, 3);
          slicedCategories.map((category) =>
            this.categories.push({
              sCategory: category.sTitle,
              sUrlPath: typeof category.sUrlPath === 'string' ? category.sUrlPath : category.sUrlPath.join('/'),
            }),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.searchControl.valueChanges
      .pipe(
        tap(() => this.showSearchDropdown()),
        filter((searchTerm: string) => searchTerm.length > 1),
        tap(() => (this.isLoading = true)),
        tap(() => (this.categories = [])),
        debounceTime(300),
        tap(() => {
          this.exactMatch = undefined;
        }),
        switchTap((searchString: string) => {
          return combineLatest([
            this.searchService.search(searchString.slice(0, 50)),
            this.searchService.getSearchSuggestions(searchString),
          ]).pipe(
            tap(([searchResults, suggestions]) => {
              this.termSuggestions = suggestions;
              for (const article of searchResults.articles) {
                if (this.isExactMatch(article, searchString)) {
                  this.categories = [];
                  this.exactMatch = <GelaArticle>article;
                  this.searchService.getProductsByArticleID(article.sArticleID.toString()).pipe(first()).subscribe();
                  break;
                }
              }
            }),
          );
        }),
        tap(() => (this.isLoading = false)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  submitSearch(searchValue: string) {
    if (!searchValue.length) {
      return;
    }
    if (this.exactMatch) {
      return this.router.navigate([this.urlHelper.localizeUrl('article'), this.exactMatch.sArticleID]);
    } else {
      return this.router.navigate([this.urlHelper.localizeUrl('search'), searchValue]);
    }
  }

  getCleanSuggestion(suggestion: string): string {
    return suggestion.replace(/<\/?[^>]+(>|$)/g, ''); // .replace(/\//g, '%2f');
  }

  searchSuggestion(value: string) {
    return this.router.navigate([this.urlHelper.localizeUrl('search'), this.getCleanSuggestion(value)]);
  }

  fillInputField(term: string) {
    this.searchControl.patchValue(term);
  }

  focusIn() {
    this.hasFocus = true;
    this.showSearchDropdown();
    this.changeRef.markForCheck();
  }

  focusOut() {
    this.hasFocus = false;
    this.changeRef.markForCheck();
  }

  articleRouterLinkClicked(event: Event) {
    this.searchItemClicked.emit();
    this.closeSearchDropdown(event);
  }

  emptyInput(e: Event) {
    e.stopPropagation();
    this.searchControl.patchValue('');
    this.document
      .querySelector<HTMLElement>('.js-header-search')!
      .querySelector<HTMLElement>('.input-form')!
      .querySelector<HTMLElement>('.js-header-search-input')!
      .focus();
  }

  productFocusEnterClick(event: Event, product: Product) {
    this.closeSearchDropdown(event);
    this.router.navigate([this.urlHelper.localizeUrl(this.catalogHelperService.getCatalogPathByLocation(product.sUrlPath))]);
  }

  articleFocusEnterClick(event: Event, article: Article) {
    this.closeSearchDropdown(event);
    this.router.navigate([this.urlHelper.localizeUrl('article'), article.sArticleID]);
  }

  showSearchDropdown() {
    if (this.searchControl.value.length > 1) {
      this.showResults = true;
      this.document.body.classList.add('overflow-hidden');
    } else {
      this.showResults = false;
      this.document.body.classList.remove('overflow-hidden');
    }
  }

  getCategoryUrlPath(level: string, urlPath: string): string {
    if (!isNaN(+level)) {
      const splitArray = urlPath.split('/');
      if (splitArray.length >= +level) {
        let returnString = '';
        for (let i = 0; i <= +level; i++) {
          returnString += splitArray[i];
          returnString += '/';
        }
        return returnString;
      }
    }
    return '';
  }

  onOutsideClick(event: Event) {
    if (!this.hasFocus) {
      this.closeSearchDropdown(event);
    }
  }

  closeSearchDropdown(event: Event) {
    event.stopPropagation();
    this.document.body.classList.remove('overflow-hidden');
    this.showResults = false;
  }

  isExactMatch(article: Article, searchValue: string) {
    return !!(
      (article?.sArticleID && article.sArticleID.toString().toLowerCase() === this.searchControl.value.toLowerCase()) ||
      ((article as GelaArticle)?.sPurchaseDiscounts &&
        (article as GelaArticle).sPurchaseDiscounts.some(
          (purchaseDiscount) => purchaseDiscount?.toLowerCase() === searchValue?.toLowerCase(),
        ))
    );
  }

  isOfTypeHierarchy(hierarchy: Hierarchy | HierarchicalCategory) {
    return 'sUrlPath' in hierarchy;
  }

  trackByArticleId(_: number, item: Article) {
    return item.sArticleID;
  }

  trackByProductId(_: number, item: Product) {
    return item.lngGroup;
  }

  focusEvent(event: KeyboardEvent) {
    if (['arrowdown'].includes(event.key.toLowerCase())) {
      this.focusList?.focus();
    } else if (['enter'].includes(event.key.toLowerCase())) {
      this.focusList?.focus();
      this.submitSearch(this.searchControl.value);
      this.closeSearchDropdown(event);
      this.focusOut();
    }
  }
}
