import { Pipe, PipeTransform } from '@angular/core';
import { GelaFeature } from '../../services/catalog/model/gela-feature';

@Pipe({
  name: 'articleFeaturesToKeyValue',
})
export class ArticleFeaturesToKeyValuePipe implements PipeTransform {
  public transform(features: GelaFeature[]): { key: string; value: string }[] {
    return features.map((feature) => ({
      key: feature.sName,
      value:
        feature.dimension && feature.dimension.length > 0 && feature.dimension !== 'keine' && feature.dimension !== 'aucune'
          ? feature.sValue + ' ' + feature.dimension
          : feature.sValue,
    }));
  }
}
