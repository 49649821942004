<div
  *transloco="let t; scope: 'header'"
  class="js-header-big--nav hidden mobile-nav:block max-w-page 3xl:max-w-midscreen 4xl:max-w-bigscreen px-5 md:px-10 tablet:px-82px mx-auto"
>
  <ul class="flex w-full">
    <li class="first:mr-auto mr-12 3xl:mr-3-9vw last:mr-0">
      <a
        [routerLink]="['/']"
        [title]="t('header.main-navigation.shop-title')"
        [ngClass]="('cms' | cmsRouterLinkActive | async) === false ? 'text-gela-black' : 'text-primary'"
        class="text-gela-black hover:text-gela-black font-montserrat font-bold text-xl transition-all duration-200 leading-tight relative pb-5"
      >
        {{ t("header.main-navigation.shop-title") }}
        <span
          *ngIf="('cms' | cmsRouterLinkActive | async) === false"
          class="w-full h-5px bg-gela-black inline-block absolute left-0 bottom-0 transition-all duration-200"
        ></span>
      </a>
    </li>
    <li *ngFor="let menuItem of menu" class="first:mr-auto mr-12 3xl:mr-3-9vw last:mr-0">
      <a
        [routerLink]="'cms/' + menuItem.url | localizeUrlPath"
        [title]="menuItem.title"
        [ngClass]="('cms/' + menuItem.url | cmsRouterLinkActive | async) ? 'text-gela-black' : 'text-primary'"
        class="hover:text-gela-black font-montserrat font-bold text-xl transition-all duration-200 leading-tight relative pb-5"
      >
        {{ menuItem.title }}
        <span
          *ngIf="'cms/' + menuItem.url | cmsRouterLinkActive | async"
          class="w-full h-5px bg-gela-black inline-block absolute left-0 bottom-0 transition-all duration-200"
        ></span>
      </a>
    </li>
  </ul>
</div>
