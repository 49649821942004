import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';

@Injectable({
  providedIn: 'root',
})
export class LanguageResolver implements Resolve<boolean> {
  private readonly response: Response;

  constructor(@Optional() @Inject(RESPONSE) response: Response, private translocoService: TranslocoService, private router: Router) {
    this.response = response;
  }

  public resolve(route: ActivatedRouteSnapshot): boolean {
    const lang: string = route.params?.['lang'] ?? this.translocoService.getDefaultLang();
    if (!(this.translocoService.getAvailableLangs() as string[]).includes(lang)) {
      this.languageNotFound();
      return false;
    }

    this.translocoService.setActiveLang(lang);
    return true;
  }

  private languageNotFound(): void {
    // this will only be sent in SSR environment
    if (this.response) {
      return this.response.redirect(302, '/');
    }

    this.router.navigate(['/']);
  }
}
