import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, LanguageService, switchTap, UserService } from '@lobos/library-v2';
import { getBrowserLang, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, from, iif, of } from 'rxjs';
import { filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AlternateVersionInterface, AlternateVersionQuery } from '../alternate-version';
import { SettingsInterface, SettingsQuery, SettingsService } from '../settings';
import { Language, User } from '@lobos/common';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class LanguageHelperService {
  public alternative$ = this.alternativeVersionQuery.select('versions').pipe(shareReplay(1));

  constructor(
    private alternativeVersionQuery: AlternateVersionQuery<AlternateVersionInterface>,
    private transloco: TranslocoService,
    private authService: AuthService,
    private settingsService: SettingsService<SettingsInterface>,
    private languageService: LanguageService<Language>,
    private userService: UserService<User>,
    private router: Router,
    private settingsQuery: SettingsQuery<SettingsInterface>,
  ) {}

  public async determineUserLanguage(): Promise<string> {
    // 1. Stored language has first priority
    const settings: SettingsInterface | undefined = await this.settingsQuery.select().pipe(first(), untilDestroyed(this)).toPromise();
    if (settings?.language) {
      return settings.language;
    }

    // 1.b. Stored in Cookie (mainly SSR)
    const cookieSettings: SettingsInterface | undefined = this.settingsService.getFromCookie();
    if (cookieSettings) {
      return cookieSettings.language;
    }

    // 2. Logged-in user has second priority
    const user: User | undefined = await this.authService.authUser$.pipe(first(), untilDestroyed(this)).toPromise();
    if (user?.shtLanguageID) {
      return user.sLanguageIsoCode;
    }

    // 3. Browser lang or default locale have last priority
    const browserLang: string | undefined = getBrowserLang();
    return environment.availableLang.includes(browserLang || environment.defaultLocale)
      ? browserLang || environment.defaultLocale
      : environment.defaultLocale;
  }

  public async changeVersion(versionKey: string): Promise<unknown> {
    return this.alternative$
      .pipe(
        first(),
        map((versions: AlternateVersionInterface[]) => versions.find((version: AlternateVersionInterface) => version.key === versionKey)),
        filter((version: AlternateVersionInterface | undefined) => !!version),
        map((version: AlternateVersionInterface | undefined) => version!),
        tap((version: AlternateVersionInterface) => this.transloco.setActiveLang(version.key)),
        tap((version: AlternateVersionInterface) => this.settingsService.update({ language: version.key })),
        // change the language on the user
        // retrieve all languages first, since we need the language id
        // we have to send the whole user object for the update
        switchTap((version: AlternateVersionInterface) =>
          combineLatest([of(version), this.authService.authUser$]).pipe(
            first(),
            switchMap(([version, user]: [AlternateVersionInterface, User | undefined]) =>
              iif(
                () => !!user,
                // true
                this.languageService.get().pipe(
                  map((languages: Language[]) => languages.find((language: Language) => language.sIsoCode === version.key)),
                  filter((language: Language | undefined) => !!language),
                  switchMap((language: Language | undefined) =>
                    this.userService.update(user?.lngContactID, {
                      ...user,
                      shtLanguageID: language?.shtLanguageID,
                    }),
                  ),
                ),
                // false
                of(undefined),
              ),
            ),
          ),
        ),
        switchMap((version: AlternateVersionInterface) => from(this.router.navigate([version.url]))),
        untilDestroyed(this),
      )
      .toPromise();
  }
}
