import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService, Catch } from '@lobos/library-v2';
import { TranslocoService } from '@ngneat/transloco';
import { shareReplay } from 'rxjs/operators';
import { LanguageHelperService } from '../../../services/language/language-helper.service';
import { SettingsInterface, SettingsService } from '../../../services/settings';
import { Observable } from 'rxjs';
import { User } from '@lobos/common';
import { ServiceNav } from '../../../services/cms/interfaces/cms-static.interface';
import { CmsQuery } from '../../../services/cms/cms.query';
import { ComnormService } from '../../../services/comnorm/comnorm.service';

@Component({
  selector: 'app-topbar-navigation',
  templateUrl: './topbar-navigation.component.html',
  styleUrls: ['./topbar-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopbarNavigationComponent {
  public alternative$ = this.languageHelper.alternative$.pipe(shareReplay(1));
  public currentVersionKey$ = this.transloco.langChanges$;
  public isLanguageDropdownOpen = false;
  public isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  public user$: Observable<User | undefined> = this.authService.authUser$;

  constructor(
    private authService: AuthService,
    private languageHelper: LanguageHelperService,
    private settingsService: SettingsService<SettingsInterface>,
    private transloco: TranslocoService,
    private cmsQuery: CmsQuery,
    protected comNormService: ComnormService,
  ) {}

  @Catch({ label: 'TopbarNavigationComponent.changeVersion()' })
  public async changeVersion(versionKey: string): Promise<void> {
    await this.languageHelper.changeVersion(versionKey);
    this.toggleLanguageDropdown();
    window.location.reload();
  }

  toggleLanguageDropdown(): void {
    this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen;
  }

  topNav$: Observable<ServiceNav[]> = this.cmsQuery.topNav$;
}
