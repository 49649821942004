<ng-container *transloco="let t; scope: 'shared'">
  <div class="js-number-wrap rounded-default flex items-center relative group">
    <div class="relative">
      <span
        *ngIf="!isLoongGood; else loongGood"
        class="bg-white absolute px-3px text-primary-dark text-small leading-tight top-0 -translate-y-1/2 left-2.5 group-table:bg-paper transition-all duration-200 js-hover-bg"
      >
        {{ t("shared.units." + valueLabel) }}
      </span>
      <ng-template #loongGood>
        <span
          class="bg-white absolute px-3px text-primary-dark text-small leading-tight top-0 -translate-y-1/2 left-2.5 group-table:bg-paper transition-all duration-200 js-hover-bg"
        >
          {{ t("shared.units.ST") }}
        </span>
      </ng-template>
      <form [formGroup]="form">
        <input
          type="number"
          class="js-number border-2 leading-tight rounded-default focus:border-primary w-full text-center py-3.5 pr-5 focus:ring-0"
          formControlName="quantity"
          [ngClass]="form.invalid ? 'border-secondary-dark' : 'border-primary-light'"
          (focusout)="quantityOut()"
        />
      </form>
      <div class="absolute flex-col justify-between right-1 top-1.5 h-input flex">
        <button
          (click)="increase($event)"
          class="js-increase w-4 h-4 bg-primary-light text-primary-dark relative rounded-default hover:bg-primary-dark hover:text-primary-light transition-all duration-200"
        >
          <span class="ifont ifont--plus text-lg absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 pointer-events-none"></span>
        </button>
        <button
          (click)="decrease($event)"
          class="js-decrease w-4 h-4 bg-primary-light text-primary-dark relative rounded-default hover:bg-primary-dark hover:text-primary-light transition-all duration-200"
        >
          <span class="ifont ifont--minus text-lg absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 pointer-events-none"></span>
        </button>
      </div>
    </div>
    <div *ngIf="isLoongGood" class="relative">
      <span
        class="bg-white absolute px-3px text-primary-dark text-small leading-tight top-0 -translate-y-1/2 left-2.5 group-table:bg-paper transition-all duration-200 js-hover-bg"
      >
        {{ lengthLabel }}
      </span>
      <input
        id="length"
        type="number"
        pattern="\d*"
        class="js-number border-2 leading-tight rounded-default focus:border-primary w-full text-center py-3.5 pr-5 focus:ring-0 border-primary-light"
        [value]="length"
        (focusout)="lengthOut()"
      />
      <div class="absolute flex-col justify-between right-1 top-1.5 h-input flex">
        <button
          (click)="increaseLength()"
          class="js-increase w-4 h-4 bg-primary-light text-primary-dark relative rounded-default hover:bg-primary-dark hover:text-primary-light transition-all duration-200"
        >
          <span class="ifont ifont--plus text-lg absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 pointer-events-none"></span>
        </button>
        <button
          (click)="decreaseLength()"
          class="js-decrease w-4 h-4 bg-primary-light text-primary-dark relative rounded-default hover:bg-primary-dark hover:text-primary-light transition-all duration-200"
        >
          <span class="ifont ifont--minus text-lg absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 pointer-events-none"></span>
        </button>
      </div>
    </div>
  </div>
  <ng-container *ngIf="form.controls.quantity.hasError('required')">
    <p class="text-secondary-dark text-small mt-1.5 leading-tight -mb-0.5">{{ t("shared.cart.quantity.error.required") }}</p>
  </ng-container>
  <ng-container *ngIf="form.controls.quantity.hasError('min')">
    <p class="text-secondary-dark text-small mt-1.5 leading-tight -mb-0.5">
      {{
        t("shared.cart.quantity.error.min", {
          quantity: roundTo,
          unit: t("shared.units." + valueLabel)
        })
      }}
    </p>
  </ng-container>
</ng-container>
