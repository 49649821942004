<ng-container *transloco="let t; scope: 'checkout'">
  <div
    class="js-modal-commission js-modal fixed z-50 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gela-black opacity-64 transition-opacity" aria-hidden="true"></div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&ZeroWidthSpace;</span>
      <div
        class="relative inline-block align-bottom text-left shadow-flyout transform transition-all sm:my-8 sm:align-middle sm:max-w-modal sm:w-full"
      >
        <div class="block absolute top-5 right-4 z-10">
          <button
            (click)="close()"
            class="js-close-modal p-1 leading-none rounded-default group text-primary hover:bg-primary-bright transition-all duration-200"
          >
            <span class="ifont ifont--close text-icon-sml group-hover:text-primary-dark transition-all duration-200"></span>
          </button>
        </div>
        <div class="w-full bg-white p-6 border border-primary-light rounded-20px">
          <p class="font-montserrat font-bold text-lg tablet:text-2xl pr-6">{{ t("checkout.cart.components.commission-modal.header") }}</p>
          <div class="tablet:flex mt-6 mb-5">
            <app-image
              *ngIf="item && item.oArticle.sArticleID"
              [image]="item.oArticle | articleMainImage"
              classes="w-92px h-92px object-contain object-center mx-auto tablet:mx-0 mb-5 tablet:mb-0"
            ></app-image>
            <div class="tablet:pl-5 w-full block">
              <div class="mb-7">
                <app-select
                  [options]="(commission$ | async) || []"
                  [name]="'sPL1Commission'"
                  [value]="item?.sPL1Commission || ''"
                  [form]="form"
                  [editMode]="true"
                  prefix="commission-modal"
                  (newSelected)="newCommission($event)"
                  [label]="t('checkout.cart.components.commission-modal.placeholder.commission')"
                  [newPlaceholder]="t('checkout.cart.components.commission-modal.placeholder.new-commission')"
                  [hasMaxHeight]="true"
                ></app-select>
              </div>
            </div>
          </div>
          <div [ngClass]="{ 'flex w-full justify-between': dontShowOption || this.form!.value.sPL1Commission }" class="mt-12">
            <app-button
              (click)="apply()"
              [ngClass]="{ 'w-full': dontShowOption || this.form!.value.sPL1Commission }"
              classes="w-full self-start bg-primary-light text-gela-black hover:text-white hover:bg-secondary"
              [text]="t('checkout.cart.components.commission-modal.button.apply')"
            ></app-button>
            <app-button
              *ngIf="dontShowOption || this.form!.value.sPL1Commission"
              (click)="apply(true)"
              [text]="
                this.form!.value.sPL1Commission
                  ? t('checkout.cart.components.commission-modal.button.delete')
                  : t('checkout.cart.components.commission-modal.button.dont-show')
              "
              class="w-full pl-3.5"
              classes="w-full inline-block text-center font-montserrat font-bold px-6 py-3.5 border-2 border-primary-light rounded-button bg-white hover:text-white hover:bg-secondary hover:border-secondary"
            ></app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
