import { Pipe, PipeTransform } from '@angular/core';
import { ShippingAddress } from '@lobos/common';
import { AddressHelperService } from '../../services/catalog/address-helper.service';

@Pipe({
  name: 'shippingAddressList',
})
export class ShippingAddressListPipe implements PipeTransform {
  constructor(private addressHelperService: AddressHelperService) {}

  public transform(addresses: ShippingAddress[] | null): { key: string | number; value: string }[] {
    const normalizedAddresses: { key: string | number; value: string }[] = [];
    if (!addresses?.length) {
      return normalizedAddresses;
    }

    for (const address of addresses) {
      normalizedAddresses.push({
        key: address.lngAddressID,
        value: this.addressHelperService.summary(address),
      });
    }

    return normalizedAddresses;
  }
}
