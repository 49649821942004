import { Injectable } from '@angular/core';
import { HookEvent, HookHandlerAbstract, HookPriority, LanguageChangedHook } from '@lobos/library-v2';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { CmsService } from '../../../services/cms/cms.service';
import { CmsStore } from '../../../services/cms/cms.store';

type type = LanguageChangedHook;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class LanguageChangedCmsStoresHandler extends HookHandlerAbstract<type, Observable<string>> {
  constructor(private cmsStore: CmsStore, private cmsService: CmsService) {
    super();
  }

  ids(): type[] {
    return [{ type: 'LanguageChangedHook' }];
  }

  override priority(): number {
    return HookPriority.LOW;
  }

  override after(event: HookEvent<type, Observable<string>>, result: Observable<string>): Observable<string> {
    this.cmsStore.reset();

    forkJoin([
      this.cmsService.getHomePage(),
      this.cmsService.getMainMenu(),
      this.cmsService.getFooterMenu(),
      this.cmsService.getStaticContent(),
    ])
      .pipe(first(), untilDestroyed(this))
      .subscribe();

    return super.after(event, result);
  }
}
