<div class="flex flex-col">
  <button
    (click)="sortClicked.emit({ column: tableHeaderName, direction: 'asc' })"
    [ngClass]="sortStatus.column === tableHeaderName && sortStatus.direction === 'asc' ? '' : 'text-primary'"
    class="ifont ifont--chevron-down text-xl rotate-180 hover:text-primary-dark transition-all duration-200"
  ></button>
  <button
    (click)="sortClicked.emit({ column: tableHeaderName, direction: 'desc' })"
    [ngClass]="sortStatus.column === tableHeaderName && sortStatus.direction === 'desc' ? '' : 'text-primary'"
    class="ifont ifont--chevron-down text-xl hover:text-primary-dark transition-all duration-200"
  ></button>
</div>
