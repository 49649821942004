import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, Input, OnChanges, ViewChild } from '@angular/core';
import { NgxPopperjsContentComponent, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';

export type FlyoutPosition = 'left' | 'right' | 'top' | 'bottom';

@Component({
  selector: 'app-flyout',
  templateUrl: './flyout.component.html',
  styleUrls: ['./flyout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlyoutComponent implements OnChanges {
  @ViewChild('flyout')
  public flyout: NgxPopperjsContentComponent | undefined;

  @Input() title: string = '';
  @Input() closable: boolean = true;
  @Input() disableOnTouch: boolean = false;
  @Input() compact: boolean = false;
  @Input() flyoutPosition: FlyoutPosition = 'right';
  @Input() closeDelay: number = 300;
  @Input() disablePopper: boolean = false;

  isTouch: boolean = !this.breakpoint.isMatched('(hover)');
  position: NgxPopperjsPlacements = NgxPopperjsPlacements.LEFT;
  trigger?: NgxPopperjsTriggers;

  constructor(private breakpoint: BreakpointObserver) {}

  public ngOnChanges() {
    this.trigger = this.isTouch ? (this.disableOnTouch ? NgxPopperjsTriggers.none : NgxPopperjsTriggers.click) : NgxPopperjsTriggers.hover;

    switch (this.flyoutPosition) {
      case 'left':
        this.position = NgxPopperjsPlacements.LEFT;
        break;
      case 'right':
        this.position = NgxPopperjsPlacements.RIGHT;
        break;
      case 'top':
        this.position = NgxPopperjsPlacements.TOP;
        break;
      case 'bottom':
        this.position = NgxPopperjsPlacements.BOTTOM;
        break;
    }
  }

  public close() {
    if (this.flyout instanceof NgxPopperjsContentComponent) {
      this.flyout.hide();
    }
  }
}
