import { Pipe, PipeTransform } from '@angular/core';
import { GelaArticle } from '../../services/catalog/model/gela-article';
import { CustomerArticleInterface } from '../../services/customer-article/customer-article.interface';

@Pipe({
  name: 'returnGelaArticleOrUndefined',
})
export class ReturnGelaArticleOrUndefinedPipe implements PipeTransform {
  transform(article: GelaArticle | CustomerArticleInterface): GelaArticle | undefined {
    if (article.shtPL1CustomerArticle) {
      return undefined;
    }
    return article as GelaArticle;
  }
}
