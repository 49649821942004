<div (click)="toggleIsOpen()" [class.md:pb-24]="!isOpen" class="js-toggle-cat-nav flex items-center cursor-pointer" *ngIf="category">
  <h1 class="font-montserrat font-bold text-2xl tablet:text-5xl" [innerHTML]="category.sTitle"></h1>
  <button *ngIf="subLevelCategories?.length" class="hidden md:inline-block ml-5">
    <span [class.rotate-180]="isOpen" class="js-chevron ifont ifont--chevron-down text-3xl translate-y-1.5 rotate-180"></span>
  </button>
</div>
<ul
  *ngIf="subLevelCategories"
  [class.md:hidden]="!isOpen"
  class="js-cat-nav px-5 md:px-0 -mx-5 md:mx-0 w-slider-phone md:w-auto snap-x snap-mandatory grid-flow-col md:grid-flow-row overflow-x-auto md:overflow-hidden scrollbar scrollbar--mobile grid col-span-full md:grid-cols-4 lg:grid-cols-6 2xl:grid-cols-8 gap-5 3xl:gap-11 4xl:gap-68px mt-7 md:mt-10 pb-7 md:pb-9"
>
  <li *ngFor="let subCategory of subLevelCategories" class="w-142px md:w-auto">
    <a [routerLink]="subCategory.sUrlPath | catalogPath | localizeUrlPath" [title]="subCategory.sTitle" class="group">
      <div class="bg-white p-2.5 rounded-10px [aspect-ratio:1/1]">
        <app-image [image]="subCategory | catalogMainImage" classes="'w-full mx-auto'"></app-image>
      </div>
      <span
        class="hyphens-auto md:hyphens-none text-left text-small font-montserrat font-medium inline-block relative w-auto pb-1.5 mt-2 md:mt-1.5"
        [innerHTML]="subCategory.sTitle"
      >
        <span
          class="h-3px w-full bg-gela-black inline-block absolute left-0 bottom-0 opacity-0 transition-opacity duration-200 group-hover:opacity-100"
        ></span>
      </span>
    </a>
  </li>
</ul>
