import { Injectable } from '@angular/core';
import { Configuration, Label, LabelData, PaddingBoxInterface, RendererAbstract, Template } from '@lobos/label-print-v2';
import { GelaConfigurationExtraInterface } from '../model/gela-configuration-extra.interface';
import * as jsbarcode from 'jsbarcode';

@Injectable({
  providedIn: 'root',
})
export class Gela3x8Renderer extends RendererAbstract<GelaConfigurationExtraInterface> {
  public template(): Template {
    return {
      id: 'default',
      title: 'profile.label-print.domain.template.suedo3x8.title',
      format: { width: 210, height: 297 },
      boxSizes: { width: 70, height: 36.4 },
      orientation: 'p',
      numberOfBoxes: { horizontal: 3, vertical: 8 },
    };
  }

  public override prepare(labels: Label[], configuration: Configuration<GelaConfigurationExtraInterface>): void {
    this.configuration = configuration;

    this.rawLabels = labels;

    this.labels = [];
    // attach empty offset labels
    this.labels = this.labels.concat(Array(0).fill({ type: 'empty' } as Label));
    // this will repeat the labels as often, as `amount` is set
    this.rawLabels
      .filter((rawLabel: Label) => rawLabel.print && rawLabel.amount > 0)
      .forEach((rawLabel: Label, _: number) => {
        this.labels = this.labels.concat(Array(rawLabel.amount).fill(rawLabel));
      });

    this.jspdf.startDocument(this.orientation, this.format);
  }

  /**
   * Geiser 3x8
   *
   * We try to layout the following:
   * ┌───────────────────────────────────────────────────────────────────────────────────────────────────────────┐
   * │ ┌─────────────────────────────────────────────────────────────────────────┐ ┌───────────────────────────┐ │
   * │ │                                                                         │ │                           │ │
   * │ │                                                                         │ │                           │ │
   * │ │                            1/3V; 2/3H; Title                            │ │                           │ │
   * │ │                                                                         │ │                           │ │
   * │ │                                                                         │ │                           │ │
   * │ └─────────────────────────────────────────────────────────────────────────┘ │     2/3V; 1/3H; Image     │ │
   * │ ┌─────────────────────────────────────────────────────────────────────────┐ │                           │ │
   * │ │                                                                         │ │                           │ │
   * │ │                                                                         │ │                           │ │
   * │ │                         1/3V; 2/3H; Description                         │ │                           │ │
   * │ │                                                                         │ │                           │ │
   * │ │                                                                         │ │                           │ │
   * │ └─────────────────────────────────────────────────────────────────────────┘ └───────────────────────────┘ │
   * │ ┌────────────────────────────────┐ ┌────────────────────────────────────────────────────────────────────┐ │
   * │ │                                │ │                                                                    │ │
   * │ │                                │ │                                                                    │ │
   * │ │       1/3V; 1/3H; Logo         │ │                          1/3V; 2/3H; Barcode                       │ │
   * │ │                                │ │                                                                    │ │
   * │ │                                │ │                                                                    │ │
   * │ └────────────────────────────────┘ └────────────────────────────────────────────────────────────────────┘ │
   * └───────────────────────────────────────────────────────────────────────────────────────────────────────────┘
   */
  protected async drawData(label: Label, x: number, y: number): Promise<void> {
    const labelData: LabelData = label.data;
    let paddingBox: PaddingBoxInterface;

    const extraPaddingX = 2.5;

    const extraPaddingImage = 1;

    const extraPaddingY = 2;

    // Title
    paddingBox = this.addPadding(x, y, this.boxWidth * (this.configuration!.extra?.hideImage ? 1 : 2 / 3), this.boxHeight * (1 / 3));
    this.jspdf.drawText(
      labelData.title + ' / ' + labelData.unit,
      paddingBox.x + extraPaddingX,
      paddingBox.y + extraPaddingY,
      paddingBox.width,
      8,
      2,
      'bold',
    );

    // Description
    paddingBox = this.addPadding(
      x,
      y + this.boxHeight * (1 / 3) - 5,
      this.boxWidth * (this.configuration!.extra?.hideImage ? 2 / 3 : 1),
      this.boxHeight * (1 / 3),
    );
    this.jspdf.drawText(labelData.description, paddingBox.x + extraPaddingX, paddingBox.y, paddingBox.width, 8, 3);

    // Amount
    paddingBox = this.addPadding(
      x + this.boxWidth * (this.configuration?.extra?.hideLogo ? 0 : 1 / 3),
      y,
      this.boxWidth * (2 / 3),
      this.boxHeight,
    );
    this.jspdf.drawText(
      `Menge: ${labelData.customFields.amount}`,
      paddingBox.x + extraPaddingX,
      paddingBox.y + paddingBox.height,
      paddingBox.width,
      6,
      1,
      'normal',
      {
        baseline: 'bottom',
      },
    );

    // Code
    paddingBox = this.addPadding(x, y, this.boxWidth, this.boxHeight);
    this.jspdf.drawText(
      labelData.code,
      paddingBox.x + (this.configuration?.extra?.hideLogo ? (paddingBox.width * 2) / 3 : paddingBox.width),
      paddingBox.y + paddingBox.height,
      paddingBox.width,
      6,
      1,
      'normal',
      {
        align: 'right',
        baseline: 'bottom',
      },
    );

    // BarCode (EAN13)
    paddingBox = this.addPadding(
      x + this.boxWidth * (this.configuration!.extra?.hideLogo ? 0 : 1 / 3),
      y + this.boxHeight * (2 / 3) - 0.5,
      this.boxWidth * (2 / 3),
      this.boxHeight * (1 / 3) - this.jspdf.getTextDimensions(labelData.code).h,
    );
    await this.addCode128(labelData.code, paddingBox.x + extraPaddingX, paddingBox.y, paddingBox.width, paddingBox.height);

    // Image
    if (this.configuration!.extra?.hideImage) {
      paddingBox = this.addPadding(x + this.boxWidth * (2 / 3) - extraPaddingImage, y, this.boxWidth * (1 / 3), this.boxHeight * (2 / 3));
      await this.addImage(labelData.imgPath!, paddingBox.x, paddingBox.y + extraPaddingY, paddingBox.width, paddingBox.height);
    }

    if (this.configuration!.extra?.hideLogo) {
      // Logo
      paddingBox = this.addPadding(
        x + this.boxWidth * (2 / 3),
        y + this.boxHeight * (2 / 3) - 3,
        this.boxWidth * (1 / 3),
        this.boxHeight * (1 / 3),
      );
      await this.addImage(labelData.logoPath!, paddingBox.x, paddingBox.y, paddingBox.width, paddingBox.height);
    }
  }

  protected async addCode128(code: string, x: number, y: number, maxWidth: number, maxHeight: number): Promise<void> {
    if (!code) {
      return;
    }

    const canvas: HTMLCanvasElement = this.document.createElement('canvas');
    jsbarcode(canvas, code, { format: 'CODE128', width: 7.5, displayValue: false, flat: true, margin: 0 });

    await this.jspdf.drawImage(canvas.toDataURL(), x, y, maxWidth, maxHeight, undefined, 'right');
  }
}
