import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-property-list',
  templateUrl: './property-list.component.html',
  styleUrls: ['./property-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyListComponent implements OnInit {
  @Input() size: 's' | 'm' = 'm';
  @Input() properties: { key: string; value: string }[] | undefined;

  ngOnInit(): void {
    this.sortPropertiesByName();
  }

  private sortPropertiesByName(): void {
    if (this.properties) {
      this.properties.sort((a, b) => a.key.localeCompare(b.key));
    }
  }
}
