import { Pipe, PipeTransform } from '@angular/core';
import { GelaArticle } from '../../services/catalog/model/gela-article';

@Pipe({
  name: 'orderByMs3',
})
export class OrderByMs3Pipe implements PipeTransform {
  public transform(articles: GelaArticle[], productId: string | number): GelaArticle[] {
    const sortedArticles = [...articles];
    if (articles.find((article) => article.ms3Order.length > 1)) {
      sortedArticles.sort((a, b) => {
        const aOrderNr = a.ms3Order.find((orderNr) => orderNr.productID === productId);
        const bOrderNr = b.ms3Order.find((orderNr) => orderNr.productID === productId);
        if (aOrderNr && bOrderNr) {
          return aOrderNr.orderNr - bOrderNr.orderNr;
        }
        return 0;
      });
    } else {
      sortedArticles.sort((a, b) => {
        return a.ms3Order[0].orderNr - b.ms3Order[0].orderNr;
      });
    }
    return sortedArticles;
  }
}
