import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '@angular/common';

@Pipe({
  name: 'urlLocation',
})
export class UrlLocationPipe implements PipeTransform {
  constructor(private location: Location) {}

  public transform(locationUrl: any): string {
    return this.location.path(locationUrl);
  }
}
