import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { MenuItem } from './interfaces/cms-menu.interface';
import { tap } from 'rxjs/operators';
import { CmsPage } from './interfaces/cms-page.interface';
import { CmsStore } from './cms.store';
import { StaticContent } from './interfaces/cms-static.interface';
import { CmsExpert } from './interfaces/cms-expert.interface';

@Injectable({
  providedIn: 'root',
})
export class CmsService<T extends CmsPage = CmsPage> {
  constructor(private http: HttpClient, private translocoService: TranslocoService, private store: CmsStore<T>) {}

  getPageBySlug(pageSlug: string): Observable<T> {
    return this.request<T>(pageSlug).pipe(tap((cmsPage: T) => this.store.upsert(cmsPage.uuid, cmsPage)));
  }

  getHomePage() {
    return this.http.get<T>(`cms/${this.translocoService.getActiveLang()}`);
  }

  getMainMenu(): Observable<MenuItem[]> {
    return this.request<MenuItem[]>('main-menu').pipe(tap((menu) => this.store.update({ mainMenu: menu })));
  }

  getFooterMenu(): Observable<MenuItem[]> {
    return this.request<MenuItem[]>('footer-menu').pipe(tap((menu) => this.store.update({ footerMenu: menu })));
  }

  getStaticContent() {
    return this.request<StaticContent>('static-content').pipe(
      tap((staticContent) =>
        this.store.update({ footer: staticContent.footer, topNav: staticContent.service_nav, footerNav: staticContent.footer_nav }),
      ),
    );
  }

  getExpert(link: string): Observable<CmsExpert> {
    return this.request(link);
  }

  getMorePosts(pageIndex: number): Observable<CmsPage> {
    return this.request(`beratung-service/blog/page:${pageIndex}`);
  }

  request<T>(url: string): Observable<T> {
    return this.http.get<T>(`cms/${this.translocoService.getActiveLang()}/${url}`);
  }

  queryRequest<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(url, body, { headers: { 'X-Language': this.translocoService.getActiveLang() } });
  }
}
