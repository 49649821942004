<button
  (click)="closed.emit(true)"
  class="js-tag flex items-center bg-white pl-2.5 py-1 rounded-default border border-gela-black relative leading-tight transition-all duration-200 group"
  [class.pr-8]="closable"
  [class.pr-2.5]="!closable"
>
  <p class="font-montserrat font-bold text-small">
    {{ title }}<span class="ml-4">{{ value }}</span>
  </p>
  <span *ngIf="closable" class="ifont ifont--close-small text-3xl absolute right-0 cursor-pointer group-hover:text-secondary"></span>
</button>
