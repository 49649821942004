import { Pipe, PipeTransform } from '@angular/core';
import { Label } from '@lobos/label-print-v2';

@Pipe({
  name: 'isALabelSelected',
})
export class IsALabelSelectedPipe implements PipeTransform {
  transform(labels: Label[]): boolean {
    return !!labels.find((label) => label.print);
  }
}
