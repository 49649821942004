<ng-container *transloco="let t; scope: 'shared'">
  <ng-container *ngIf="$stockAvailability | async as stockAvailability">
    <div
      [ngClass]="placement === 'default' ? 'top-2.5 left-3' : ''"
      class="absolute inline-block z-0"
      *ngIf="
        article && (article.sPL1ShopInfo || article.sPL1Promotion || [2, 3, 5].includes(article.shtStatus) || article.lngReturnsNoteID)
      "
    >
      <app-flyout
        [disableOnTouch]="true"
        [closable]="false"
        [closeDelay]="0"
        [title]="t('shared.article-info.info')"
        [disablePopper]="
          !article.sPL1ShopInfo && ![2, 3, 5].includes(article.shtStatus) && !article.sPL1Promotion && !article.lngReturnsNoteID
        "
      >
        <button
          *ngIf="
            article.sPL1Promotion ||
            ([3, 5].includes(article.shtStatus) && stockAvailability.shtAvailable > 0) ||
            article.sPL1ShopInfo ||
            article.shtStatus === 2 ||
            article.lngReturnsNoteID
          "
          trigger
          [ngClass]="article.sPL1Promotion?.length ? 'px-2.5 py-5px pr-8' : 'w-26px h-26px'"
          class="js-show-flyout js-index bg-gela-black leading-tight flex items-center text-white relative rounded-default mt-1"
        >
          <ng-container *ngIf="article.sPL1Promotion?.length">
            <span class="uppercase text-small font-montserrat font-bold">{{ article.sPL1Promotion }}</span>
            <span class="ifont ifont--info text-3xl absolute right-1 top-1/2 -translate-y-1/2"></span>
          </ng-container>

          <ng-container *ngIf="!article.sPL1Promotion?.length">
            <span class="ifont ifont--info text-3xl absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"></span>
          </ng-container>
        </button>
        <ng-container flyout>
          <p *ngIf="shopInfoText" [innerHTML]="shopInfoText"></p>
          <p *ngIf="article.shtStatus === 2">{{ t("shared.article-info.blocked") }}</p>
          <p *ngIf="[3, 5].includes(article.shtStatus)">
            <ng-container *ngIf="authUser$ | async as authUser; else showIcons">
              <ng-container *ngIf="[1, 2].includes(authUser.shtStockLevel); then showIcons; else showNumbers"></ng-container>
            </ng-container>
            <ng-template #showIcons>
              <span *ngIf="stockAvailability.shtAvailable > 0">
                {{ t("shared.article-info.while-stock-nonumbers") }}
              </span>
            </ng-template>
            <ng-template #showNumbers>
              <span *ngIf="stockAvailability.shtAvailable > 0">
                {{ t("shared.article-info.while-stock", { stockAmount: stockAvailability.decAvailable }) }}
              </span>
            </ng-template>
          </p>
          <p *ngIf="article.lngReturnsNoteID">{{ t("shared.returns-note." + article.lngReturnsNoteID) }}</p>
        </ng-container>
      </app-flyout>
    </div>
  </ng-container>
</ng-container>
