import { Pipe, PipeTransform } from '@angular/core';
import { GelaCartItem } from '../../services/cart/model/gela-cart-item.model';

@Pipe({
  name: 'hasArticleWithoutCommission',
})
export class HasArticleWithoutCommissionPipe implements PipeTransform {
  transform(items: GelaCartItem[] | null | undefined): boolean {
    return items ? items.some((item) => item.sArticleName && item.sPL1Commission === '') : false;
  }
}
