<!-- start info aussendienst -->
<ng-container *transloco="let t; scope: 'core'">
  <div class="bottom-0 fixed w-full flex flex-col items-center p-8 pb-24 mobile-nav:pb-8 z-40" *ngIf="customer$ | async as customer">
    <div
      class="justify-between w-full bg-secondary shadow-flyout pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden rounded-20px p-6 flex items-start relative leading-tight"
    >
      <p class="font-montserrat text-white text-left">
        {{
          t("core.sales-rep-banner.title", {
            company: customer.sCompany1,
            customerId: customer.lngCustomerID,
            place: customer.sCity
          })
        }}
      </p>
      <div class="cursor-pointer justify-end font-montserrat font-bold text-white pr-10" (click)="endSalesRepMode($event)">
        {{ t("core.sales-rep-banner.logout") }}
      </div>
    </div>
  </div>
</ng-container>
<!-- end info aussendienst -->
