import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ID } from '@datorama/akita';
import { CartHeader, User } from '@lobos/common';
import { AuthService, CartHeaderQuery, CartService, createCartHeader, CreateCartItemInterface } from '@lobos/library-v2';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { iif, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { GelaCartItem } from '../../services/cart/model/gela-cart-item.model';

@UntilDestroy()
@Component({
  selector: 'app-modal-cart',
  templateUrl: './modal-cart.component.html',
  styleUrls: ['./modal-cart.component.scss'],
})
export class ModalCartComponent extends SimpleModalComponent<{ id?: ID; addMode?: boolean }, boolean> implements OnInit {
  id: ID = '';
  addMode: boolean = false;

  public cart$?: Observable<CartHeader>;

  public form?: FormGroup<{ sCartName: FormControl<string>; sMemo: FormControl<string> }>;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private cartHeaderQuery: CartHeaderQuery,
    private cartService: CartService<CartHeader, GelaCartItem, CreateCartItemInterface>,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.cart$ = of(undefined).pipe(
      switchMap(() =>
        iif(
          () => this.addMode,
          of(createCartHeader({ sCartName: '' })),
          iif(
            () => this.cartHeaderQuery.hasEntity((cart: CartHeader) => cart.lngOrderID.toString() === this.id.toString()),
            this.cartHeaderQuery.selectEntity((cart: CartHeader) => cart.lngOrderID.toString() === this.id.toString()),
            this.cartService
              .getCartById(this.id)
              .pipe(
                switchMap(() => this.cartHeaderQuery.selectEntity((cart: CartHeader) => cart.lngOrderID.toString() === this.id.toString())),
              ),
          ),
        ),
      ),
      filter((cart: CartHeader | undefined) => !!cart),
      map((c: CartHeader | undefined) => c!),
      tap((cart: CartHeader) => this.initializeForm(cart)),
      shareReplay(1),
    );
  }

  public save(cart: CartHeader): void {
    this.form?.markAllAsTouched();
    if (!this.form?.valid) {
      return;
    }

    if (this.addMode) {
      this.auth.authUser$
        .pipe(
          filter((user: User | undefined) => !!user),
          distinctUntilChanged((a?: User, b?: User) => a?.sUserName === b?.sUserName),
          switchMap((user: User | undefined) =>
            this.cartService.createCartHeader({
              ...createCartHeader({}),
              ...this.form?.value,
              lngContactID: user!.lngContactID,
              lngCustomerID: user!.lngCustomerID,
            }),
          ),
          tap((cartHeader: CartHeader) => this.cartService.changeActiveCart(cartHeader.lngOrderID).pipe(take(1)).subscribe()),
          untilDestroyed(this),
        )
        .subscribe(() => this.close(true));

      return;
    }

    this.cartService
      .updateCartHeader({ ...cart, ...this.form!.value })
      .pipe(untilDestroyed(this))
      .subscribe(() => this.close(true));
  }

  public override close(result: boolean = false): Promise<void> {
    this.result = result;
    return super.close();
  }

  private initializeForm(cart: CartHeader): void {
    this.form = this.fb.group({
      sCartName: new FormControl<string>(cart.sCartName ?? '', { validators: [Validators.required], nonNullable: true }),
      sMemo: new FormControl<string>(cart.sMemo ?? '', { nonNullable: true }),
    });
  }
}
