import { Inject, Injectable } from '@angular/core';
import { CATALOG_CONFIG, CatalogConfig, ProductService, ProductStore } from '@lobos/library-v2';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GelaProduct } from './model/gela-product';

@Injectable({ providedIn: 'root' })
export class GelaProductService extends ProductService<GelaProduct> {
  constructor(
    protected override store: ProductStore<GelaProduct>,
    @Inject(CATALOG_CONFIG) protected override catalogConfig: CatalogConfig,
  ) {
    super(store, catalogConfig);
  }

  public getByNavName(navName: string): Observable<GelaProduct> {
    return this.getHttp()
      .get<GelaProduct>(`${this.catalogConfig.apiUrl}/products/nav-name/${navName}`)
      .pipe(tap((response: GelaProduct) => this.store.add(response)));
  }
}
