<!-- start login modal -->
<ng-container *transloco="let t; scope: 'shared'">
  <div class="js-modal-signin js-modal fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gela-black opacity-64 transition-opacity" aria-hidden="true"></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&ZeroWidthSpace;</span>
      <div
        class="relative inline-block align-bottom text-left overflow-hidden shadow-flyout transform transition-all sm:my-8 sm:align-middle sm:max-w-modal sm:w-full"
      >
        <div class="w-full bg-white p-6 border border-primary-light rounded-20px">
          <form class="flex flex-col space-y-6" (submit)="login()">
            <p class="font-montserrat font-bold text-lg tablet:text-2xl pr-6">
              {{ forgotPassword ? t("shared.login-modal.forgot-password") : t("shared.login-modal.title") }}
            </p>
            <app-input
              name="username"
              [label]="t('shared.login-modal.username')"
              [type]="'text'"
              [form]="form"
              [hasError]="!!form.get('username')?.touched && !!form.get('username')?.errors"
              [errorText]="
                form
                  | getError
                    : 'username'
                    : {
                        required: t('shared.login-modal.error.username-required'),
                        email: t('shared.login-modal.error.email-validation'),
                        server: t('shared.login-modal.error.server'),
                        inactive: t('shared.login-modal.error.inactive')
                      }
              "
            ></app-input>
            <app-input
              *ngIf="!forgotPassword"
              name="password"
              [label]="t('shared.login-modal.password')"
              [type]="'password'"
              [form]="form"
              [hasError]="!!form.get('password')?.touched && !!form.get('password')?.errors"
              [errorText]="
                form
                  | getError
                    : 'password'
                    : {
                        required: t('shared.login-modal.error.password-required'),
                        server: t('shared.login-modal.error.server'),
                        inactive: t('shared.login-modal.error.inactive')
                      }
              "
            ></app-input>
            <div class="w-full flex space-x-5 self-start pt-6">
              <ng-container *ngIf="!forgotPassword; else forgotPasswordIsActive">
                <div class="basis-1/2">
                  <app-gela-button
                    [look]="'primary'"
                    [text]="t('shared.login-modal.button-title')"
                    [size]="'large'"
                    [type]="'button'"
                    [appLoading]="isLoading$ | async"
                    [loading]="isLoading$ | async"
                    [fullWidth]="true"
                  ></app-gela-button>
                </div>
                <div class="basis-1/2">
                  <a
                    type="button"
                    target="_blank"
                    class="block w-full font-montserrat font-bold px-6 py-4 rounded-button transition-all duration-200 self-start bg-primary-light text-center text-gela-black hover:text-white hover:bg-secondary"
                    [href]="t('shared.login-modal.register-link')"
                  >
                    {{ t("shared.login-modal.signup") }}
                  </a>
                </div>
              </ng-container>
              <ng-template #forgotPasswordIsActive>
                <div class="basis-1/2">
                  <app-gela-button
                    (click)="requestPassword()"
                    [look]="'primary'"
                    [text]="t('shared.login-modal.button-request-password')"
                    [size]="'large'"
                    [type]="'button'"
                    [appLoading]="isLoadingRequestPassword$ | async"
                    [loading]="isLoadingRequestPassword$ | async"
                    [fullWidth]="true"
                  ></app-gela-button>
                </div>
              </ng-template>
            </div>
            <div>
              <div class="-mt-3.5">
                <a
                  (click)="toggleForgotPassword()"
                  [title]="t('shared.login-modal.forgot-password')"
                  class="self-start text-small font-montserrat font-bold relative pb-1.5 group ml-auto cursor-pointer"
                >
                  {{ forgotPassword ? t("shared.login-modal.back-to-login") : t("shared.login-modal.forgot-password") }}
                  <span
                    class="h-3px w-full bg-primary inline-block absolute bottom-0 left-0 transition-all duration-200 group-hover:bg-gela-black"
                  ></span>
                </a>
              </div>
            </div>
          </form>
        </div>
        <div class="block absolute top-5 right-4 z-10">
          <button
            (click)="close()"
            class="js-close-modal p-1 leading-none rounded-default group text-primary hover:bg-primary-bright transition-all duration-200"
          >
            <span class="ifont ifont--close text-icon-sml group-hover:text-primary-dark transition-all duration-200"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- end login modal -->
