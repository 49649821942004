<ng-container *ngIf="trigger">
  <div
    [popperDisabled]="disablePopper"
    [popper]="flyout"
    [popperTimeout]="closeDelay"
    [popperAppendTo]="'body'"
    [popperPlacement]="position"
    [popperTrigger]="trigger"
  >
    <ng-content select="[trigger]"></ng-content>
  </div>

  <popper-content #flyout [class.compact]="compact">
    <button *ngIf="closable" (click)="close()" class="js-close-flyout absolute right-3 top-3">
      <span class="ifont ifont--close text-3xl text-primary hover:text-gela-black pointer-events-none"></span>
    </button>

    <p *ngIf="title" class="font-montserrat font-bold text-small mb-3.5 pr-5">{{ title }}</p>

    <ng-content select="[flyout]"></ng-content>
  </popper-content>
</ng-container>
