import { Injectable } from '@angular/core';
import { ErrorHook, ErrorInterface, ExceptionEvent, HookEvent, HookHandlerAbstract, TrackingFactory } from '@lobos/library-v2';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

type type = ErrorHook;

@Injectable({
  providedIn: 'root',
})
export class ErrorSendToTrackingHandler extends HookHandlerAbstract<type, Observable<ErrorInterface>> {
  constructor(private tracking: TrackingFactory) {
    super();
  }

  ids(): type[] {
    return [{ type: 'ErrorHook' }];
  }

  override after(event: HookEvent<type, Observable<ErrorInterface>>, result: Observable<ErrorInterface>): Observable<ErrorInterface> {
    return super.after(event, result).pipe(
      tap((error: ErrorInterface) =>
        error.ignore
          ? undefined
          : this.tracking.trackEvent<ExceptionEvent>({
              name: 'exception',
              payload: {
                description: error.error?.message || error.label,
                fatal: error.error?.fatal || false,
              },
            }),
      ),
    );
  }
}
