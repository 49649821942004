<ng-container *transloco="let t; scope: 'catalog'">
  <div class="js-modal fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gela-black opacity-64 transition-opacity" aria-hidden="true"></div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&ZeroWidthSpace;</span>
      <div
        class="relative inline-block align-bottom text-left overflow-hidden shadow-flyout transform transition-all sm:my-8 sm:align-middle sm:max-w-modal sm:w-full"
      >
        <div class="block absolute top-5 right-4 z-10">
          <button
            (click)="close()"
            class="js-close-modal p-1 leading-none rounded-default group text-primary hover:bg-primary-bright transition-all duration-200"
          >
            <span class="ifont ifont--close text-icon-sml group-hover:text-primary-dark transition-all duration-200"></span>
          </button>
        </div>
        <div class="min-h-304px w-full bg-white p-6 border border-primary-light rounded-20px">
          <form (submit)="submitDatasheet()" *ngIf="form" [formGroup]="form" class="flex flex-col space-y-6">
            <p class="font-montserrat font-bold text-lg tablet:text-2xl pr-6">{{ t("catalog.download-modal.title") }}</p>
            <ng-container *ngIf="isLoggedIn$ | async">
              <app-button-dropdown
                name="priceSelection"
                [showValue]="true"
                [options]="priceSelection"
                (valueChange)="changedPriceSelection($any($event))"
                [value]="this.form.controls.priceOption.value"
              ></app-button-dropdown>
            </ng-container>
            <ng-container *ngIf="isLoggedIn$ | async; else notLoggedIn">
              <app-gela-button
                [look]="'primary'"
                [text]="t('catalog.download-modal.send')"
                [size]="'large'"
                [type]="'button'"
                [appLoading]="isLoading"
                [loading]="isLoading"
                [fullWidth]="true"
                classes="w-auto self-start bg-secondary text-white hover:bg-gela-black mt-10"
              ></app-gela-button>
            </ng-container>
            <ng-template #notLoggedIn>
              <app-gela-button
                [look]="'primary'"
                [text]="t('catalog.download-modal.download')"
                [size]="'large'"
                [type]="'button'"
                [appLoading]="isLoading"
                [loading]="isLoading"
                [fullWidth]="true"
                classes="w-auto self-start bg-secondary text-white hover:bg-gela-black mt-10"
              ></app-gela-button>
            </ng-template>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-container>
