import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { CustomerArticleInterface } from './customer-article.interface';
import { CustomerArticleStore } from './customer-article.store';

@Injectable({ providedIn: 'root' })
export class CustomerArticleService {
  constructor(private http: HttpClient, private store: CustomerArticleStore) {}

  public searchByStartOfId(start: string): Observable<CustomerArticleInterface[]> {
    return this.http.get<CustomerArticleInterface[]>(`api/customer-article/search/${start}`);
  }

  public getById(id: string): Observable<CustomerArticleInterface> {
    return of(undefined).pipe(
      tap(() => this.store.setLoading(true)),
      switchMap(() => this.http.get<CustomerArticleInterface>(`api/customer-article/${id}`)),
      tap((customerArticle: CustomerArticleInterface) => this.store.upsert(id, customerArticle)),
      finalize(() => this.store.setLoading(false)),
    );
  }
}
