<ng-container *transloco="let t; scope: 'shared'">
  <ng-container *ngIf="comNormService.bComNormMode$ | async; else notComNormMode">
    <div class="flex border-2 border-primary-light rounded-default items-center w-201px">
      <div class="grow -ml-px">
        <a
          href="{{ comNormService.sComNormBaseUrl }}?Method=GetProductInfo&ProductNumber={{ article.sArticleID }}&SessionKey={{
            comNormService.sessionKey$ | async
          }}
"
          class="flex justify-center w-full basis-12 items-center leading-none transition-all duration-200 text-secondary px-2 py-2 hover:bg-secondary hover:text-white relative z-0 font-montserrat font-bold disabled:hover:bg-primary"
          [ngClass]="{ 'sm:px-7': size === 'l' }"
        >
          <span class="ifont comnorm-icon text-3xl"></span>
        </a>
      </div>
    </div>
  </ng-container>
  <ng-template #notComNormMode>
    <div
      *ngIf="article"
      class="flex border-2 border-primary-light rounded-default items-center w-201px"
      [ngClass]="{
        'sm:w-368px': size === 'l' && !article.sPl1LoongGoodArticle?.length,
        'sm:w-480px': size === 'l' && article.sPl1LoongGoodArticle?.length
      }"
    >
      <div
        class="relative"
        [ngClass]="{
          'w-[length:55%]': size === 'm' && !article.sPl1LoongGoodArticle?.length,
          'w-[length:33%]': size === 'm' && article.sPl1LoongGoodArticle?.length,
          'w-[55%] sm:w-[28.8%]': size === 'l' && !article.sPl1LoongGoodArticle?.length,
          'w-[length:33%] sm:w-[22%]': size === 'l' && article.sPl1LoongGoodArticle?.length
        }"
      >
        <span
          *ngIf="!article.sPl1LoongGoodArticle; else isLoongGood"
          class="absolute -top-[3px] -translate-y-1/2 left-2.5 px-3px bg-white text-primary-dark text-small leading-tight"
          >{{ t("shared.units." + article.sQuantityUnitSales) }}</span
        >
        <ng-template #isLoongGood>
          <span class="absolute -top-[3px] -translate-y-1/2 left-2.5 px-3px bg-white text-primary-dark text-small leading-tight">{{
            t("shared.units.ST")
          }}</span>
        </ng-template>
        <input
          [formControl]="quantityInput"
          (focusout)="validQuantity()"
          pattern="\d*"
          class="w-[calc(100%-20px)] border-0 focus:ring-0 text-center"
          min="0"
          type="number"
        />
        <div class="absolute flex flex-col justify-between h-full py-[2px] top-0 right-[5px]">
          <button
            (click)="increaseQuantity($event)"
            class="relative w-4 h-4 bg-primary-light text-primary-dark rounded-default hover:bg-primary-dark hover:text-primary-light transition-all duration-200"
          >
            <span class="absolute ifont ifont--plus text-lg left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 pointer-events-none"></span>
          </button>
          <button
            (click)="decreaseQuantity($event)"
            class="relative w-4 h-4 bg-primary-light text-primary-dark rounded-default hover:bg-primary-dark hover:text-primary-light transition-all duration-200"
          >
            <span class="absolute ifont ifont--minus text-lg left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 pointer-events-none"></span>
          </button>
        </div>
        <span class="absolute w-px bg-primary-light top-[2px] bottom-[2px] right-0 z-0"></span>
      </div>
      <div
        *ngIf="article.sPl1LoongGoodArticle?.length"
        class="relative"
        [ngClass]="{ 'w-[length:33%]': size === 'm', 'w-[length:33%] sm:w-[22%]': size === 'l' }"
      >
        <span class="absolute -top-[3px] -translate-y-1/2 left-2.5 px-3px bg-white text-primary-dark text-small leading-tight">{{
          article.sPl1LongGoodUnit?.toLocaleLowerCase()
        }}</span>
        <input
          [formControl]="lengthInput"
          (focusout)="validLength()"
          pattern="\d*"
          class="w-[calc(100%-20px)] border-0 focus:ring-0"
          type="number"
          min="0"
        />
        <div class="absolute flex flex-col justify-between h-full py-[2px] top-0 right-[5px]">
          <button
            (click)="increaseLength($event)"
            class="relative w-4 h-4 bg-primary-light text-primary-dark rounded-default hover:bg-primary-dark hover:text-primary-light transition-all duration-200"
          >
            <span class="absolute ifont ifont--plus text-lg left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 pointer-events-none"></span>
          </button>
          <button
            (click)="decreaseLength($event)"
            class="relative w-4 h-4 bg-primary-light text-primary-dark rounded-default hover:bg-primary-dark hover:text-primary-light transition-all duration-200"
          >
            <span class="absolute ifont ifont--minus text-lg left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 pointer-events-none"></span>
          </button>
        </div>
        <span class="absolute w-px bg-primary-light top-[2px] bottom-[2px] right-0 z-0"></span>
      </div>
      <div class="grow -ml-px">
        <button
          [appLoading]="isLoading$ | async"
          (click)="addToCart(article, $event)"
          class="flex justify-center w-full basis-12 items-center leading-none transition-all duration-200 text-secondary px-2 py-2 hover:bg-secondary hover:text-white relative z-0 font-montserrat font-bold disabled:hover:bg-primary"
          [ngClass]="{ 'sm:px-7': size === 'l' }"
          [disabled]="[2, 4].includes(article.shtStatus)"
        >
          <span *ngIf="size === 'l'" class="hidden sm:inline-block mr-6">{{ t("shared.add-to-cart") }}</span>
          <span *ngIf="isLoading$ | async" class="loading sm:-mr-1"></span>
          <span
            *ngIf="(isLoading$ | async) === false"
            class="ifont ifont--cart text-3xl"
            [ngClass]="{ 'sm:ml-auto sm:-mr-1': size === 'l' }"
          ></span>
        </button>
      </div>
    </div>
  </ng-template>
</ng-container>
