<dl
  [ngClass]="{ 'w-269px grid hyphens-auto grid-cols-2': size === 's', 'w-500px flex flex-wrap self-start': size === 'm' }"
  class="text-small"
>
  <ng-container *ngFor="let property of properties">
    <dt class="font-montserrat font-bold basis-1/2 border-b border-primary-light py-1.5">
      {{ property.key }}
    </dt>
    <dd class="text-right basis-1/2 border-b border-primary-light py-1.5" [innerHTML]="property.value"></dd>
  </ng-container>
</dl>
