import { Pipe, PipeTransform } from '@angular/core';
import { Team } from '../../features/cms/cms-components/team/team.component';

@Pipe({
  name: 'filterTeam',
})
export class FilterTeamPipe implements PipeTransform {
  public transform(team: Team[], selectedRole: string | number): Team[] {
    return selectedRole ? team.filter((teamMember: Team) => teamMember.role.includes(selectedRole as string)) : team;
  }
}
