<div class="fixed z-999 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 bg-gela-black opacity-64 transition-opacity" aria-hidden="true"></div>
    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div
      class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6"
    >
      <div class="w-full flex items-end flex-col">
        <button
          (click)="stop()"
          type="button"
          class="js-close-filter inline-flex items-center justify-center hover:text-primary hover:bg-gray-100 focus:text-primary"
        >
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>
      <div class="">
        <div class="scanner" [class.ready]="scannerReady$ | async" [class.scan-complete]="articles">
          <div class="mb-3 mt-1" *ngIf="cameras$ | async as cameras">
            <app-select
              *transloco="let t; scope: 'scanner'"
              [options]="cameras"
              [value]="cameras[0].key"
              (valueChange)="selectCamera($event)"
            ></app-select>
          </div>

          <div class="scanner-preview">
            <div #preview class="scanner-container"></div>
            <div *ngIf="(scannerReady$ | async) === true"></div>
          </div>

          <div class="scanner-result items-center space-y-4" *ngIf="articles">
            <ng-container *transloco="let t; scope: 'scanner'">
              <div class="flex items-center justify-center">
                <app-scanner-results [quantity]="quantity" [articles]="articles"></app-scanner-results>
              </div>

              <div class="flex flex-col space-y-4">
                <app-button-border [text]="t('scanner.ui-scanner.button.rescan')" (click)="rescan()"></app-button-border>
                <app-button-border [text]="t('scanner.ui-scanner.button.stop')" (click)="stop()"></app-button-border>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
