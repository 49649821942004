<ng-container *ngIf="href">
  <a
    [routerLink]="href"
    [fragment]="fragment"
    class="self-start relative pb-1.5 group"
    [ngClass]="{ 'text-left break-words': regular, 'font-montserrat font-bold text-small': !regular }"
    [class]="classes"
  >
    {{ text }}
    <span
      class="h-3px w-full bg-primary inline-block absolute bottom-0 left-0 transition-all duration-200 group-hover:bg-gela-black"
    ></span>
  </a>
</ng-container>
<ng-container *ngIf="samePageFragment">
  <a
    [href]="samePageLocation + fragment"
    class="self-start relative pb-1.5 group"
    [ngClass]="{ 'text-left break-words': regular, 'font-montserrat font-bold text-small': !regular }"
    [class]="classes"
  >
    {{ text }}
    <span
      class="h-3px w-full bg-primary inline-block absolute bottom-0 left-0 transition-all duration-200 group-hover:bg-gela-black"
    ></span>
  </a>
</ng-container>
<ng-container *ngIf="!href && !samePageFragment">
  <button
    class="self-start relative pb-1.5 group"
    [ngClass]="{ 'text-left break-words': regular, 'font-montserrat font-bold text-small': !regular }"
    [class]="classes"
  >
    {{ text }}
    <span
      class="h-3px w-full bg-primary inline-block absolute bottom-0 left-0 transition-all duration-200 group-hover:bg-gela-black"
    ></span>
  </button>
</ng-container>
