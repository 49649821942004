import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isPlatformBrowser } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'app-in-viewport',
  template: `
    <div #sentinel class="sentinel"></div>
    <ng-container #container></ng-container>
  `,
  styles: [
    `
      .sentinel {
        width: 1px;
        height: 1px;
        opacity: 0;
        position: absolute;
      }
    `,
  ],
})
export class InViewportComponent implements OnInit, OnDestroy {
  private intersectionObserver: IntersectionObserver | undefined;

  @ViewChild('container', {
    read: ViewContainerRef,
    static: true,
  })
  container: ViewContainerRef | undefined;
  @ViewChild('sentinel', { static: true }) sentinel: ElementRef | undefined;
  @ContentChild(TemplateRef, { static: false }) template: TemplateRef<any> | undefined;

  constructor(private ref: ChangeDetectorRef, @Inject(PLATFORM_ID) private platformId: string) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.intersectionObserver = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          this.container!.createEmbeddedView(this.template!);
          this.ref.markForCheck();
          this.intersectionObserver!.disconnect();
        }
      });

      this.intersectionObserver.observe(this.sentinel!.nativeElement);
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this.intersectionObserver!.disconnect();
    }
  }
}
