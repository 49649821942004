<!-- navbar mobile start -->
<div
  class="js-mobile-bar flex mobile-nav:hidden fixed bottom-0 min-h-14 bg-primary-light w-full z-50 pb-safe shadow-flyout"
  *ngIf="{ isLoggedIn: isLoggedIn$ | async, activeFavoriteItems: activeFavoriteItems$ | async } as context"
>
  <div [class.bg-white]="activeNav === mobileNavId.FAVORITE" class="w-nav-bar-phone flex items-center">
    <button
      (click)="toggleNav(mobileNavId.FAVORITE)"
      class="js-open-mobile--favourites flex flex-col mx-auto items-center p-1 leading-none transition-all duration-200"
      data-id="js-mobile-favourites"
    >
      <div class="relative pointer-events-none">
        <span
          [class.opacity-0]="activeNav !== mobileNavId.FAVORITE"
          class="js-show ifont ifont--close text-3xl text-primary-dark transition-all duration-200 absolute opacity-0 pointer-events-none"
        ></span>
        <span
          [class.opacity-0]="activeNav === mobileNavId.FAVORITE"
          class="js-hide ifont ifont--like text-3xl text-primary-dark transition-all duration-200 pointer-events-none"
        ></span>
        <span
          *ngIf="context.activeFavoriteItems?.length"
          [class.hidden]="!context.isLoggedIn"
          [class.opacity-0]="activeNav === mobileNavId.FAVORITE"
          class="js-hide bg-gela-black border-2 border-primary-light text-white text-small font-montserrat font-bold rounded-full w-4 h-4 flex items-center justify-center absolute top-1 -right-2 pointer-events-none"
          >&nbsp;&nbsp;</span
        >
      </div>
    </button>
  </div>
  <div [class.bg-white]="activeNav === mobileNavId.CART" class="w-nav-bar-phone flex items-center">
    <button
      (click)="toggleNav(mobileNavId.CART)"
      class="js-open-mobile--cart w-full flex flex-col mx-auto items-center p-1 leading-none transition-all duration-200 relative"
      data-id="js-mobile-cart"
    >
      <div class="relative pointer-events-none">
        <span
          [class.opacity-0]="activeNav !== mobileNavId.CART"
          class="js-show ifont ifont--close text-3xl text-primary-dark transition-all duration-200 absolute opacity-0 pointer-events-none"
        ></span>
        <span
          [class.opacity-0]="activeNav === mobileNavId.CART"
          class="js-hide ifont ifont--cart text-3xl text-primary-dark transition-all duration-200 pointer-events-none"
        ></span>
        <span
          *ngIf="activeCartItems$ | async as items"
          [class.opacity-0]="activeNav === mobileNavId.CART"
          class="js-hide bg-gela-black border-2 border-primary-light text-white text-cart font-montserrat font-bold rounded-full px-5px min-w-22px h-22px flex items-center justify-center absolute -top-px -right-3 pointer-events-none"
          >{{ (items | cartItemIsMaster).length }}</span
        >
      </div>
    </button>
  </div>
  <div class="w-nav-bar-phone">
    <button
      (click)="scannerHelper.openScanner()"
      class="box-content -mt-6 flex flex-col mx-auto items-center justify-center relative rounded-full bg-secondary text-white border-4 border-white w-12 h-12"
    >
      <span class="js-hide ifont ifont--scanner text-3xl pointer-events-none"></span>
    </button>
  </div>
  <div [class.bg-white]="activeNav === mobileNavId.PROFILE" class="w-nav-bar-phone flex items-center">
    <button
      *ngIf="context.isLoggedIn"
      (click)="toggleNav(mobileNavId.PROFILE)"
      class="js-open-mobile--account w-full flex flex-col mx-auto items-center p-1 leading-none transition-all duration-200 relative"
      data-id="js-mobile-account"
    >
      <div class="relative pointer-events-none">
        <span
          [class.opacity-0]="activeNav !== mobileNavId.PROFILE"
          class="js-show ifont ifont--close text-3xl text-primary-dark transition-all duration-200 absolute pointer-events-none"
        ></span>
        <span
          [class.opacity-0]="activeNav === mobileNavId.PROFILE"
          class="js-hide ifont ifont--person-default text-3xl text-primary-dark transition-all duration-200 pointer-events-none"
        ></span>
        <span
          [class.opacity-0]="activeNav === mobileNavId.PROFILE"
          class="js-hide bg-gela-black border-2 border-primary-light text-white text-small font-montserrat font-bold rounded-full w-22px h-22px flex items-center justify-center absolute bottom-0 -right-2.5 pointer-events-none"
        >
          <i class="ifont ifont--step-done text-xl absolute"></i>
        </span>
      </div>
    </button>
    <button
      *ngIf="!context.isLoggedIn"
      (click)="openLoginModal()"
      class="js-open-signin-modal w-full flex flex-col mx-auto items-center p-1 leading-none transition-all duration-200 relative"
    >
      <span
        class="js-show ifont ifont--close text-3xl text-primary-dark transition-all duration-200 absolute opacity-0 pointer-events-none"
      ></span>
      <span class="js-hide ifont ifont--person-default text-3xl text-primary-dark transition-all duration-200 pointer-events-none"></span>
    </button>
  </div>
  <div [class.bg-white]="activeNav === mobileNavId.MENU" class="w-nav-bar-phone flex items-center">
    <button
      (click)="toggleNav(mobileNavId.MENU)"
      class="js-open-mobile--nav w-full flex flex-col mx-auto items-center p-1 leading-none transition-all duration-200 relative"
      data-id="js-mobile-menu"
    >
      <span
        [class.opacity-0]="activeNav !== mobileNavId.MENU"
        class="js-show ifont ifont--close text-3xl text-primary-dark transition-all duration-200 absolute pointer-events-none"
      ></span>
      <span
        [class.opacity-0]="activeNav === mobileNavId.MENU"
        class="js-hide ifont ifont--burger text-3xl text-primary-dark transition-all duration-200 pointer-events-none"
      ></span>
    </button>
  </div>
</div>
<!-- navbar mobile end -->
