<ng-container *transloco="let t; scope: 'shared'">
  <p *ngIf="!showNumbers" class="text-small ml-1">
    {{ t("shared.article-availability.available-status-" + stock.shtStatus) }}
  </p>
  <p *ngIf="showNumbers" class="text-small ml-1">
    {{
      t("shared.article-availability.available-status-" + stock.shtStatus, {
        availableMsg: stock.decAvailable + " " + article?.sQuantityUnitSales
      })
    }}
  </p>
</ng-container>
