import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[appInnerHtmlRouterLink]',
})
export class InnerHtmlRouterLinkDirective {
  @Input()
  appStyle: boolean = true;

  constructor(private router: Router, private ref: ElementRef) {}

  @HostListener('click', ['$event'])
  onClick(e: any) {
    e.preventDefault();
    const href = e.target.getAttribute('href');
    href && this.router.navigate([href]);
  }
}
