import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent implements OnInit {
  @Input() public name: string = '';

  @Input() public rows: number = 4;

  @Input() public classes: string = '';

  @Input() public placeholder: string = '';

  @Input() public form: UntypedFormGroup | FormGroup | undefined;

  @Input() public required: boolean = false;

  @Input() public hasError: boolean = false;

  @Input() public errorText: string = '';

  @Input()
  public value: string = '';

  @Input()
  public valueChangeDebouncing: number = 500;

  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();

  public valueChangeDebouncer: Subject<string> = new Subject<string>();

  ngOnInit() {
    this.valueChangeDebouncer
      .pipe(debounceTime(this.valueChangeDebouncing), untilDestroyed(this))
      .subscribe((input: string) => this.valueChange.emit(input));
  }
}
