import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-link-icon',
  templateUrl: './link-icon.component.html',
  styleUrls: ['./link-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkIconComponent {
  @Input() isLoading: boolean = false;
  @Input() text: string | undefined;
  @Input() href: string | undefined;
  @Input() icon: string = 'ifont--download';
  @Input() disabled: boolean = false;
  @Output() linkClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  public click($event: MouseEvent): void {
    this.linkClick.emit($event);
  }
}
