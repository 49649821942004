import { Component } from '@angular/core';
import { ACCESS_ID, AuthService, Catch, UrlHelperService } from '@lobos/library-v2';
import { SimpleModalService } from 'ngx-simple-modal';
import { Observable } from 'rxjs';
import { LoginModalComponent } from '../../../login-modal/login-modal.component';
import { Router } from '@angular/router';
import { User } from '@lobos/common';

@Component({
  selector: 'app-user-flyout',
  templateUrl: './user-flyout.component.html',
  styleUrls: ['./user-flyout.component.scss'],
})
export class UserFlyoutComponent {
  public isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  public user$: Observable<User | undefined> = this.authService.authUser$;
  public showFlyout: boolean = false;
  public authUser$: Observable<User | undefined> = this.authService.authUser$;
  accessId: typeof ACCESS_ID = ACCESS_ID;

  constructor(
    private authService: AuthService,
    private modal: SimpleModalService,
    private router: Router,
    private urlHelper: UrlHelperService,
  ) {}

  open(isLoggedIn: boolean): void {
    if (isLoggedIn) {
      this.showFlyout = true;
    } else {
      this.modal.addModal(LoginModalComponent);
    }
  }

  closeFlyout(): void {
    this.showFlyout = false;
  }

  @Catch({ label: 'HeaderComponent.logout()' })
  public async logout(): Promise<void> {
    await this.authService.logout();
    await this.router.navigate([this.urlHelper.localizeUrl('')]);
  }

  protected readonly ACCESS_ID = ACCESS_ID;
}
