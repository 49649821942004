<a
  [title]="text"
  [href]="href"
  *ngIf="href"
  (click)="click($event)"
  class="js-link-detail self-end flex items-center relative pr-8 py-1 font-montserrat font-bold text-small -mr-1 transition-all duration-200 hover:text-primary"
  [ngClass]="{ 'opacity-50 pointer-events-none': disabled }"
  target="_blank"
>
  <span class="whitespace-nowrap">{{ text }}</span>
  <span *ngIf="!isLoading" [ngClass]="icon" class="ifont text-icon absolute -right-1 top-1/2 -translate-y-1/2"></span>

  <svg
    *ngIf="isLoading"
    class="animate-spin h-4 w-4 text-red-900 absolute -right-0.5"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#3C3C3B" stroke-width="4"></circle>
    <path
      class="opacity-75"
      fill="#3C3C3B"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
</a>

<button
  [title]="text"
  *ngIf="!href"
  (click)="click($event)"
  class="js-link-detail self-end flex items-center relative pr-8 py-1 font-montserrat font-bold text-small -mr-1 transition-all duration-200 hover:text-primary"
  [ngClass]="{ 'opacity-50 pointer-events-none': disabled }"
>
  <span class="whitespace-nowrap">{{ text }}</span>

  <span *ngIf="!isLoading" [ngClass]="icon" class="ifont text-icon absolute -right-1 top-1/2 -translate-y-1/2"></span>

  <svg
    *ngIf="isLoading"
    class="animate-spin h-4 w-4 text-red-900 absolute -right-0.5"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#3C3C3B" stroke-width="4"></circle>
    <path
      class="opacity-75"
      fill="#3C3C3B"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
</button>
