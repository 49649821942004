import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent implements OnChanges {
  @Input() public label: string = '';
  @Input() public name: string = '';
  @Input() public form: UntypedFormGroup | FormGroup | undefined;
  @Input() public options: {
    key: string | number;
    value: string | number;
  }[] = [];
  @Input() public translateValue: boolean = false;
  @Input() public value: string | number | null | undefined = '';
  @Input() public showValue = true;
  @Input() public grey: boolean = false;
  @Input() public prefix: string = '';
  @Input() public editMode: boolean = false;
  @Input() public newPlaceholder: string = '';
  @Input() public hasError: boolean = false;
  @Input() public errorText: string = '';
  @Input() public disabled: boolean = false;
  @Input() public emptyValue: string = '';
  @Input() public canBeEmpty: boolean = false;
  @Input() public hasMaxHeight: boolean = false;

  @Output() public valueChange: EventEmitter<string | number> = new EventEmitter<string | number>();
  @Output() public newSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() public editInputFocused: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isOpen = false;
  public newValue = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options'] && this.canBeEmpty) {
      this.transloco.selectTranslate('dropdown.no-selection', undefined, 'shared').subscribe((translation) =>
        this.options.unshift({
          key: '0',
          value: translation,
        }),
      );
    }
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: Event) {
    if (this.isOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.toggleIsOpen();
    }
  }

  constructor(private elementRef: ElementRef, private transloco: TranslocoService) {}

  public toggleIsOpen(): void {
    this.isOpen = !this.isOpen;
  }

  public addPrefix(value: string | number): string {
    return this.prefix + value;
  }

  public select(key: string | number): void {
    this.value = key;
    this.valueChange.emit(key);
    this.toggleIsOpen();
  }

  public submitNew(): void {
    if (!this.newValue) {
      return;
    }

    this.select(this.newValue);
    this.form?.controls[this.name].setValue(this.newValue);
    this.newSelected.emit(this.newValue);
    this.newValue = '';
  }
}
