import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, forkJoin, from, of } from 'rxjs';
import { AuthService, LocalstorageService } from '@lobos/library-v2';
import { environment } from '../../../environments/environment';
import { isPlatformServer } from '@angular/common';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ComnormService {
  public bComNormMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public sessionKey$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public sComNormBaseUrl: string = '';
  private bComNormModeKey = 'bComNormMode';
  private bComNormSessionKeyKey = 'bComNormSessionKey';

  constructor(
    private route: ActivatedRoute,
    protected authService: AuthService,
    protected storage: LocalstorageService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.initServiceState()
      .then(() => {
        console.log('ComnormService.initServiceState() success');
      })
      .catch((error) => {
        console.log('ComnormService.initServiceState() error', error);
      });
  }

  private async initServiceState() {
    let bComNormModeValue: boolean | null = false;
    let sessionKeyValue = null;
    this.sComNormBaseUrl = environment.sComNormBaseUrl;

    if (isPlatformServer(this.platformId)) {
      bComNormModeValue = await this.storage.get<boolean>(this.bComNormModeKey);
      sessionKeyValue = await this.storage.get<string>(this.bComNormSessionKeyKey);
    }

    const bComNormModeDefaultValue = bComNormModeValue ?? false;
    const sessionKeyDefaultValue = sessionKeyValue ?? null;

    this.bComNormMode$.next(bComNormModeDefaultValue);
    this.sessionKey$.next(sessionKeyDefaultValue);

    this.route.queryParamMap
      .pipe(
        switchMap((params: ParamMap) => {
          const sessionKey = params.get('SessionKey');
          const bComNormParam = params.get('bComNorm'); // replace with the actual parameter name

          // Set bComNormValue to true if bComNormParam is not defined or is 'true', else false
          const bComNormValue = bComNormParam !== 'false';

          if (sessionKey) {
            this.bComNormMode$.next(bComNormValue);
            this.sessionKey$.next(sessionKey);

            return from(this.authService.loginComNorm(sessionKey)).pipe(
              switchMap(() => {
                if (isPlatformServer(this.platformId)) {
                  return forkJoin([
                    this.storage.set(this.bComNormModeKey, bComNormValue),
                    this.storage.set(this.bComNormSessionKeyKey, sessionKey),
                  ]);
                }
                return of(null);
              }),
            );
          }
          return of(null);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
