import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-table-select-small',
  templateUrl: './table-select-small.component.html',
  styleUrls: ['./table-select-small.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSelectSmallComponent {
  @Input() public label: string = '';
  @Input() public name: string = '';
  @Input() public options: { key: string | number; value: string | number }[] = [];
  @Input() public translateValue: boolean = false;
  @Input() public values: (string | number)[] = [];
  @Input() public grey: boolean = false;
  @Input() public prefix: string = '';

  @Output() public valuesChange: EventEmitter<(string | number)[]> = new EventEmitter<(string | number)[]>();

  public isOpen = false;

  @HostListener('document:click', ['$event'])
  clickOut(event: Event) {
    if (this.isOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.toggleIsOpen();
    }
  }

  constructor(private elementRef: ElementRef) {}

  public toggleIsOpen(): void {
    this.isOpen = !this.isOpen;
  }

  public addPrefix(value: string | number): string {
    return this.prefix + value;
  }

  public toggle(key: string | number): void {
    if (this.values.includes(key)) {
      this.values = this.values.filter((value: number | string) => value !== key);
    } else {
      this.values = [...this.values, key];
    }
    this.valuesChange.emit(this.values);
  }
}
