import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { CreateCartItemInterface } from '@lobos/library-v2';
import { CartItem } from '@lobos/common';
import { Decimal } from 'decimal.js';

@Component({
  selector: 'app-qty-modal',
  templateUrl: './qty-modal.component.html',
  styleUrls: ['./qty-modal.component.scss'],
})
export class QtyModalComponent extends SimpleModalComponent<
  CreateCartItemInterface | CartItem,
  CreateCartItemInterface | CartItem | undefined
> {
  oCartItem!: CreateCartItemInterface | CartItem;
  decQuantityDown: number = 0;
  decQuantityUp: number = 0;
  decQuantityDefault: number = 1;

  constructor() {
    super();
  }

  override mapDataObject(data: CreateCartItemInterface | CartItem) {
    super.mapDataObject(data);
    this.oCartItem = data;
    this.setData();
  }

  setData() {
    if (this.oCartItem.oArticle.decQuantityPackage <= 0 || !this.oCartItem.oArticle.shtNoPackageBreak)
      this.oCartItem.oArticle.decQuantityPackage = 1;

    this.decQuantityDown = new Decimal(this.oCartItem.decQuantity)
      .toNearest(this.oCartItem.oArticle.decQuantityPackage, Decimal.ROUND_DOWN)
      .toNumber();

    this.decQuantityUp = new Decimal(this.oCartItem.decQuantity)
      .toNearest(this.oCartItem.oArticle.decQuantityPackage, Decimal.ROUND_UP)
      .toNumber();

    this.decQuantityDefault =
      this.oCartItem.oArticle.decQuantityPackage > 0 && this.oCartItem.oArticle.shtNoPackageBreak >= 1
        ? this.oCartItem.oArticle.decQuantityPackage
        : 1;
  }

  roundUp() {
    this.result = {
      ...this.oCartItem,
      decQuantity: this?.oCartItem?.decQuantity ? this.decQuantityUp : this.decQuantityDefault,
    };
    this.close();
  }

  roundDown() {
    this.result = {
      ...this.oCartItem,
      decQuantity: this?.oCartItem?.decQuantity ? this.decQuantityDown : this.decQuantityDefault,
    };
    this.close();
  }

  cancel() {
    if ('decQuantityOrdered' in this.oCartItem) {
      this.result = {
        ...this.oCartItem,
        decQuantity: this.oCartItem.decQuantityOrdered,
      };
    }
    this.close();
  }
}
