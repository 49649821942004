import { Injectable } from '@angular/core';
import { CatalogService, CatalogStore, HookEvent, HookHandlerAbstract, HookPriority, LanguageChangedHook } from '@lobos/library-v2';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { first } from 'rxjs/operators';

type type = LanguageChangedHook;

@Injectable({
  providedIn: 'root',
})
export class LanguageChangedCatalogMenuHandler extends HookHandlerAbstract<type, Observable<boolean>> {
  constructor(private catalogService: CatalogService, private catalogStore: CatalogStore) {
    super();
  }

  override priority(): number {
    return HookPriority.HIGH;
  }

  ids(): type[] {
    return [{ type: 'LanguageChangedHook' }];
  }

  override after(event: HookEvent<type, Observable<boolean>>, result: Observable<boolean>): Observable<boolean> {
    this.catalogStore.reset();

    this.catalogService.getCatalogLevelByParentGroupId(environment.rootLevel).pipe(first()).subscribe();

    return super.after(event, result);
  }
}
