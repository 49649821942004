import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavigationInterface } from '../header.component';
import { CmsMenu } from '../../../services/cms/interfaces/menu.interface';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNavigationComponent {
  @Input() navigationMenu: NavigationInterface[] | undefined | null;
  @Input() menu: CmsMenu[] | undefined | null;
}
