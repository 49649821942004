import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, of } from 'rxjs';
import { Customer, SalesRepCustomer, User } from '@lobos/common';
import { AuthService, CustomerService, SalesRepService, UrlHelperService } from '@lobos/library-v2';
import { switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-sales-rep-banner',
  templateUrl: './sales-rep-banner.component.html',
  styleUrls: ['./sales-rep-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalesRepBannerComponent implements OnInit {
  customer$: Observable<Customer | undefined> | undefined;

  constructor(
    private authService: AuthService,
    private salesRepService: SalesRepService<SalesRepCustomer>,
    protected customerService: CustomerService<Customer>,
    private urlHelperService: UrlHelperService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.customer$ = this.authService.authUser$.pipe(
      switchMap((user: User | undefined) =>
        user?.sP48SalesRepForUser?.length ? this.customerService.getById(user.lngCustomerID) : of(undefined),
      ),
    );
  }

  endSalesRepMode($event?: MouseEvent) {
    $event?.stopPropagation();
    this.salesRepService
      .resetSalesRepCustomer()
      .pipe(
        take(1),
        tap(() => {
          this.location.go(this.urlHelperService.localizeUrl('/sales-rep'));
          window.location.reload();
        }),
      )
      .subscribe();
  }
}
