<header compactHeader class="js-header sticky top-0 z-40 bg-white 5xl:max-w-maxpage mx-auto relative">
  <app-topbar-navigation></app-topbar-navigation>

  <app-service-navigation [menu]="cmsMainMenu$ | async"></app-service-navigation>

  <app-main-navigation [menu]="cmsMainMenu$ | async"></app-main-navigation>

  <app-category-navigation
    *ngIf="cmsMainMenu$ | async as cmsMenu"
    [menuLevel1]="catalogFirstLevel$ | async"
    [menu]="cmsMenu"
  ></app-category-navigation>

  <app-category-nav-flyout [menuLevel1]="$any(catalogFirstLevelFlyout$ | async)" [navOpen]="categoryNavOpen"></app-category-nav-flyout>
</header>
