import { Directive, ElementRef, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[stickyTd]',
})
export class StickyTdDirective {
  constructor(@Inject(DOCUMENT) private _document: Document, private elementRef: ElementRef) {}

  @HostListener('scroll', ['$event']) // for window scroll events
  onScroll(): void {
    const tableEl = this.elementRef.nativeElement;
    const tableScrollPos = tableEl.scrollLeft;
    const maxScrollPos = tableEl.scrollWidth - tableEl.clientWidth;

    if (tableScrollPos > 0) {
      if (tableEl.closest('.js-table-wrap').querySelector('.js-table-scroll--left.hidden')) {
        tableEl.closest('.js-table-wrap').querySelector('.js-table-scroll--left').classList.remove('hidden');
      }
    } else {
      tableEl.closest('.js-table-wrap').querySelector('.js-table-scroll--left').classList.add('hidden');
    }

    if (tableScrollPos == maxScrollPos) {
      tableEl.closest('.js-table-wrap').querySelector('.js-table-scroll--right').classList.add('hidden');
    } else {
      if (tableEl.closest('.js-table-wrap').querySelector('.js-table-scroll--right.hidden')) {
        tableEl.closest('.js-table-wrap').querySelector('.js-table-scroll--right').classList.remove('hidden');
      }
    }

    [].forEach.call(tableEl.querySelectorAll('.js-sticky-td'), function (stickyEl: HTMLElement) {
      if (tableScrollPos < 44) {
        stickyEl.classList.add('after:hidden');
        stickyEl.classList.remove('pr-12');

        if (!tableEl.closest('.js-table-wrap').querySelector('.js-table-scroll--left').classList.contains('hidden')) {
          tableEl.closest('.js-table-wrap').querySelector('.js-table-scroll--left').classList.add('hidden');
        }
      }

      if (tableScrollPos >= 54) {
        stickyEl.classList.remove('after:hidden');
        stickyEl.classList.add('pr-12');
      }
    });
  }
}
