import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function EqualValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    if (!(formGroup instanceof UntypedFormGroup)) {
      return null;
    }

    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors['confirmedValidator']) {
      return null;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ equal: true });
      return { equal: true };
    } else {
      matchingControl.setErrors(null);
      return null;
    }
  };
}
