export class DomHelperService {
  public static delay(elem: any, callback: (...args: any[]) => void): void {
    let timeout: NodeJS.Timeout | null = null;
    elem.onmouseover = function () {
      // Set timeout to be a timer which will invoke callback after 1s
      // eslint-disable-next-line angular/timeout-service
      timeout = setTimeout(callback, 400);
    };

    elem.onmouseout = function () {
      // Clear any timers set to timeout
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }

  public static getTransitionEndEventName($document: Document) {
    const transitions: { [key: string]: string } = {
      transition: 'transitionend',
      OTransition: 'oTransitionEnd',
      MozTransition: 'transitionend',
      WebkitTransition: 'webkitTransitionEnd',
    };
    for (const transition in transitions) {
      if ($document.body.style[transition as any] != undefined) {
        return transitions[transition];
      }
    }

    return undefined;
  }

  public static showContent(el: Element | null): void {
    if (!el) {
      return;
    }

    el.classList.remove('hidden');
    el.classList.add('block');
  }

  public static hideContent(el: Element | null): void {
    if (!el) {
      return;
    }

    el.classList.add('hidden');
    el.classList.remove('block');
  }

  public static is_touch_enabled() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  public static checkBoxMinHeight(el: HTMLElement, currentHeight: number) {
    if (parseInt(el.getAttribute('data-minheight')!) != currentHeight && window.innerWidth > 767) {
      el.style.minHeight = currentHeight + 'px';
      el.setAttribute('data-minheight', String(currentHeight));
    }
  }

  public static bodyScrollDisable($document: Document) {
    const widthBefore = getComputedStyle($document.querySelector('html')!).getPropertyValue('width');
    $document.querySelector('html')!.classList.add('overflow-hidden');
    $document.querySelector('html')!.style.width = widthBefore;
  }

  public static bodyScrollEnable($document: Document) {
    $document.querySelector('html')!.classList.remove('overflow-hidden');
    $document.querySelector('html')!.style.width = '';
  }

  public static hideOpenOptions($document: Document) {
    [].forEach.call($document.querySelectorAll('.js-select-options:not(.hidden)'), function (selectWrap: Element) {
      if (!selectWrap) {
        return;
      }

      if (
        !selectWrap.closest('.js-select-wrap')!.querySelector('.js-select')!.classList.contains('js-select--language') &&
        !selectWrap.closest('.js-select-wrap')!.querySelector('.js-select')!.classList.contains('js-select--table')
      ) {
        selectWrap.closest('.js-select-wrap')!.classList.remove('rounded-t-20px');
        selectWrap.closest('.js-select-wrap')!.classList.add('rounded-button');
      }

      if (selectWrap.closest('.js-select-wrap')!.querySelector('.js-select')!.classList.contains('js-select--table')) {
        selectWrap.closest('.js-select-wrap')!.classList.remove('rounded-t-default');
        selectWrap.closest('.js-select-wrap')!.classList.add('rounded-default');
      }

      if (selectWrap.closest('.js-select-wrap')!.querySelector('.js-select-icon') != null) {
        selectWrap.closest('.js-select-wrap')!.querySelector('.js-select-icon')!.classList.remove('rotate-180');
      }
      selectWrap.classList.add('hidden');
    });
  }

  public static hideFlyout(closeBtn: Element, clickedBtn: Element, $document: Document) {
    let flyoutEl: Element;
    if (clickedBtn) {
      flyoutEl = closeBtn.parentNode as Element;
      if (flyoutEl!.parentNode!.querySelector('.js-flyout-with-close') != null) {
        flyoutEl!.parentNode!.querySelector('.js-flyout-with-close')!.classList.remove('open');
      }
    } else {
      flyoutEl = closeBtn.parentNode!.querySelector('.js-flyout')!;
    }

    if (flyoutEl!.parentNode!.querySelector('.js-index') != null) {
      (flyoutEl.parentNode as Element).classList.add('z-0');
      (flyoutEl.parentNode as Element).classList.remove('z-20');
      if (flyoutEl.closest('.js-flyout-index')) {
        flyoutEl.closest('.js-flyout-index')!.classList.add('z-10');
        flyoutEl.closest('.js-flyout-index')!.classList.remove('z-50');
      }
    }

    flyoutEl.classList.add('hidden');
    flyoutEl.classList.remove('block');

    DomHelperService.fixZIndex('.js-tile.z-20', '.js-tile', $document);
    DomHelperService.fixZIndex('.js-mini-card.z-20', '.js-mini-card', $document);
  }

  public static fixZIndex(activeEl: string, className: string, $document: Document) {
    [].forEach.call($document.querySelectorAll(activeEl), function (activeTile: Element) {
      if ($document.querySelectorAll('.js-flyout-with-close.open').length == 0) {
        activeTile.closest(className)!.classList.add('z-10');
        activeTile.closest(className)!.classList.remove('z-20');

        if (className == '.js-mini-card') {
          activeTile.closest('.js-sticky-td')!.classList.remove('z-30');
          activeTile.closest('.js-sticky-td')!.classList.add('z-20');
        }
      }
    });
  }

  //reset flyout on mouseleave the whole tile or on click on document
  public static resetFlyout(tileEl: NodeListOf<Element>) {
    [].forEach.call(tileEl, function (flyoutEl: Element) {
      if (flyoutEl.classList.contains('block')) {
        flyoutEl.classList.add('hidden');
        flyoutEl.classList.remove('block');

        if (flyoutEl.closest('.js-tile') == null) {
          flyoutEl.closest('.js-mini-card')!.classList.add('z-10');
          flyoutEl.closest('.js-mini-card')!.classList.remove('z-20');

          if (flyoutEl.closest('.js-sticky-td')) {
            flyoutEl.closest('.js-sticky-td')!.classList.remove('z-30');
            flyoutEl.closest('.js-sticky-td')!.classList.add('z-20');
          }
        } else {
          flyoutEl.closest('.js-tile')!.classList.add('z-10');
          flyoutEl.closest('.js-tile')!.classList.remove('z-20');
        }
      }
    });
  }

  public static removeActiveStateLink(parentEl: Element) {
    const activeEl = parentEl.querySelectorAll('.js-active');
    if (activeEl.length > 0) {
      [].forEach.call(activeEl, function (currentEl: Element) {
        currentEl.querySelector('.js-btn-line')!.classList.remove('opacity-100');
        currentEl.querySelector('.js-btn-icon')!.classList.remove('after:opacity-100', 'text-white');
        currentEl.querySelector('.js-btn-icon')!.classList.add('text-primary');
        currentEl.classList.remove('js-active');
      });
    }
  }

  public static activeStateLink(el: Element) {
    el.classList.add('js-active');
    el.querySelector('.js-btn-line')!.classList.add('opacity-100');
    el.querySelector('.js-btn-icon')!.classList.add('after:opacity-100', 'text-white');
    el.querySelector('.js-btn-icon')!.classList.remove('text-primary');
  }

  public static resetDetailFlyout($document: Document) {
    [].forEach.call($document.querySelectorAll('.js-flyout-mouseleave.block'), function (flyoutEl: Element) {
      flyoutEl.classList.add('hidden');
      flyoutEl.classList.remove('block');
    });
  }

  public static resetFlyoutOnTouchDevice($document: Document) {
    [].forEach.call($document.querySelectorAll('.js-flyout.block'), function (flyoutEl: Element) {
      if (flyoutEl.closest('.js-flyout-index.z-50')) {
        flyoutEl.closest('.js-flyout-index.z-50')!.classList.add('z-10');
        flyoutEl.closest('.js-flyout-index.z-50')!.classList.remove('z-50');
      }
      flyoutEl.classList.add('hidden');
      flyoutEl.classList.remove('block');
    });
  }
}
