<div
  [ngClass]="isOpen ? 'rounded-t-20px' : 'rounded-button'"
  class="js-select-wrap relative z-20 bg-primary-light text-center font-normal"
  *transloco="let t"
>
  <button
    type="button"
    class="js-select text-left relative pl-6 pr-12 py-3 flex w-full items-center justify-start"
    (click)="toggleIsOpen()"
  >
    <span class="js-select-text block max-w-sm truncate text-gela-black pointer-events-none font-montserrat font-bold leading-tight pr-10">
      {{ showValue && value ? (translateValue ? t($any(options | getValueFromKey : value)) : (options | getValueFromKey : value)) : label }}
    </span>
    <span
      *ngIf="count !== undefined"
      class="js-select-counter bg-gela-black font-montserrat font-bold text-small text-primary-light px-9px rounded-full min-w-27px h-26px inline-flex items-center justify-center ml-3 absolute right-12 pointer-events-none"
      >{{ count }}</span
    >
    <span class="js-select-icon ifont ifont--chevron-down text-3xl absolute top-1/2 -translate-y-1/2 right-3 pointer-events-none"></span>
  </button>
  <div
    [class.hidden]="!isOpen"
    class="js-select-options hidden text-left w-full bg-white py-2.5 absolute left-0 border-r border-l border-b border-primary-light rounded-b-20px z-50"
  >
    <div *ngIf="editMode" class="mt-3 mb-1 px-6">
      <div class="relative">
        <form (ngSubmit)="editSelected.emit({ type: 'new', id: 'new', value: newValue }); newValue = ''">
          <input
            type="text"
            class="js-select-add w-full py-3.5 pr-90px pl-3.5 border-2 border-primary-light leading-tight rounded-default focus:ring-0 focus:border-primary placeholder:text-primary-dark"
            [(ngModel)]="newValue"
            [name]="'new-' + name"
            [placeholder]="newPlaceholder"
            aria-required="true"
            [required]="true"
            [attr.aria-label]="newPlaceholder"
          />
          <span class="w-px h-10 bg-primary-light top-1.5 absolute right-12"></span>
          <button
            type="submit"
            class="js-select-add-btn absolute w-12 h-12 leading-none right-0 top-1/2 -translate-y-1/2 right-0.5 text-primary transition-all duration-200 hover:text-white hover:bg-secondary"
          >
            <span class="ifont ifont--add-big text-2xl pointer-events-none"></span>
          </button>
        </form>
      </div>
    </div>

    <div [ngClass]="hasMaxHeight ? 'max-h-173px overflow-y-auto scrollbar scrollbar--select' : ''" class="divide-y divide-primary-light">
      <div
        *ngIf="nullValue"
        class="px-6 relative w-full flex items-center cursor-pointer hover:bg-primary-bright transition-all duration-200"
      >
        <div class="flex items-center h-4 cursor-pointer">
          <!-- @lobos render js-radio-change class if the select label should change to the selected text -->
          <input
            [id]="nullValue"
            [name]="name"
            type="radio"
            (change)="valueChange.emit(undefined); toggleIsOpen()"
            class="js-radio js-radio-change cursor-pointer bg-transparent h-4 w-4 text-primary border border-gela-black ring-offset-transparent ring-transparent focus:ring-0 checked:bg-secondary focus:checked:bg-secondary checked:hover:bg-secondary checked:bg-none"
            [checked]="undefined === value"
          />
        </div>
        <div class="ml-3 w-full cursor-pointer">
          <label
            [for]="nullValue"
            class="js-radio-label py-3 text-small font-montserrat font-bold w-full inline-block cursor-pointer flex items-center"
          >
            <span class="js-radio-label--text break-all">
              {{ nullValue }}
            </span>
          </label>
        </div>
      </div>

      <div
        *ngFor="let option of options"
        class="px-6 relative w-full flex items-center cursor-pointer hover:bg-primary-bright transition-all duration-200"
      >
        <div class="flex items-center h-4 cursor-pointer">
          <!-- @lobos render js-radio-change class if the select label should change to the selected text -->
          <input
            [id]="addPrefix(option.key)"
            [name]="name"
            type="radio"
            (change)="valueChange.emit(option.key); toggleIsOpen()"
            class="js-radio js-radio-change cursor-pointer bg-transparent h-4 w-4 text-primary border border-gela-black ring-offset-transparent ring-transparent focus:ring-0 checked:bg-secondary focus:checked:bg-secondary checked:hover:bg-secondary checked:bg-none"
            [checked]="option.key === value"
          />
        </div>
        <div class="ml-3 w-full cursor-pointer">
          <label
            [for]="addPrefix(option.key)"
            class="js-radio-label py-3 text-small font-montserrat font-bold w-full inline-block cursor-pointer flex items-center"
          >
            <span class="js-radio-label--text break-all">
              {{ translateValue ? t($any(option.value)) : option.value }}
            </span>
            <span
              *ngIf="option.count !== undefined"
              class="js-radio-label--counter bg-primary-dark font-montserrat font-bold text-small text-white px-9px rounded-full min-w-27px h-26px inline-flex items-center justify-center ml-3"
              >{{ option.count }}</span
            >

            <div *ngIf="editMode" class="flex ml-auto">
              <button
                (click)="editSelected.emit({ type: 'edit', id: option.key })"
                class="transition-all js-select-radio--change duration-200 text-primary hover:text-gela-black"
              >
                <span class="ifont ifont--edit text-icon pointer-events-none"></span>
              </button>
              <button
                (click)="editSelected.emit({ type: 'delete', id: option.key })"
                class="js-open-modal-delete js-select-radio--change transition-all duration-200 text-primary hover:text-gela-black"
              >
                <span class="ifont ifont--delete text-icon pointer-events-none"></span>
              </button>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
