import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { OutlineAgreement } from '../../services/outline-agreement/outline-agreement.interface';
import { CreateGelaCartItemInterface } from '../../services/cart/model/create-gela-cart-item.interface';
import { GelaCartItem } from '../../services/cart/model/gela-cart-item.model';
import { OutlineAgreementItem } from '../../services/outline-agreement/outline-agreement-item/outline-agreement-item.interface';

@Component({
  selector: 'app-modal-outline-agreement',
  templateUrl: './modal-outline-agreement.component.html',
  styleUrls: ['./modal-outline-agreement.component.scss'],
})
export class ModalOutlineAgreementComponent extends SimpleModalComponent<
  ModalOutlineAgreementData,
  CreateGelaCartItemInterface | GelaCartItem | undefined
> {
  oCartItem!: CreateGelaCartItemInterface | GelaCartItem;
  public oOutlineAgreementArray!: OutlineAgreement[];
  public oOutlineAgreementSelected: OutlineAgreement | undefined;

  constructor() {
    super();
  }

  override mapDataObject(data: ModalOutlineAgreementData) {
    super.mapDataObject(data);
    this.oCartItem = data.oCartItem;
    this.oOutlineAgreementArray = data.oOutlineAgreementArray;
  }

  public selectOutlineAgreement(oOutlineAgreement: OutlineAgreement) {
    this.oOutlineAgreementSelected = oOutlineAgreement;

    let decQty = this.oCartItem.decQuantity;
    const oOutlineAgreementItem: OutlineAgreementItem | undefined = this.oOutlineAgreementSelected.oOutlineAgreementItems.find(
      (item) => item.sArticleID === this.oCartItem.sArticleID,
    );

    if (oOutlineAgreementItem && oOutlineAgreementItem.decMinCall && decQty < oOutlineAgreementItem.decMinCall) {
      // if the quantity is less than the minimum call, set the quantity to the minimum call
      decQty = oOutlineAgreementItem.decMinCall;
    }

    // return modified cart item
    this.result = {
      ...this.oCartItem,
      decQuantity: decQty,
      lngPL1OutlAgreeSalesContractID: this.oOutlineAgreementSelected.lngContractID,
    };
    this.close();
  }

  cancel() {
    // return unmodified cart item
    this.result = {
      ...this.oCartItem,
      lngPL1OutlAgreeSalesContractID: 0,
    };
    this.close();
  }
}

export interface ModalOutlineAgreementData {
  oCartItem: CreateGelaCartItemInterface | GelaCartItem;
  oOutlineAgreementArray: OutlineAgreement[];
}
