import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MobileNavId } from '../../footer-mobile-navbar/footer-mobile-navbar.component';
import { AuthService, Catch, UrlHelperService } from '@lobos/library-v2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-profile-menu',
  templateUrl: './mobile-profile-menu.component.html',
  styleUrls: ['./mobile-profile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileProfileMenuComponent {
  @Output() setActiveNav: EventEmitter<MobileNavId> = new EventEmitter<MobileNavId>();
  public authUser$ = this.authService.authUser$;

  constructor(private authService: AuthService, private router: Router, private urlHelper: UrlHelperService) {}

  @Catch({ label: 'HeaderComponent.logout()' })
  public async logout(): Promise<void> {
    await this.authService.logout();
    await this.router.navigate([this.urlHelper.localizeUrl('')]);
  }

  closeMenu() {
    this.setActiveNav.emit(MobileNavId.NONE);
  }
}
