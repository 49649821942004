import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GelaProduct } from '../../../../services/catalog/model/gela-product';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-search-product-item',
  templateUrl: './search-product-item.component.html',
  styleUrls: ['./search-product-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchProductItemComponent {
  @Input() product: GelaProduct | undefined;
  @Output() linkClicked: EventEmitter<Event> = new EventEmitter();

  constructor(private transloco: TranslocoService) {}

  getTranslatedCategoryLocalisation(): string {
    return (
      this.product?.oCategoryLocalisations.find((l) => l.sLanguage.toLowerCase() === this.transloco.getActiveLang().toLowerCase())
        ?.sBreadcrumb || ''
    );
  }
}
