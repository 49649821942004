import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@lobos/library-v2';
import { EqualValidator } from '../../validators/equal.validator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss'],
})
export class ResetPasswordModalComponent extends SimpleModalComponent<null, string | null> {
  guiId: string | undefined;

  public form: UntypedFormGroup = this.fb.group(
    {
      password: ['', [Validators.required]],
      passwordConfirm: ['', [Validators.required]],
    },
    {
      validators: [EqualValidator('password', 'passwordConfirm')],
    },
  );

  constructor(private fb: UntypedFormBuilder, private authService: AuthService) {
    super();
  }

  public async changePassword(): Promise<void> {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    of(this.form.value)
      .pipe(
        switchMap((formValues: Record<string, string>) =>
          from(
            this.authService.resetPassword({
              sNewPassword: formValues['password'],
              sRegID: this.guiId!,
            }),
          ),
        ),
        untilDestroyed(this),
      )
      .subscribe(() => this.close());
  }
}
