<div
  [ngClass]="activeNav !== mobileNavId.NONE ? 'translate-x-0' : 'translate-x-full'"
  class="js-mobile-nav top-0 left-0 w-full h-navigation overflow-y-auto scrollbar-none fixed z-50 transition duration-200"
>
  <app-favorites-flyout
    *ngIf="activeNav === mobileNavId.FAVORITE"
    [mobile]="true"
    (closed)="setActiveNav.emit(mobileNavId.NONE)"
  ></app-favorites-flyout>
  <app-cart-flyout [mobile]="true" *ngIf="activeNav === mobileNavId.CART" (closed)="setActiveNav.emit(mobileNavId.NONE)"></app-cart-flyout>
  <app-mobile-profile-menu *ngIf="activeNav === mobileNavId.PROFILE" (setActiveNav)="setActiveNav.emit($event)"></app-mobile-profile-menu>
  <app-mobile-navigation-menu
    *ngIf="activeNav === mobileNavId.MENU"
    (setActiveNav)="setActiveNav.emit($event)"
  ></app-mobile-navigation-menu>
</div>
