import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { GelaArticle } from '../../services/catalog/model/gela-article';
import { ArticleHelperService } from '../../services/catalog/article-helper.service';
import { StockAvailability } from '@lobos/common';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AuthService, UrlHelperService } from '@lobos/library-v2';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-article-shop-info',
  templateUrl: './article-shop-info.component.html',
  styleUrls: ['./article-shop-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleShopInfoComponent implements OnInit {
  public authUser$ = this.authService.authUser$;
  private _article: GelaArticle | undefined;
  private articleChanged$: BehaviorSubject<GelaArticle | undefined> = new BehaviorSubject<GelaArticle | undefined>(undefined);

  shopInfoText: string = '';
  readonly articleNumberRegex = /\b(\d{2}\.\d{3}\.\d{2})|(AK\d{6,8})\b/g;

  @Input() set article(article: GelaArticle | undefined) {
    this._article = article;
    this.articleChanged$.next(article);
  }

  get article(): GelaArticle | undefined {
    return this._article;
  }

  @Input() placement: 'default' | 'inline' = 'default';
  $stockAvailability: Observable<StockAvailability | undefined> | undefined;

  constructor(private articleHelperService: ArticleHelperService, private authService: AuthService, private urlHelper: UrlHelperService) {}

  ngOnInit() {
    this.$stockAvailability = this.articleChanged$.pipe(
      filter((article: GelaArticle | undefined) => !!article),
      switchMap(() => this.articleHelperService.getAvailability(this.article!)),
    );

    this.articleChanged$
      .pipe(
        untilDestroyed(this),
        tap((article) => {
          if (article && article.sPL1ShopInfo) {
            this.shopInfoText = article.sPL1ShopInfo;
            if (article.sPL1ShopInfo.search(this.articleNumberRegex) !== -1) {
              const regExArray = article.sPL1ShopInfo.match(this.articleNumberRegex);
              const articleNumber = regExArray ? regExArray[0] : '';
              const styledHTMLArticleNumber = `<a href="${this.urlHelper.localizeUrl(
                'article/' + articleNumber,
              )}"><u>${articleNumber}</u></a>`;
              this.shopInfoText = this.shopInfoText.replace(this.articleNumberRegex, styledHTMLArticleNumber);
            }
          }
        }),
      )
      .subscribe();
  }
}
