<ng-container *ngIf="article">
  <ng-container *ngIf="isFavorite$ | async; else notFavorite">
    <button
      class="relative block w-6 h-6 group"
      (click)="removeArticleFromFavoriteList(article, $event)"
      [ngClass]="sizeClassesButton[size]"
    >
      <span
        [ngClass]="sizeClassesActive[size]"
        class="ifont transition-all duration-200 opacity-100 text-icon absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-20 text-primary group-hover:text-gela-black group-hover:opacity-100"
      ></span>
      <span
        [ngClass]="sizeClasses[size]"
        class="ifont transition-all duration-200 opacity-0 text-icon absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 text-primary group-hover:text-gela-black"
      ></span>
    </button>
  </ng-container>
  <ng-template #notFavorite>
    <button class="relative block group" (click)="addArticleToFavoriteList(article, $event)" [ngClass]="sizeClassesButton[size]">
      <span
        [ngClass]="sizeClassesActive[size]"
        class="ifont transition-all duration-200 opacity-0 text-icon absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-20 text-primary group-hover:text-gela-black group-hover:opacity-100"
      ></span>
      <span
        [ngClass]="sizeClasses[size]"
        class="ifont transition-all duration-200 opacity-100 text-icon absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 text-primary group-hover:text-gela-black"
      ></span>
    </button>
  </ng-template>
</ng-container>
