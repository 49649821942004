import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlyoutPosition } from '../flyout/flyout.component';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {
  @Input() text: string | undefined;
  @Input() flyoutPosition: FlyoutPosition = 'left';
}
