<ng-container *transloco="let t; scope: 'shared'">
  <ng-container *ngIf="product$ | async as product; else productNotFound">
    <div
      *ngIf="viewMode === 'grid'"
      class="js-tile bg-white rounded-20px h-full max-w-tile px-6 pb-6 pt-3.5 relative flex flex-col z-10 border-2 border-transparent hover:border-primary-light transition-all duration-200"
    >
      <a
        [routerLink]="product.sUrlPath | catalogPath | localizeUrlPath"
        [queryParams]="product.sUrlPath | catalogPath | urlFilterParams"
        class="absolute inset-0 rounded-20px z-10"
      ></a>
      <div class="flex items-center">
        <p class="text-small w-tile-cat truncate mr-auto">{{ parentCategoryName }}</p>
        <p class="text-small text-secondary ml-auto">{{ t("shared.product-item.article-count", { count: articleCount }) }}</p>
      </div>
      <app-image [image]="product | productMainImage" class="w-181px h-181px object-contain object-center mx-auto mt-30px"></app-image>

      <div class="mt-3.5 mb-4">
        <span class="text-small font-montserrat font-bold leading-tight">{{ product.sNavname | productNameReplace }}</span>
        <p class="font-source-sans font-bold line-clamp-2 mt-2 min-h-60px" [innerHTML]="product.sTitle"></p>
      </div>
      <div class="items-center mt-auto relative z-20">
        <app-gela-button
          [look]="'tertiary'"
          [text]="t('shared.product-item.multiple-variations')"
          [size]="'large'"
          [type]="'link'"
          [href]="product.sUrlPath | catalogPath | localizeUrlPath"
          [linkParams]="product.sUrlPath | catalogPath | urlFilterParams"
          [fullWidth]="true"
        ></app-gela-button>
      </div>
    </div>

    <div class="js-tile pb-6 pt-4 relative flex z-10" *ngIf="viewMode === 'list'">
      <a
        [routerLink]="product.sUrlPath | catalogPath | localizeUrlPath"
        [queryParams]="product.sUrlPath | catalogPath | urlFilterParams"
        class="absolute inset-0 z-10"
      ></a>
      <app-image
        [image]="product | productMainImage"
        class="w-40 h-40 object-contain object-center"
        classes="w-40 h-40 object-contain object-center"
      ></app-image>

      <div class="w-full pl-5 -ml-1">
        <div class="flex items-center">
          <p class="text-small w-tile-cat truncate mr-auto">{{ parentCategoryName }}</p>
          <p class="text-small text-secondary ml-auto">{{ t("shared.product-item.article-count", { count: articleCount }) }}</p>
        </div>
        <div class="flex">
          <div class="w-product-list pr-6 mt-6 mb-7">
            <span class="text-small font-montserrat font-bold leading-tight">{{ product.sNavname | productNameReplace }}</span>
            <p class="font-source-sans font-bold line-clamp-2 mt-2 min-h-60px" [innerHTML]="product.sTitle"></p>
          </div>
          <div class="w-257px flex items-center mt-auto ml-auto relative z-20">
            <app-gela-button
              [look]="'tertiary'"
              [text]="t('shared.product-item.multiple-variations')"
              [size]="'large'"
              [type]="'link'"
              [href]="product.sUrlPath | catalogPath | localizeUrlPath"
              [linkParams]="product.sUrlPath | catalogPath | urlFilterParams"
              [fullWidth]="true"
            ></app-gela-button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #productNotFound>
    <div
      *ngIf="viewMode === 'grid'"
      class="js-tile bg-white rounded-20px h-full max-w-tile px-6 pb-6 pt-3.5 relative flex flex-col z-10 border-2 border-transparent hover:border-primary-light transition-all duration-200"
    >
      {{ t("shared.product-item.item-not-found", { sku: productNumber }) }}
    </div>

    <div class="js-tile pb-6 pt-4 relative flex z-10" *ngIf="viewMode === 'list'">
      {{ t("shared.product-item.item-not-found", { sku: productNumber }) }}
    </div>
  </ng-template>
</ng-container>
