<ng-container *transloco="let t; scope: 'core'">
  <div class="js-modal-delete js-modal fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gela-black opacity-64 transition-opacity" aria-hidden="true"></div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&ZeroWidthSpace;</span>
      <div
        class="relative inline-block align-bottom text-left overflow-hidden shadow-flyout transform transition-all sm:my-8 sm:align-middle sm:max-w-modal-small sm:w-full"
      >
        <div class="block absolute top-5 right-4 z-10">
          <button
            (click)="close()"
            class="js-close-modal p-1 leading-none rounded-default group text-primary hover:bg-primary-bright transition-all duration-200"
          >
            <span class="ifont ifont--close text-icon-sml group-hover:text-primary-dark transition-all duration-200"></span>
          </button>
        </div>
        <div class="w-full bg-white p-6 border border-primary-light rounded-10px">
          <p class="font-montserrat font-bold text-center">{{ t("core.modal-downloads.title") }}</p>
          <ng-container *ngIf="salesDocuments.length; else noDocuments">
            <div *ngFor="let doc of salesDocuments" class="flex space-x-5 justify-center self-start pt-3.5">
              <app-gela-button
                [type]="'button'"
                [text]="t('core.modal-downloads.type', { documentId: doc.lngDocumentID, documentName: doc.sDocumentTypeName })"
                [loading]="isLoadingDownload$ | async"
                [appLoading]="isLoadingDownload$ | async"
                (click)="downloadDocument(doc)"
                [fullWidth]="true"
                look="secondary"
                size="small"
                class="flex items-center text-small font-montserrat font-bold hover:text-primary transition-all duration-200 cursor-pointer"
              ></app-gela-button>
            </div>
          </ng-container>
          <ng-template #noDocuments>
            <p
              class="flex items-center text-small font-montserrat text-center font-bold hover:text-primary transition-all duration-200 cursor-pointer mt-04"
            >
              {{ t("core.modal-downloads.no-document") }}
            </p>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-container>
