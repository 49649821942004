import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cart, User } from '@lobos/common';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { combineLatest, iif, merge, Observable, of, Subject } from 'rxjs';
import { first, map, mergeMap, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { GelaCartHeader } from './model/gela-cart-header.model';
import { GelaCartItem } from './model/gela-cart-item.model';
import { CreateGelaCartItemInterface } from './model/create-gela-cart-item.interface';
import {
  AuthService,
  CartGuestService,
  CartHeaderQuery,
  CartHeaderStore,
  CartHttpService,
  CartItemQuery,
  CartItemStore,
  CartLocalService,
  CartService,
  ParameterQuery,
  switchTap,
  UserService,
} from '@lobos/library-v2';

// @dynamic
@Injectable({ providedIn: 'root' })
export class GelaCartService<T extends GelaCartHeader, R extends GelaCartItem, S extends CreateGelaCartItemInterface> extends CartService<
  T,
  R,
  S
> {
  constructor(
    protected override transloco: TranslocoService,
    protected override translocoLocale: TranslocoLocaleService,
    protected override cartHttpService: CartHttpService<T, R, S>,
    protected override cartLocalService: CartLocalService<T, R, S>,
    protected override cartGuestService: CartGuestService<T, R, S>,
    protected override authService: AuthService,
    protected override cartHeaderStore: CartHeaderStore<T>,
    protected override cartItemStore: CartItemStore<R>,
    protected override cartItemQuery: CartItemQuery<R>,
    protected override cartHeaderQuery: CartHeaderQuery<T>,
    protected override http: HttpClient,
    protected override userService: UserService,
    protected override paramQuery: ParameterQuery,
  ) {
    super(
      transloco,
      translocoLocale,
      cartHttpService,
      cartLocalService,
      cartGuestService,
      authService,
      cartHeaderStore,
      cartItemStore,
      cartItemQuery,
      cartHeaderQuery,
      http,
      userService,
      paramQuery,
    );
  }

  /**
   * Converts any locally saved cart items to a newly created remote cart
   * and removes the local items in that process
   */
  override convertLocalToRemote(_: User): Observable<Cart<T, R>> {
    const end$ = new Subject<boolean>();

    return this.cartLocalService.getCartById(CartLocalService.ACTIVE_CART_ID).pipe(
      first(),
      switchMap((cart: Cart<T, R>) =>
        iif(
          // no or empty cart
          () => !cart?.oSalesItemList?.length,
          // true
          of(cart),
          // false
          combineLatest([of(cart), this.createCartHeader(cart.oSalesMaster)]).pipe(
            switchTap(([_, header]: [Cart<T, R>, T]) => this.changeActiveCart(header.lngOrderID)),
            mergeMap(([cart, _]: [Cart<T, R>, T]) => {
              const creationObservables: Observable<any>[] = [];
              creationObservables.push(this.cartLocalService.deleteCart(CartLocalService.ACTIVE_CART_ID));
              for (const cartItem of cart.oSalesItemList) {
                creationObservables.push(this.cartLocalService.deleteCartItem(cartItem));
                creationObservables.push(
                  this.createCartItem({
                    oArticle: cartItem.oArticle,
                    decQuantity: cartItem.decQuantity,
                    decPL1Length: cartItem.decPL1Length,
                    sQuantityUnit: cartItem.oArticle.sQuantityUnitSales,
                    sArticleID: cartItem.sArticleID,
                    sArticleName: cartItem.sArticleName,
                    sItemText: cartItem.sItemText,
                    emitHook: false,
                  } as S),
                );
              }

              // end stream, so toArray() can work
              creationObservables.push(
                of(undefined).pipe(
                  tap(() => {
                    end$.next(true);
                    end$.complete();
                  }),
                ),
              );

              return merge(creationObservables);
            }),
            mergeMap(($req) => $req, 1),
            takeUntil(end$),
            toArray(),
            map((carts: Cart<T, R>[]) => carts[0]),
          ),
        ),
      ),
    );
  }
}
