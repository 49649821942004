import { Directive, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[compactHeader]',
})
export class CompactHeaderDirective {
  constructor(@Inject(DOCUMENT) private _document: Document) {}

  @HostListener('window:scroll', []) // for window scroll events
  onScroll(): void {
    const header = this._document.querySelector<HTMLElement>('.js-header')!;
    const headerHeight = parseFloat(getComputedStyle(header).getPropertyValue('height'));
    const topBar = this._document.querySelector<HTMLElement>('.js-header-big--topbar')!;
    const claim = this._document.querySelector<HTMLElement>('.js-header-compact--claim')!;
    const logoWrap = this._document.querySelector<HTMLElement>('.js-logo-wrap')!;
    const logo = this._document.querySelector<HTMLElement>('.js-header-logo')!;
    const nav = this._document.querySelector<HTMLElement>('.js-header-big--nav')!;
    const cat = this._document.querySelector<HTMLElement>('.js-header-big--categories')!;
    const compactNav = this._document.querySelector<HTMLElement>('.js-header-compact--navbar')!;
    const searchContainer = this._document.querySelector<HTMLElement>('.js-header-search')!;
    const search = this._document.querySelector<HTMLElement>('.js-header-search')!.querySelector<HTMLElement>('.input-form')!;
    const searchBtn = this._document.querySelector<HTMLElement>('.js-header-search-compact-show')!;
    const searchResult = this._document.querySelector<HTMLElement>('.js-header-search-wrap')!;
    const headerWrap = this._document.querySelector<HTMLElement>('.js-header-big-wrap')!;
    const top = (window.pageYOffset || this._document.documentElement.scrollTop) - (this._document.documentElement.clientTop || 0);

    if (top > headerHeight) {
      if (!header!.classList.contains('js-compact')) {
        if (window.innerWidth >= 1000) {
          this.compactStyling(
            compactNav,
            header,
            claim,
            logo,
            headerWrap,
            searchContainer,
            search,
            searchBtn,
            searchResult,
            topBar,
            nav,
            cat,
            logoWrap,
          );
        } else {
          this._document.querySelector<HTMLElement>('.js-logo-compact-wrap')?.classList.remove('hidden');
          this._document.querySelector<HTMLElement>('.js-logo-compact-wrap')?.classList.add('self-start');
          this._document.querySelector<HTMLElement>('.js-header-big-wrap')?.classList.add('flex-nowrap');
          logoWrap.classList.add('hidden');
          this._document.querySelector<HTMLElement>('.js-header-scan')?.classList.add('hidden');
          this._document.querySelector<HTMLElement>('.js-header-search-overflow')?.classList.remove('max-h-flyout-header');

          if (window.innerWidth > 767) {
            search.style.flexBasis = window.innerWidth - 144 + 'px';
          } else {
            search.style.flexBasis = window.innerWidth - 104 + 'px';
          }

          header.classList.add('js-compact');
          //this.setSearchHeight();
        }
      }
    } else {
      if (header!.classList.contains('js-compact')) {
        if (
          this._document
            .querySelector<HTMLElement>('.js-header-search')
            ?.querySelector<HTMLElement>('.input-form')!
            .classList.contains('active')
        ) {
          this._document.querySelector<HTMLElement>('.js-header-search')!.querySelector<HTMLElement>('.input-form')!.style.top = '';
          this._document.querySelector<HTMLElement>('.js-header-search')!.querySelector<HTMLElement>('.input-form')!.style.transform = '';
          this._document.querySelector<HTMLElement>('.js-header-scan')!.style.top = '';
          this._document.querySelector<HTMLElement>('.js-header-scan')!.style.transform = '';
          this._document.querySelector<HTMLElement>('.js-header-big-wrap')?.classList.remove('relative');
          this._document
            .querySelector<HTMLElement>('.js-header-search')
            ?.querySelector<HTMLElement>('.input-form')!
            .classList.remove('absolute', 'z-10');
          this._document
            .querySelector<HTMLElement>('.js-header-search')
            ?.querySelector<HTMLElement>('.input-form')!
            .classList.add('relative');
          this._document.querySelector<HTMLElement>('.js-header-search')!.querySelector<HTMLElement>('.input-form')!.style.width = '';
          this._document.querySelector<HTMLElement>('.js-header-search')!.querySelector<HTMLElement>('.input-form')!.style.right = '';
          this._document.querySelector<HTMLElement>('.js-header-scan')!.style.position = '';
          this._document.querySelector<HTMLElement>('.js-header-scan')!.style.right = '';
          this._document
            .querySelector<HTMLElement>('.js-header-search')!
            .querySelector<HTMLElement>('.input-form')!
            .classList.remove('active');
        }

        this.revertCompact(
          compactNav,
          header,
          claim,
          logo,
          headerWrap,
          searchContainer,
          search,
          searchBtn,
          searchResult,
          topBar,
          nav,
          cat,
          logoWrap,
        );

        if (window.innerWidth < 1000) {
          this._document.querySelector<HTMLElement>('.js-logo-compact-wrap')?.classList.add('hidden');
          this._document.querySelector<HTMLElement>('.js-logo-compact-wrap')?.classList.remove('self-start');
          this._document.querySelector<HTMLElement>('.js-header-big-wrap')?.classList.remove('flex-nowrap');
          this._document.querySelector<HTMLElement>('.js-logo-wrap')?.classList.remove('hidden');
          this._document.querySelector<HTMLElement>('.js-header-scan')?.classList.remove('hidden');
          this._document.querySelector<HTMLElement>('.js-header-search')!.style.flexBasis = '';
          this._document.querySelector<HTMLElement>('.js-header')?.classList.remove('js-compact');
          this._document.querySelector<HTMLElement>('.js-header-search-overflow')?.classList.add('max-h-flyout-header');
          //this.setSearchHeight();
        }
      }
    }
  }

  compactStyling(
    compactNav: HTMLElement,
    header: HTMLElement,
    claim: HTMLElement,
    _logo: HTMLElement,
    headerWrap: HTMLElement,
    searchContainer: HTMLElement,
    search: HTMLElement,
    searchBtn: HTMLElement,
    searchResult: HTMLElement,
    topBar: HTMLElement,
    nav: HTMLElement,
    cat: HTMLElement,
    logoWrap: HTMLElement,
  ) {
    if (search.classList.contains('js-compact-search')) {
      this.resetOpenSearchBar();
    }

    this._document.querySelector<HTMLElement>('.js-logo-compact-wrap')!.classList.remove('hidden');
    this._document.querySelector<HTMLElement>('.js-logo-compact-wrap')!.classList.add('self-start');
    this._document.querySelector<HTMLElement>('.js-header-big-wrap')!.classList.add('flex-nowrap');
    logoWrap.classList.add('hidden');

    this._document.querySelector<HTMLElement>('.js-header-logo')!.classList.add('w-full');
    this._document.querySelector<HTMLElement>('.js-header-logo')!.classList.remove('w-logo-compact');
    if (claim) {
      claim.classList.add('hidden');
    }

    compactNav.classList.remove('hidden');
    compactNav.classList.add('mobile-nav:flex');

    searchContainer.classList.add('hidden', 'absolute', 'w-[614px]', 'right-0');
    search.classList.remove('basis-search-tablet', 'desktop-xs:basis-search-sml', 'xl:basis-search', '3xl:basis-search-big');
    search.classList.add('hidden');
    searchBtn.classList.remove('hidden');
    header.classList.add('js-compact');
    this.showInfo(false);
    headerWrap.style.paddingTop = '0';
    headerWrap.style.paddingBottom = '0';
    headerWrap.style.height = '86px';
    header.style.borderTop = 'solid 3px #1E1E1E';
    header.classList.add('shadow-flyout');
    this._document.querySelector<HTMLElement>('.js-header-scan')!.style.marginLeft = '0';
    this._document.querySelector<HTMLElement>('.js-header-scan')!.style.marginRight = '0';
    this._document.querySelector<HTMLElement>('.js-header-scan')!.style.marginTop = '8px';
    this._document.querySelector<HTMLElement>('.js-header-scan .ifont')!.style.fontSize = '32px';
    this._document.querySelector<HTMLElement>('.js-header-scan')!.classList.remove('bg-primary-bright');
    this._document.querySelector<HTMLElement>('.js-icons')!.style.marginTop = '8px';

    if (window.innerWidth > 1540) {
      searchBtn.style.marginRight = window.innerWidth * 0.011 + 'px';
      this._document.querySelector<HTMLElement>('.js-icons')!.style.marginLeft = 'auto';
    } else {
      searchBtn.style.marginRight = '6px';
      this._document.querySelector<HTMLElement>('.js-icons')!.style.marginLeft = '50px';

      if (window.innerWidth >= 1280 && window.innerWidth < 1440) {
        this._document.querySelector<HTMLElement>('.js-icons')!.style.marginLeft = '40px';
      }

      if (window.innerWidth >= 1080 && window.innerWidth < 1280) {
        this._document.querySelector<HTMLElement>('.js-icons')!.style.marginLeft = '26px';
      }

      if (window.innerWidth >= 1000 && window.innerWidth < 1080) {
        this._document.querySelector<HTMLElement>('.js-icons')!.style.marginLeft = '4px';
      }
    }

    if (window.innerWidth >= 1440) {
      headerWrap.style.paddingLeft = '';
      headerWrap.style.paddingRight = '';
    } else {
      headerWrap.style.paddingLeft = '68px';
      headerWrap.style.paddingRight = '68px';

      if (window.innerWidth < 1280) {
        headerWrap.style.paddingLeft = '28px';
        headerWrap.style.paddingRight = '28px';

        if (window.innerWidth < 1080) {
          headerWrap.style.paddingLeft = '20px';
          headerWrap.style.paddingRight = '20px';
        }
      }
    }

    if (window.innerWidth < 1023) {
      this._document.querySelector<HTMLElement>('.js-header-search-submit')!.style.top = '8px';
      logoWrap.style.marginBottom = '0px';
    }

    if (window.innerWidth > 1000 && window.innerWidth < 1540) {
      if (searchResult) searchResult.style.width = '614px';
    }

    this.toggleEl(topBar, true);
    this.toggleEl(nav, true);
    this.toggleEl(cat, true);
  }

  revertCompact(
    compactNav: HTMLElement,
    header: HTMLElement,
    claim: HTMLElement,
    _logo: HTMLElement,
    headerWrap: HTMLElement,
    searchContainer: HTMLElement,
    search: HTMLElement,
    searchBtn: HTMLElement,
    searchResult: HTMLElement,
    topBar: HTMLElement,
    nav: HTMLElement,
    cat: HTMLElement,
    logoWrap: HTMLElement,
  ) {
    compactNav.classList.add('hidden');
    compactNav.classList.remove('mobile-nav:flex');
    searchContainer.classList.remove('hidden', 'absolute', 'w-[614px]', 'right-0');
    searchContainer.classList.add('basis-search-tablet', 'desktop-xs:basis-search-sml', 'xl:basis-search', '3xl:basis-search-big');
    search.classList.remove('hidden');
    searchBtn.classList.add('hidden');
    header.classList.remove('js-compact');
    header.style.borderTop = '';
    header.classList.remove('shadow-flyout');
    this._document.querySelector('.js-logo-compact-wrap')!.classList.add('hidden');
    this._document.querySelector('.js-logo-compact-wrap')!.classList.remove('self-start');
    this._document.querySelector('.js-header-big-wrap')!.classList.remove('flex-nowrap');
    logoWrap.classList.remove('hidden');
    this._document.querySelector('.js-header-logo')!.classList.remove('w-full');
    this._document.querySelector('.js-header-logo')!.classList.add('w-logo-compact');
    if (claim) {
      claim.classList.remove('hidden');
    }
    this.toggleEl(topBar, false);
    this.toggleEl(nav, false);
    this.toggleEl(cat, false);
    this.showInfo(true);
    headerWrap.style.paddingTop = '';
    headerWrap.style.paddingBottom = '';
    headerWrap.style.height = '';
    headerWrap.style.paddingRight = '';
    headerWrap.style.paddingLeft = '';
    logoWrap.style.marginBottom = '';
    if (searchResult) searchResult.style.width = '';
    this._document.querySelector<HTMLElement>('.js-header-scan')!.style.marginRight = '';
    this._document.querySelector<HTMLElement>('.js-header-scan')!.style.marginLeft = '';
    this._document.querySelector<HTMLElement>('.js-header-scan .ifont')!.style.fontSize = '';
    this._document.querySelector<HTMLElement>('.js-header-scan')!.classList.add('bg-primary-bright');
    this._document.querySelector<HTMLElement>('.js-icons')!.style.marginLeft = '';
    this._document.querySelector<HTMLElement>('.js-header-scan')!.style.marginTop = '';
    this._document.querySelector<HTMLElement>('.js-icons')!.style.marginTop = '';
  }

  showInfo(compact: boolean) {
    [].forEach.call(this._document.querySelectorAll('.js-show-info'), function (infoEl: HTMLElement) {
      if (compact) {
        infoEl.style.marginRight = '';
      } else {
        if (window.innerWidth > 1540) {
          infoEl.style.marginRight = window.innerWidth * 0.011 + 'px';
        }
      }
    });
  }

  toggleEl(el: HTMLElement, hide: boolean) {
    if (hide) {
      el.classList.add('hidden');
      el.classList.remove('mobile-nav:block');
    } else {
      el.classList.remove('hidden');
      el.classList.add('mobile-nav:block');

      if (window.innerWidth < 1065) {
        el.classList.add('hidden');
        el.classList.remove('mobile-nav:block');
      }
    }
  }

  resetOpenSearchBar() {
    this._document.querySelector<HTMLElement>('.js-header-big-wrap')!.classList.remove('relative');
    this._document
      .querySelector<HTMLElement>('.js-header-search')!
      .querySelector<HTMLElement>('.input-form')!
      .classList.remove('absolute', 'z-10');
    this._document.querySelector<HTMLElement>('.js-header-search')!.querySelector<HTMLElement>('.input-form')!.classList.add('relative');
    this._document.querySelector<HTMLElement>('.js-header-search')!.querySelector<HTMLElement>('.input-form')!.style.width = '';
    this._document.querySelector<HTMLElement>('.js-header-search')!.querySelector<HTMLElement>('.input-form')!.style.right = '';
    this._document.querySelector<HTMLElement>('.js-header-scan')!.style.position = '';
    this._document.querySelector<HTMLElement>('.js-header-scan')!.style.right = '';
    this._document.querySelector<HTMLElement>('.js-header-search')!.querySelector<HTMLElement>('.input-form')!.classList.remove('active');

    if (this._document.querySelector<HTMLElement>('.js-header')!.classList.contains('js-compact')) {
      this._document.querySelector<HTMLElement>('.js-header-search')!.classList.add('hidden');
      this._document.querySelector<HTMLElement>('.js-header-search')!.querySelector<HTMLElement>('.input-form')!.classList.add('hidden');
      this._document.querySelector<HTMLElement>('.js-header-search-compact-show')!.classList.remove('hidden');
      this._document.querySelector<HTMLElement>('.js-header-search')!.querySelector<HTMLElement>('.input-form')!.style.top = '';
      this._document.querySelector<HTMLElement>('.js-header-search')!.querySelector<HTMLElement>('.input-form')!.style.transform = '';
      this._document.querySelector<HTMLElement>('.js-header-scan')!.style.top = '';
      this._document.querySelector<HTMLElement>('.js-header-scan')!.style.transform = '';
      this._document
        .querySelector<HTMLElement>('.js-header-search')!
        .querySelector<HTMLElement>('.input-form')!
        .classList.remove('js-compact-search');

      if (window.innerWidth > 1540) {
        this._document.querySelector<HTMLElement>('.js-icons')!.style.marginLeft = window.innerWidth * 0.03472 + 'px';
      } else {
        this._document.querySelector<HTMLElement>('.js-icons')!.style.marginLeft = '50px';

        if (window.innerWidth >= 1280 && window.innerWidth < 1440) {
          this._document.querySelector<HTMLElement>('.js-icons')!.style.marginLeft = '40px';
        }

        if (window.innerWidth >= 1080 && window.innerWidth < 1280) {
          this._document.querySelector<HTMLElement>('.js-icons')!.style.marginLeft = '26px';
        }

        if (window.innerWidth >= 1000 && window.innerWidth < 1080) {
          this._document.querySelector<HTMLElement>('.js-icons')!.style.marginLeft = '4px';
        }
      }
    }
  }
}
