import { Component, ViewEncapsulation } from '@angular/core';
import { Toast } from 'ngx-toastr';

@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomToastComponent extends Toast {}
