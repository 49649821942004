import { Component, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CmsMenu } from '../../../services/cms/interfaces/menu.interface';

@Component({
  selector: 'app-footer-accordion',
  templateUrl: './footer-accordion.component.html',
  styleUrls: ['./footer-accordion.component.scss'],
})
export class FooterAccordionComponent {
  @Input() cmsMenu: CmsMenu | undefined;

  constructor(@Inject(DOCUMENT) private _document: Document) {}

  accordionToggle(event: Event) {
    const e: HTMLElement = event.currentTarget! as HTMLElement;

    if (e.querySelector('.js-accordion-wrap')?.classList.contains('max-h-0')) {
      const t = parseFloat(getComputedStyle(e.querySelector('.js-accordion-content')!).getPropertyValue('height')) + 56;
      e.querySelector('.js-accordion-wrap')?.classList.remove('max-h-0'),
        e.querySelector('.js-accordion-wrap')!.classList.add('pb-9'),
        e.querySelector('.js-accordion-wrap')!.classList.add('pt-5'),
        (e.querySelector<HTMLElement>('.js-accordion-wrap')!.style.maxHeight = t + 'px'),
        e.querySelector('.js-accordion-icon')!.classList.add('rotate-180');
    } else
      e.querySelector('.js-accordion-wrap')!.classList.add('max-h-0'),
        e.querySelector('.js-accordion-wrap')!.classList.remove('pb-9'),
        e.querySelector('.js-accordion-wrap')!.classList.remove('pt-5'),
        (e.querySelector<HTMLElement>('.js-accordion-wrap')!.style.maxHeight = ''),
        e.querySelector('.js-accordion-icon')!.classList.remove('rotate-180');
  }
}
