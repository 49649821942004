<ng-container *transloco="let t; scope: 'profile'">
  <div
    class="js-modal-notes-edit js-modal fixed z-50 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gela-black opacity-64 transition-opacity" aria-hidden="true"></div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&ZeroWidthSpace;</span>
      <div
        class="relative inline-block align-bottom text-left overflow-hidden shadow-flyout transform transition-all sm:my-8 sm:align-middle sm:max-w-modal-big sm:w-full"
      >
        <div class="block absolute top-5 right-12 mr-1.5 z-10">
          <button
            class="p-1 leading-none rounded-default group text-primary hover:bg-primary-bright transition-all duration-200 bg-primary-bright text-primary-dark"
          >
            <span class="ifont ifont--edit text-icon-sml group-hover:text-primary-dark transition-all duration-200"></span>
          </button>
          <button
            *ngIf="note"
            (click)="remove(note)"
            class="p-1 leading-none rounded-default group text-primary hover:bg-primary-bright transition-all duration-200"
          >
            <span class="ifont ifont--delete text-icon-sml group-hover:text-primary-dark transition-all duration-200"></span>
          </button>
        </div>
        <div class="block absolute top-5 right-4 z-10">
          <button
            (click)="close()"
            class="js-close-modal p-1 leading-none rounded-default group text-primary hover:bg-primary-bright transition-all duration-200"
          >
            <span class="ifont ifont--close text-icon-sml group-hover:text-primary-dark transition-all duration-200"></span>
          </button>
        </div>
        <form *ngIf="note && form" (submit)="save(note, form, $event)" class="w-full bg-white p-6 border border-primary-light rounded-20px">
          <div class="mt-12 md:mt-0 md:w-10/12">
            <p class="text-small">{{ note.article.sName }}</p>
            <p class="font-source-sans font-bold mt-0.5 leading-tight">{{ note.article.sDescription }}</p>
          </div>
          <div class="flex justify-between text-small mt-10 mb-1">
            <p class="font-source-sansfont-bold mt-1.5">{{ note.user }}</p>
            <p class="text-secondary mt-1.5">{{ note.created | translocoDate }}</p>
          </div>
          <app-textarea
            name="note"
            classes="w-full h-232px"
            [placeholder]="t('profile.notes.note-modal.label.note')"
            [hasError]="form | hasError : 'note'"
            [form]="form"
          ></app-textarea>
          <div class="w-full flex space-x-5 justify-end self-start pt-3.5">
            <app-button
              (click)="close()"
              [text]="t('profile.notes.note-modal.button.cancel')"
              classes="js-close-modal w-auto self-start bg-primary-light text-gela-black hover:text-white hover:bg-secondary"
            >
            </app-button>
            <app-button
              [text]="t('profile.notes.note-modal.button.save')"
              type="submit"
              classes="w-auto self-start bg-secondary text-white hover:bg-gela-black"
            >
            </app-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>
