import { Injectable } from '@angular/core';
import { Label, LabelData, PaddingBoxInterface, Template } from '@lobos/label-print-v2';
import { Gela3x8Renderer } from './gela3x8.renderer';
import * as jsbarcode from 'jsbarcode';

@Injectable({
  providedIn: 'root',
})
export class Gela3x10Renderer extends Gela3x8Renderer {
  public override template(): Template {
    return {
      id: 'gela3x10',
      title: 'profile.label-print.domain.template.suedo3x10.title',
      format: { width: 210, height: 297 },
      boxSizes: { width: 70, height: 29.7 },
      orientation: 'p',
      numberOfBoxes: { horizontal: 3, vertical: 10 },
    };
  }

  protected override async drawData(label: Label, x: number, y: number): Promise<void> {
    const labelData: LabelData = label.data;
    let paddingBox: PaddingBoxInterface;

    const extraPaddingX = 2.5;

    const extraPaddingY = 3;

    // Title
    paddingBox = this.addPadding(x, y, this.boxWidth * (this.configuration!.extra?.hideImage ? 1 : 2 / 3), this.boxHeight * (1 / 3));
    this.jspdf.drawText(
      labelData.title + ' / ' + labelData.unit,
      paddingBox.x + extraPaddingX,
      paddingBox.y + extraPaddingY,
      paddingBox.width,
      8,
      2,
      'bold',
    );

    // Description
    paddingBox = this.addPadding(
      x,
      y + this.boxHeight * (1 / 3) - 5,
      this.boxWidth * (this.configuration!.extra?.hideImage ? 2 / 3 : 1),
      this.boxHeight * (1 / 3),
    );
    this.jspdf.drawText(labelData.description, paddingBox.x + extraPaddingX, paddingBox.y + extraPaddingY, paddingBox.width, 8, 3);

    // Amount
    paddingBox = this.addPadding(
      x + this.boxWidth * (this.configuration?.extra?.hideLogo ? 0 : 1),
      y,
      this.boxWidth * (2 / 3),
      this.boxHeight,
    );
    this.jspdf.drawText(
      `Menge: ${labelData.customFields.amount}`,
      paddingBox.x + extraPaddingX,
      paddingBox.y + paddingBox.height,
      paddingBox.width,
      6,
      1,
      'normal',
      {
        baseline: 'bottom',
      },
    );

    // Code
    paddingBox = this.addPadding(x, y, this.boxWidth, this.boxHeight);
    this.jspdf.drawText(
      labelData.code,
      paddingBox.x + (this.configuration?.extra?.hideLogo ? (paddingBox.width * 2) / 3 : paddingBox.width),
      paddingBox.y + paddingBox.height,
      paddingBox.width,
      6,
      1,
      'normal',
      {
        align: 'right',
        baseline: 'bottom',
      },
    );

    // BarCode (EAN13)
    paddingBox = this.addPadding(
      x + this.boxWidth * (this.configuration!.extra?.hideLogo ? 0 : 1 / 3),
      y + this.boxHeight * (2 / 3) - 0.5,
      this.boxWidth * (2 / 3),
      this.boxHeight * (1 / 3) - this.jspdf.getTextDimensions(labelData.code).h,
    );
    await this.addCode128(labelData.code, paddingBox.x + extraPaddingX, paddingBox.y, paddingBox.width, paddingBox.height);

    // Image
    if (this.configuration!.extra?.hideImage) {
      paddingBox = this.addPadding(x + this.boxWidth * (2 / 3), y, this.boxWidth * (1 / 3), this.boxHeight * (2 / 3));
      await this.addImage(labelData.imgPath!, paddingBox.x, paddingBox.y + extraPaddingY, paddingBox.width, paddingBox.height);
    }

    if (this.configuration!.extra?.hideLogo) {
      // Logo
      paddingBox = this.addPadding(
        x + this.boxWidth * (2 / 3),
        y + this.boxHeight * (2 / 3) - 3,
        this.boxWidth * (1 / 3),
        this.boxHeight * (1 / 3),
      );
      await this.addImage(labelData.logoPath!, paddingBox.x, paddingBox.y, paddingBox.width, paddingBox.height);
    }
  }

  protected override async addCode128(code: string, x: number, y: number, maxWidth: number, maxHeight: number): Promise<void> {
    if (!code) {
      return;
    }

    const canvas: HTMLCanvasElement = this.document.createElement('canvas');
    jsbarcode(canvas, code, { format: 'CODE128', width: 7.5, displayValue: false, flat: true, margin: 0 });

    await this.jspdf.drawImage(canvas.toDataURL(), x, y, maxWidth, maxHeight, undefined, 'right');
  }
}
