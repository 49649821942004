import { AfterViewInit, Component, ElementRef, HostListener, Input, Optional } from '@angular/core';
import { FacetOption } from '@lobos/common';
import { ArticleFacetedSearchQuery } from '@lobos/library-v2';
import { GelaArticle } from '../../services/catalog/model/gela-article';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-table-scroll',
  templateUrl: './table-scroll.component.html',
  styleUrls: ['./table-scroll.component.scss'],
})
export class TableScrollComponent implements AfterViewInit {
  @Input() fullLength: boolean = false;
  @Input() tableFacetOption: FacetOption | undefined;

  @HostListener('window:resize', ['$event']) // for window scroll events
  onResize() {
    const scrollBtns: NodeListOf<HTMLElement> = this.elementRef.nativeElement.querySelectorAll('.js-table-scroll');
    if (!scrollBtns.length) {
      return;
    }

    scrollBtns.forEach((scrollBtn: HTMLElement) => this.hideScrollRightButton(scrollBtn));
  }

  constructor(private elementRef: ElementRef, @Optional() private articleFacetedSearchQuery: ArticleFacetedSearchQuery<GelaArticle>) {}

  public ngAfterViewInit(): void {
    this.handleViewOfScrollButtons();

    // show scroll buttons after article facets have changed
    this.articleFacetedSearchQuery?.facets$
      .pipe(
        tap(() => {
          this.handleViewOfScrollButtons();
        }),
      )
      .subscribe();
  }

  handleViewOfScrollButtons() {
    const scrollBtns: NodeListOf<HTMLElement> = this.elementRef.nativeElement.querySelectorAll('.js-table-scroll');
    if (!scrollBtns.length) {
      return;
    }

    setTimeout(() => scrollBtns.forEach((scrollBtn: HTMLElement) => this.hideScrollRightButton(scrollBtn)), 500);
  }

  scrollHandler(e: Event) {
    const scrollBtn = (e.target as HTMLElement)!;
    this.hideScrollRightButton(scrollBtn);

    const table = scrollBtn.closest('.js-table-wrap')?.querySelector('.js-table');
    const tableScrollPos = table!.scrollLeft;
    const maxScrollPos = table!.scrollWidth - table!.clientWidth;
    let newScrollPos = tableScrollPos + 100;

    if (scrollBtn.classList.contains('js-table-scroll--right')) {
      if (scrollBtn.closest('.js-table-wrap')!.querySelector('.js-table-scroll--left')!.classList.contains('hidden')) {
        scrollBtn.closest('.js-table-wrap')!.querySelector('.js-table-scroll--left')!.classList.remove('hidden');
      }
    } else {
      newScrollPos = tableScrollPos - 100;
      if (scrollBtn.closest('.js-table-wrap')!.querySelector('.js-table-scroll--right')!.classList.contains('hidden')) {
        scrollBtn.closest('.js-table-wrap')!.querySelector('.js-table-scroll--right')!.classList.remove('hidden');
      }
    }

    if (tableScrollPos <= maxScrollPos) {
      if (maxScrollPos == tableScrollPos) {
        scrollBtn.closest('.js-table-wrap')!.querySelector('.js-table-scroll--right')!.classList.add('hidden');
      }

      table!.scrollTo({
        top: 0,
        left: newScrollPos,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        behaviour: 'smooth',
      });
    }
  }

  hideScrollRightButton(scrollBtn: HTMLElement) {
    const tableWidth = scrollBtn.closest('.js-table-wrap')!.querySelector('.js-table')!.clientWidth;
    const scrollWidth = scrollBtn.closest('.js-table-wrap')!.querySelector('.js-table')!.scrollWidth;

    if (tableWidth == scrollWidth && scrollBtn.classList.contains('js-table-scroll--right')) {
      scrollBtn.classList.add('hidden');
    } else {
      if (scrollBtn.classList.contains('js-table-scroll--right')) {
        scrollBtn.classList.remove('hidden');
      }
    }
  }
}
