import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Params } from '@angular/router';

@Component({
  selector: 'app-button-border',
  templateUrl: './button-border.component.html',
  styleUrls: ['./button-border.component.scss'],
})
export class ButtonBorderComponent {
  @Input() text: string = '';
  @Input() classes: string =
    'w-full inline-block text-center font-montserrat font-bold px-6 py-3.5 border-2 border-primary-light rounded-button transition-all duration-200 bg-white hover:text-white hover:bg-secondary hover:border-secondary';
  @Input() type: 'button' | 'link' | 'submit' = 'button';
  @Input() href: string = '';
  @Input() public value: string | number | boolean = '';
  @Input() linkParams: Params = {};

  @Output() linkClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  public click($event: MouseEvent): void {
    this.linkClick.emit($event);
  }
}
