import { Pipe, PipeTransform } from '@angular/core';
import { ID } from '@datorama/akita';
import { GelaCartItem } from '../../services/cart/model/gela-cart-item.model';

@Pipe({ name: 'cartItemQuantitySum' })
export class CartItemQuantitySumPipe implements PipeTransform {
  transform(items: GelaCartItem[], id: ID): number {
    return items
      .filter((item) => item.sArticleID === id)
      .reduce((value, item) => {
        return value + item.decQuantityOrdered;
      }, 0);
  }
}
