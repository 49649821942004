import { GelaArticle } from '../catalog/model/gela-article';
import { CustomerArticleInterface } from '../customer-article/customer-article.interface';

export class NumberService {
  static roundNumber(unroundedNumber: number, options?: { roundTo?: number; direction?: string }): number {
    let roundedNumber = 0;
    if (options?.roundTo) {
      const factor = 1 / options.roundTo;

      if (options.direction?.toUpperCase() === 'UP' || options.direction?.toUpperCase() === 'CEIL') {
        roundedNumber = Math.ceil(unroundedNumber * factor) / factor;
      } else if (options.direction?.toUpperCase() === 'DOWN' || options.direction?.toUpperCase() === 'FLOOR') {
        roundedNumber = Math.floor(unroundedNumber * factor) / factor;
      } else {
        roundedNumber = Math.round(unroundedNumber * factor) / factor;
      }

      if (options.roundTo == 0.99) roundedNumber = this.enventaCustomRounding(unroundedNumber);

      return roundedNumber < options.roundTo ? options.roundTo : roundedNumber;
    }

    if (options?.direction?.toUpperCase() === 'UP' || options?.direction?.toUpperCase() === 'CEIL') {
      roundedNumber = Math.ceil(unroundedNumber);
    } else if (options?.direction?.toUpperCase() === 'DOWN' || options?.direction?.toUpperCase() === 'FLOOR') {
      roundedNumber = Math.floor(unroundedNumber);
    } else {
      roundedNumber = Math.round(unroundedNumber);
    }
    return roundedNumber;
  }

  static getRoundTo(article: GelaArticle | CustomerArticleInterface): number {
    if (article.shtRoundUnit) {
      switch (article.shtRoundUnit) {
        case 0:
          return 0;
        case 1:
          return 0.05;
        case 2:
          return 0.1;
        case 3:
          return 0.99;
        case 4:
          return 1;
        case 5:
          return 5;
        case 6:
          return 10;
        case 7:
          return 0.01;
        case 8:
          return 0.5;
        case 9:
          return 1;
      }
    }
    return 0;
  }

  private static enventaCustomRounding(unroundedNumber: number) {
    let result = unroundedNumber;

    if (unroundedNumber) {
      const modulo = (unroundedNumber * 10) % 10;
      if (unroundedNumber > 0) {
        if (modulo < 5) {
          result = Math.floor(unroundedNumber - 0.6) + 0.99;
        } else {
          result = Math.floor(unroundedNumber) + 0.99;
        }
      } else {
        if (modulo > -5) {
          result = Math.trunc(unroundedNumber + 0.6) - 0.99;
        } else {
          result = Math.trunc(unroundedNumber) - 0.99;
        }
      }
    }
    return result;
  }
}
