import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CustomerArticleNoteState, CustomerArticleNoteStore } from './customer-article-note.store';

@Injectable({
  providedIn: 'root',
})
export class CustomerArticleNoteQuery extends QueryEntity<CustomerArticleNoteState> {
  constructor(protected override store: CustomerArticleNoteStore) {
    super(store);
  }
}
