import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { LabelPrintModule, MapperAbstract, TemplateRendererFactory } from '@lobos/label-print-v2';
import {
  AuthModule,
  BacklogModule,
  CartLocalService,
  CartModule,
  CartService,
  CatalogModule,
  CatalogQuery,
  CoreModule,
  CrudModule,
  ElasticsearchModule,
  ErrorModule,
  FavoriteModule,
  GoogleTagManagerAdapterModule,
  HttpStatusErrorFilter,
  InjectorClass,
  PageMetaModule,
  ParameterModule,
  ProductService,
  SalesCreditNoteModule,
  SalesDocumentModule,
  SalesOfferModule,
  SalesOrderModule,
  ScannerAdapterAbstract,
  ScannerModule,
  SentryModule,
  StrapiCmsModule,
} from '@lobos/library-v2';
import { ZbarAdapter, ZbarConfig, ZbarSymbologyEnum } from '@lobos/scanner-adapter-zbar-v2';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { CookieModule } from 'ngx-cookie';
import { MarkdownModule } from 'ngx-markdown';
import { defaultSimpleModalOptions, SimpleModalModule } from 'ngx-simple-modal';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { CustomToastComponent } from './components/custom-toast/custom-toast.component';
import { HookHandlerModule } from './hooks/hook-handler/hook-handler.module';
import { HookTriggerModule } from './hooks/hook-trigger/hook-trigger.module';
import { BrowserStateInterceptor } from './interceptors/browser-state-interceptor';
import { PipesModule } from './pipes/pipes.module';
import { GelaProductService } from './services/catalog/gela-product.service';
import { FavoriteMapper } from './services/label-print/mapper/favorite.mapper';
import { GelaConfigurationExtraInterface } from './services/label-print/model/gela-configuration-extra.interface';
import { Gela3x10Renderer } from './services/label-print/renderer/gela3x10.renderer';
import { Gela3x10QrRenderer } from './services/label-print/renderer/gela3x10_qr.renderer';
import { Gela3x11Renderer } from './services/label-print/renderer/gela3x11.renderer';
import { Gela3x11QrRenderer } from './services/label-print/renderer/gela3x11_qr.renderer';
import { Gela3x17Renderer } from './services/label-print/renderer/gela3x17.renderer';
import { Gela3x17QrRenderer } from './services/label-print/renderer/gela3x17_qr.renderer';
import { Gela3x8Renderer } from './services/label-print/renderer/gela3x8.renderer';
import { Gela3x8QrRenderer } from './services/label-print/renderer/gela3x8_qr.renderer';
import { TranslocoRootModule } from './transloco-root.module';
import { registerLocaleData } from '@angular/common';
import * as deCH from '@angular/common/locales/de-CH';
import { GelaCatalogQuery } from './services/catalog/catalog.query';
import { GelaCartLocalService } from './services/cart/gela-cart-local.service';
import { GelaCartService } from './services/cart/gela-cart.service';
import { ClarityModule } from './clarity.module';

registerLocaleData(deCH.default);

@NgModule({
  declarations: [AppComponent],
  imports: [
    ErrorModule.forRoot({
      showOutput: !environment.production || environment.debugging,
      disableErrorHandler: environment.debugging,
      filters: [new HttpStatusErrorFilter(401), new HttpStatusErrorFilter(403)],
    }),
    CoreModule.forRoot({
      baseUrl: environment.host,
      branchKey: environment.api.branchKey,
      webshopId: environment.api.webshopId,
      mobileBreakpoint: '1279px',
      tabletBreakpoint: '990px',
    }),
    SentryModule.forRoot({
      active: environment.sentry.active,
      debug: environment.debugging,
      environment: environment.name,
      dsn: environment.sentry.dsn,
      release: environment.version,
    }),
    HookHandlerModule.forRoot(),
    ElasticsearchModule.forRoot({
      availableLanguages: environment.availableLang,
      defaultLanguage: environment.defaultLocale,
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      toastComponent: CustomToastComponent,
    }),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AuthModule.forRoot({
      unauthorizedRoute: environment.auth.routes.login,
      authorizedRoute: environment.auth.routes.home,
      apiUrl: environment.apiUrl,
    }),
    LabelPrintModule.forRoot({
      logoPath: '/assets/images/layout/geiser_logo.png',
      images: environment.images,
      mapper: {
        provide: MapperAbstract,
        useClass: FavoriteMapper,
      },
    }),
    AppRoutingModule,
    HttpClientModule,
    CartModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    FavoriteModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    TranslocoRootModule,
    TranslocoLocaleModule.forRoot({
      langToLocaleMapping: {
        en: 'en-US',
        de: 'de-CH',
        fr: 'fr-CH',
      },
    }),
    TranslocoMessageFormatModule.forRoot(),
    StrapiCmsModule.forRoot({ apiUrl: environment.cmsUrl }),
    ComponentsModule,
    BrowserTransferStateModule,
    PipesModule,
    PageMetaModule,
    ParameterModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    GoogleTagManagerAdapterModule.forRoot({ id: environment.tracking.gtm }),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    CatalogModule.forRoot({
      apiUrl: environment.host + environment.apiUrl,
      sortBy: 'relevance',
      viewMode: 'grid',
      pageIndex: 0,
      pageSize: 8,
    }),
    HookTriggerModule.forRoot(),
    CookieModule.forRoot(),
    SimpleModalModule.forRoot(
      { container: 'modal-container' },
      {
        ...defaultSimpleModalOptions,
        ...{
          closeOnEscape: true,
          closeOnClickOutside: true,
          autoFocus: true,
        },
      },
    ),
    MarkdownModule.forRoot(),
    SalesOfferModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    SalesOrderModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    SalesCreditNoteModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    SalesDocumentModule.forRoot({
      apiUrl: environment.apiUrl,
      useArchive: false,
    }),
    ScannerModule.forRoot<ZbarConfig>({
      adapter: {
        provide: ScannerAdapterAbstract,
        useClass: ZbarAdapter,
      },
      scanFrequency: 750,
      adapterConfig: {
        engineLocation: 'assets/wasm/',
        symbology: [ZbarSymbologyEnum.EAN13, ZbarSymbologyEnum.QR, ZbarSymbologyEnum.CODE128],
      },
    }),
    CrudModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    BacklogModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    ClarityModule.forRoot({
      enabled: environment.clarity.enabled,
      projectId: environment.clarity.id,
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: { baseUrl: environment.host + environment.apiUrl },
    },
    {
      provide: ProductService,
      useClass: GelaProductService,
    },
    {
      provide: CartLocalService,
      useClass: GelaCartLocalService,
    },
    {
      provide: CartService,
      useClass: GelaCartService,
    },
    {
      provide: CatalogQuery,
      useClass: GelaCatalogQuery,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-CH',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    labelPrintTemplateRendererFactory: TemplateRendererFactory<GelaConfigurationExtraInterface>,
    injector: Injector,
    gela3x8Renderer: Gela3x8Renderer,
    gela3x10Renderer: Gela3x10Renderer,
    gela3x11Renderer: Gela3x11Renderer,
    gela3x17Renderer: Gela3x17Renderer,
    gela3x8QrRenderer: Gela3x8QrRenderer,
    gela3x10QrRenderer: Gela3x10QrRenderer,
    gela3x11QrRenderer: Gela3x11QrRenderer,
    gela3x17QrRenderer: Gela3x17QrRenderer,
  ) {
    InjectorClass.set(injector);
    labelPrintTemplateRendererFactory.register(gela3x8Renderer);
    labelPrintTemplateRendererFactory.register(gela3x10Renderer);
    labelPrintTemplateRendererFactory.register(gela3x11Renderer);
    labelPrintTemplateRendererFactory.register(gela3x17Renderer);
    labelPrintTemplateRendererFactory.register(gela3x8QrRenderer);
    labelPrintTemplateRendererFactory.register(gela3x10QrRenderer);
    labelPrintTemplateRendererFactory.register(gela3x11QrRenderer);
    labelPrintTemplateRendererFactory.register(gela3x17QrRenderer);
  }
}
