import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { GelaArticle } from '../../services/catalog/model/gela-article';
import { ArticlePreviewModalComponent } from '../../features/catalog/catalog-components/article-preview-modal/article-preview-modal.component';
import { DownloadModalComponent } from '../../features/catalog/catalog-components/download-modal/download-modal.component';
import { CustomerArticleInterface } from '../../services/customer-article/customer-article.interface';

@Component({
  selector: 'app-article-mini-card',
  templateUrl: './article-mini-card.component.html',
  styleUrls: ['./article-mini-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleMiniCardComponent implements OnChanges {
  @Input() article: GelaArticle | CustomerArticleInterface | undefined;

  quantity: number = 1;

  constructor(private modal: SimpleModalService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['article']) {
      this.quantity =
        changes['article'].currentValue?.shtNoPackageBreak >= 1 ? changes['article'].currentValue?.decQuantityPackage || 1 : 1;
    }
  }

  openArticlePreview(article: GelaArticle): void {
    this.modal.addModal(ArticlePreviewModalComponent, { article }, { closeOnClickOutside: true });
  }

  openDownloadModal(article: GelaArticle): void {
    this.modal.addModal(DownloadModalComponent, { article }, { closeOnClickOutside: true, closeOnEscape: true });
  }

  quantityChanged(quantity: number) {
    this.quantity = quantity;
  }
}
