import { CartService, CreateCartItemInterface, HookEvent, HookHandlerAbstract, WorkflowUpdateHook } from '@lobos/library-v2';
import { Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { HandleQtyInput } from './util/cart-item-qty-input-handler-util';
import { Cart, CartHeader, CartItem } from '@lobos/common';

type type = WorkflowUpdateHook<'UpdateCartItemHook'>;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CartItemUpdatedQtyInputHandlerHandler extends HookHandlerAbstract<type, Observable<Cart<CartHeader, CartItem> | undefined>> {
  constructor(private modal: SimpleModalService, private cartService: CartService<CartHeader, CartItem, CreateCartItemInterface>) {
    super();
  }

  ids(): type[] {
    return [{ type: 'WorkflowUpdateHook', entity: 'UpdateCartItemHook' }];
  }

  override before(
    event: HookEvent<type, Observable<Cart<CartHeader, CartItem> | undefined>>,
    _: HookEvent<type, Observable<Cart<CartHeader, CartItem> | undefined>>,
  ): () => Observable<Cart<CartHeader, CartItem> | undefined> {
    const oCartItem: CreateCartItemInterface = event.params[0];
    const callable = event.callable;
    return HandleQtyInput(oCartItem, callable, this.modal, this.cartService, false);
  }
}
