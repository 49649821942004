import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { OutlineAgreementStore } from './outline-agreement.store';
import { OutlineAgreement } from './outline-agreement.interface';
import { PaginationResponseInterface } from '@lobos/library-v2';

@Injectable({
  providedIn: 'root',
})
export class OutlineAgreementService {
  constructor(private http: HttpClient, private store: OutlineAgreementStore) {}

  public getAll(filter: any): Observable<any> {
    let params: string = '';

    for (const [key, value] of Object.entries(filter)) {
      params += value ? `${params.length > 0 ? '&' : ''}${key}=${encodeURIComponent(value as string)}` : '';
    }

    return of(undefined).pipe(
      tap(() => this.store.setLoading(true)),
      switchMap(() => this.http.get<PaginationResponseInterface<OutlineAgreement>>(`api/outline-agreement${params ? '?' + params : ''}`)),
      tap((data: PaginationResponseInterface<OutlineAgreement>) => this.store.set(data.data)),
      finalize(() => this.store.setLoading(false)),
    );
  }

  public getById(contractId: number): Observable<any> {
    return of(undefined).pipe(
      tap(() => this.store.setLoading(true)),
      switchMap(() => this.http.get<OutlineAgreement>('api/outline-agreement/' + contractId)),
      tap((outlineAgreement: OutlineAgreement) => this.store.upsert(outlineAgreement.lngContractID, outlineAgreement)),
      finalize(() => this.store.setLoading(false)),
    );
  }

  public getOpenContractsForArticle(sArticleID: string): Observable<OutlineAgreement[]> {
    const url = `api/outline-agreement/open?sArticleID=${sArticleID}`;

    return this.http.get<OutlineAgreement[]>(url).pipe(
      catchError((error) => {
        console.error('An error occurred:', error);
        return throwError(error);
      }),
    );
  }
}
