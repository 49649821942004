import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Pipe({
  name: 'cmsRouterLinkActive',
})
export class CmsRouterLinkActivePipe implements PipeTransform {
  constructor(private location: Location, private router: Router) {}

  transform(link: string): Observable<boolean> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      startWith(false),
      map(() => this.location.path().substring(4).startsWith(link)),
    );
  }
}
