export enum HrefType {
  EMAIL = 'email',
  TEL = 'tel',
  ANCHOR = 'anchor',
  URL = 'url',
  PAGE = 'page',
}

export interface Href {
  type: HrefType;
  value: string;
}
