import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { OutlineAgreement } from './outline-agreement.interface';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OutlineAgreementState extends EntityState<OutlineAgreement> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'outline-agreement' })
export class OutlineAgreementStore extends EntityStore<OutlineAgreementState> {}
