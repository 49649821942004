import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { AuthService } from '@lobos/library-v2';
import { SimpleModalService } from 'ngx-simple-modal';
import { iif, Observable, ReplaySubject, Subject, switchMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NoteEditModalComponent } from '../../features/profile/profile-features/notes/notes-components/note-edit-modal/note-edit-modal.component';
import { GelaArticle } from '../../services/catalog/model/gela-article';
import { CustomerArticleNoteInterface } from '../../services/customer/customer-article-note/customer-article-note.interface';
import { CustomerArticleNoteQuery } from '../../services/customer/customer-article-note/customer-article-note.query';
import { CustomerArticleNoteService } from '../../services/customer/customer-article-note/customer-article-note.service';

@Component({
  selector: 'app-article-note',
  templateUrl: './article-note.component.html',
  styleUrls: ['./article-note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleNoteComponent implements OnChanges {
  @Input() button: boolean = true;
  @Input() title: string = '';
  @Input() article: GelaArticle | undefined;
  @Input() mini: boolean = false;
  @Input() search: boolean = false;

  public isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  public article$: Subject<GelaArticle | undefined> = new ReplaySubject(1);
  public note$: Observable<CustomerArticleNoteInterface | undefined> = this.article$.asObservable().pipe(
    filter((article: GelaArticle | undefined) => !!article),
    map((article: GelaArticle | undefined) => article!),
    switchMap((article: GelaArticle) =>
      iif(
        () => this.query.getHasCache(),
        // true
        this.query.selectAll(),
        // false
        this.service.getAll().pipe(switchMap(() => this.query.selectAll())),
      ).pipe(
        map((notes: CustomerArticleNoteInterface[]) => notes.find((note: CustomerArticleNoteInterface) => note.id === article.sArticleID)),
      ),
    ),
  );

  constructor(
    private query: CustomerArticleNoteQuery,
    private service: CustomerArticleNoteService,
    private modal: SimpleModalService,
    private authService: AuthService,
  ) {}

  public ngOnChanges(): void {
    this.article$.next(this.article);
  }

  public open(note: CustomerArticleNoteInterface): void {
    this.modal.addModal(NoteEditModalComponent, { note });
  }

  public create(): void {
    this.modal.addModal(NoteEditModalComponent, {
      note: {
        id: this.article?.sArticleID,
        article: this.article,
        note: '',
      },
    });
  }
}
