import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CmsMenu } from './interfaces/menu.interface';
import { CmsPage } from './interfaces/cms-page.interface';
import { Footer, FooterNav, ServiceNav } from './interfaces/cms-static.interface';

export interface CmsContentState<T extends CmsPage = CmsPage> extends EntityState<T> {
  mainMenu: CmsMenu[];
  footerMenu: CmsMenu[];
  footer: Footer[];
  topNav: ServiceNav[];
  footerNav: FooterNav[];
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cms-store', resettable: true, idKey: 'uuid' })
export class CmsStore<T extends CmsPage = CmsPage> extends EntityStore<CmsContentState<T>> {
  constructor() {
    super({
      mainMenu: undefined,
      footerMenu: undefined,
      footer: undefined,
      topNav: undefined,
      footerNav: undefined,
    });
  }
}
