import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { CmsQuery } from 'src/app/services/cms/cms.query';
import { GelaCatalog } from '../../services/catalog/model/gela-catalog';
import { CmsMenu } from '../../services/cms/interfaces/menu.interface';
import { environment } from '../../../environments/environment';
import { GelaCatalogQuery } from '../../services/catalog/catalog.query';

export interface NavigationInterface {
  title: string;
  url: string;
  specialClass?: string;

  isOpen?: boolean;

  subNavigation?: NavigationInterface[];
}

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  categoryNavOpen = false;
  catalogFirstLevel$: Observable<GelaCatalog[]> = this.catalogQuery.selectCategoriesByParentGroupId$(environment.rootLevel);
  catalogFirstLevelFlyout$: Observable<GelaCatalog[]> = this.catalogQuery.selectCategoriesByParentGroupIdFlyout$(environment.rootLevel);
  cmsMainMenu$: Observable<CmsMenu[]> | undefined = this.cmsQuery.cmsMainMenu$;

  constructor(private catalogQuery: GelaCatalogQuery, private cmsQuery: CmsQuery) {}
}
