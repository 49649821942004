import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthService } from '@lobos/library-v2';
import { auditTime, BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { GelaArticle } from '../../services/catalog/model/gela-article';
import { StockAvailability } from '@lobos/common';
import { GelaStockAvailabilityService } from '../../services/stock-availability/gela-stock-availability.service';
import { GelaStockAvailabilityQueryInterface } from '../../services/stock-availability/gela-stock-availability-query.interface';
import { AvailableStatus } from './article-availability-icon/article-availability-icon.component';

@Component({
  selector: 'app-article-availability',
  templateUrl: './article-availability.component.html',
  styleUrls: ['./article-availability.component.scss'],
})
export class ArticleAvailabilityComponent implements OnInit, OnChanges {
  @Input() font: string = 'font-bold';
  @Input() showButton: boolean = true;
  @Input() quantity: number = 0;
  @Input() deliveryDate: number = 0;
  @Input() shtStorageID: number | null = 0;
  public authUser$ = this.authService.authUser$;
  public stockAvailability$: Observable<StockAvailability | undefined> | undefined;
  protected readonly AvailableStatus = AvailableStatus;
  private articleChanged$: BehaviorSubject<GelaArticle | undefined> = new BehaviorSubject<GelaArticle | undefined>(undefined);
  private gelaStockAvailabilityQuery: GelaStockAvailabilityQueryInterface = {
    sArticleID: '',
    decQuantity: 0,
    dtDeliveryDate: 0,
    shtStorageID: 0,
  };

  constructor(protected stockAvailabilityService: GelaStockAvailabilityService, private authService: AuthService) {}

  public _article: GelaArticle | undefined;

  get article(): GelaArticle | undefined {
    return this._article;
  }

  @Input() set article(value: GelaArticle | undefined) {
    if (value !== undefined) {
      this._article = value;
      this.gelaStockAvailabilityQuery.sArticleID = value.sArticleID!;
    }

    this.articleChanged$.next(value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('quantity' in changes || 'deliveryDate' in changes) {
      this.gelaStockAvailabilityQuery.decQuantity = this.quantity; // Update quantity
      this.gelaStockAvailabilityQuery.dtDeliveryDate = this.deliveryDate; // Update delivery date
      this.gelaStockAvailabilityQuery.shtStorageID = this.shtStorageID; // Update storage ID
      // If quantity or deliveryDate change, trigger a re-fetch of stock availability
      this.articleChanged$.next(this._article);
    }
  }

  ngOnInit() {
    this.stockAvailability$ = this.articleChanged$.pipe(
      debounceTime(200),
      auditTime(200), // Use auditTime to react to changes in the value
      switchMap(() => this.stockAvailabilityService.get(this.gelaStockAvailabilityQuery)),
    );

    this.authUser$.subscribe(() => {
      // If user changes, trigger a re-fetch of stock availability
      this.articleChanged$.next(this._article);
    });
  }
}
