import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { CmsQuery } from '../../services/cms/cms.query';
import { Footer, FooterNav } from '../../services/cms/interfaces/cms-static.interface';
import { CmsMenu } from '../../services/cms/interfaces/menu.interface';
import { GelaCatalog } from '../../services/catalog/model/gela-catalog';
import { BreakpointService } from '@lobos/library-v2';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';
import { GelaCatalogQuery } from '../../services/catalog/catalog.query';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  public currentVersionKey$ = this.translocoService.langChanges$;

  constructor(
    private breakpointService: BreakpointService,
    @Inject(DOCUMENT) private _document: Document,
    private cmsQuery: CmsQuery,
    private catalogQuery: GelaCatalogQuery,
    private translocoService: TranslocoService,
  ) {}

  catalogFirstLevel$: Observable<GelaCatalog[]> = this.catalogQuery.selectCategoriesByParentGroupId$(environment.rootLevel);
  catalogFirstLevelFlyout$: Observable<GelaCatalog[]> = this.catalogQuery.selectCategoriesByParentGroupIdFlyout$(environment.rootLevel);

  footer$: Observable<Footer[]> = this.cmsQuery.footer$;

  footerNav$: Observable<FooterNav[]> = this.cmsQuery.footerNav$;

  cmsMainMenu$: Observable<CmsMenu[]> | undefined = this.cmsQuery.cmsMainMenu$;

  yearToday: number = new Date().getFullYear();

  isTablet$: Observable<boolean> = this.breakpointService.tablet;

  accordionToggle(event: Event) {
    const e: HTMLElement = event.currentTarget! as HTMLElement;

    if (e.querySelector('.js-accordion-wrap')?.classList.contains('max-h-0')) {
      const t = parseFloat(getComputedStyle(e.querySelector('.js-accordion-content')!).getPropertyValue('height')) + 56;
      e.querySelector('.js-accordion-wrap')?.classList.remove('max-h-0'),
        e.querySelector('.js-accordion-wrap')!.classList.add('pb-9'),
        e.querySelector('.js-accordion-wrap')!.classList.add('pt-5'),
        (e.querySelector<HTMLElement>('.js-accordion-wrap')!.style.maxHeight = t + 'px'),
        e.querySelector('.js-accordion-icon')!.classList.add('rotate-180');
    } else
      e.querySelector('.js-accordion-wrap')!.classList.add('max-h-0'),
        e.querySelector('.js-accordion-wrap')!.classList.remove('pb-9'),
        e.querySelector('.js-accordion-wrap')!.classList.remove('pt-5'),
        (e.querySelector<HTMLElement>('.js-accordion-wrap')!.style.maxHeight = ''),
        e.querySelector('.js-accordion-icon')!.classList.remove('rotate-180');
  }
}
