import { Component, Input } from '@angular/core';
import { AvailableStatus } from '../article-availability-icon/article-availability-icon.component';

@Component({
  selector: 'app-article-availability-text-addon',
  templateUrl: './article-availability-text-addon.component.html',
  styleUrls: ['./article-availability-text-addon.component.scss'],
})
export class ArticleAvailabilityTextAddonComponent {
  private _status: number = AvailableStatus.Default;
  get status(): number {
    return this._status;
  }

  @Input() set status(value: number) {
    if (value !== undefined) {
      this._status = value;
    }
  }
}
