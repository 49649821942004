<app-sales-rep-banner></app-sales-rep-banner>

<app-header></app-header>
<app-mobile-navbar (setActiveNav)="setActiveMobileNav($event)" [activeNav]="activeMobileNav"></app-mobile-navbar>
<main class="overflow-clip min-h-1150px">
  <ng-content></ng-content>
</main>

<app-scroll-top-button></app-scroll-top-button>

<!--<app-sales-rep-banner></app-sales-rep-banner>-->
<app-footer-mobile-navbar (setActiveNav)="setActiveMobileNav($event)" [activeNav]="activeMobileNav"></app-footer-mobile-navbar>
<app-footer></app-footer>
