<ng-container *ngIf="href; else noAnchor" [ngSwitch]="href.type">
  <ng-container *ngSwitchCase="hrefTypes.ANCHOR">
    <a [routerLink]="'/cms/' | urlLocation" [fragment]="href.value" [ngClass]="classes">
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="hrefTypes.EMAIL">
    <a [href]="'mailto:' + href.value" [title]="title || href.value" [ngClass]="classes">
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="hrefTypes.TEL">
    <a [href]="'tel:' + href.value" [title]="title || href.value" [ngClass]="classes">
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </a>
  </ng-container>
  <!-- todo -->
  <ng-container *ngSwitchCase="hrefTypes.URL">
    <a target="_blank" [href]="href.value" [title]="title || href.value" [ngClass]="classes">
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="hrefTypes.PAGE">
    <ng-container *ngIf="href.value.includes('shop/'); else cmsLink">
      <a [routerLink]="[href.value.replace('shop/', '') | localizeUrlPath]">
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
      </a>
    </ng-container>
    <ng-template #cmsLink>
      <a *ngIf="href.value" [routerLink]="'cms/' + href.value | localizeUrlPath" [title]="title || href.value" [ngClass]="classes">
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
      </a>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <a [href]="href.value" [title]="title || href.value" [ngClass]="classes">
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </a>
  </ng-container>
</ng-container>
<ng-template #noAnchor>
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</ng-template>

<ng-template #contentTpl>
  <ng-content></ng-content>
</ng-template>
