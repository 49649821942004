import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { ArticleService, ScannerAdapterAbstract } from '@lobos/library-v2';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { GelaArticle } from '../../../services/catalog/model/gela-article';
import { ScanNotificationService } from '../../../services/scanner/scan-notification.service';

@UntilDestroy()
@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
})
export class ScannerComponent extends SimpleModalComponent<void, void> implements OnInit, OnDestroy {
  @ViewChild('preview', { static: true })
  videoContainer: ElementRef<HTMLDivElement> | undefined;

  public scannerReady$: Observable<boolean> = this.scannerAdapter.ready();
  public articles: GelaArticle[] | undefined;

  public searchTerm: string = '';
  public quantity: number = 0;

  private startScan$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private scannerStream$: Observable<string> | undefined;
  private enabled: boolean = true;

  selectedCamera: MediaDeviceInfo | undefined;
  _cameras: MediaDeviceInfo[] | undefined;
  cameras$: Observable<{ key: string; value: string }[]> = this.getVideoDevices().pipe(
    tap((devices: MediaDeviceInfo[]) => (this._cameras = devices)),
    map((devices: MediaDeviceInfo[]) =>
      devices.map((device: MediaDeviceInfo) => ({
        key: device.deviceId,
        value: device.label,
      })),
    ),
  );

  constructor(
    private scannerAdapter: ScannerAdapterAbstract,
    private articleService: ArticleService<GelaArticle>,
    private scanNotification: ScanNotificationService,
    private router: Router,
    private dialog: SimpleModalService,
  ) {
    super();
    // when navigation happens while scanner is open
    // -> close the scanner
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => this.stop());

    this.startScan$
      .pipe(
        filter((start: boolean) => start),
        switchMap(() =>
          this.scannerStream$
            ? this.scannerStream$
            : (this.scannerStream$ = this.scannerAdapter.start(this.videoContainer?.nativeElement as HTMLDivElement)),
        ),
        catchError((error: Error) => of(!this.scannerAdapter.handleError(error))),
        filter((searchTerm: string | boolean) => !!searchTerm && this.enabled),
        tap(() => this.scanNotification.play()),
        tap(() => this.startScan$.next(false)),
        tap(() => (this.enabled = false)),
        mergeMap((searchTerm: string | boolean) => {
          const searchTermDeconstructed = searchTerm.toString().split('#');
          this.searchTerm = searchTermDeconstructed[0];
          this.quantity = +searchTermDeconstructed[1] || 0;

          return this.articleService.getArticleByMultiMatchQuery(this.searchTerm.replace(/\s/g, ''), ['sArticleID', 'sEAN']);
        }),
        untilDestroyed(this),
      )
      .subscribe((articles: GelaArticle[]) => (this.articles = articles));
  }

  public ngOnInit(): void {
    if (!this.videoContainer?.nativeElement) {
      return;
    }

    this.startScan$.next(true);
  }

  public rescan(): void {
    this.articles = undefined;
    this.startScan$.next(true);
    this.enabled = true;
  }

  public stop(): void {
    this.scannerStream$ = undefined;
    this.scannerAdapter.stop();
    this.startScan$.next(false);
    this.dialog.removeAll();
  }

  ngOnDestroy(): void {
    this.scannerAdapter.stop();
  }

  selectCamera(id: string | number) {
    this.selectedCamera = this._cameras?.find((camera: MediaDeviceInfo) => camera.deviceId === id);
    this.scannerStream$ = undefined;
    this.scannerAdapter.stop();
    this.startScan$.next(true);
  }

  public getVideoDevices(): Observable<MediaDeviceInfo[]> {
    return from(navigator.mediaDevices.enumerateDevices()).pipe(
      first(),
      map((devices: MediaDeviceInfo[]) => devices.filter((device: MediaDeviceInfo) => ['video', 'videoinput'].includes(device.kind))),
      map((devices: MediaDeviceInfo[]) =>
        devices.map((device: MediaDeviceInfo, index: number) => {
          const kind = 'videoinput';
          const deviceId = device.deviceId || (device as any).id;
          const label = device.label || `Video device ${index}`;
          const groupId = device.groupId;

          return { ...device, deviceId, label, kind, groupId };
        }),
      ),
    );
  }
}
