import { Pipe, PipeTransform } from '@angular/core';
import { Ressource } from '@lobos/common';

@Pipe({
  name: 'dangerSymbols',
})
export class DangerSymbolsPipe implements PipeTransform {
  transform(items: Ressource[] | undefined): Ressource[] {
    return items ? items.filter((item: Ressource) => item.shtResourceGroupIDInternal === 6).sort((a, b) => a.lngItemID - b.lngItemID) : [];
  }
}
