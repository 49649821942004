import { Pipe, PipeTransform } from '@angular/core';
import { GelaCartItem } from '../../services/cart/model/gela-cart-item.model';
import { Discount } from '@lobos/common';

@Pipe({
  name: 'hasDiscount',
})
export class HasDiscountPipe implements PipeTransform {
  transform(cartItem: GelaCartItem): boolean {
    for (let i = 1; i < 7; i++) {
      const discount = this.getDiscountObject(cartItem, i);
      if (discount.decDiscountPercent !== 0 || discount.decDiscountAmount !== 0) {
        return true;
      }
    }

    return false;
  }

  getDiscountObject(cartItem: GelaCartItem, i: number): Discount {
    return cartItem[('oDiscount' + i) as keyof GelaCartItem] as Discount;
  }
}
