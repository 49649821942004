<div *transloco="let t; scope: 'header'">
  <!-- flyout card start -->
  <div class="js-tile bg-white relative z-10 flex" *ngIf="product">
    <a
      focusable
      (click)="linkClicked.emit($event)"
      [routerLink]="[product.sUrlPath | catalogPath | localizeUrlPath]"
      class="absolute inset-0 rounded-20px"
    ></a>
    <!--      <div class="flex xl:block z-30 mb-2 xl:mb-0">-->
    <!--        &lt;!&ndash; @lobos if there is the auf mass button if needs the class left-10 otherwise it needs class left-0 &ndash;&gt;-->
    <!--        <div class="xl:absolute xl:top-14 2xl:top-6 xl:left-0 inline-block">-->
    <!--          <app-article-shop-info [article]="article" placement="inline"></app-article-shop-info>-->
    <!--        </div>-->
    <!--      </div>-->
    <app-image [image]="product | productMainImage" classes="w-20 xl:w-32 h-20 xl:h-32 object-contain object-center"></app-image>
    <div class="block basis-flyout-card-sml xl:basis-flyout-card pl-3">
      <div class="w-full flex absolute -top-6 2xl:top-0 left-0 2xl:relative z-20">
        <p
          class="w-flyout-cat text-small truncate"
          [innerHTML]="product.oCategoryLocalisations ? (getTranslatedCategoryLocalisation() | getCategoryFromBreadcrumb) : ''"
        ></p>
        <p class="text-small text-secondary ml-auto">{{ product.oArticles.length }} {{ t("header.search.articles") }}</p>
      </div>
      <div class="mt-0">
        <div class="text-small font-montserrat font-bold leading-tight">{{ product.sNavname | productNameReplace }}</div>
        <span class="font-source-sans font-bold line-clamp-2 xl:line-clamp-1" [innerHTML]="product.sTitle"> </span>
      </div>
      <div *ngIf="product.oArticles.length > 1" class="flex items-center mt-3 relative z-20 w-60 xl:w-64 leading-tight">
        <button
          (click)="linkClicked.emit($event)"
          class="w-full inline-block text-center font-montserrat font-bold px-6 py-3.5 border-2 border-primary-light rounded-button transition-all duration-200 bg-white hover:text-white hover:bg-secondary hover:border-secondary"
        >
          {{ t("header.search.item.multiple-variants") }}
        </button>
      </div>
    </div>
  </div>
  <!-- flyout card end -->
</div>
