import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormHelperService } from '../../services/form/form-helper.service';
import { AuthService, Catch, UrlHelperService } from '@lobos/library-v2';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent extends SimpleModalComponent<null, string | null> {
  public forgotPassword: boolean = false;
  public form: UntypedFormGroup = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  isLoading$ = new BehaviorSubject<boolean>(false);
  isLoadingRequestPassword$ = new BehaviorSubject<boolean>(false);

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private urlHelper: UrlHelperService,
    private formHelper: FormHelperService,
  ) {
    super();
  }

  toggleForgotPassword() {
    this.forgotPassword = !this.forgotPassword;
    this.formHelper.removeError(this.form, 'username', 'server');
    this.formHelper.removeError(this.form, 'password', 'server');
    this.form.controls['password'].reset();
  }

  @Catch({ label: 'LoginPageComponent.login()' })
  public async login(): Promise<void> {
    this.form.markAllAsTouched();
    this.formHelper.removeError(this.form, 'username', 'server');
    this.formHelper.removeError(this.form, 'password', 'server');

    if (!this.form.valid) {
      return;
    }

    this.isLoading$.next(true);

    const username: string = this.form.value.username;
    const password: string = this.form.value.password;

    try {
      await this.authService.login(username, password);
      //await this.router.navigate([this.urlHelper.localizeUrl('profile/my-profile')]);
      this.result = 'success';
      await this.close();
    } catch (exception: any) {
      if (exception.error.message && exception.error.message.match('inactive')) {
        this.formHelper.addError(this.form, 'username', { inactive: true });
        this.formHelper.addError(this.form, 'password', { inactive: true });
      } else {
        this.formHelper.addError(this.form, 'username', { server: true });
        this.formHelper.addError(this.form, 'password', { server: true });
      }
    } finally {
      this.isLoading$.next(false);
    }
  }

  @Catch({ label: 'LoginPageComponent.requestPassword()' })
  public async requestPassword(): Promise<void> {
    this.form.markAllAsTouched();
    this.formHelper.removeError(this.form, 'username', 'server');

    if (!this.form.controls['username'].valid) {
      return;
    }

    this.isLoadingRequestPassword$.next(true);

    const username: string = this.form.value.username;

    try {
      await this.authService.requestPassword(username);
      this.toggleForgotPassword();
    } catch (exception) {
      this.formHelper.addError(this.form, 'username', { server: true });
    } finally {
      this.isLoadingRequestPassword$.next(false);
    }
  }
}
