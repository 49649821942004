import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { AlreadyOrdered } from './already-ordered.interface';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AlreadyOrderedState extends EntityState<AlreadyOrdered> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'already-ordered', resettable: true, idKey: 'sArticleId' })
export class AlreadyOrderedStore extends EntityStore<AlreadyOrderedState> {}
