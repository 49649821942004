import { Pipe, PipeTransform } from '@angular/core';
import { SalesStatusInterface } from '../../services/sales/sales-status.interface';

@Pipe({
  name: 'getStatusName',
})
export class GetStatusNamePipe implements PipeTransform {
  transform(id: number, options: SalesStatusInterface[]): string {
    return options.find((option) => option.shtStatus === id)?.sStatusName || '';
  }
}
