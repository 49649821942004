<div class="sticky-container relative" [ngClass]="fullLength ? 'top-[260px]' : 'top-[220px]'">
  <div class="block sticky top-1/2 right-0 -translate-y-[60px] z-30">
    <div [ngClass]="fullLength ? '' : 'left-520px'" class="w-10 absolute h-20 top-1/2 -translate-y-1/2 overflow-hidden">
      <button
        (click)="scrollHandler($event)"
        class="js-table-scroll js-table-scroll--left hidden -translate-x-1/2 leading-none group transition-all duration-200"
      >
        <span
          class="ifont ifont--chevron-left text-3xl absolute top-1/2 right-2 -translate-y-1/2 group-hover:text-white pointer-events-none"
        ></span>
        <span class="bg-primary-light w-20 h-20 rounded-full inline-block group-hover:bg-secondary pointer-events-none"></span>
      </button>
    </div>
    <button
      (click)="scrollHandler($event)"
      class="js-table-scroll js-table-scroll--right absolute right-0 top-1/2 -translate-y-1/2 translate-x-1/2 leading-none group transition-all duration-200"
    >
      <span
        class="ifont ifont--chevron-right text-3xl absolute top-1/2 left-2 -translate-y-1/2 group-hover:text-white pointer-events-none"
      ></span>
      <span class="bg-primary-light w-20 h-20 rounded-full inline-block group-hover:bg-secondary pointer-events-none"></span>
    </button>
  </div>
</div>
