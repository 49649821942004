import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { FormControl, FormGroup } from '@angular/forms';
import { GelaProduct } from '../../../../services/catalog/model/gela-product';
import { HttpClient } from '@angular/common/http';
import { finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { GelaArticle } from '../../../../services/catalog/model/gela-article';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, forkJoin, iif, Observable, of } from 'rxjs';
import { AuthService, Catch, PriceQuery } from '@lobos/library-v2';
import { saveAs } from 'file-saver';
import { GelaPrice } from 'src/app/services/catalog/model/gela-price';
import { ArticleHelperService } from '../../../../services/catalog/article-helper.service';

interface ArticleData {
  article: GelaArticle;
  price: number;
  translations: {
    [key: string]: string;
  };
}

interface ProductData {
  product?: GelaProduct;
  articles: { article: GelaArticle; price: number }[];
  translations: { [key: string]: string };
}

interface DownloadForm {
  priceOption: FormControl<number>;
}

@Component({
  selector: 'app-download-modal',
  templateUrl: './download-modal.component.html',
  styleUrls: ['./download-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadModalComponent extends SimpleModalComponent<
  {
    product?: GelaProduct;
    article?: GelaArticle;
  },
  string | null
> {
  private static TRANSLATION_SCOPE: string = 'catalog';
  article: GelaArticle | undefined;
  product: GelaProduct | undefined;

  isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  isLoading: boolean = false;

  public form = new FormGroup<DownloadForm>({
    priceOption: new FormControl<number>(1, { nonNullable: true }),
    // priceOption: new FormControl<number>(this.isLoggedIn$ ? 1 : 3, { nonNullable: true }),
  });

  public priceSelection: { key: number; value: any }[] = [];

  constructor(
    private http: HttpClient,
    private translocoService: TranslocoService,
    private priceQuery: PriceQuery<GelaPrice>,
    private authService: AuthService,
    private changeRef: ChangeDetectorRef,
    private articleHelper: ArticleHelperService,
  ) {
    super();

    this.priceSelection = [
      {
        key: 1,
        value: this.translocoService.translate('download-modal.price.customer-price', {}, 'catalog'),
      },
      {
        key: 2,
        value: this.translocoService.translate('download-modal.price.suggested-price', {}, 'catalog'),
      },
      {
        key: 3,
        value: this.translocoService.translate('download-modal.price.no-price', {}, 'catalog'),
      },
    ];
  }

  submitDatasheet() {
    this.form.markAllAsTouched();
    if (!this.product && !this.article) {
      return;
    }

    if (!this.form.valid) {
      return;
    }

    this.isLoading = true;

    iif(() => !!this.article, this.buildArticleData(this.article!), this.buildProductData(this.product))
      .pipe(
        switchMap((data: ArticleData | ProductData | undefined) => this.http.post('cms/pdf.php', data, { responseType: 'blob' })),
        first(),
        tap((res) => {
          this.saveFileAs(res as any, `Datenblatt.pdf`);
        }),
        finalize(() => {
          this.isLoading = false;
          this.changeRef.markForCheck();
        }),
      )
      .subscribe();
  }

  saveFileAs(fileBlob: any, fileName: string) {
    const file = new Blob([fileBlob], { type: 'application/pdf' });
    saveAs(file, fileName);
  }

  changedPriceSelection(id: number) {
    this.form.controls.priceOption.patchValue(id);
  }

  @Catch({ label: 'DownloadModalComponent.buildProductData()' })
  buildProductData(product: GelaProduct | undefined): Observable<ProductData | undefined> {
    return this.isLoggedIn$.pipe(
      switchMap((isLoggedIn: boolean) => {
        const priceOption = isLoggedIn ? this.form.controls.priceOption.value : 3;

        return combineLatest([
          of(priceOption),
          this.translocoService.selectTranslateObject('download-modal.product.pdf', {}, DownloadModalComponent.TRANSLATION_SCOPE),
        ]).pipe(
          switchMap(([priceOption, translations]: [number, any]) => {
            if (!product?.oArticles) {
              return of(undefined);
            }
            return forkJoin(
              product.oArticles.map((article) =>
                forkJoin({
                  article: of(article),
                  price: this.getPriceForArticle(article, priceOption).pipe(first()),
                }),
              ),
            ).pipe(
              map((articleArray: any[]) => ({
                product: product,
                articles: {
                  ...articleArray,
                  oFeatures: this.article?.oFeatures.slice().sort((a, b) => a.sName.localeCompare(b.sName)),
                },
                translations,
              })),
            );
          }),
        );
      }),
    );
  }

  @Catch({ label: 'DownloadModalComponent.buildArticleData()' })
  buildArticleData(article: GelaArticle): Observable<ArticleData | undefined> {
    return this.isLoggedIn$.pipe(
      switchMap((isLoggedIn: boolean) => {
        const priceOption = isLoggedIn ? this.form.controls.priceOption.value : 3;

        return combineLatest([
          this.getPriceForArticle(article, priceOption),
          this.translocoService.selectTranslateObject('download-modal.article.pdf', {}, DownloadModalComponent.TRANSLATION_SCOPE),
        ]).pipe(
          map(([price, translations]: [number, any | undefined]) => {
            return {
              article: {
                ...article,
                oFeatures: article.oFeatures.slice().sort((a, b) => a.sName.localeCompare(b.sName)),
              },
              price,
              translations,
            };
          }),
        );
      }),
    );
  }

  getPriceForArticle(article: GelaArticle, priceOption: number): Observable<number> {
    return iif(
      () => this.priceQuery.hasEntity(article.sArticleID),
      this.priceQuery.selectEntity(article.sArticleID),
      this.articleHelper
        .getPriceFromApi(article.sArticleID.toString(), {
          articleId: article.sArticleID,
          unit: article.sQuantityUnitSales,
          currency: 'CHF',
          qty: article.lngSalesPriceUnit > 0 ? article.lngSalesPriceUnit : 1,
          factor1: article.decFactor1,
          factor2: article.decFactor2,
          factor3: article.decFactor3,
        })
        .pipe(
          first(),
          switchMap(() => this.priceQuery.selectEntity(article.sArticleID)),
        ),
    ).pipe(
      map((price: GelaPrice | undefined) => {
        if (!price) {
          return 0;
        }
        let priceValue = 0;
        switch (priceOption) {
          case 1:
            priceValue = price.decAmountNetFC;
            break;
          case 2:
            priceValue = price.decPL1RecommendeSalesPrice;
            break;
          default:
            priceValue = 0;
        }
        return priceValue;
      }),
    );
  }
}
