import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, InjectionToken, ModuleWithProviders, NgModule, PLATFORM_ID } from '@angular/core';

function clarityScript(projectId: string): string {
  return `(function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "${projectId}");`;
}

const _CLARITY_CONFIG_TOKEN = new InjectionToken<ClarityConfiguration>('clarity.config');

export type ClarityConfiguration = Readonly<{
  enabled: boolean;
  projectId: string;
}>;

@NgModule({})
export class ClarityModule {
  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) platformId: Object,
    @Inject(DOCUMENT)
    d: Document,
    @Inject(_CLARITY_CONFIG_TOKEN)
    { enabled, projectId }: ClarityConfiguration,
  ) {
    if (isPlatformBrowser(platformId) && enabled) {
      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.innerHTML = clarityScript(projectId);
      d.head.appendChild(s);
    }
  }

  static forRoot(config: ClarityConfiguration): ModuleWithProviders<ClarityModule> {
    return {
      ngModule: ClarityModule,
      providers: [
        {
          provide: _CLARITY_CONFIG_TOKEN,
          useValue: config,
        },
      ],
    };
  }
}
