import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from '@lobos/common';

@Pipe({
  name: 'contactList',
})
export class ContactListPipe implements PipeTransform {
  public transform(contacts: Contact[] | null): { key: string; value: string }[] {
    const normalizedContacts: { key: string; value: string }[] = [];
    if (!contacts?.length) {
      return normalizedContacts;
    }

    for (const contact of contacts) {
      normalizedContacts.push({
        key: contact.lngContactId.toString(),
        value: `${contact.sFirstName} ${contact.sLastName}`,
      });
    }

    return normalizedContacts;
  }
}
