import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@lobos/common';

@Pipe({
  name: 'urlReplace',
})
export class UrlReplacePipe implements PipeTransform {
  transform(url: string, user: User): string {
    if (!user) {
      return url;
    }

    const replaced = user.oCustomer.sMatchCode.replace('&', '');
    let check = replaced.toUpperCase().replace(/[A-Z]/g, '0');

    if (check.match(/^\d+$/)) {
      let sum = 0;
      for (let i = 0; i < check.length; i++) {
        sum += parseInt(check.charAt(i));
      }
      sum += replaced.length;
      check = sum.toString();
    }

    url = url.replace('<USERID>', user.oCustomer.sMatchCode.replace('&', ''));
    url = url.replace('<CHECK>', check);
    url = url.replace('<USERNAME>', user.sUserName);
    url = url.replace('<USERMAIL>', user.oContact.sEmail);

    return url;
  }
}
