import { Pipe, PipeTransform } from '@angular/core';
import { Post } from '../../services/cms/interfaces/cms-page.interface';

@Pipe({
  name: 'filterBlogCategory',
})
export class FilterBlogCategoryPipe implements PipeTransform {
  public transform(posts: Post[], selectedCategory: string | number): Post[] {
    return selectedCategory ? posts.filter((postEntry: Post) => postEntry.blogtags.includes(selectedCategory as string)) : posts;
  }
}
