import { Pipe, PipeTransform } from '@angular/core';
import { Article } from '@lobos/common';

type Link = {
  title: string;
  linkUrl: string;
};

@Pipe({
  name: 'articleInformation',
})
export class ArticleInformationPipe implements PipeTransform {
  transform(article: Article | undefined, articleInfoType: string, productInfoType: number): Link[] {
    if (!article || (!article.oReferenceLink && !article.oProductInfo)) {
      return [];
    }

    let links: Link[] = article.oReferenceLink
      .filter((item) => articleInfoType === item.sTypeCode)
      .map((item) => ({
        title: item.sDescription,
        linkUrl: item.sLinkReference,
      }));

    if (!links || links.length === 0) {
      links = [...article.oProductInfo[0].oResources]
        .sort((a, b) => a.lngItemID - b.lngItemID)
        .filter((item) => item.shtResourceGroupIDInternal === productInfoType)
        .map((item) => ({
          title: item.sResourceName,
          linkUrl: item.sHyperlinkUrl,
        }));
    }

    return links;
  }
}
