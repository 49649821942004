import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
  @Input()
  public title: string = '';

  @Input()
  public value: string | null = '';

  @Input()
  public closable: boolean = false;

  @Output()
  public closed: EventEmitter<boolean> = new EventEmitter<boolean>();
}
