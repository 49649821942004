import { Pipe, PipeTransform } from '@angular/core';
import { GelaArticle } from '../../services/catalog/model/gela-article';

@Pipe({
  name: 'ctaExpertArticle',
})
export class CtaExpertArticlePipe implements PipeTransform {
  public transform(article: GelaArticle): string | undefined {
    const articleExpert = article.oReferenceLink.find((reference) => reference.sTypeCode === 'expert');

    if (articleExpert) {
      return articleExpert.sLinkReference.replace('https://cms.gela.ch/de/', '');
    }

    const productExpert = article.oProductInfo[0].oResources?.find((resource) => resource.shtResourceGroupIDInternal === 5);

    if (productExpert) {
      return productExpert.sHyperlinkUrl.replace('https://cms.gela.ch/de/', '');
    }

    return undefined;
  }
}
