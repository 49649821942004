import { Pipe, PipeTransform } from '@angular/core';
import { GelaProduct } from '../../services/catalog/model/gela-product';

@Pipe({
  name: 'ctaExpertProduct',
})
export class CtaExpertProductPipe implements PipeTransform {
  public transform(product: GelaProduct): string | undefined {
    const productExpert = product.oResources?.find((resource) => resource.shtResourceGroupIDInternal === 5);

    if (productExpert) {
      return productExpert.sHyperlinkUrl.replace('https://cms.gela.ch/de/', '');
    }

    return undefined;
  }
}
