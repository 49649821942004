import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService, CartHeaderQuery, CartItemQuery, FavoriteItemQuery, FavoriteListQuery } from '@lobos/library-v2';
import { SimpleModalService } from 'ngx-simple-modal';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { filter, shareReplay, switchMap } from 'rxjs/operators';
import { ID } from '@datorama/akita';
import { GelaCartItem } from '../../services/cart/model/gela-cart-item.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ScannerHelperService } from '../../services/scanner/scanner-helper.service';
import { CartHeader, CartItem } from '@lobos/common';
import { GelaFavoriteItem } from '../../services/favorite/model/gela-favorite-item.model';

export enum MobileNavId {
  MENU = 'menu',
  FAVORITE = 'favorite',
  CART = 'cart',
  PROFILE = 'profile',
  NONE = 'none',
}

@UntilDestroy()
@Component({
  selector: 'app-footer-mobile-navbar',
  templateUrl: './footer-mobile-navbar.component.html',
  styleUrls: ['./footer-mobile-navbar.component.scss'],
})
export class FooterMobileNavbarComponent {
  @Output() setActiveNav: EventEmitter<MobileNavId> = new EventEmitter<MobileNavId>();
  @Input() activeNav: MobileNavId | undefined;
  public isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  public mobileNavId = MobileNavId;

  public activeCartItems$: Observable<CartItem[]> = this.cartHeaderQuery.selectActiveId().pipe(
    filter((id: ID | null | undefined) => !!id),
    switchMap((id: ID | null | undefined) => this.cartItemQuery.selectCartItems(id!)),
  );

  activeFavoriteItems$: Observable<GelaFavoriteItem[]> = this.favoriteListQuery.selectActiveId().pipe(
    filter((id: ID | null | undefined) => !!id),
    switchMap((id: ID | null | undefined) => this.favoriteItemQuery.selectFavoriteItems(id!)),
    shareReplay(1),
  );

  constructor(
    private authService: AuthService,
    private modal: SimpleModalService,
    private cartHeaderQuery: CartHeaderQuery<CartHeader>,
    private cartItemQuery: CartItemQuery<GelaCartItem>,
    public scannerHelper: ScannerHelperService,
    private favoriteListQuery: FavoriteListQuery,
    private favoriteItemQuery: FavoriteItemQuery<GelaFavoriteItem>,
  ) {}

  toggleNav(nav: MobileNavId): void {
    this.setActiveNav.emit(nav === this.activeNav ? MobileNavId.NONE : nav);
  }

  openLoginModal(): void {
    this.modal.addModal(LoginModalComponent, {}, { closeOnClickOutside: true });
  }
}
