<ng-container *transloco="let t; scope: 'shared'">
  <ng-container *ngIf="isLoggedIn$ | async">
    <ng-container *ngIf="{ note: note$ | async } as context">
      <app-flyout [title]="t('shared.article-note.title')">
        <button trigger *ngIf="button" class="js-show-flyout js-flyout-with-close group">
          <span
            class="ifont text-icon text-primary group-hover:text-gela-black transition-all duration-200"
            [class.ifont--has-notice]="!!context.note"
            [class.ifont--notice]="!context.note"
          ></span>
        </button>
        <app-link-icon
          trigger
          *ngIf="!button"
          class="js-show-flyout js-flyout-with-close group"
          [text]="title"
          [icon]="context.note ? 'ifont--has-notice' : 'ifont--notice'"
        ></app-link-icon>

        <ng-container flyout *ngIf="context.note as note">
          <p class="font-montserrat font-bold text-small">{{ note.user }}</p>
          <p class="text-primary text-small">{{ note.created | translocoDate }}</p>
          <div class="my-2.5 overflow-y-auto scrollbar" [class.max-h-48]="mini" [class.max-h-60.lg:max-h-full]="!mini">
            <p class="nl2br text-small pr-1.5 lg:pr-0" [class.js-scroll-notice]="mini">{{ note.note }}</p>
            <div class="mt-2">
              <app-link-text (click)="open(note)" [text]="t('shared.article-note.edit')" classes="js-open-notes-modal-edit"></app-link-text>
            </div>
          </div>
        </ng-container>
        <ng-container flyout *ngIf="!context.note">
          <app-link-text (click)="create()" [text]="t('shared.article-note.create')" classes="js-open-notes-modal-edit"></app-link-text>
        </ng-container>
      </app-flyout>
    </ng-container>
  </ng-container>
</ng-container>
