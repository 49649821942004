import { Injectable } from '@angular/core';
import { BehaviorSubject, iif, Observable, of } from 'rxjs';
import { GelaCatalog } from '../catalog/model/gela-catalog';
import { first, switchMap, tap } from 'rxjs/operators';
import { CatalogQuery, CatalogService } from '@lobos/library-v2';

@Injectable({ providedIn: 'root' })
export class MobileMenuService {
  private readonly _activeLevelId$: BehaviorSubject<GelaCatalog | null> = new BehaviorSubject<GelaCatalog | null>(null);
  public activeLevel$: Observable<GelaCatalog | null> = this._activeLevelId$.asObservable();

  constructor(private catalogQuery: CatalogQuery<GelaCatalog>, private catalogService: CatalogService<GelaCatalog>) {}

  public setActiveLevel(catalog: GelaCatalog | null): void {
    this._activeLevelId$.next(catalog);
  }

  public setActiveLevelById(id: string): void {
    of(id)
      .pipe(
        switchMap((id: string) => iif(() => this.catalogQuery.hasEntity(id), this.catalogQuery.selectEntity(id), of(null))),
        first(),
        tap((catalog: GelaCatalog | null | undefined) => this.setActiveLevel(catalog!)),
      )
      .subscribe();
  }
}
