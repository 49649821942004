<ng-container *transloco="let t; scope: 'shared'">
  <ng-container *ngIf="stockAvailability$ | async as stockAvailability; else loading">
    <ng-container *ngIf="authUser$ | async as authUser; else showIcons">
      <ng-container *ngIf="[0].includes(authUser.shtStockLevel) || !authUser.shtStockLevel; else availableState">
        <!-- shtStockLevel: 0  show nothing-->
        <div class="flex">
          <div class="flex items-center">
            <!-- nothing to show -->
          </div>
        </div>
      </ng-container>
      <ng-template #availableState>
        <ng-container *ngIf="[1, 2].includes(authUser.shtStockLevel); then showIcons; else showNumbers"></ng-container>

        <ng-template #showNumbers>
          <!-- shtStockLevel: 3,4,5,6,7 show icon and numbers -->
          <div class="flex mt-2">
            <div *ngIf="!showButton" class="flex">
              <div class="flex items-center">
                <app-article-availability-icon [status]="stockAvailability.shtStatus"></app-article-availability-icon>
                <app-article-availability-text
                  [stock]="stockAvailability"
                  [article]="_article"
                  [showNumbers]="true"
                ></app-article-availability-text>
              </div>
              <div class="flex flex-col">
                <app-article-availability-text-addon [status]="stockAvailability.shtStatus"></app-article-availability-text-addon>
              </div>
            </div>
            <app-flyout *ngIf="showButton" [closable]="false" [title]="t('shared.article-availability.title')" class="leading-none">
              <button class="js-show-flyout h-full leading-none relative" trigger>
                <app-article-availability-icon [status]="stockAvailability.shtStatus"></app-article-availability-icon>
              </button>
              <ng-container flyout>
                <div class="flex items-center">
                  <app-article-availability-icon [status]="stockAvailability.shtStatus"></app-article-availability-icon>
                  <app-article-availability-text
                    [stock]="stockAvailability"
                    [article]="_article"
                    [showNumbers]="true"
                  ></app-article-availability-text>
                </div>
                <div class="flex flex-col">
                  <app-article-availability-text-addon [status]="stockAvailability.shtStatus"></app-article-availability-text-addon>
                </div>
              </ng-container>
            </app-flyout>
          </div>
        </ng-template>
      </ng-template>
    </ng-container>
    <ng-template #showIcons>
      <!-- shtStockLevel: 1,2 show icon -->
      <div class="flex mt-2">
        <div *ngIf="!showButton">
          <div class="flex items-center">
            <app-article-availability-icon [status]="stockAvailability.shtStatus"></app-article-availability-icon>
            <app-article-availability-text
              [stock]="stockAvailability"
              [article]="_article"
              [showNumbers]="false"
            ></app-article-availability-text>
          </div>
          <!--app-article-availability-text-addon not shown -->
        </div>
        <app-flyout *ngIf="showButton" [closable]="false" [title]="t('shared.article-availability.title')" class="leading-none">
          <button class="js-show-flyout h-full leading-none relative" trigger>
            <app-article-availability-icon [status]="stockAvailability.shtStatus"></app-article-availability-icon>
          </button>
          <ng-container flyout>
            <div class="flex items-center">
              <app-article-availability-icon [status]="stockAvailability.shtStatus"></app-article-availability-icon>
              <app-article-availability-text
                [stock]="stockAvailability"
                [article]="_article"
                [showNumbers]="false"
              ></app-article-availability-text>
            </div>
            <div class="flex flex-col">
              <app-article-availability-text-addon [status]="stockAvailability.shtStatus"></app-article-availability-text-addon>
            </div>
          </ng-container>
        </app-flyout>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
<ng-template #loading>
  <div class="flex">
    <app-article-availability-icon [status]="AvailableStatus.Default"></app-article-availability-icon>
  </div>
</ng-template>
