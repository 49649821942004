<ng-container *transloco="let t; scope: 'catalog'">
  <ng-container *ngIf="isLoggedIn$ | async">
    <ng-container *ngIf="cartHeader$ | async as cartHeader">
      <app-flyout [title]="t('catalog.preferred-commission.title')" [flyoutPosition]="flyoutPosition">
        <app-link-icon
          *ngIf="!button"
          trigger
          [text]="t('catalog.preferred-commission.title')"
          [icon]="!!cartHeader.sPreferredCommission ? 'ifont--commission-filled' : 'ifont--commission'"
        ></app-link-icon>

        <button *ngIf="button" trigger class="js-show-flyout js-flyout-with-close group">
          <span
            [ngClass]="{
              'ifont--commission': !cartHeader.sPreferredCommission,
              'ifont--commission-filled': !!cartHeader.sPreferredCommission
            }"
            class="ifont text-icon text-primary group-hover:text-gela-black transition-all duration-200"
          ></span>
        </button>
        <p *ngIf="!!cartHeader.sPreferredCommission" flyout class="text-small pr-1.5 lg:pr-0">
          {{ t("catalog.preferred-commission.active-commission") }} <i>{{ cartHeader.sPreferredCommission }}</i>
        </p>
        <app-link-text
          *ngIf="item"
          flyout
          [text]="!!cartHeader.sPreferredCommission ? t('catalog.preferred-commission.change') : t('catalog.preferred-commission.add')"
          (click)="showModal(item, cartHeader)"
        ></app-link-text>
      </app-flyout>
    </ng-container>
  </ng-container>
</ng-container>
