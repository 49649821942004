import { Pipe, PipeTransform } from '@angular/core';
import { iif, Observable, of, switchMap } from 'rxjs';
import { GelaProductService } from '../../services/catalog/gela-product.service';
import { ProductQuery } from '@lobos/library-v2';
import { Product } from '@lobos/common';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'productUrl',
})
export class ProductUrlPipe implements PipeTransform {
  constructor(private productService: GelaProductService, private productQuery: ProductQuery) {}

  transform(productId: string): Observable<string> {
    return of(productId).pipe(
      switchMap((productNumber: string) =>
        iif(
          () => this.productQuery.hasEntity((product: Product) => product!.sNavname.startsWith(productNumber)),
          this.productQuery.selectEntity((product: Product) => product!.sNavname.startsWith(productNumber)),
          this.productService
            .getByNavName(productNumber!)
            .pipe(switchMap(() => this.productQuery.selectEntity((product: Product) => product!.sNavname.startsWith(productNumber)))),
        ),
      ),
      map((product: Product | undefined) => {
        if (!product) {
          return '';
        }

        if (Array.isArray(product.sUrlPath)) {
          return product.sUrlPath[0];
        } else {
          return product.sUrlPath;
        }
      }),
    );
  }
}
