import { AuthService, CartService, CreateCartItemInterface, HookEvent, HookHandlerAbstract, WorkflowCreateHook } from '@lobos/library-v2';
import { Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { Cart } from '@lobos/common';
import { HandleOutlineAgreement } from './util/cart-item-created-outline-agreement-handler-util';
import { OutlineAgreementService } from '../../../services/outline-agreement/outline-agreement.service';
import { CreateGelaCartItemInterface } from '../../../services/cart/model/create-gela-cart-item.interface';
import { GelaCartItem } from '../../../services/cart/model/gela-cart-item.model';
import { GelaCartHeader } from '../../../services/cart/model/gela-cart-header.model';

type type = WorkflowCreateHook<'CreateCartItemHook'>;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CartItemCreatedOutlineAgreementHandler extends HookHandlerAbstract<
  type,
  Observable<Cart<GelaCartHeader, GelaCartItem> | undefined>
> {
  constructor(
    private modal: SimpleModalService,
    private outlineAgreementService: OutlineAgreementService,
    private cartService: CartService<GelaCartHeader, GelaCartItem, CreateCartItemInterface>,
    private authService: AuthService,
  ) {
    super();
  }

  ids(): type[] {
    return [
      {
        type: 'WorkflowCreateHook',
        entity: 'CreateCartItemHook',
      },
    ];
  }

  override before(
    event: HookEvent<type, Observable<Cart<GelaCartHeader, GelaCartItem> | undefined>>,
    _: HookEvent<type, Observable<Cart<GelaCartHeader, GelaCartItem> | undefined>>,
  ): () => Observable<Cart<GelaCartHeader, GelaCartItem> | undefined> {
    const oCartItem: CreateGelaCartItemInterface = event.params[0];
    const callable = event.callable;
    if (!this.authService.isAuthorized()) {
      return () => callable([oCartItem]);
    }

    return () => HandleOutlineAgreement(oCartItem, callable, this.modal, this.cartService, this.outlineAgreementService);
  }
}
