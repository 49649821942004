import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MobileNavId } from '../../footer-mobile-navbar/footer-mobile-navbar.component';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { GelaCatalog } from '../../../services/catalog/model/gela-catalog';
import { CatalogQuery, CatalogService, ParameterQuery } from '@lobos/library-v2';
import { CmsQuery } from '../../../services/cms/cms.query';
import { TranslocoService } from '@ngneat/transloco';
import { CmsMenu } from '../../../services/cms/interfaces/menu.interface';
import { FooterNav } from '../../../services/cms/interfaces/cms-static.interface';
import { switchMap } from 'rxjs/operators';
import { MobileMenuService } from '../../../services/mobile-menu/mobile-menu.service';
import { environment } from '../../../../environments/environment';
import { LanguageHelperService } from '../../../services/language/language-helper.service';

@Component({
  selector: 'app-mobile-navigation-menu',
  templateUrl: './mobile-navigation-menu.component.html',
  styleUrls: ['./mobile-navigation-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNavigationMenuComponent {
  @Output() setActiveNav: EventEmitter<MobileNavId> = new EventEmitter<MobileNavId>();
  cmsMainMenu$: Observable<CmsMenu[]> | undefined = this.cmsQuery.cmsMainMenu$;
  footerNav$: Observable<FooterNav[]> = this.cmsQuery.footerNav$;

  activeLevel$: Observable<GelaCatalog | null> = this.mobileMenuService.activeLevel$;
  catalogFirstLevel$: Observable<GelaCatalog[]> = this.catalogQuery.selectCategoriesByParentGroupId$(environment.rootLevel);

  activeCatalogLevel$: Observable<GelaCatalog[]> = combineLatest([this.catalogFirstLevel$, this.mobileMenuService.activeLevel$]).pipe(
    switchMap(([firstLevel, activeCatalog]: [GelaCatalog[], GelaCatalog | null]) => {
      if (activeCatalog) {
        return iif(
          () => this.catalogQuery.getHasCategoriesByParentGroupId(activeCatalog!.lngGroup),
          this.catalogQuery.selectCategoriesByParentGroupId$(activeCatalog!.lngGroup),
          this.catalogService
            .getCatalogLevelByParentGroupId(String(activeCatalog!.lngGroup))
            .pipe(switchMap(() => this.catalogQuery.selectCategoriesByParentGroupId$(activeCatalog!.lngGroup))),
        );
      }

      return of(firstLevel);
    }),
  );

  constructor(
    private parameterQuery: ParameterQuery,
    private catalogQuery: CatalogQuery<GelaCatalog>,
    private cmsQuery: CmsQuery,
    private catalogService: CatalogService,
    public translocoService: TranslocoService,
    private mobileMenuService: MobileMenuService,
    private languageHelper: LanguageHelperService,
  ) {}

  closeMenu() {
    this.setActiveNav.emit(MobileNavId.NONE);
  }

  async setLanguage(lang: string) {
    await this.languageHelper.changeVersion(lang);
  }

  setActiveLevelId(catalog: GelaCatalog | null) {
    this.mobileMenuService.setActiveLevel(catalog);
  }

  setActiveLevelById(id: string) {
    this.mobileMenuService.setActiveLevelById(id);
  }
}
