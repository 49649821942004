import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { GelaArticle } from '../../../../services/catalog/model/gela-article';
import { DownloadModalComponent } from '../download-modal/download-modal.component';

@Component({
  selector: 'app-article-preview-modal',
  templateUrl: './article-preview-modal.component.html',
  styleUrls: ['./article-preview-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlePreviewModalComponent extends SimpleModalComponent<null, string | null> implements OnChanges {
  @Input() article: GelaArticle | undefined;

  quantity: number = 1;

  constructor(private modal: SimpleModalService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['article']) {
      this.quantity =
        changes['article'].currentValue?.shtNoPackageBreak >= 1 ? changes['article'].currentValue?.decQuantityPackage || 1 : 1;
    }
  }

  openDownloadModal(article: GelaArticle): void {
    this.modal.addModal(DownloadModalComponent, { article }, { closeOnClickOutside: true, closeOnEscape: true });
  }

  quantityChanged(quantity: number) {
    this.quantity = quantity;
  }
}
