import { Component, Input } from '@angular/core';
import { StockAvailability } from '@lobos/common';
import { GelaArticle } from '../../../services/catalog/model/gela-article';

@Component({
  selector: 'app-article-availability-text',
  templateUrl: './article-availability-text.component.html',
  styleUrls: ['./article-availability-text.component.scss'],
})
export class ArticleAvailabilityTextComponent {
  private _stock: StockAvailability = {
    iStatus: 0,
    sStatus: '',
    sStatusText: '',
    sStatusColor: '',
  } as unknown as StockAvailability;
  private _article: GelaArticle | undefined = {} as GelaArticle;
  private _showNumbers: boolean = false;

  get showNumbers(): boolean {
    return this._showNumbers;
  }

  @Input() set showNumbers(value: boolean) {
    if (value !== undefined) {
      this._showNumbers = value;
    }
  }

  get article(): GelaArticle | undefined {
    return this._article;
  }

  @Input()
  set article(value: GelaArticle | undefined) {
    this._article = value;
  }

  get stock(): StockAvailability {
    return this._stock;
  }

  @Input() set stock(value: StockAvailability) {
    if (value !== undefined) {
      this._stock = value;
    }
  }
}
