<ng-container *ngIf="form" [formGroup]="form">
  <textarea
    [rows]="rows"
    [name]="name"
    [placeholder]="placeholder"
    [required]="required"
    [formControlName]="name"
    class="w-full py-3 px-3.5 border-2 border-primary-light leading-tight rounded-default focus:ring-0 focus:border-primary placeholder:text-primary-dark"
    [class.border-error]="hasError"
    [class]="classes"
    [ngClass]="hasError ? 'border-secondary-dark' : 'border-primary-light'"
  ></textarea>
</ng-container>

<textarea
  [rows]="rows"
  *ngIf="!form"
  [name]="name"
  [required]="required"
  [placeholder]="placeholder"
  class="w-full py-3 px-3.5 border-2 border-primary-light leading-tight rounded-default focus:ring-0 focus:border-primary placeholder:text-primary-dark"
  [class.border-error]="hasError"
  [class]="classes"
  [(ngModel)]="value"
  [ngClass]="hasError ? 'border-secondary-dark' : 'border-primary-light'"
  (ngModelChange)="valueChangeDebouncer.next(value)"
></textarea>

<ng-container *ngIf="hasError && errorText">
  <span class="ifont ifont--form-error text-3xl absolute text-secondary-dark right-1 top-2.5"></span>
  <p class="text-secondary-dark text-small mt-1.5 leading-tight -mb-0.5">{{ errorText }}</p>
</ng-container>
