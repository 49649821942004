import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'addArticleAnchor',
})
export class AddArticleAnchorPipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {}

  public transform(text: string): string {
    let newText = text.replace(
      /(\d{2}\.\d{3}\.\d{2}[-+]|\d{2}\.\d{3}\.\B)/g,
      (x) =>
        `<a class="underline" href="${this.translocoService.getActiveLang()}/product/navname/p${x
          .replace(/\.|[+-]/g, '-')
          .replace(/\-$/g, '')
          .trim()}">${x}</a>`,
    );
    newText = newText.replace(
      /(\d{2}\.\d{3}\.\d{2})(?!\+|-)/gm,
      (x) => `<a class="underline" href="${this.translocoService.getActiveLang()}/article/${x.replace(/(.*?)[\.,<]$/g, '')}">${x}</a>`,
    );
    return newText;
  }
}
