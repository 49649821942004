import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-link-underline',
  templateUrl: './link-underline.component.html',
  styleUrls: ['./link-underline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkUnderlineComponent {
  @Input() text: string | undefined;
  @Input() href: string | undefined;
  @Input() fragment: string | undefined;
  @Input() classes: string = '';
  @Input() regular: boolean = false;
  @Input() samePageFragment: boolean = false;

  public samePageLocation = this.location.path();

  constructor(private location: Location) {}
}
