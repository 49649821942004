import { Component, Input } from '@angular/core';
import { GelaArticle } from '../../../services/catalog/model/gela-article';

@Component({
  selector: 'app-scanner-results',
  templateUrl: './scanner-results.component.html',
  styleUrls: ['./scanner-results.component.scss'],
})
export class ScannerResultsComponent {
  @Input() quantity: number = 0;
  @Input() articles: GelaArticle[] = [];
}
