import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { AuthService, CartHeaderQuery, CartItemQuery } from '@lobos/library-v2';
import { BehaviorSubject, Observable, switchMap, throwError } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { CommissionInterface } from './interfaces/commission.interface';
import { GelaCartItem } from './model/gela-cart-item.model';
import { HttpClient } from '@angular/common/http';
import { GelaCartHeader } from './model/gela-cart-header.model';

/**
 * Service to deliver all available commissions for the user
 * @TODO implement final api call, currently it is only mocked
 */
@Injectable({
  providedIn: 'root',
})
export class CommissionService {
  private commissions$ = new BehaviorSubject<CommissionInterface[]>([]);

  private activeCommissions$ = this.cartHeaderQuery.selectActiveId().pipe(
    filter((id: ID | null | undefined) => !!id),
    switchMap((id: ID | null | undefined) => this.cartItemQuery.selectCartItems(id!)),
    map((items: GelaCartItem[]) => items.map((item: GelaCartItem) => item.sPL1Commission)),
    map((commissionTitles: string[]) => [...new Set(commissionTitles.filter((title: string) => !!title))]),
    map((commissionTitles: string[]) => commissionTitles.map((title: string) => ({ sTitle: title }))),
  );

  constructor(
    private http: HttpClient,
    private cartHeaderQuery: CartHeaderQuery<GelaCartHeader>,
    private cartItemQuery: CartItemQuery<GelaCartItem>,
    private authService: AuthService,
  ) {
    this.authService.authUser$
      .pipe(
        filter((user) => !!user),
        distinctUntilChanged((a, b) => a?.sUserName === b?.sUserName),
        switchMap(() => this.loadCommission()),
      )
      .subscribe();

    this.cartHeaderQuery.selectActive().pipe(
      tap((activeCart) => {
        if (activeCart && activeCart.sPreferredCommission) {
          this.add(activeCart.sPreferredCommission);
        }
      }),
    );
  }

  public loadCommission() {
    return this.http.get<CommissionInterface[]>('api/order-commission').pipe(tap((response) => this.commissions$.next(response)));
  }

  public getAll(): Observable<CommissionInterface[]> {
    return this.commissions$.asObservable().pipe(
      map((commissions) => {
        const withoutDuplicates: CommissionInterface[] = [];
        commissions.forEach((acc) => {
          if (!withoutDuplicates.includes(acc)) {
            withoutDuplicates.push(acc);
          }
        });
        return withoutDuplicates;
      }),
    );
  }

  public getActive(): Observable<CommissionInterface[]> {
    return this.activeCommissions$;
  }

  public add(title: string): Observable<CommissionInterface[]> {
    if (this.find(title)) {
      return throwError(() => 'shared.error.commission.not-unique');
    }
    this.commissions$.next([...this.commissions$.getValue(), { sTitle: title, dtDate: Math.floor(Date.now() / 1000) }]);
    return this.getAll();
  }

  private find(title: string): CommissionInterface | undefined {
    return this.commissions$.getValue().find((c: CommissionInterface) => c.sTitle === title);
  }
}
