import { NgModule } from '@angular/core';
import { AddressSummaryPipe } from './address-summary/address-summary.pipe';
import { ArticleImagesPipe } from './article-images/article-images.pipe';
import { ArticleMainImagePipe } from './article-main-image/article-main-image.pipe';
import { ArticleManufacturerLogoPipe } from './article-manufacturer-logo/article-manufacturer-logo.pipe';
import { ContactListPipe } from './contact-list/contact-list.pipe';
import { CountryListPipe } from './country-list/country-list.pipe';
import { FacetOptionFilterPipe } from './facet-option-filter/facet-option-filter.pipe';
import { FilterListPipe } from './filter-list/filter-list.pipe';
import { GetErrorPipe } from './get-error/get-error.pipe';
import { HasErrorPipe } from './has-error/has-error.pipe';
import { LanguageHumanReadablePipe } from './language-human-readable/language-human-readable.pipe';
import { LanguageListPipe } from './language-list/language-list.pipe';
import { OrderByBooleanPipe } from './order-facet-option-by-boolean/order-by-boolean.pipe';
import { ProductFeaturesPipe } from './product-features/product-features.pipe';
import { ProductImagesPipe } from './product-images/product-images.pipe';
import { ProductMainImagePipe } from './product-main-image/product-main-image.pipe';
import { ProductManufacturerLogoPipe } from './product-manufacturer-logo/product-manufacturer-logo.pipe';
import { RoleHumanReadablePipe } from './role-human-readable/role-human-readable.pipe';
import { RoleListPipe } from './role-list/role-list.pipe';
import { SafeUrlPipe } from './safe-url/safe-url.pipe';
import { ShippingAddressListPipe } from './shipping-address-list/shipping-address-list.pipe';
import { ShippingTypeListPipe } from './shipping-type-list/shipping-type-list.pipe';
import { UrlFilterParamsPipe } from './url-filter-params/url-filter-params.pipe';
import { UrlPathPipe } from './url-path/url-path.pipe';
import { RouteActivePipe } from './route-active/route-active.pipe';
import { ArticleDocumentsPipe } from './article-documents/article-documents.pipe';
import { CartItemIsChildPipe } from './cart-item-is-child/cart-item-is-child.pipe';
import { CartItemIsMasterPipe } from './cart-item-is-master/cart-item-is-master.pipe';
import { GetValueFromKeyPipe } from './get-value-from-key/get-value-from-key.pipe';
import { ArticleBrandLinkPipe } from './article-brand-link/article-brand-link.pipe';
import { CatalogImagePipe } from './catalog-image/catalog-image.pipe';
import { ArticleFeaturesToKeyValuePipe } from './article-features-to-key-value/article-features-to-key-value.pipe';
import { GetFeatureValuePipe } from './get-feature-value/get-feature-value.pipe';
import { CatalogInfoPipe } from './catalog-info/catalog-info.pipe';
import { CmsCloudinaryImagePipe } from './cms-cloudinary-image/cms-cloudinary-image.pipe';
import { CloudinaryImagePipe } from './cloudinary-image/cloudinary-image.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { CmsUrlReplacePipe } from './cms-url-replace/cms-url-replace.pipe';
import { AddArticleAnchorPipe } from './add-article-anchor/add-article-anchor.pipe';
import { CmsRouterLinkActivePipe } from './cms-router-link-active/cms-router-link-active.pipe';
import { UrlLocationPipe } from './url-location/url-location.pipe';
import { CatalogPathPipe } from './catalog-path/catalog-path.pipe';
import { CmsRoleListPipe } from './cms-role-list/cms-role-list.pipe';
import { FilterTeamPipe } from './filter-team/filter-team.pipe';
import { ArticleInformationPipe } from './article-information/article-information.pipe';
import { CmsReadableRolePipe } from './cms-readable-role/cms-readable-role.pipe';
import { CatalogProductNameReplacePipe } from './catalog-product-name-replace/catalog-product-name-replace.pipe';
import { CmsBlogCategoryListPipe } from './cms-blog-category-list/cms-blog-category-list.pipe';
import { FilterBlogCategoryPipe } from './filter-blog-category/filter-blog-category.pipe';
import { DangerSymbolsPipe } from './danger-symbols/danger-symbols.pipe';
import { HasDiscountPipe } from './has-discount/has-discount.pipe';
import { GetStatusNamePipe } from './get-status-name/get-status-name.pipe';
import { PathLengthPipe } from './path-length/path-length.pipe';
import { CtaExpertArticlePipe } from './cta-export/cta-expert-article.pipe';
import { CtaExpertProductPipe } from './cta-export/cta-expert-product.pipe';
import { UrlReplacePipe } from './url-replace/url-replace.pipe';
import { TruckRouteDaysPipe } from './truck-route-days/truck-route-days.pipe';
import { GetCategoryFromBreadcrumbPipe } from './get-category-from-breadcrumb/get-category-from-breadcrumb.pipe';
import { HasArticleWithoutCommissionPipe } from './has-article-without-commission/has-article-without-commission.pipe';
import { SanitizeUrlPipe } from './sanitize-url/sanitize-url.pipe';
import { CartItemQuantitySumPipe } from './cart-item-quantity-sum/cart-item-quantity-sum.pipe';
import { ScannerDonePipe } from './scanner-done/scanner-done.pipe';
import { ReturnGelaArticleOrUndefinedPipe } from './return-gela-article-or-undefined/return-gela-article-or-undefined.pipe';
import { GetCustomerArticleByIdPipe } from './get-customer-article-by-id/get-customer-article-by-id.pipe';
import { GetDefaultCloudinaryImagePipe } from './get-default-cloudinary-image/get-default-cloudinary-image.pipe';
import { OrderByMs3Pipe } from './order-by-ms3/order-by-ms3.pipe';
import { GetPriceFromPriceScalePipe } from './get-price-from-price-scale/get-price-from-price-scale.pipe';
import { GetAmountForLongGuestCartItemPipe } from './get-amount-for-long-guest-cart-item/get-amount-for-long-guest-cart-item.pipe';
import { ProductUrlPipe } from './product-url/product-url.pipe';
import { IsALabelSelectedPipe } from './is-a-label-selected/is-a-label-selected.pipe';

const pipes: any[] = [
  GetErrorPipe,
  HasErrorPipe,
  AddressSummaryPipe,
  ShippingAddressListPipe,
  ShippingTypeListPipe,
  CountryListPipe,
  LanguageListPipe,
  RoleListPipe,
  ContactListPipe,
  LanguageHumanReadablePipe,
  RoleHumanReadablePipe,
  UrlPathPipe,
  UrlFilterParamsPipe,
  OrderByBooleanPipe,
  FacetOptionFilterPipe,
  ProductImagesPipe,
  ProductMainImagePipe,
  ArticleMainImagePipe,
  ArticleImagesPipe,
  RoleListPipe,
  SafeUrlPipe,
  FilterListPipe,
  ProductManufacturerLogoPipe,
  ProductFeaturesPipe,
  ArticleManufacturerLogoPipe,
  RouteActivePipe,
  ArticleDocumentsPipe,
  CartItemIsChildPipe,
  CartItemIsMasterPipe,
  GetValueFromKeyPipe,
  ArticleBrandLinkPipe,
  CatalogImagePipe,
  ArticleFeaturesToKeyValuePipe,
  GetFeatureValuePipe,
  CatalogInfoPipe,
  CmsCloudinaryImagePipe,
  SafeHtmlPipe,
  CmsUrlReplacePipe,
  CloudinaryImagePipe,
  AddArticleAnchorPipe,
  CmsRouterLinkActivePipe,
  UrlLocationPipe,
  CatalogPathPipe,
  CmsRoleListPipe,
  FilterTeamPipe,
  ArticleInformationPipe,
  CmsReadableRolePipe,
  CatalogProductNameReplacePipe,
  CmsBlogCategoryListPipe,
  FilterBlogCategoryPipe,
  DangerSymbolsPipe,
  HasDiscountPipe,
  GetStatusNamePipe,
  PathLengthPipe,
  CtaExpertArticlePipe,
  CtaExpertProductPipe,
  UrlReplacePipe,
  TruckRouteDaysPipe,
  GetCategoryFromBreadcrumbPipe,
  HasArticleWithoutCommissionPipe,
  SanitizeUrlPipe,
  CartItemQuantitySumPipe,
  ScannerDonePipe,
  ReturnGelaArticleOrUndefinedPipe,
  GetCustomerArticleByIdPipe,
  GetDefaultCloudinaryImagePipe,
  OrderByMs3Pipe,
  GetPriceFromPriceScalePipe,
  GetAmountForLongGuestCartItemPipe,
  ProductUrlPipe,
  IsALabelSelectedPipe,
];

@NgModule({
  declarations: pipes,
  imports: [],
  exports: pipes,
})
export class PipesModule {}
