import { Injectable } from '@angular/core';
import { ScannerAdapterAbstract } from '@lobos/library-v2';
import { SimpleModalService } from 'ngx-simple-modal';
import { ScanNotificationService } from './scan-notification.service';
import { catchError, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScannerComponent } from '../../components/scanner/scanner/scanner.component';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ScannerHelperService {
  constructor(
    private scannerAdapter: ScannerAdapterAbstract,
    private dialog: SimpleModalService,
    private scanNotification: ScanNotificationService,
  ) {}

  public openScanner(): void {
    this.scanNotification.prepare();
    this.scannerAdapter
      .askForPermission()
      .pipe(
        catchError((error: Error) => of(!this.scannerAdapter.handleError(error))),
        filter((result: boolean) => result),
        untilDestroyed(this),
      )
      .subscribe(() => this.dialog.addModal(ScannerComponent, undefined, { closeOnClickOutside: true }));
  }
}
