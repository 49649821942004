import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getCategoryFromBreadcrumb',
})
export class GetCategoryFromBreadcrumbPipe implements PipeTransform {
  public transform(breadcrumb: string): string {
    const categories = breadcrumb.split('/');
    return categories[categories.length - 2] ? categories[categories.length - 2] : '';
  }
}
