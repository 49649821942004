<div
  class="js-input relative w-full"
  (click)="startAnimation()"
  [class.js-has-error]="hasError"
  [ngClass]="{ 'opacity-50': form ? form.controls[name].disabled : disableField }"
>
  <!-- @lobos label will be animated on focus on input or on click on js-input, if input stays empty animation will be removed on focus out -->
  <label
    [for]="name"
    [ngClass]="[
      !!(form ? form.value[name] : value) || disableField ? 'top-0 scale-81.25' : 'top-1/2',
      hasError ? 'text-secondary-dark' : 'text-primary-dark'
    ]"
    class="js-label px-1 transition-all duration-200 absolute -translate-y-1/2 left-3 pointer-events-none origin-left leading-tight"
  >
    <span class="relative z-20 text-primary-dark">{{ label }} <ng-container *ngIf="required">*</ng-container></span>
    <span
      class="js-label-border w-full h-1 left-0 top-2.5 absolute"
      [class.bg-primary-bright]="grey && (hasFocus || (form ? form.value[name] : value))"
      [class.bg-white]="!grey"
    ></span>
  </label>

  <ng-container *ngIf="form" [formGroup]="form">
    <input
      id="{{ inputID }}"
      (keydown)="onKeyDown($event)"
      [type]="type"
      [step]="'any'"
      [name]="name"
      [attr.min]="min"
      [placeholder]="placeholder"
      [required]="required"
      [formControlName]="name"
      [class.border-error]="hasError"
      (focus)="startAnimation()"
      (blur)="startAnimation()"
      (focusout)="resetAnimation($event)"
      [disabled]="disableField"
      tabindex="0"
      class="w-full py-3.5 pr-3 pl-3.5 border-2 leading-tight rounded-default focus:ring-0 focus:border-primary placeholder:text-primary-dark"
      [ngClass]="hasError ? 'border-secondary-dark' : 'border-primary-light'"
    />
  </ng-container>

  <input
    *ngIf="!form"
    id="{{ inputID }}"
    (keydown)="onKeyDown($event)"
    [type]="type"
    [step]="'any'"
    [name]="name"
    [attr.min]="min"
    [placeholder]="placeholder"
    [required]="required"
    [(ngModel)]="value"
    (ngModelChange)="valueChangeDebouncer.next(value)"
    [class.border-error]="hasError"
    tabindex="0"
    (focusout)="resetAnimation($event)"
    (focus)="startAnimation()"
    (blur)="startAnimation()"
    [disabled]="disableField"
    class="w-full py-3.5 pr-3 pl-3.5 border-2 leading-tight rounded-default focus:ring-0 focus:border-primary placeholder:text-primary-dark"
    [ngClass]="hasError ? 'border-secondary-dark' : 'border-primary-light'"
  />
  <ng-container *ngIf="hasError">
    <span class="ifont ifont--form-error text-3xl absolute text-secondary-dark right-1 top-2.5"></span>
  </ng-container>
</div>
<ng-container *ngIf="hasError && errorText">
  <p class="text-secondary-dark text-small mt-1.5 leading-tight -mb-0.5">{{ errorText }}</p>
</ng-container>
