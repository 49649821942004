import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MobileNavId } from '../footer-mobile-navbar/footer-mobile-navbar.component';

@Component({
  selector: 'app-mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  styleUrls: ['./mobile-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNavbarComponent {
  @Input() activeNav: MobileNavId | undefined;
  @Output() setActiveNav: EventEmitter<MobileNavId> = new EventEmitter<MobileNavId>();
  public mobileNavId = MobileNavId;
}
