import { Pipe, PipeTransform } from '@angular/core';
import { CatalogHelperService } from '../../services/catalog/catalog-helper.service';

@Pipe({
  name: 'catalogPath',
})
export class CatalogPathPipe implements PipeTransform {
  constructor(private catalogHelperService: CatalogHelperService) {}

  public transform(path: string | string[]): string {
    return this.catalogHelperService.getCatalogPathByLocation(path);
  }
}
