<a
  *ngIf="href"
  [routerLink]="href"
  [attr.data-href]="attr"
  [title]="text"
  class="font-montserrat font-bold text-primary text-small relative pb-1 group hover:text-primary-dark transition-all duration-200"
  [ngClass]="classes"
>
  {{ text }}
  <span class="w-full h-3px bg-primary absolute left-0 bottom-0 group-hover:bg-primary-dark transition-all duration-200"></span>
</a>

<button
  *ngIf="!href"
  [attr.data-href]="attr"
  [title]="text"
  class="font-montserrat font-bold text-primary text-small relative pb-1 group hover:text-primary-dark transition-all duration-200"
  [ngClass]="classes"
>
  {{ text }}
  <span class="w-full h-3px bg-primary absolute left-0 bottom-0 group-hover:bg-primary-dark transition-all duration-200"></span>
</button>
