import { Injectable } from '@angular/core';
import { LabelData, Template } from '@lobos/label-print-v2';
import { Gela3x8QrRenderer } from './gela3x8_qr.renderer';

@Injectable({
  providedIn: 'root',
})
export class Gela3x17QrRenderer extends Gela3x8QrRenderer {
  public override template(): Template {
    return {
      id: 'gela3x17Qr',
      title: 'profile.label-print.domain.template.suedo3x17_qr.title',
      format: { width: 210, height: 297 },
      boxSizes: { width: 70, height: 16.9 },
      orientation: 'p',
      numberOfBoxes: { horizontal: 3, vertical: 17 },
    };
  }

  override async addDescription(
    x: number,
    y: number,
    labelData: LabelData,
    extraPaddingX: number = 0,
    fontSize: number = 7,
  ): Promise<void> {
    await super.addDescription(x, y - 2.5, labelData, extraPaddingX, fontSize - 2);
  }

  override async addQuantity(x: number, y: number, labelData: LabelData, extraPaddingX: number = 0, fontSize: number = 6): Promise<void> {
    await super.addQuantity(x, y - 1, labelData, extraPaddingX, fontSize - 1);
  }

  override async drawImage(x: number, y: number, labelData: LabelData, extraPaddingX: number = 0): Promise<void> {
    // Image
    if (this.configuration!.extra?.hideImage) {
      const paddingBox = this.addPadding(x + extraPaddingX, y + 1, this.boxWidth * (1 / 4), this.boxHeight * (3 / 5));
      await this.addImage(labelData.imgPath!, paddingBox.x, paddingBox.y, paddingBox.width, paddingBox.height);
    }
  }

  override async addQrCode(code: string, x: number, y: number, maxWidth: number, maxHeight: number): Promise<void> {
    return super.addQrCode(code, x - 2, y, maxWidth, maxHeight);
  }
}
