import { Pipe, PipeTransform } from '@angular/core';
import { ShippingType } from '@lobos/common';

@Pipe({
  name: 'shippingTypeList',
})
export class ShippingTypeListPipe implements PipeTransform {
  public transform(types: ShippingType[] | null): { key: string | number; value: string }[] {
    const normalizedTypes: { key: string | number; value: string }[] = [];
    if (!types?.length) {
      return normalizedTypes;
    }

    for (const type of types) {
      normalizedTypes.push({
        key: type.shtShippingTypeID,
        value: type.sNameFL || type.sName,
      });
    }

    return normalizedTypes;
  }
}
