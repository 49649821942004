import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlyoutPosition } from '../flyout/flyout.component';
import { BehaviorSubject, iif, Observable, switchMap } from 'rxjs';
import { AuthService } from '@lobos/library-v2';
import { Article } from '@lobos/common';
import { AlreadyOrdered } from '../../services/already-ordered/already-ordered.interface';
import { AlreadyOrderedQuery } from '../../services/already-ordered/already-ordered.query';
import { AlreadyOrderedService } from '../../services/already-ordered/already-ordered.service';

@Component({
  selector: 'app-article-ordered',
  templateUrl: './article-ordered.component.html',
  styleUrls: ['./article-ordered.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleOrderedComponent {
  @Input() button: boolean = true;

  @Input() flyoutPosition: FlyoutPosition = 'left';

  private _article: Article | undefined;

  get article(): Article | undefined {
    return this._article;
  }

  @Input() set article(value: Article | undefined) {
    this._article = value;
    this.articleChanged$.next(value);
  }

  isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;

  private articleChanged$: BehaviorSubject<Article | undefined> = new BehaviorSubject<Article | undefined>(undefined);

  oAlreadyOrdered$: Observable<AlreadyOrdered | undefined> = this.articleChanged$.pipe(
    switchMap(() =>
      iif(
        () => this.alreadyOrderedQuery.hasEntity(this.article?.sArticleID),
        this.alreadyOrderedQuery.selectEntity(this.article?.sArticleID),
        this.alreadyOrderedService
          .getForArticle(this.article!.sArticleID)
          .pipe(switchMap(() => this.alreadyOrderedQuery.selectEntity(this.article?.sArticleID))),
      ),
    ),
  );

  constructor(
    private authService: AuthService,
    private alreadyOrderedQuery: AlreadyOrderedQuery,
    private alreadyOrderedService: AlreadyOrderedService,
  ) {}
}
