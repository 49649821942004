import { Injectable } from '@angular/core';
import { AuthChangedHook, ErrorService, FavoriteService, HookEvent, HookHandlerAbstract, switchTap } from '@lobos/library-v2';
import { iif, Observable, of } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { FavoriteItem, FavoriteList, User } from '@lobos/common';

type type = AuthChangedHook;

/**
 * When the user logs in and has no active favorite list (`gActiveBookmarkListID`),
 * activate the most recent list. If there is no list at all, create a new empty list.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthorizedActivateFavoriteListHandler extends HookHandlerAbstract<type, Observable<User>> {
  constructor(private errorService: ErrorService, private favoriteService: FavoriteService<FavoriteList, FavoriteItem>) {
    super();
  }

  ids(): type[] {
    return [{ type: 'AuthChangedHook' }];
  }

  override after(event: HookEvent<type, Observable<User>>, result: Observable<User>): Observable<User> {
    return super.after(event, result).pipe(
      switchTap<User>((user?: User) =>
        iif(
          // no user, or user `gActiveBookmarkListID` is already set
          () => !user || !!user.gActiveBookmarkListID,
          // true
          of(user),
          // false
          this.favoriteService.activateRecentOrCreateNew().pipe(
            catchError((error: Error) =>
              of(
                this.errorService.add({
                  label: 'AuthorizedActivateFavoriteList.after()',
                  ...error,
                  error,
                }),
              ),
            ),
            first(),
          ),
        ),
      ),
    );
  }
}
