import { Injectable } from '@angular/core';
import { Catalog } from '@lobos/common';
import { GelaCatalog } from './model/gela-catalog';
import { CatalogQuery, CatalogStore } from '@lobos/library-v2';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GelaCatalogQuery extends CatalogQuery<GelaCatalog> {
  constructor(protected override store: CatalogStore<GelaCatalog>) {
    super(store);
  }

  override selectCategoriesByParentGroupId$(parentGroupId: number | string): Observable<GelaCatalog[]> {
    return this.selectAll({
      filterBy: ({ lngParentGroup, shtDisplayShop }) =>
        lngParentGroup === parentGroupId && (shtDisplayShop === undefined || shtDisplayShop === '1'),
      sortBy: (category1: Catalog, category2: Catalog) => {
        if (category1.lngItemId !== category2.lngItemId) {
          return category1.lngItemId - category2.lngItemId;
        }

        return category1.sTitle.toLowerCase().localeCompare(category2.sTitle.toLowerCase());
      },
    });
  }

  selectCategoriesByParentGroupIdFlyout$(parentGroupId: number | string): Observable<GelaCatalog[]> {
    return super.selectAll({
      filterBy: ({ lngParentGroup, shtDisplayShop }) =>
        lngParentGroup === parentGroupId && (shtDisplayShop === undefined || shtDisplayShop === '1' || shtDisplayShop === '4'),
      sortBy: (category1: Catalog, category2: Catalog) => {
        if (category1.lngItemId !== category2.lngItemId) {
          return category1.lngItemId - category2.lngItemId;
        }

        return category1.sTitle.toLowerCase().localeCompare(category2.sTitle.toLowerCase());
      },
    });
  }

  override getCategoriesByParentGroupId(parentGroupId: number | string): GelaCatalog[] {
    return this.getAll({
      filterBy: ({ lngParentGroup, shtDisplayShop }) =>
        lngParentGroup === parentGroupId && (shtDisplayShop === undefined || shtDisplayShop === '1'),
      sortBy: (category1: Catalog, category2: Catalog) => {
        if (category1.lngItemId !== category2.lngItemId) {
          return category1.lngItemId - category2.lngItemId;
        }

        return category2.sTitle.toLowerCase().localeCompare(category1.sTitle.toLowerCase());
      },
    });
  }
}
