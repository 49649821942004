import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClickDirective } from './click-helper/click.directive';
import { ClickOutsideDirective } from './click-outside-element/click-outside-element.directive';
import { FakeSlidesDirective } from './fake-slides/fake-slides.directive';
import { HoverMenuDirective } from './hover-menu/hover-menu.directive';
import { LoadingDirective } from './loading/loading.directive';
import { OwlPreventClickDirective } from './owl-prevent-click/owl-prevent-click.directive';
import { ToggleMenuDirective } from './toggle-menu/toggle-menu.directive';
import { HoverToggleHiddenDirective } from './hover/hover-toggle-hidden.directive';
import { AfterElementDirective } from './after-element/after-element.directive';
import { CompactHeaderDirective } from './compact-header/compact-header.directive';
import { StickyTdDirective } from './sticky-td/sticky-td.directive';
import { InnerHtmlRouterLinkDirective } from './inner-html-router-link/inner-html-router-link.directive';
import { SwiperHelperDirective } from './swiper-helper/swiper-helper.directive';
import { DomChangedDirective } from './watch-dom-tree/watch-dom-tree.directive';

const directives: any[] = [
  OwlPreventClickDirective,
  ClickDirective,
  FakeSlidesDirective,
  LoadingDirective,
  ToggleMenuDirective,
  HoverMenuDirective,
  ClickOutsideDirective,
  HoverToggleHiddenDirective,
  AfterElementDirective,
  CompactHeaderDirective,
  StickyTdDirective,
  InnerHtmlRouterLinkDirective,
  SwiperHelperDirective,
  DomChangedDirective,
];

@NgModule({
  declarations: directives,
  imports: [CommonModule],
  exports: directives,
})
export class DirectivesModule {}
