import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent {
  @Input() checked: boolean = false;
  @Input() disableCheckedStyle: boolean = false;
  @Input() preventToggleOnActive: boolean = false;
  @Input() allowAutomaticCheck: boolean = true;
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('toggleSwitchEl') toggleSwitchEl: ElementRef | undefined;

  toggleSwitch() {
    if (this.preventToggleOnActive && this.checked) {
      return;
    }

    if (this.allowAutomaticCheck) {
      this.checked = !this.checked;
      this.toggleDom(this.toggleSwitchEl?.nativeElement);
    }

    this.checkedChange.emit(this.checked);
  }

  toggleDom(toggleEl: HTMLElement) {
    const opacityHide = 'opacity-0';
    const opacityShow = 'opacity-100';
    const easeOut = 'ease-out';
    const easeIn = 'ease-in';
    const duration100 = 'duration-100';
    const duration200 = 'duration-200';

    const inactiveIconClasslist = toggleEl.querySelector('.js-transition-inactive')!.classList;
    const activeIconClasslist = toggleEl.querySelector('.js-transition-active')!.classList;
    const toggleElClasslist = toggleEl.querySelector('button')!.classList;

    if (toggleElClasslist.contains('js-active')) {
      this.toggleOpacity(inactiveIconClasslist, true, opacityHide, easeOut, duration100);
      this.toggleOpacity(activeIconClasslist, true, opacityShow, easeIn, duration200);
      this.toggleOpacity(inactiveIconClasslist, false, opacityShow, easeIn, duration200);
      this.toggleOpacity(activeIconClasslist, false, opacityHide, easeOut, duration100);
    } else {
      this.toggleOpacity(inactiveIconClasslist, false, opacityHide, easeOut, duration100);
      this.toggleOpacity(activeIconClasslist, false, opacityShow, easeIn, duration200);
      this.toggleOpacity(inactiveIconClasslist, true, opacityShow, easeIn, duration200);
      this.toggleOpacity(activeIconClasslist, true, opacityHide, easeOut, duration100);
    }
  }

  toggleOpacity(
    elClasslist: { remove: (arg0: any) => void; add: (arg0: any) => void },
    removeClass: boolean,
    classOpacity: string,
    classEase: string,
    classDuration: string,
  ) {
    if (removeClass) {
      elClasslist.remove(classOpacity);
      elClasslist.remove(classEase);
      elClasslist.remove(classDuration);
    } else {
      elClasslist.add(classOpacity);
      elClasslist.add(classEase);
      elClasslist.add(classDuration);
    }
  }
}
