import { Pipe, PipeTransform } from '@angular/core';
import { ScannerItem } from '../../features/scanner-adapter/scanner-adapter.component';

@Pipe({
  name: 'scannerDone',
})
export class ScannerDonePipe implements PipeTransform {
  public transform(scannedItems: ScannerItem[] | null): boolean {
    return scannedItems?.length ? scannedItems.every((a) => a.isSuccess || a.hasError) : false;
  }
}
