<ng-container *transloco="let t; scope: 'core'">
  <div class="js-modal-delete js-modal fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gela-black opacity-64 transition-opacity" aria-hidden="true"></div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&ZeroWidthSpace;</span>
      <div
        class="relative inline-block align-bottom text-left overflow-hidden shadow-flyout transform transition-all sm:my-8 sm:align-middle sm:max-w-modal-small sm:w-full"
      >
        <div class="w-full bg-white p-6 border border-primary-light rounded-10px">
          <p class="font-montserrat font-bold text-center">{{ translate ? t(message) : message }}</p>
          <div class="flex space-x-5 justify-center self-start pt-3.5">
            <app-button
              [text]="t('core.modal-message.close')"
              (click)="close()"
              [classes]="'js-close-modal self-start bg-primary-light text-gela-black hover:text-white hover:bg-secondary'"
            ></app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
