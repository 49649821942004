import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HookEvent, HookHandlerAbstract, switchTap, WorkflowCreateHook } from '@lobos/library-v2';
import { Cart, CartHeader, CartItem } from '@lobos/common';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { first, tap } from 'rxjs/operators';

type type = WorkflowCreateHook<'CreateCartItemHook'>;

@Injectable({
  providedIn: 'root',
})
export class CartCreateCartItemHandler extends HookHandlerAbstract<type, Observable<Cart<CartHeader, CartItem>>> {
  constructor(private toastr: ToastrService, private translocoService: TranslocoService) {
    super();
  }

  ids(): type[] {
    return [{ type: 'WorkflowCreateHook', entity: 'CreateCartItemHook' }];
  }

  override after(
    event: HookEvent<type, Observable<Cart<CartHeader, CartItem>>>,
    result: Observable<Cart<CartHeader, CartItem>>,
  ): Observable<Cart<CartHeader, CartItem>> {
    return super.after(event, result).pipe(
      switchTap((data: Cart<CartHeader, CartItem>) => {
        if (data) {
          return this.translocoService.selectTranslate('shared.add-to-cart-success-message').pipe(
            first(),
            tap((message: string) => this.toastr.success(message, '', { timeOut: 2000 })),
          );
        }
        return of(data);
      }),
    );
  }
}
