import { Directive, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';

// watch dom change and fire (dom-changed) event
@Directive({
  selector: '[appWatchDomTree]',
})
export class DomChangedDirective implements OnInit {
  @Output()
  domchange: EventEmitter<any> = new EventEmitter<any>();
  constructor(private elRef: ElementRef) {}

  ngOnInit() {
    this.registerDomChangedEvent(this.elRef.nativeElement);
  }

  registerDomChangedEvent(el: Node) {
    const observer = new MutationObserver((list) => {
      this.domchange.emit(list);
    });
    const attributes = false;
    const childList = true;
    const subtree = false;
    observer.observe(el, { attributes, childList, subtree });
  }
}
