<ng-container *transloco="let t; scope: 'catalog'">
  <ng-container *ngIf="isLoggedIn$ | async">
    <ng-container *ngIf="oAlreadyOrdered$ | async as alreadyOrdered">
      <app-flyout *ngIf="alreadyOrdered.bHasOrdered" [title]="t('catalog.article.already-ordered')" [flyoutPosition]="flyoutPosition">
        <app-link-icon
          *ngIf="!button"
          trigger
          [text]="t('catalog.article.already-ordered')"
          [icon]="'ifont&#45;&#45;order'"
        ></app-link-icon>

        <button *ngIf="button" trigger class="js-show-flyout js-flyout-with-close group">
          <span class="ifont ifont--order text-icon text-primary group-hover:text-gela-black transition-all duration-200"></span>
        </button>
        <app-link-text
          *ngIf="alreadyOrdered.sArticleId"
          flyout
          [routerLink]="'/profile/orders' | localizeUrlPathAsync | async"
          [queryParams]="{ articleId: alreadyOrdered.sArticleId }"
          [text]="t('catalog.article.show-orders')"
        ></app-link-text>
      </app-flyout>
    </ng-container>
  </ng-container>
</ng-container>
