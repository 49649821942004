import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { AuthService } from '@lobos/library-v2';
import { Observable } from 'rxjs';
import { ScannerHelperService } from 'src/app/services/scanner/scanner-helper.service';
import { MenuItem } from '../../../services/cms/interfaces/cms-menu.interface';
import { DomHelperService } from '../../../services/dom/dom-helper.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-service-navigation',
  templateUrl: './service-navigation.component.html',
  styleUrls: ['./service-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceNavigationComponent {
  @Input() menu: MenuItem[] | null | undefined;
  public isLoggedIn: Observable<boolean> = this.authService.isLoggedIn$;
  public currentVersionKey$ = this.translocoService.langChanges$;

  constructor(
    private authService: AuthService,
    @Inject(DOCUMENT) private _document: Document,
    public scannerHelper: ScannerHelperService,
    private translocoService: TranslocoService,
  ) {}

  increaseSearchBar(e: Event) {
    const headerPadding = parseFloat(
      getComputedStyle(this._document.querySelector<HTMLElement>('.js-header-big-wrap')!).getPropertyValue('padding-left'),
    );
    const iconsWidth = parseFloat(getComputedStyle(this._document.querySelector<HTMLElement>('.js-icons')!).getPropertyValue('width'));
    const iconsMargin = parseFloat(
      getComputedStyle(this._document.querySelector<HTMLElement>('.js-icons')!).getPropertyValue('margin-left'),
    );
    const scannerWidth = parseFloat(
      getComputedStyle(this._document.querySelector<HTMLElement>('.js-header-scan')!).getPropertyValue('width'),
    );
    const scannerMargin = parseFloat(
      getComputedStyle(this._document.querySelector<HTMLElement>('.js-header-scan')!).getPropertyValue('margin-right'),
    );
    let gap;

    //add position for search input and scanner on compact header
    this._document.querySelector<HTMLElement>('.js-header-search')!.classList.remove('hidden');
    this._document.querySelector<HTMLElement>('.js-header-search')!.querySelector<HTMLElement>('.input-form')!.classList.remove('hidden');
    this._document.querySelector<HTMLElement>('.js-header-search-compact-show')!.classList.add('hidden');
    this._document.querySelector<HTMLElement>('.js-icons')!.style.marginLeft = '';
    this._document.querySelector<HTMLElement>('.js-header-search')!.querySelector<HTMLElement>('.input-form')!.style.top =
      'calc(50% + 4px)';
    this._document.querySelector<HTMLElement>('.js-header-search')!.querySelector<HTMLElement>('.input-form')!.style.transform =
      'translateY(-50%)';
    this._document
      .querySelector<HTMLElement>('.js-header-search')!
      .querySelector<HTMLElement>('.input-form')!
      .classList.add('js-compact-search');
    this._document.querySelector<HTMLElement>('.js-header-scan')!.style.top = 'calc(50% - 4px)';
    this._document.querySelector<HTMLElement>('.js-header-scan')!.style.transform = 'translateY(-50%)';

    if (window.innerWidth > 1540) {
      gap = window.innerWidth * 0.011 + 2;
    } else {
      gap = 8;
    }

    this._document.querySelector<HTMLElement>('.js-header-big-wrap')!.classList.add('relative');
    this._document
      .querySelector<HTMLElement>('.js-header-search')!
      .querySelector<HTMLElement>('.input-form')!
      .classList.add('absolute', 'z-10', 'active');
    this._document.querySelector<HTMLElement>('.js-header-search')!.classList.remove('relative');
    this._document.querySelector<HTMLElement>('.js-header-search')!.querySelector<HTMLElement>('.input-form')!.classList.remove('relative');
    this._document.querySelector<HTMLElement>('.js-header-search')!.querySelector<HTMLElement>('.input-form')!.style.width = '614px';
    this._document.querySelector<HTMLElement>('.js-header-scan')!.style.position = 'absolute';
    this._document.querySelector<HTMLElement>('.js-header-search')!.querySelector<HTMLElement>('.input-form')!.style.right =
      iconsWidth + headerPadding + scannerWidth + iconsMargin + scannerMargin + gap + 'px';
    this._document.querySelector<HTMLElement>('.js-header-scan')!.style.right =
      iconsWidth + headerPadding + iconsMargin + scannerMargin + 'px';
    this._document
      .querySelector<HTMLElement>('.js-header-search')!
      .querySelector<HTMLElement>('.input-form')!
      .querySelector<HTMLElement>('.js-header-search-input')!
      .focus();

    e.stopPropagation();
  }

  openNav(): void {
    const navEl: Element | null = this._document.querySelector('.js-nav');

    if (navEl?.classList.contains('hidden')) {
      DomHelperService.showContent(navEl);
      DomHelperService.bodyScrollDisable(this._document);
    } else {
      DomHelperService.hideContent(navEl);
      DomHelperService.bodyScrollEnable(this._document);

      DomHelperService.removeActiveStateLink(this._document.querySelector('.js-nav')!);
      this._document.querySelector('.js-nav-list--2')!.classList.add('hidden');
      this._document.querySelector('.js-nav-list--3')!.classList.add('hidden');
    }
  }
}
