<ng-container *transloco="let t; scope: 'header'">
  <div>
    <form appClickOutside (clickOutside)="focusOut()" class="relative input-form">
      <input
        #searchInput
        [formControl]="searchControl"
        (keydown)="focusEvent($any($event))"
        (focusin)="focusIn()"
        [ngClass]="hasFocus ? 'search-field-focus' : ''"
        type="text"
        class="py-9px mobile-nav:py-3.5 pr-12 pl-2.5 mobile-nav:pl-3.5 border-0 bg-primary-bright leading-tight rounded-default focus:ring-1 focus:ring-transparent placeholder:text-gela-black js-header-input js-header-search-input w-full truncate"
        id="text"
        enterkeyhint="search"
        name="q"
        [placeholder]="t('header.search.placeholder')"
        aria-required="false"
        [attr.aria-label]="t('header.search.placeholder')"
        autocomplete="off"
      />
      <button
        *ngIf="hasFocus"
        [disabled]="searchInput.value.length === 0"
        (click)="emptyInput($event)"
        class="js-header-search-submit text-primary absolute -translate-y-0.5 top-2 right-2 leading-none hover:text-primary-dark transition-all duration-200"
      >
        <span class="ifont ifont--close text-2xl mobile-nav:text-3xl pointer-events-none"></span>
      </button>
      <button
        *ngIf="!hasFocus"
        [disabled]="searchInput.value.length === 0"
        (click)="closeSearchDropdown($event)"
        type="submit"
        class="js-header-search-submit text-primary absolute -translate-y-0.5 top-2 right-2 leading-none hover:text-primary-dark transition-all duration-200"
      >
        <span class="ifont ifont--search text-2xl mobile-nav:text-3xl pointer-events-none"></span>
      </button>
    </form>
    <div
      *ngIf="showResults"
      class="fixed z-40 bottom-56px mobile-nav:bottom-inital mobile-search-flyout h-screen left-1/2 mobile-nav:overflow-hidden -translate-x-1/2 mobile-nav:top-148px w-full tablet:w-full js-header-search-wrap block mt-8"
    >
      <div appClickOutside (clickOutside)="onOutsideClick($event)" class="bg-white px-0 mobile-nav:px-5 relative z-10">
        <div
          class="js-header-search-overflow md:max-h-66vh 2xl:max-h-614px scrollbar overflow-y-auto overflow-x-hidden mobile-nav:overflow-hidden relative z-10"
        >
          <div class="w-full mt-0.5 mobile-nav:pb-11 block mobile-nav:flex mobile-nav:justify-center">
            <div
              class="flex justify-between no-search-results:hidden mobile-nav:w-350px 2xl:w-full 2xl:max-w-400px px-5 mobile-nav:pl-0 mobile-nax:pr-6 2xl:pr-12 flex-col pt-5 pb-6 md:pb-0 border-b border-primary last:border-0 md:border-0"
            >
              <div>
                <!-- suggestions start -->
                <div *ngIf="termSuggestions" class="js-search-meta">
                  <ng-container *ngIf="termSuggestions.length">
                    <div class="font-montserrat font-bold">{{ t("header.search.search-terms") }}</div>
                    <div class="flex flex-col last:mb-6">
                      <a
                        *ngFor="let term of termSuggestions"
                        (click)="fillInputField(getCleanSuggestion(term)); closeSearchDropdown($event); searchSuggestion(term)"
                        class="text-black hover:text-primary focus:text-primary focus:outline-primary hover:cursor-pointer"
                        [innerHTML]="term"
                      ></a>
                    </div>
                  </ng-container>
                </div>
                <!-- suggestions end -->
                <!-- categories start -->
                <ng-container *ngIf="categories">
                  <div class="font-montserrat font-bold relative z-10">{{ t("header.search.categories") }}</div>
                  <ng-container *ngIf="categories.length">
                    <div class="js-search-category flex flex-col pb-6">
                      <a
                        (click)="fillInputField(category.sCategory); closeSearchDropdown($event)"
                        focusable
                        *ngFor="let category of categories | slice : 0 : 10"
                        [routerLink]="'' + category.sUrlPath | catalogPath | localizeUrlPath"
                        class="text-black hover:text-primary focus:text-primary focus:outline-primary"
                        >{{ category.sCategory }}</a
                      >
                    </div>
                  </ng-container>
                  <ng-container *ngIf="categories.length <= 0 && isLoading; else noResultsCategories">
                    <div class="font-montserrat font-bold px-5 xl:px-6 first:mt-0 mt-6 relative z-10">{{ t("header.search.loading") }}</div>
                  </ng-container>
                  <ng-template #noResultsCategories>
                    <div *ngIf="categories.length <= 0" class="font-montserrat font-bold px-5 xl:px-6 first:mt-0 mt-6 relative z-10">
                      {{ t("header.search.no-results-categories") }}: «{{ searchInput.value }}»
                    </div>
                  </ng-template>
                </ng-container>
                <!-- categories end -->
              </div>
              <div class="hidden mobile-nav:block w-full pb-6">
                <a
                  (click)="submitSearch(searchControl.value); closeSearchDropdown($event); focusOut()"
                  class="mr-2.5 2xl:mr-5 3xl:mr-10 last:mr-0 bg-primary-light border-2 border-primary-light hover:text-white hover:bg-secondary hover:border-secondary font-montserrat font-bold px-6 py-2.5 self-center rounded-button transition-all duration-200 inline-block hover:cursor-pointer"
                >
                  {{ t(exactMatch ? "header.search.button-exact-match" : "header.search.button") }}
                </a>
              </div>
            </div>
            <!-- articles column start -->
            <div
              class="mobile-nav:w-648px py-5 mobile-nav:pb-0 px-5 w-full mobile-nav:pl-0 xl:pr-7 border-t border-primary mobile-nav:border-t-0"
            >
              <div class="font-montserrat font-bold first:mt-0 mt-6 relative z-10">{{ t("header.search.articles") }}</div>
              <div *ngIf="articles$ | async as articles" class="js-mini-card border-b border-primary last:border-none">
                <div
                  *ngIf="articles.length"
                  class="flex flex-col divide-y divide-primary-light xl:pt-5 -mt-2 xl:-mt-7 2xl:mt-0 relative z-0"
                >
                  <app-search-article-item
                    *ngIf="exactMatch"
                    focusable
                    (keyup.enter)="articleFocusEnterClick($event, articles[0])"
                    [article]="exactMatch"
                    (linkClicked)="articleRouterLinkClicked($event)"
                    class="focus:outline-primary 2xl:first:pt-0 pt-12 2xl:pt-6 pb-6"
                  ></app-search-article-item>
                  <ng-container *ngIf="!exactMatch">
                    <app-search-article-item
                      focusable
                      (keyup.enter)="articleFocusEnterClick($event, article)"
                      *ngFor="let article of articles; trackBy: trackByArticleId"
                      [article]="article"
                      (linkClicked)="articleRouterLinkClicked($event)"
                      class="focus:outline-primary 2xl:first:pt-0 pt-12 2xl:pt-6 pb-6"
                    ></app-search-article-item>
                  </ng-container>
                </div>
                <ng-container *ngIf="articles.length <= 0 && isLoading; else noResultsArticles">
                  <div class="font-montserrat font-bold px-5 xl:px-6 first:mt-0 mt-6 relative z-10">{{ t("header.search.loading") }}</div>
                </ng-container>
                <ng-template #noResultsArticles>
                  <div *ngIf="articles.length <= 0" class="font-montserrat font-bold px-5 xl:px-6 first:mt-0 mt-6 relative z-10">
                    {{ t("header.search.no-results-articles") }}: «{{ searchInput.value }}»
                  </div>
                </ng-template>
              </div>
            </div>
            <!-- articles column end -->
            <!-- products column start -->
            <div
              class="js-header-search-results mobile-nav:w-648px pt-5 pb-16 md:pb-0 px-5 mobile-nav:pr-0 xl:pl-6 border-t border-primary mobile-nav:border-t-0"
            >
              <div class="font-montserrat font-bold first:mt-0 mt-6 relative z-10">{{ t("header.search.products") }}</div>
              <div *ngIf="products$ | async as products" class="js-mini-card border-b border-primary last:border-none">
                <div
                  *ngIf="products.length"
                  class="flex flex-col divide-y divide-primary-light xl:pt-5 -mt-2 xl:-mt-7 2xl:mt-0 relative z-0"
                >
                  <app-search-product-item
                    focusable
                    (keyup.enter)="productFocusEnterClick($event, product)"
                    (linkClicked)="productFocusEnterClick($event, product)"
                    *ngFor="let product of products; trackBy: trackByProductId"
                    [product]="product"
                    class="focus:outline-primary 2xl:first:pt-0 pt-12 2xl:pt-6 pb-6"
                  ></app-search-product-item>
                </div>
                <ng-container *ngIf="products.length <= 0 && isLoading; else noResultsProduct">
                  <div class="font-montserrat font-bold px-5 xl:px-6 first:mt-0 mt-6 relative z-10">{{ t("header.search.loading") }}</div>
                </ng-container>
                <ng-template #noResultsProduct>
                  <div *ngIf="products.length <= 0" class="font-montserrat font-bold px-5 xl:px-6 first:mt-0 mt-6 relative z-10">
                    {{ t("header.search.no-results-products") }}: «{{ searchInput.value }}»
                  </div>
                </ng-template>
              </div>
            </div>
            <!-- products column end -->
          </div>
        </div>
      </div>
      <div class="absolute z-0 inset-0 bg-gela-black opacity-64 transition-opacity h-screen pointer-events-none"></div>
    </div>
  </div>
</ng-container>
