import { QueryEntity } from '@datorama/akita';
import { CmsContentState, CmsStore } from './cms.store';
import { Observable } from 'rxjs';
import { CmsMenu } from './interfaces/menu.interface';
import { Injectable } from '@angular/core';
import { Footer, FooterNav, ServiceNav } from './interfaces/cms-static.interface';

@Injectable({ providedIn: 'root' })
export class CmsQuery extends QueryEntity<CmsContentState> {
  cmsMainMenu$: Observable<CmsMenu[]> = this.select('mainMenu');
  footer$: Observable<Footer[]> = this.select('footer');
  topNav$: Observable<ServiceNav[]> = this.select('topNav');
  footerNav$: Observable<FooterNav[]> = this.select('footerNav');

  constructor(protected override store: CmsStore) {
    super(store);
  }
}
