import { Injectable } from '@angular/core';
import { Configuration, Label, LabelData, PaddingBoxInterface, RendererAbstract, Template } from '@lobos/label-print-v2';
import { GelaConfigurationExtraInterface } from '../model/gela-configuration-extra.interface';
import * as QRCode from 'qrcode';

@Injectable({
  providedIn: 'root',
})
export class Gela3x8QrRenderer extends RendererAbstract<GelaConfigurationExtraInterface> {
  public template(): Template {
    return {
      id: 'defaultQr',
      title: 'profile.label-print.domain.template.suedo3x8_qr.title',
      format: { width: 210, height: 297 },
      boxSizes: { width: 70, height: 36.4 },
      orientation: 'p',
      numberOfBoxes: { horizontal: 3, vertical: 8 },
    };
  }

  public override prepare(labels: Label[], configuration: Configuration<GelaConfigurationExtraInterface>): void {
    this.configuration = configuration;

    this.rawLabels = labels;

    this.labels = [];
    // attach empty offset labels
    this.labels = this.labels.concat(Array(0).fill({ type: 'empty' } as Label));
    // this will repeat the labels as often, as `amount` is set
    this.rawLabels
      .filter((rawLabel: Label) => rawLabel.print && rawLabel.amount > 0)
      .forEach((rawLabel: Label, _: number) => {
        this.labels = this.labels.concat(Array(rawLabel.amount).fill(rawLabel));
      });

    this.jspdf.startDocument(this.orientation, this.format);
  }

  protected async drawData(label: Label, x: number, y: number): Promise<void> {
    const labelData: LabelData = label.data;
    let paddingBox: PaddingBoxInterface;

    const extraPaddingX = 2;

    await this.drawImage(x, y, labelData, extraPaddingX);

    await this.addQuantity(x, y, labelData, extraPaddingX);

    // QR Code
    paddingBox = this.addPadding(
      x + this.boxWidth * (2 / 4),
      y,
      this.boxWidth * (2 / 4),
      this.boxHeight * (3 / 4) - this.jspdf.getTextDimensions(labelData.sku!).h,
    );
    await this.addQrCode(labelData.code, paddingBox.x, paddingBox.y, paddingBox.width, paddingBox.height);

    //logo
    if (this.configuration!.extra?.hideLogo) {
      // Logo
      paddingBox = this.addPadding(
        x + this.boxWidth * (3 / 4),
        y + this.boxHeight * (4 / 5),
        this.boxWidth * (1 / 4),
        this.boxHeight * (1 / 5),
      );
      await this.addImage(labelData.logoPath!, paddingBox.x, paddingBox.y, paddingBox.width, paddingBox.height);
    }

    //article number and unit
    paddingBox = this.addPadding(
      x + this.boxWidth * (this.configuration!.extra?.hideImage ? 1 / 4 : 0) + extraPaddingX,
      y + this.boxHeight * (1 / 6),
      this.boxWidth * (1 / 2),
      this.boxHeight * (1 / 6),
    );
    this.jspdf.drawText(labelData.sku! + ' / ' + labelData.unit, paddingBox.x, paddingBox.y, paddingBox.width, 8, 1, 'bold');

    await this.addDescription(x, y, labelData, extraPaddingX);
  }

  async drawImage(x: number, y: number, labelData: LabelData, extraPaddingX: number = 0) {
    // Image
    if (this.configuration!.extra?.hideImage) {
      const paddingBox = this.addPadding(
        x + extraPaddingX,
        y,
        this.boxWidth * (1 / 4),
        this.boxHeight * (4 / 5) - this.jspdf.getTextDimensions(labelData.date).h,
      );
      await this.addImage(labelData.imgPath!, paddingBox.x, paddingBox.y, paddingBox.width, paddingBox.height);
    }
  }

  async addDescription(x: number, y: number, labelData: LabelData, extraPaddingX: number = 0, fontSize: number = 7) {
    // Description
    const paddingBox = this.addPadding(
      x + extraPaddingX,
      y + this.boxHeight * (3 / 6) + 2.5,
      this.boxWidth - extraPaddingX,
      this.boxHeight * (1 / 6),
    );
    this.jspdf.drawText(labelData.description, paddingBox.x, paddingBox.y, paddingBox.width, fontSize, 2, 'normal');
  }

  async addQuantity(x: number, y: number, labelData: LabelData, extraPaddingX: number = 0, fontSize: number = 6) {
    //quantity
    const paddingBox = this.addPadding(x + extraPaddingX, y + this.boxHeight * (4 / 5), this.boxWidth * (1 / 4), this.boxHeight * (1 / 5));
    this.jspdf.drawText(`Menge: ${labelData.customFields.amount}`, paddingBox.x, paddingBox.y, paddingBox.width, fontSize, 1, 'normal');
  }

  async addQrCode(code: string, x: number, y: number, maxWidth: number, maxHeight: number): Promise<void> {
    if (!code) {
      return;
    }

    await this.jspdf.drawImage(await QRCode.toDataURL(code), x, y, maxWidth, maxHeight, undefined, 'right');
  }
}
