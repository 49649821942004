import { Injectable } from '@angular/core';
import { ErrorHook, ErrorInterface, HookEvent, HookHandlerAbstract, SentryService } from '@lobos/library-v2';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

type type = ErrorHook;

@Injectable({
  providedIn: 'root',
})
export class ErrorSendToSentryHandler extends HookHandlerAbstract<type, Observable<ErrorInterface>> {
  constructor(private sentry: SentryService) {
    super();
  }

  ids(): type[] {
    return [{ type: 'ErrorHook' }];
  }

  override after(event: HookEvent<type, Observable<ErrorInterface>>, result: Observable<ErrorInterface>): Observable<ErrorInterface> {
    return super
      .after(event, result)
      .pipe(tap((error: ErrorInterface) => (error.ignore ? undefined : this.sentry.captureException(error))));
  }
}
