import { Observable } from 'rxjs';
import { SimpleModalService } from 'ngx-simple-modal';
import { first, switchMap } from 'rxjs/operators';
import { Cart } from '@lobos/common';
import {
  ModalOutlineAgreementComponent,
  ModalOutlineAgreementData,
} from '../../../../components/modal-outline-agreement/modal-outline-agreement.component';
import { OutlineAgreementService } from '../../../../services/outline-agreement/outline-agreement.service';
import { CreateGelaCartItemInterface } from '../../../../services/cart/model/create-gela-cart-item.interface';
import { GelaCartItem } from '../../../../services/cart/model/gela-cart-item.model';
import { GelaCartHeader } from '../../../../services/cart/model/gela-cart-header.model';
import { CartService } from '@lobos/library-v2';

export function HandleOutlineAgreement(
  oCartItem: CreateGelaCartItemInterface,
  callable: (params?: any[]) => Observable<Cart<GelaCartHeader, GelaCartItem> | undefined>,
  modal: SimpleModalService,
  cartService: CartService<GelaCartHeader, GelaCartItem, CreateGelaCartItemInterface>,
  outlineAgreementService: OutlineAgreementService,
): Observable<Cart<GelaCartHeader, GelaCartItem> | undefined> {
  return outlineAgreementService.getOpenContractsForArticle(oCartItem.sArticleID).pipe(
    switchMap((outlineAgreements) => {
      if (outlineAgreements?.length > 0 && oCartItem.lngPL1OutlAgreeSalesContractID === undefined) {
        const oModalData: ModalOutlineAgreementData = {
          oCartItem: oCartItem,
          oOutlineAgreementArray: outlineAgreements,
        };
        return modal
          .addModal<ModalOutlineAgreementData, CreateGelaCartItemInterface | GelaCartItem | undefined>(
            ModalOutlineAgreementComponent,
            oModalData,
          )
          .pipe(
            switchMap((cartItem: CreateGelaCartItemInterface | GelaCartItem | undefined) => {
              return cartService.createCartItem({
                ...(cartItem as CreateGelaCartItemInterface),
              });

              /*                return callable([cartItem]);*/
            }),
            /*              first(),*/
          );
      } else {
        return callable([oCartItem]);
      }
    }),
    first(),
  );
}
